import { getDataAndSetOptions, formatCustomGraphicsFilepath, readCSV } from '@/utils'
import { setFilterOptions } from './sharedLoadData'
import { groupForAreaChartRefLine, groupForSmallMultAreaChart } from '@/highchartsFormatters'
import { getMultiYearCharRefNameBase, getMultiYearCharRefNameCat } from '@/chartTitleFormatter'

export default async function characteristicLoadData (state, pageObject, hs) {
  // Step 1: Get data from cache, or retrieve CSV based on selected filters and set dropdown options
  let data = await getDataAndSetOptions(hs, 'All', 'All', setFilterOptions)

  // Initial data filter to determine years w/ missing data
  let filtered = data.filter(d => !isNaN(d[hs.getFilter('unitSelector')]), 'year')
  let spannedyears = [...new Set(filtered.map(f => f.year))]
  let minyear = Math.min(...spannedyears)
  let maxyear = Math.max(...spannedyears)

  // Step 2: Format data for reference line
  let cache = hs.getRequestCache()
  let refData = null
  let refFilepath = null
  let refSeries = null
  let cachedRefFilepath = null
  let estimateCache = cache.estimate // This should always exist since estimate cache is initially set in getDataAndSetOptions
  let refName = null
  let refFilters = [{ column: 'crimeType', value: hs.getFilter('crimeType') }]

  if (hs.getFilter('categoryDesc1') !== 'All' && hs.getFilter('categoryDesc2') !== 'All') {
    refFilters.push(
      { column: 'categoryDesc1', value: 'All' },
      { column: 'levelDesc1', value: 'All' },
      { column: 'categoryDesc2', value: hs.getFilter('categoryDesc2') },
      { column: 'levelDesc2', value: hs.getFilter('levelDesc2') }
    )
    refFilepath = formatCustomGraphicsFilepath(hs, 'all', hs.getFilter('categoryDesc2'))
    refName = getMultiYearCharRefNameCat(hs)
  } else {
    refFilepath = formatCustomGraphicsFilepath(hs, 'all', 'all')
    refName = getMultiYearCharRefNameBase(hs)
  }

  if (cache && cache.hasOwnProperty('reference')) {
    refData = cache.reference.data
    if (refData) {
      refData = refData.filter(d => d.year >= minyear && d.year <= maxyear)
    }
    refSeries = cache.reference.refSeries
    cachedRefFilepath = cache.reference.filepath
  }

  // Only get new ref data when reference filepath changes
  if (refFilepath !== cachedRefFilepath) {
    refData = await readCSV(refFilepath)
    if (refData) {
      refData = refData.filter(d => d.year >= minyear && d.year <= maxyear)
    }
  }

  // Format ref series
  refSeries = groupForAreaChartRefLine(hs, refData, hs.getFilter('unitSelector'), refFilters, refName)

  // Set the request cache. Must set 'estimate' cache too.
  hs.setRequestCache({
    'estimate': estimateCache,
    'reference': {
      'refData': refData,
      'refSeries': refSeries,
      'refFilepath': refFilepath
    }
  })
  // End step 2

  // Step 3A: Get the list of options from the Selection A filter
  let levelDescOptions = hs.getOptionsForFilter('levelDesc1')

  let levelDescOptionsList = []
  levelDescOptions.forEach(option => {
    levelDescOptionsList.push(option.label)
  })

  // function useCrimeColor (cA, cB) {
  //  if (cA === 'All' && cB === 'All') { return true }
  // }

  // Step 3B: Get data once for each set of small multiples for the Characteristic Page
  // For Characteristic Page, we do not filter on levelDesc1
  // Rather those levels of the selected categoryDesc1 are presented as the small multiple headers
  let characteristicSmallMult = groupForSmallMultAreaChart(
    hs,
    data,
    hs.getFilter('unitSelector'),
    [
      { column: 'crimeType', value: hs.getFilter('crimeType') },
      { column: 'categoryDesc1', value: hs.getFilter('categoryDesc1') },
      { column: 'categoryDesc2', value: hs.getFilter('categoryDesc2') },
      { column: 'levelDesc2', value: hs.getFilter('levelDesc2') }
    ],
    {
      column: 'levelDesc1',
      values: levelDescOptionsList
    }
  )
  // reset the maxYear so that is consistent for each of the small mults
  for (const property in characteristicSmallMult) {
    characteristicSmallMult[property].maxYear = maxyear
  }
  // Step 4: Return an object with a key for each chart and a value with each chart's formatted data
  let characteristicObject = {}
  // Add each small multiple object for characteristic page viz to the characteristic page viz object
  let i = 0
  levelDescOptions.forEach(option => {
    let charKeyName = 'charSmallMult' + i
    characteristicObject[charKeyName] = characteristicSmallMult[option.label]
    i += 1
  })
  return characteristicObject
}
