import { getDataAndSetOptions, formatCustomGraphicsFilepath, readCSV } from '@/utils'
import { setFilterOptions, setRefFilters, addMinorRefFilters } from './sharedLoadData'
import { groupForLollipopMinorRefLine, groupForLollipopMajorRefLine, groupForLollipop } from '@/highchartsFormatters'
import { getSingleYearCharMajorRefName, getSingleYearCharMinorRefNameSingleCat, getSingleYearCharMinorRefNameDualCat } from '@/chartTitleFormatter'
export default async function crimeTypeLoadData (state, pageObject, hs) {
  // Step 1: Get data for estimates and set appropriate filter options
  let data = await getDataAndSetOptions(hs, 'All', 'All', setFilterOptions)
  // BEGIN Step 2: Format data for reference line
  let cache = hs.getRequestCache()
  // Minor reference variables
  let minorRefData = null
  let minorRefFilepath = null
  let minorRefSeries = null
  let minorRefName = null
  let cachedMinorRefData = null
  let cachedMinorRefFilepath = null
  let minorRefFilters = setRefFilters(hs, ['crimeType', 'year'])
  // Major reference variables
  let majorRefData = null
  let majorRefSeries = null
  let cachedMajorRefData = null
  let cachedMajorRefFilepath = null
  let majorRefName = getSingleYearCharMajorRefName(hs)
  let majorRefFilepath = formatCustomGraphicsFilepath(hs, 'all', 'all')
  let majorRefFilters = setRefFilters(hs, ['crimeType', 'year'])
  let estimateCache = cache.estimate // This should always exist since estimate cache is initially set in getDataAndSetOptions

  if (hs.getFilter('categoryDesc1') !== 'All' && hs.getFilter('categoryDesc2') !== 'All') {
    minorRefFilters = addMinorRefFilters(hs, minorRefFilters, ['categoryDesc1', 'levelDesc1', 'categoryDesc2'])
    minorRefFilepath = formatCustomGraphicsFilepath(hs, 'all', hs.getFilter('categoryDesc2'))

    if (hs.getFilter('categoryDesc2') !== 'All') {
      minorRefName = getSingleYearCharMinorRefNameDualCat(hs)
    } else {
      minorRefName = getSingleYearCharMinorRefNameSingleCat(hs)
    }
  } else {
    minorRefFilepath = formatCustomGraphicsFilepath(hs, 'all', 'all')
  }

  // Get data and filepath names from cache
  if (cache && cache.hasOwnProperty('reference')) {
    cachedMinorRefData = cache.reference.minorRefData
    cachedMinorRefFilepath = cache.reference.minorRefFilepath
    cachedMajorRefData = cache.reference.majorRefData
    cachedMajorRefFilepath = cache.reference.majorRefFilepath
  }

  // If filepath names don't match, go get new data
  if (minorRefFilepath !== cachedMinorRefFilepath) {
    minorRefData = await readCSV(minorRefFilepath)
  } else {
    minorRefData = cachedMinorRefData
  }

  // Get major ref data if there's none in cache
  if (majorRefFilepath !== cachedMajorRefFilepath) {
    majorRefData = await readCSV(majorRefFilepath)
  } else {
    majorRefData = cachedMajorRefData
  }
  // Format ref series
  minorRefSeries = groupForLollipopMinorRefLine(hs, minorRefData, hs.getFilter('unitSelector'), minorRefFilters, minorRefName)
  majorRefSeries = groupForLollipopMajorRefLine(hs, majorRefData, hs.getFilter('unitSelector'), majorRefFilters, majorRefName)
  // Set the request cache. Must set 'estimate' cache too.
  hs.setRequestCache({
    'estimate': estimateCache,
    'reference': {
      'minorRefData': minorRefData,
      'minorRefFilepath': minorRefFilepath,
      'majorRefData': majorRefData,
      'majorRefFilepath': majorRefFilepath
    }
  })
  // END Step 2
  return {
    Characteristic: groupForLollipop(
      hs,
      data,
      hs.getFilter('unitSelector'),
      [
        { column: 'year', value: hs.getFilter('year') },
        { column: 'crimeType', value: hs.getFilter('crimeType') },
        { column: 'categoryDesc1', value: hs.getFilter('categoryDesc1') },
        { column: 'categoryDesc2', value: hs.getFilter('categoryDesc2') }
      ],
      false,
      minorRefSeries,
      majorRefSeries
    )
  }
}
