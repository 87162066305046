export function setFilterOptions (hs, data) {
  let filterKeys = Object.keys(hs.filters)
  // Set programmatic filter options
  filterKeys.forEach(key => {
    if (hs.filters[key].programmatic) {
      // let options = hs.getDistinctValues(data, filterKey)
      // switched to this to not force alphabetize and use sorted data order
      let options = [...new Set(data.map(d => d[key]))]
      let formattedOptions = []
      let currFilter = hs.getFilter(key)
      options.forEach(option => {
        formattedOptions.push({
          key: option,
          label: option,
          default: currFilter === option
        })
      })
      let defaultOption = formattedOptions.filter(option => option.default === true)[0]
      if (!defaultOption) {
        formattedOptions[0].default = true
      }
      hs.setOptionsForFilter(key, formattedOptions, true)
    }
  })
}
