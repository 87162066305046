import singleYearCrimeType from '@/components/pages/single-year-comparison/crimeType'
import categoryFactory, { categories, demographicFilters } from '../filters/categories'
import allOption from '../filters/all'
import crimeTypes from '../filters/crimeTypes'
// import years from '../filters/years'
import { onOffOptions, demographicOptions, incidentOptions } from '../filters/toggle'
import { lollipopTableAdapter, tableClasses } from '@/highchartsFormatters'
import { components } from '@rtidatascience/harness-ui'
import lollipop from '@/components/charts/highcharts/lollipop'
import crimeTypeLoadData from './crimeTypeLoadData'
import ChartWithTableWrapper from '@/components/chartWithTableWrapper'

export default class singleYearComparisonCrimeType {
  title = 'Single-Year Comparison'
  key = 'Single-Year Comparison Crime Type'
  subtype = 'custom-graphics'
  pageComponent = singleYearCrimeType
  pageProps = {}
  retrieveData = crimeTypeLoadData
  filters = function () {
    return {
      victimizationType: {
        label: 'Crime Category:',
        component: components['HarnessUiRadioGroup'],
        props: {
          synchronous: true, // Remember to call loadData in afterSet or elsewhere
          inline: true
        },
        options: [
          {
            key: 'person',
            label: 'Personal',
            default: true
          },
          {
            key: 'property',
            label: 'Property'
          }
        ],
        afterSet (action, hs) {
          hs.setOptionsForFilter('categoryDesc1', categories[hs.getFilter('victimizationType')], true)
          hs.setOptionsForFilter('categoryDesc2', categories[hs.getFilter('victimizationType')], true)
          hs.setOptionsForFilter('levelDesc2', allOption, true)
          let ctOptions = crimeTypes[hs.getFilter('victimizationType')].reduce((acc, ct) => { ct.default = true; acc.push(ct); return acc }, [])
          hs.setOptionsForFilter('crimeType', ctOptions, true)
          hs.loadData()
        }
      },
      crimeType: {
        label: 'Crime Type: <small><i>(Select Multiple)&nbsp;&nbsp;</i></small><br /><small>(Click to expand/collapse)</small>',
        component: components['HarnessUiCheckboxGroup'],
        options: crimeTypes['person'].reduce((acc, ct) => { ct.default = true; acc.push(ct); return acc }, []),
        props: {
          collapse: true,
          multiple: true
        }
      },
      categoryDesc1: {
        label: 'Comparison Characteristic:',
        component: components['HarnessUiSelect'],
        options: categoryFactory('person'),
        afterSet (action, hs) {
          if (action.payload !== 'All') {
            hs.disableOptions('categoryDesc2', [hs.getFilter('categoryDesc1')])
          } else {
            hs.enableOptions('categoryDesc2', hs.getOptionsForFilter('categoryDesc2').map((x) => x.key))
            if (!demographicFilters.includes(hs.getFilter('categoryDesc2'))) {
              hs.setOptionsForFilter('unitSelector', incidentOptions(), true)
            }
          }

          if (demographicFilters.includes(action.payload)) {
            if (action.payload !== 'All') {
              hs.setOptionsForFilter('unitSelector', demographicOptions(), true)
            }
            if (hs.getFilter('unitSelector') === 'rate' && action.payload !== 'All') {
              hs.enableOptions('referenceLine', [true])
            } else {
              hs.setOptionsForFilter('unitSelector', demographicOptions(), true)
              hs.setFilter('referenceLine', false)
              hs.disableOptions('referenceLine', [true])
            }
          } else {
            hs.setOptionsForFilter('unitSelector', incidentOptions(), true)
            hs.setFilter('referenceLine', false)
            hs.disableOptions('referenceLine', [true])
          }
        }
      },
      unitFlag: {
        label: 'Estimate Flags:',
        component: components['HarnessUiRadioGroup'],
        props: {
          inline: true
        },
        options: onOffOptions('On')
      },
      unitSelector: {
        label: 'Unit <span id="unitdotspot"></span>:',
        component: components['HarnessUiRadioGroup'],
        props: {
          inline: true
          // helperText: 'For demographic characteristic selections, this setting will default to “rate” as the unit, to account for each group’s representation within the population. For incident characteristic selections, this will default to the “percent of crime type” in which these incidents occurred.',
          // helperTextClass: 'text-muted'
        },
        options: demographicOptions(),
        afterSet (action, hs) {
          if (action.payload === 'rate' && hs.getFilter('categoryDesc1') !== 'All' && demographicFilters.includes(hs.getFilter('categoryDesc1'))) {
            hs.enableOptions('referenceLine', [true])
          } else {
            hs.setFilter('referenceLine', false)
            hs.disableOptions('referenceLine', [true])
          }
          // call load data because the reference lines are formatted in highcharts formatted
          hs.loadData()
        }
      },
      confidenceInterval: {
        label: 'Confidence Intervals <span id="cidotspot"></span>:',
        component: components['HarnessUiRadioGroup'],
        props: {
          inline: true
        },
        options: onOffOptions('Off')
      },
      referenceLine: {
        label: 'Reference Lines <span id="reflinedotspot"></span>:',
        component: components['HarnessUiRadioGroup'],
        props: {
          inline: true
          // helperText: 'Reference lines are available for comparisons of demographic rates.',
          // helperTextClass: 'text-muted'
        },
        options: onOffOptions('Off', 'On')
      },

      categoryDesc2: {
        label: 'Filter Characteristic:',
        component: components['HarnessUiSelect'],
        options: categoryFactory('person'),
        afterSet (action, hs) {
          if (action.payload !== 'All') {
            hs.disableOptions('categoryDesc1', [hs.getFilter('categoryDesc2')])
          } else {
            hs.enableOptions('categoryDesc1', hs.getOptionsForFilter('categoryDesc1').map((x) => x.key))
          }
        }
      },
      levelDesc2: {
        label: 'Filter Characteristic Value: <small><i>(select one)</i></small>',
        component: components['HarnessUiSelect'],
        programmatic: true,
        options: allOption
      },
      year: {
        label: 'year:',
        component: components['HarnessUiSelect'],
        programmatic: true,
        options: []
      }
    }
  }

  charts = function () {
    return {
      CrimeType: {
        component: ChartWithTableWrapper,
        props: {
          chartComponent: lollipop,
          card: false,
          buttonPosition: 'bottom',
          tableClass: tableClasses,
          tableAdapter: lollipopTableAdapter
        }
      }
    }
  }
}
