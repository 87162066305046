/* eslint-disable no-useless-escape */
import Highcharts from 'highcharts'
import { smallMultSpanStyle, formatFilename } from './chartTitleFormatter'

function getSvgWidth (svg) {
  return +svg.match(
    /^<svg[^>]*width\s*=\s*\"?(\d+)\"?[^>]*>/
  )[1]
}

function getSvgHeight (svg) {
  return +svg.match(
    /^<svg[^>]*height\s*=\s*\"?(\d+)\"?[^>]*>/
  )[1]
}
function translateSvg (svg, xTranslate, yTranslate) {
  let svgTransform = '<g transform="translate(' + xTranslate + ',' + yTranslate + ')" '
  svg = svg.replace('<svg', svgTransform)
  svg = svg.replace('</svg>', '</g>')
  return svg
}
/**
 * Create a getSVG method that takes an array of charts as an argument and return a callback with the svg.
 * Source: https://jsfiddle.net/gh/get/jquery/1.7.2/highcharts/highcharts/tree/master/samples/highcharts/exporting/multiple-charts/
 */
function getSVG (charts, numRows, options) {
  let title = options.filename
  let subtitleArr = options.subtitleArr
  let paddingLeft = options.chartOptions.paddingLeft
  let paddingRight = options.chartOptions.paddingRight
  let titleRectHeight = 60
  let padding = 60 // padding is used to create space between charts and legend
  // let titleStart = titleRectHeight / 3 + 20
  let x = paddingLeft
  let y = titleRectHeight
  let maxChartX = 0
  let maxChartY = 0
  let makeBigger = charts.length > 3 && numRows === 1

  let chartsInRow = 0
  let chartLength = charts.length > 1 ? charts.length - 1 : charts.length // If it's a small multiple, there will always be an additional "legend chart"
  let maxChartsPerRow = Math.ceil(chartLength / numRows)
  let svgArr = []
  let svgWidth = null
  let svgHeight = null
  for (let i = 0; i < chartLength; i++) {
    chartsInRow += 1
    let mainYaxisMaxToPass = charts[i].yAxis[0].max
    let mainYaxisIntervalToPass = charts[i].yAxis[0].tickInterval
    // this does work and alters PNG yAxis, but highcharts auto-adjust to fit space w/in PNG bounds applied afterwards.
    charts[i].options.yAxis.forEach(d => { d.max = mainYaxisMaxToPass })
    // so, also forcing tick interval
    charts[i].options.yAxis.forEach(d => { d.tickInterval = mainYaxisIntervalToPass })
    let svg = makeBigger
      ? charts[i].getSVG(
        {
          chart: {
            height: 500
          },
          title: {
            y: -430,
            useHTML: true
          }
        })
      : charts[i].getSVG()
    // Fix HTML-to-SVG formatting bug
    svg = svg.replace('<tspan>“95% C.I.”: 95% confidence interval.</tspan>', '<tspan x="0" dy="14">“95% C.I.”: 95% confidence interval.</tspan>')

    svgWidth = getSvgWidth(svg)
    svgHeight = getSvgHeight(svg)
    svg = translateSvg(svg, x, y)
    x += svgWidth
    // If row is full, do pixel math for setting y and x on following next charts
    if (maxChartsPerRow / chartsInRow === 1) {
      y += svgHeight
      maxChartX = Math.max(maxChartX, x) + paddingRight
      x = paddingLeft
      chartsInRow = 0
    }
    // Calculate the "overall" svg pixel height when last chart encountered
    if (chartLength - 1 === i) {
      maxChartY = numRows * svgHeight
    }
    svgArr.push(svg)
  }
  // Create blank background rect. This displays a white background behind small mults w/ unequal charts per row (eg: marital status)
  let svgBackground = '<g><rect x="0" y="0" width="100%" height="100%" fill="white"></rect></g>'

  // Begin title formatting
  let formattedTitle = ''
  let titleSplit = title.split('<br/>')
  formattedTitle = '<tspan x="50%" font-family="Arial, Helvetica, sans-serif" style="color:#33333;font-size:1.6em;font-weight:600;fill:#333333;">' + titleSplit[0] + '</tspan>'
  for (let i = 1; i < titleSplit.length; i++) {
    formattedTitle += titleSplit[i]
  }
  // Replace HTML elements with SVG equivalents
  let styleRe = new RegExp(smallMultSpanStyle, 'g')
  formattedTitle = formattedTitle.replace(/<span/g, '<tspan x="50%" dy="1.2em"').replace(/<\/span>/g, '</tspan>').replace(styleRe, 'style="font-size:1.2em; font-weight:300"')
  let svgTitle = (chartLength > 1 || options.pageType === 'customGraphics') ? '<g><rect x="0" y="0" width="100%" height="' + titleRectHeight + '" fill="white"></rect><text font-family="Arial, Helvetica, sans-serif" x="50%" y="' + 20 + '" dominant-baseline="middle" text-anchor="middle">' + formattedTitle + '</text></g>' : ''
  // End title formatting
  // Begin legend formatting
  let svgLegendHeight = 60
  if (chartLength > 1 || options.pageType === 'customGraphics') {
    let svgLegend = charts[chartLength].getSVG()
    let svgLegendStart = (maxChartX - getSvgWidth(svgLegend)) / 2
    svgLegend = translateSvg(svgLegend, svgLegendStart, maxChartY + svgLegendHeight)
    svgArr.push(svgLegend)
  }
  // End legend formatting

  //   Begin subtitle formatting
  let formattedSubtitle = ''
  if (subtitleArr.length !== 0) {
    subtitleArr.forEach(text => {
      formattedSubtitle += '<tspan font-family="Arial, Helvetica, sans-serif" x="' + paddingLeft + '" dy="1.2em">' + text.replace(/(<([^>]+)>)/ig, '') + '</tspan>'
    })
  }
  let svgSubtitleHeight = subtitleArr.length * 15
  // Replace HTML elements with SVG equivalents
  formattedSubtitle = formattedSubtitle.replace(/<span/g, '<tspan').replace(/<\/span>/g, '</tspan>').replace(/color/g, 'fill').replace(/<br\/>/g, '').replace(/class="small-multiples-subtitle-span"/g, 'dx="30px"')
  let svgSubtitle = '<g><rect x="0" y="' + (maxChartY + padding + svgLegendHeight) + '" width="100%" height="' + svgLegendHeight + '" fill="white"></rect><text x="10" y="' + (maxChartY + padding + svgLegendHeight) + '" text-anchor="start" class="highcharts-subtitle" style="color:#666666;font-size:11px;fill:#666666;">' + formattedSubtitle + '</text></g>'
  //   End subtitle formatting
  //   Combine svg elements and return
  svgArr.unshift(svgBackground)
  svgArr.push([svgTitle, svgSubtitle])
  let fullSvg = '<svg height="' + (maxChartY + padding + svgLegendHeight + svgSubtitleHeight) + '" width="' + maxChartX +
    '" version="1.1" xmlns="http://www.w3.org/2000/svg">' +
    svgArr.join('') + '</svg>'
  return fullSvg
}

/**
 * Create a exportCharts method that takes an array of charts as an argument, and exporting options as the second argument
 * Source: https://jsfiddle.net/gh/get/jquery/1.7.2/highcharts/highcharts/tree/master/samples/highcharts/exporting/multiple-charts/
 */
function exportCharts (charts, options, numRows) {
  options = Highcharts.merge(Highcharts.getOptions().exporting, options)
  let svg = Highcharts.getSVG(charts, numRows, options)
  options.filename = formatFilename(options.filename)
  Highcharts.downloadSVGLocal(
    svg,
    options,
    function () {
      console.warn('Failed to export on client side')
    }
  )
}

export { getSVG, exportCharts }
