<template>
  <div class="container-fluid">
    <div
      class="row"
      id="maincontentrow"
    >
      <router-view />
    </div>
  </div>
</template>
<script>
export default {
  name: 'Interim',
  props: {
    'name': {
      type: String,
      required: true
    }
  },
  methods: {
    flattenChildren (routes) {
      let ret = [...routes]
      routes.forEach(function (route) {
        if (route.children) {
          ret = ret.concat(this.flattenChildren(route.children))
        }
      }.bind(this))
      return ret
    }
  },
  computed: {
    matchedRoute () {
      let name = this.name
      return this.flattenChildren(this.$router.options.routes).filter(route => route.name === name)[0]
    }
  }
}
</script>
