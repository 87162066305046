import singleYearCharacteristic from '@/components/pages/single-year-comparison/characteristic'
import characteristicLoadData from './characteristicLoadData'
import categoryFactory, { categories, demographicFilters } from '../filters/categories'
import crimeTypes from '../filters/crimeTypes'
// import years from '../filters/years'
import { onOffOptions, demographicOptions, incidentOptions } from '../filters/toggle'
import { lollipopTableAdapter, tableClasses } from '@/highchartsFormatters'
import { components } from '@rtidatascience/harness-ui'
import lollipop from '@/components/charts/highcharts/lollipop'
import ChartWithTableWrapper from '@/components/chartWithTableWrapper'

export default class singleYearComparisonCharacteristic {
  title = 'Single-Year Comparison'
  key = 'Single-Year Comparison Characteristic'
  subtype = 'custom-graphics'
  pageComponent = singleYearCharacteristic
  pageProps = {}
  retrieveData = characteristicLoadData

  filters = function () {
    return {
      victimizationType: {
        label: 'Crime Category:',
        component: components['HarnessUiRadioGroup'],
        props: {
          synchronous: true, // Remember to call loadData in afterSet or elsewhere
          inline: true
        },
        options: [
          {
            key: 'person',
            label: 'Personal',
            default: true
          },
          {
            key: 'property',
            label: 'Property'
          }
        ],
        afterSet (action, hs) {
          hs.setOptionsForFilter('crimeType', crimeTypes[hs.getFilter('victimizationType')], true)
          hs.setOptionsForFilter('categoryDesc1', categories[hs.getFilter('victimizationType')], true)
          hs.setOptionsForFilter('categoryDesc2', categories[hs.getFilter('victimizationType')], true)
          hs.loadData()
        }
      },
      unitSelector: {
        label: 'Unit <span id="unitdotspot"></span>:',
        component: components['HarnessUiRadioGroup'],
        props: {
          inline: true
        },
        options: demographicOptions(),
        afterSet (action, hs) {
          if (action.payload === 'rate' && ((demographicFilters.includes(hs.getFilter('categoryDesc1')) && hs.getFilter('categoryDesc1') !== 'All') || (demographicFilters.includes(hs.getFilter('categoryDesc2')) && hs.getFilter('categoryDesc2') !== 'All'))) {
            hs.enableOptions('referenceLine', [true])
          } else {
            hs.setFilter('referenceLine', false)
            hs.disableOptions('referenceLine', [true])
          }
          // call load data because the reference lines are formatted in highcharts formatted
          hs.loadData()
        }
      },
      unitFlag: {
        label: 'Estimate Flags:',
        component: components['HarnessUiRadioGroup'],
        props: {
          inline: true
        },
        options: onOffOptions('On')
      },
      confidenceInterval: {
        label: 'Confidence Intervals <span id="cidotspot"></span>:',
        component: components['HarnessUiRadioGroup'],
        props: {
          inline: true
        },
        options: onOffOptions('Off')
      },
      referenceLine: {
        label: 'Reference Lines <span id="reflinedotspot"></span>:',
        component: components['HarnessUiRadioGroup'],
        props: {
          inline: true
        },
        options: onOffOptions('Off', 'On')
      },
      crimeType: {
        label: 'Crime Type:',
        component: components['HarnessUiSelect'],
        options: crimeTypes['person']
      },
      year: {
        label: 'year:',
        component: components['HarnessUiSelect'],
        programmatic: true,
        options: []
      },
      categoryDesc1: {
        label: 'Comparison Characteristic <b>A</b>:',
        component: components['HarnessUiSelect'],
        options: categoryFactory('person'),
        afterSet (action, hs) {
          if (action.payload !== 'All') {
            hs.disableOptions('categoryDesc2', [hs.getFilter('categoryDesc1')])
          } else {
            hs.enableOptions('categoryDesc2', hs.getOptionsForFilter('categoryDesc2').map((x) => x.key))
            // if the filter for catDesc2 is not included in the demographic filters
            if (!demographicFilters.includes(hs.getFilter('categoryDesc2'))) {
              // this should catch when cat2 is incident char and cat1 is all
              hs.setOptionsForFilter('unitSelector', incidentOptions(), true)
            }
          }
          if (demographicFilters.includes(action.payload)) {
            if (action.payload !== 'All') {
              hs.setOptionsForFilter('unitSelector', demographicOptions(), true)
            }
            if (hs.getFilter('unitSelector') === 'rate' && action.payload !== 'All') {
              hs.enableOptions('referenceLine', [true])
            }
            if (action.payload === 'All' && hs.getFilter('categoryDesc2') === 'All') {
              hs.setOptionsForFilter('unitSelector', demographicOptions(), true)
              hs.setFilter('referenceLine', false)
              hs.disableOptions('referenceLine', [true])
            }
            if (action.payload === 'All' && hs.getFilter('categoryDesc2') !== 'All') {
              // here this will need to set unit based upon setting of char2
              hs.getOptionsForFilter('categoryDesc2')
              if (!demographicFilters.includes(hs.getFilter('categoryDesc2'))) {
                hs.setOptionsForFilter('unitSelector', incidentOptions(), true)
              } else {
                hs.setOptionsForFilter('unitSelector', demographicOptions(), true)
              }
            }
          } else {
            hs.setOptionsForFilter('unitSelector', incidentOptions(), true)
            hs.setFilter('referenceLine', false)
            hs.disableOptions('referenceLine', [true])
          }
        }
      },
      categoryDesc2: {
        label: 'Comparison Characteristic <b>B</b>:',
        component: components['HarnessUiSelect'],
        options: categoryFactory('person'),
        afterSet (action, hs) {
          if (action.payload !== 'All') {
            hs.disableOptions('categoryDesc1', [hs.getFilter('categoryDesc2')])
            if (hs.getFilter('categoryDesc1') === 'All') {
              if (!demographicFilters.includes(action.payload)) {
                hs.setOptionsForFilter('unitSelector', incidentOptions(), true)
              } else {
                hs.setOptionsForFilter('unitSelector', demographicOptions(), true)
              }
            }
          } else {
            hs.enableOptions('categoryDesc1', hs.getOptionsForFilter('categoryDesc1').map((x) => x.key))
            if (demographicFilters.includes(hs.getFilter('categoryDesc1'))) {
              hs.setOptionsForFilter('unitSelector', demographicOptions(), true)
            }
          }

          if ((demographicFilters.includes(action.payload) && action.payload !== 'All') || (demographicFilters.includes(hs.getFilter('categoryDesc1')) && hs.getFilter('categoryDesc1') !== 'All')) {
            if (hs.getFilter('unitSelector') === 'rate') {
              hs.enableOptions('referenceLine', [true])
            }
          } else {
            hs.setFilter('referenceLine', false)
            hs.disableOptions('referenceLine', [true])
          }
        }
      }
    }
  }

  charts = function () {
    return {
      Characteristic: {
        component: ChartWithTableWrapper,
        props: {
          chartComponent: lollipop,
          card: false,
          buttonPosition: 'bottom',
          tableAdapter: lollipopTableAdapter,
          tableClass: tableClasses
        }
      }
    }
  }
}
