export default {
  // HTML styling must exist inline so that it passes to svg export.
  confidenceInterval: '“95% C.I.”: 95% confidence interval.',
  dataFilterCrimePgWyear: 'Filter data by characteristic and characteristic value, by year(s). Use “Clear Filters” to reset.<br/><i>To see results across values rather than results for a particular value, go to the characteristic comparison view.</i>',
  dataFilterCharPgWyear: 'Filter data by crime type and year(s). Use “Clear Filters” to reset.<br/><i>To see results <u>across</u> crime types rather than results for a particular crime type, go to the crime type comparison view.<i>',
  dataFilterCharPgTrends: 'Filter data by characteristic, characteristic value, and crime type. Use “Clear Filters” to reset.<br/><i>To see results <u>across</u> crime types rather than results for a particular crime type, go to the crime type comparison view.</i>',
  dataFilterCrimePg: 'Filter data by characteristic and characteristic value. Use “Clear Filters” to reset.<br/><i>To see results <u>across</u> values rather than results for a particular value, go to the characteristic comparison view.</i>',
  // displaySettingsCrimePg: 'Choose the crime category and type, comparison characteristic, and chart features. Use “Clear Filters” to reset.<br/><i>To compare across characteristics rather than crime types, see the characteristic comparison view.</i>',
  displaySettingsCrimePgTrends: 'Choose the crime category and chart features. Use “Clear Filters” to reset.<br/><i>To compare across characteristics rather than crime types, see the characteristic comparison view.</i>',
  displaySettingsCrimePg: 'Choose the crime category and type(s), characteristic, and chart features. Use “Clear Filters” to reset.<br/><i>To compare across characteristics rather than crime types, see the characteristic comparison view.</i>',
  displaySettingsCharPg: 'Choose the crime category, comparison characteristics, and chart features. Use “Clear Filters” to reset.<br/><i>To compare across crime types rather than characteristics, see the crime type comparison view.</i>',
  referenceLines: 'Reference lines are available for comparisons of demographic rates.',
  unit: 'When demographic characteristics are chosen, will default to “Rate”. Results will show victimization rate for demographic group(s). When incident characteristics are chosen, will default to “Percent.” Results will show percentages for the selected crime type.'
}
