import { readCSV } from '@/utils'
import { groupForAreaChart, groupForSmallMultAreaChart, groupForLollipop } from '@/highchartsFormatters'

export default async function quickGraphicsLoadData (state, pageObject, hs) {
  // Step 1: Get data from cache, or retrieve CSV and put it in cache
  let data = await readCSV('/data/quick-graphics/quick_graphics')
  // Set request cache once since quick graphics has no filters and the data will never change.
  // In the page component, we check for the requestCache before running loadData (e.g: loadData runs once)
  hs.setRequestCache({ 'estimate': true })

  // Step 2: Apply filters from harness to the data
  // no filters on this page so far

  // Step 3: Get data once for each set of small multiples
  let violentCrimeTypeSmallMult = groupForSmallMultAreaChart(
    hs,
    data,
    'rate',
    [
      { column: 'categoryDesc1', value: 'All' },
      { column: 'levelDesc1', value: 'All' },
      { column: 'categoryDesc2', value: 'All' },
      { column: 'levelDesc2', value: 'All' }
    ],
    {
      column: 'crimeType',
      values: ['Rape/sexual assault', 'Robbery', 'Aggravated assault', 'Simple assault']
    }
  )
  let violentWeaponCategoryLevelSmallMult = groupForSmallMultAreaChart(
    hs,
    data,
    'percent',
    [
      { column: 'crimeType', value: 'Violent victimization' },
      { column: 'categoryDesc1', value: 'Weapon category' },
      { column: 'categoryDesc2', value: 'All' },
      { column: 'levelDesc2', value: 'All' }
    ],
    {
      column: 'levelDesc1',
      values: ['No weapon', 'Firearm', 'Knife', 'Other weapon type', 'Unknown weapon type', "Don't know if offender had weapon"]
    }
  )
  let violentRaceHispanicOriginSmallMult = groupForSmallMultAreaChart(
    hs,
    data,
    'rate',
    [
      { column: 'crimeType', value: 'Violent victimization' },
      { column: 'categoryDesc1', value: 'Race/Hispanic origin2' },
      { column: 'categoryDesc2', value: 'All' },
      { column: 'levelDesc2', value: 'All' }
    ],
    {
      column: 'levelDesc1',
      values: ['White', 'Black', 'Hispanic', 'Asian/Native Hawaiian or Other Pacific Islander', 'American Indian or Alaska Native and persons of two or more races']
    }
  )
  let householdCrimeTypeSmallMult = groupForSmallMultAreaChart(
    hs,
    data,
    'rate',
    [
      { column: 'victimizationType', value: 'Property' },
      { column: 'categoryDesc1', value: 'All' },
      { column: 'levelDesc1', value: 'All' },
      { column: 'categoryDesc2', value: 'All' },
      { column: 'levelDesc2', value: 'All' }
    ],
    {
      column: 'crimeType',
      values: ['Burglary', 'Trespassing', 'Motor vehicle theft', 'Other theft']
    }
  )
  let personVictimServicesCrimeTypeSmallMult = groupForSmallMultAreaChart(
    hs,
    data,
    'percent',
    [
      { column: 'victimizationType', value: 'Person' },
      { column: 'categoryDesc1', value: 'Victim services use' },
      { column: 'levelDesc1', value: 'Yes' },
      { column: 'categoryDesc2', value: 'All' },
      { column: 'levelDesc2', value: 'All' }
    ],
    {
      column: 'crimeType',
      values: ['Rape/sexual assault', 'Robbery', 'Aggravated assault', 'Simple assault']
    }
  )
  let personViolentReportPoliceSmallMult = groupForSmallMultAreaChart(
    hs,
    data,
    'percent',
    [
      { column: 'victimizationType', value: 'Person' },
      { column: 'crimeType', value: 'Violent victimization' },
      { column: 'categoryDesc1', value: 'Reporting to the police' },
      { column: 'categoryDesc2', value: 'All' },
      { column: 'levelDesc2', value: 'All' }
    ],
    {
      column: 'levelDesc1',
      values: ['Yes', 'No', 'Don\'t know']
    }
  )
  let householdPropertyReportPoliceSmallMult = groupForSmallMultAreaChart(
    hs,
    data,
    'percent',
    [
      { column: 'victimizationType', value: 'Property' },
      { column: 'crimeType', value: 'Property victimization' },
      { column: 'categoryDesc1', value: 'Reporting to the police' },
      { column: 'categoryDesc2', value: 'All' },
      { column: 'levelDesc2', value: 'All' }
    ],
    {
      column: 'levelDesc1',
      values: ['Yes', 'No', 'Don\'t know']
    }
  )

  // step 4: Return an object with a key for each chart and a value with each chart's formatted data
  let retObj = {
    ViolentVictimization1994To2015: groupForAreaChart(
      hs,
      data,
      'rate',
      [
        { column: 'crimeType', value: 'Violent victimization' },
        { column: 'categoryDesc1', value: 'All' },
        { column: 'levelDesc1', value: 'All' },
        { column: 'categoryDesc2', value: 'All' },
        { column: 'levelDesc2', value: 'All' }
      ]
    ),
    HouseholdPropertyVictimization1994to2015: groupForAreaChart(
      hs,
      data,
      'rate',
      [
        { column: 'victimizationType', value: 'Property' },
        { column: 'crimeType', value: 'Property victimization' },
        { column: 'categoryDesc1', value: 'All' },
        { column: 'levelDesc1', value: 'All' },
        { column: 'categoryDesc2', value: 'All' },
        { column: 'levelDesc2', value: 'All' }
      ]
    ),
    ViolentVictimizationByVictimOffenderRelationship2018: groupForLollipop(
      hs,
      data,
      'percent',
      [
        { column: 'year', value: hs.getMax(data, 'year') },
        { column: 'crimeType', value: 'Violent victimization' },
        { column: 'categoryDesc1', value: 'Victim-offender relationship' },
        { column: 'categoryDesc2', value: 'All' }
      ],
      false
    ),
    //  new additions on Reporting to Police reasons
    // person crimes reasons for
    ViolentVictimizationByReasonforReportingToPolice2018: groupForLollipop(
      hs,
      data,
      'percent',
      [
        { column: 'year', value: hs.getMax(data, 'year') },
        { column: 'crimeType', value: 'Violent victimization' },
        // { column: 'categoryDesc1', value: 'Reporting to the police' },
        { column: 'levelDesc1', value: 'Yes' },
        { column: 'categoryDesc2', value: 'Reason for reporting to police' }
      ],
      false
    ),
    ViolentCrimeExcludingSimpleAssaultByReasonforReportingToPolice2018: groupForLollipop(
      hs,
      data,
      'percent',
      [
        { column: 'year', value: hs.getMax(data, 'year') },
        { column: 'crimeType', value: 'Violent crime excluding simple assault' },
        // { column: 'categoryDesc1', value: 'Reporting to the police' },
        { column: 'levelDesc1', value: 'Yes' },
        { column: 'categoryDesc2', value: 'Reason for reporting to police' }
      ],
      false
    ),
    SimpleAssaultByReasonforReportingToPolice2018: groupForLollipop(
      hs,
      data,
      'percent',
      [
        { column: 'year', value: hs.getMax(data, 'year') },
        { column: 'crimeType', value: 'Simple assault' },
        // { column: 'categoryDesc1', value: 'Reporting to the police' },
        { column: 'levelDesc1', value: 'Yes' },
        { column: 'categoryDesc2', value: 'Reason for reporting to police' }
      ],
      false
    ),
    // person crimes reasons for NOT
    ViolentVictimizationByReasonforNotReportingToPolice2018: groupForLollipop(
      hs,
      data,
      'percent',
      [
        { column: 'year', value: hs.getMax(data, 'year') },
        { column: 'crimeType', value: 'Violent victimization' },
        // { column: 'categoryDesc1', value: 'Reporting to the police' },
        { column: 'levelDesc1', value: 'No' },
        { column: 'categoryDesc2', value: 'Reason for not reporting to police' }
      ],
      false
    ),
    ViolentCrimeExcludingSimpleAssaultByReasonforNotReportingToPolice2018: groupForLollipop(
      hs,
      data,
      'percent',
      [
        { column: 'year', value: hs.getMax(data, 'year') },
        { column: 'crimeType', value: 'Violent crime excluding simple assault' },
        // { column: 'categoryDesc1', value: 'Reporting to the police' },
        { column: 'levelDesc1', value: 'No' },
        { column: 'categoryDesc2', value: 'Reason for not reporting to police' }
      ],
      false
    ),
    SimpleAssaultByReasonforNotReportingToPolice2018: groupForLollipop(
      hs,
      data,
      'percent',
      [
        { column: 'year', value: hs.getMax(data, 'year') },
        { column: 'crimeType', value: 'Simple assault' },
        // { column: 'categoryDesc1', value: 'Reporting to the police' },
        { column: 'levelDesc1', value: 'No' },
        { column: 'categoryDesc2', value: 'Reason for not reporting to police' }
      ],
      false
    ),
    PropertyByReasonforReportingToPolice2018: groupForLollipop(
      hs,
      data,
      'percent',
      [
        { column: 'year', value: hs.getMax(data, 'year') },
        { column: 'crimeType', value: 'Property victimization' },
        // { column: 'categoryDesc1', value: 'Reporting to the police' },
        { column: 'levelDesc1', value: 'Yes' },
        { column: 'categoryDesc2', value: 'Reason for reporting to police' }
      ],
      false
    ),
    PropertyByReasonforNotReportingToPolice2018: groupForLollipop(
      hs,
      data,
      'percent',
      [
        { column: 'year', value: hs.getMax(data, 'year') },
        { column: 'crimeType', value: 'Property victimization' },
        // { column: 'categoryDesc1', value: 'Reporting to the police' },
        { column: 'levelDesc1', value: 'No' },
        { column: 'categoryDesc2', value: 'Reason for not reporting to police' }
      ],
      false
    ),

    // Chunk data to individual small multiple charts
    RapeAndSexualAssault1994To2015: violentCrimeTypeSmallMult['Rape/sexual assault'],
    Robbery1994To2015: violentCrimeTypeSmallMult['Robbery'],
    AggravatedAssault1994To2015: violentCrimeTypeSmallMult['Aggravated assault'],
    SimpleAssault1994To2015: violentCrimeTypeSmallMult['Simple assault'],
    ViolentWeaponUnknownIfOffenderHadWeapon1994To2015: violentWeaponCategoryLevelSmallMult["Don't know if offender had weapon"],
    ViolentWeaponFirearm1994To2015: violentWeaponCategoryLevelSmallMult['Firearm'],
    ViolentWeaponKnife1994To2015: violentWeaponCategoryLevelSmallMult['Knife'],
    ViolentWeaponNoWeapon1994To2015: violentWeaponCategoryLevelSmallMult['No weapon'],
    ViolentWeaponOtherWeaponType1994To2015: violentWeaponCategoryLevelSmallMult['Other weapon type'],
    ViolentWeaponUnknownWeaponType1994To2015: violentWeaponCategoryLevelSmallMult['Unknown weapon type'],
    ViolentRaceHispanicOriginWhite2003To2015: violentRaceHispanicOriginSmallMult['White'],
    ViolentRaceHispanicOriginBlack2003To2015: violentRaceHispanicOriginSmallMult['Black'],
    ViolentRaceHispanicOriginHispanic2003To2015: violentRaceHispanicOriginSmallMult['Hispanic'],
    ViolentRaceHispanicOriginAsian2003To2015: violentRaceHispanicOriginSmallMult['Asian/Native Hawaiian or Other Pacific Islander'],
    ViolentRaceHispanicOriginOther2003To2015: violentRaceHispanicOriginSmallMult['American Indian or Alaska Native and persons of two or more races'],
    HouseholdBurglaryVictimization1994to2015: householdCrimeTypeSmallMult['Burglary'],
    HouseholdTrespassingVictimization1994to2015: householdCrimeTypeSmallMult['Trespassing'],
    HouseholdMotorVehicleTheftVictimization1994to2015: householdCrimeTypeSmallMult['Motor vehicle theft'],
    HouseholdTheftVictimization1994to2015: householdCrimeTypeSmallMult['Other theft'],
    PersonVictimServicesSimpleAssault1994To2015: personVictimServicesCrimeTypeSmallMult['Simple assault'],
    PersonVictimServicesAggravatedAssault1994To2015: personVictimServicesCrimeTypeSmallMult['Aggravated assault'],
    PersonVictimServicesRobbery1994To2015: personVictimServicesCrimeTypeSmallMult['Robbery'],
    PersonVictimServicesRapeAndSexualAssault1994To2015: personVictimServicesCrimeTypeSmallMult['Rape/sexual assault'],
    PersonViolentReportPoliceYes1994to2015: personViolentReportPoliceSmallMult['Yes'],
    PersonViolentReportPoliceNo1994to2015: personViolentReportPoliceSmallMult['No'],
    PersonViolentReportPoliceDontKnow1994to2015: personViolentReportPoliceSmallMult['Don\'t know'],
    HouseholdPropertyReportPoliceYes1994to2015: householdPropertyReportPoliceSmallMult['Yes'],
    HouseholdPropertyReportPoliceNo1994to2015: householdPropertyReportPoliceSmallMult['No'],
    HouseholdPropertyReportPoliceDontKnow1994to2015: householdPropertyReportPoliceSmallMult['Don\'t know']
  }
  return retObj
}
