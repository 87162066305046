<template>
  <div class="container-fluid cgpg multiyr crime">
    <div
      v-if="this.getRequestCache()"
    >
      <!-- Crime Type View -->
      <div
        class="row mb-5"
        id="filterbankrow"
      >
        <div class="container">
          <div class="row">
            <div class="col col-12 mt-4">
              <h1 class="sg">
                Custom Graphics: Multi-Year Trends: Crime Type
              </h1>
            </div>
          </div>
          <div class="row filterbank pt-3 pb-4">
            <div class="col col-12 col-md-5 pr-md-4 viewsettings">
              <h6 class="mb-3">
                Display Settings
                <InfoDot
                  :tooltip-text="helpertext.displaySettingsCrimePgTrends"
                  :id="'displaydot'"
                />
              </h6>
              <filterGrid
                :only="['victimizationType', 'unitSelector', 'confidenceInterval' ].concat(this.checkForUnitFlags)"
                :label-position="'horizontal'"
                :clear-button="false"
              />
            </div>
            <div class="col col-12 col-md-7 pl-md-4 pt-4 pt-md-0 filtersettings">
              <h6 class="mb-3">
                Data Filter Settings
                <InfoDot
                  :tooltip-text="helpertext.dataFilterCrimePg"
                  :id="'filterdot'"
                />
              </h6>
              <div class="row">
                <div class="col-6">
                  <filterGrid
                    :only="['categoryDesc1', 'levelDesc1']"
                    :label-position="'vertical'"
                    :columns="1"
                    :clear-button="false"
                  />
                </div>
                <div class="col-6">
                  <filterGrid
                    :only="['categoryDesc2', 'levelDesc2']"
                    :label-position="'vertical'"
                    :columns="1"
                    :clear-button="false"
                  />
                </div>
              </div>
            </div>
            <clearButton />
            <div class="col-12 mt-3">
              <small class="statsignote pt-2">BJS did not test whether estimate differences in the N-DASH are statistically significant; therefore, please do not draw inferences when comparing. <a
                href="/terms#ci_description"
                target="/terms"
              >Confidence intervals</a> and <a
                href="/terms#se_description"
                target="/terms"
              >standard errors</a> are two measures of the accuracy of an estimate. For more information, see <a
                href="/methodology#methodology"
                target="/methodology"
              >User’s Guide</a>. The dashboard is best viewed with Google Chrome or Mozilla Firefox. Though the dashboard has been optimized for viewing on computers and mobile devices, one’s device size may affect the display of graphics.
              </small>
            </div>
          </div>
        </div>
      </div>
      <div
        class="row"
        id="allchartsrow"
      >
        <div
          v-if="hs.getFilter('victimizationType') === 'person'"
          class="container"
        >
          <chartHolder
            :chart-ids="['ViolentVictimization1994To2015']"
            :force-table="isMobileView"
          />
          <smallMultiplesHolder
            :title="this.smallMultATitle"
            :chart-ids="['RapeAndSexualAssault1994To2015', 'Robbery1994To2015', 'AggravatedAssault1994To2015', 'SimpleAssault1994To2015']"
            :user-instruction="this.getSmallMultUserInstruct"
          />
          <smallMultiplesHolder
            :title="this.smallMultBTitle"
            :chart-ids="['SimpleAssault1994To2015_b', 'ViolentExcludeSimpleAssault1994To2015']"
            :user-instruction="this.getSmallMultUserInstruct"
          />
          <chartHolder
            :chart-ids="['PersonalTheftLarcency1994To2015']"
            :force-table="isMobileView"
            id="larcenyholder"
          />
          <StatNote />
        </div>
        <div
          v-if="hs.getFilter('victimizationType') === 'property'"
          class="container"
        >
          <chartHolder
            :chart-ids="['PropertyVictimization1994To2015']"
            :force-table="isMobileView"
          />
          <smallMultiplesHolder
            :title="this.smallMultCTitle"
            :chart-ids="['MotorVehicleTheft1994To2015', 'Burglary1994To2015', 'Trespassing1994To2015', 'Theft1994To2015']"
            :user-instruction="this.getSmallMultUserInstruct"
          />
          <StatNote />
        </div>
      </div>
    </div>
    <loadModal />
    <InfoDot
      :tooltip-text="helpertext.unit"
      :id="'unitdot'"
    />
    <InfoDot
      :tooltip-text="helpertext.confidenceInterval"
      :id="'cidot'"
    />
  </div>
</template>
<script>
import chartHolder from '../../chartHolder'
import smallMultiplesHolder from '../../smallMultiplesHolder'
import { getSmallMultCrimeTypeTitle, getSmallMultUserInstruct } from '@/chartTitleFormatter'
import loadModal from '../../loadModal'
import clearButton from '../../clearButton'
import StatNote from '../../statNote'
import { unitFlagMixin } from '../mixins'
import helpertext from '@/helpertext'
import InfoDot from '../../infoDot'
import $ from 'jquery'
import { mapGetters } from 'vuex'
let cicontents = ''
let refcontents = ''
let unitcontents = ''
export default {
  name: 'MultiYearTrendsCrimeType',
  mixins: [unitFlagMixin],
  components: {
    chartHolder,
    smallMultiplesHolder,
    loadModal,
    clearButton,
    StatNote,
    InfoDot
  },
  data () {
    return {
      helpertext
    }
  },
  beforeRouteEnter (to, from, next) {
    next(vm => {
      if (!vm.getRequestCache()) {
        vm.loadData()
      }
    })
  },
  computed: {
    ...mapGetters([
      'isMobileView'
    ]),
    getSmallMultUserInstruct () {
      return getSmallMultUserInstruct(this)
    },
    smallMultATitle () {
      return getSmallMultCrimeTypeTitle(this, 'RapeAndSexualAssault1994To2015')
    },
    smallMultBTitle () {
      return getSmallMultCrimeTypeTitle(this, 'SimpleAssault1994To2015_b')
    },
    smallMultCTitle () {
      return getSmallMultCrimeTypeTitle(this, 'MotorVehicleTheft1994To2015')
    }
  },
  beforeUpdate () {
    cicontents = $('#cidot')
    refcontents = $('#reflinedot')
    unitcontents = $('#unitdot')
    $('#unitdot').appendTo('#unitdotspot')
    $('#reflinedot').appendTo('#reflinedotspot')
    $('#cidot').appendTo('#cidotspot')
  },
  updated () {
    $(cicontents).appendTo('#cidotspot')
    $(refcontents).appendTo('#reflinedotspot')
    $(unitcontents).appendTo('#unitdotspot')
  }
}
</script>
