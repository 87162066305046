<template>
  <div id="app">
    <div id="abovenav" />
    <div
      id="scrollspyer"
      data-spy="scroll"
      data-target="#uptotop"
      data-offset="-300"
    >
      <bjsBanner
        id="bjsBanner"
      />

      <nav class="navbar navbar-lightvers navbar-expand-lg gov-nav d-flex align-items-end">
        <div class="container">
          <button
            class="navbar-toggler"
            type="button"
            data-toggle="collapse"
            data-target="#navbarNavDropdown"
            aria-controls="navbarNavDropdown"
            aria-expanded="false"
            aria-label="Toggle navigation"
          >
            <span class="navbar-toggler-icon" />
          </button>
          <div
            class="collapse navbar-collapse"
            id="navbarNavDropdown"
            style="padding: 0;"
          >
            <ul class="navbar-nav d-lg-flex align-items-lg-end">
              <li class="nav-item active pr-0 pb-0">
                <router-link
                  class="nav-link navbar-link"
                  :to="{'name': 'Home'}"
                >
                  <svg
                    xmlns="http://www.w3.org/2000/svg"
                    xmlns:xlink="http://www.w3.org/1999/xlink"
                    viewBox="0 0 24 24"
                    version="1.1"
                    x="0px"
                    y="0px"
                    title="Home Icon"
                  ><title>icon/homepage-alt-solid</title><desc>Created with Sketch.</desc><g stroke="none"><path d="M12,1.0048 L3,10.0048 L3,21.0048 L10,21.0048 L10,16.0048 C10,14.9008 10.896,14.0048 12,14.0048 C13.104,14.0048 14,14.9008 14,16.0048 L14,21.0048 L21,21.0048 L21,10.0048 L12,1.0048 Z" /></g></svg>
                  N-DASH Home
                </router-link>
              </li>
              <!--
              <li class="nav-item pr-3 pb-0">
                <router-link
                  class="nav-link navbar-link"
                  :to="{name: 'quickGraphics', hash: '#quickgraphicstop'}"
                >
                  Quick Graphics
                </router-link>
              </li>
-->
            </ul>
            <ul class="navbar-nav d-flex ml-auto">
              <li class="nav-item pr-3 pb-0">
                <router-link
                  class="nav-link navbar-link"
                  :to="{name: 'ToolOverview', hash: '#overviewTop'}"
                >
                  Tool Overview
                </router-link>
              </li>
              <li class="nav-item pr-3 pb-0">
                <router-link
                  class="nav-link navbar-link"
                  :to="{name: 'quickGraphics', hash: '#quickgraphicstop'}"
                >
                  Quick Graphics
                </router-link>
              </li>
              <li class="nav-item dropdown pr-3 pb-0">
                <a
                  href=""
                  :class="'nav-link dropdown-toggle navbar-link ' + activeClass"
                  id="navbarDropdownMenuLink"
                  role="button"
                  data-toggle="dropdown"
                  aria-haspopup="true"
                  aria-expanded="false"
                >
                  Custom Graphics
                </a>
                <div
                  class="dropdown-menu nested-menu"
                  aria-labelledby="navbarDropdownMenuLink"
                >
                  <router-link
                    class="nav-link navbar-link"
                    :to="{name: 'customGraphics', hash: '#customgraphicstop'}"
                  >
                    Custom Graphics Home
                  </router-link>

                  <ul class="px-3">
                    <div class="dropdown-divider" />
                    <!-- Multi-Year Trends -->
                    <h6 class="dropdown-header">
                      Multi-Year Trends:
                    </h6>

                    <li>
                      <router-link
                        class="nav-link navbar-link"
                        :to="{name: 'Multi-Year Trends Crime Type'}"
                      >
                        Crime Type
                      </router-link>
                    </li>
                    <li>
                      <router-link
                        class="nav-link navbar-link"
                        :to="{name: 'Multi-Year Trends Characteristic'}"
                      >
                        Characteristic
                      </router-link>
                    </li>

                    <div class="dropdown-divider" />

                    <!-- Single-Year Comparison -->
                    <h6 class="dropdown-header">
                      Single-Year Comparison:
                    </h6>

                    <li>
                      <router-link
                        class="nav-link navbar-link"
                        :to="{name: 'Single-Year Comparison Crime Type'}"
                      >
                        Crime Type
                      </router-link>
                    </li>
                    <li>
                      <router-link
                        class="nav-link navbar-link"
                        :to="{name: 'Single-Year Comparison Characteristic'}"
                      >
                        Characteristic
                      </router-link>
                    </li>

                    <div class="dropdown-divider" />

                    <!-- Year-To-Year Comparison -->
                    <h6 class="dropdown-header">
                      Year-To-Year Comparison:
                    </h6>

                    <li>
                      <router-link
                        class="nav-link navbar-link"
                        :to="{name: 'Year-to-Year Comparison Crime Type'}"
                      >
                        Crime Type
                      </router-link>
                    </li>
                    <li>
                      <router-link
                        class="nav-link navbar-link"
                        :to="{name: 'Year-to-Year Comparison Characteristic'}"
                      >
                        Characteristic
                      </router-link>
                    </li>
                  </ul>
                </div>
              </li>

              <li class="nav-item pr-3 pb-0">
                <router-link
                  class="nav-link navbar-link"
                  :to="{name: 'User’s Guide', hash: '#methodology'}"
                >
                  User's Guide
                </router-link>
              </li>
              <li class="nav-item pr-3 pb-0">
                <router-link
                  class="nav-link navbar-link"
                  :to="{name: 'Terms and Definitions', hash: '#terms'}"
                >
                  Terms & Definitions
                </router-link>
              </li>
              <li class="nav-item pr-0 pb-0">
                <a
                  href="https://www.bjs.ojp.gov/data-collection/ncvs"
                  class="nav-link navbar-link"
                  target="_blank"
                >
                  NCVS Page <small><i class="fas fa-external-link-alt" /></small>
                </a>
              </li>
            </ul>
          </div>
        </div>
        <div />
      </nav>

      <homeTop
        v-if="$route.name === 'Home'"
        :id="'hometop' + $route.name"
      />
      <router-view :key="$route.name" />
    </div>
    <bjsFooter
      id="bjsFooter"
    />
  </div>
</template>
<script>
import { mapGetters, mapActions } from 'vuex'
import homeTop from './components/hometop'
import bjsBanner from './components/bjsBanner'
import bjsFooter from './components/bjsFooter'

export default {
  name: 'App',
  components: { homeTop, bjsBanner, bjsFooter },
  computed: {
    ...mapGetters([
      'isMobileView'
    ]),
    activeClass () {
      let matches = ['customGraphics', 'Multi-Year Trends', 'Year-to-Year Comparison', 'Single-Year Comparison']
      let matched = this.$route.matched.find(r => matches.includes(r.name))
      if (!matched) {
        return null
      }
      return 'router-link-active'
    },
    routeName () {
      if (this.$route.name === 'Terms and Definitions') {
        return 'Terms & Definitions'
      }
      return this.$route.name
    }
  },
  methods: {
    ...mapActions([
      'setIsMobileView'
    ]),
    isMobileWidth () {
      return window.innerWidth <= 768
    }
  },
  mounted () {
    this.setIsMobileView(this.isMobileWidth())
    window.addEventListener('resize', () => {
      let currentMobileWidth = this.isMobileWidth()
      if (currentMobileWidth && !this.isMobileView) {
        this.setIsMobileView(true)
      }
      if (!currentMobileWidth && this.isMobileView) {
        this.setIsMobileView(false)
      }
    })
  }
}
</script>
