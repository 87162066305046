<template>
  <div class="chartWithTableWrapperDiv">
    <ChartWithTable
      v-if="!forceTable"
      v-bind="{...$attrs, ...$props, 'rowHeaderCol': true}"
      :downloadable="false"
      ref="chartWithTable"
      @view="view = $event"
    >
      <template v-slot:title>
        <span
          class="chartWithTableWrapperTitle"
          v-html="tableTitle"
        />
      </template>
      <template v-slot:additional-buttons>
        <button
          type="button"
          :aria-label="'Download Table: ' + title"
          class="btn btn-sm btn-primary"
          @click="downloadCustomCSV()"
        >
          Download Table
        </button>
        <button
          type="button"
          :aria-label="'Download PNG: ' + title"
          class="btn btn-sm btn-primary"
          @click="downloadImage"
        >
          Download PNG/image
        </button>
      </template>
    </ChartWithTable>
    <div
      v-if="forceTable"
      class="mobile-view-table"
    >
      <div
        class="chartWithTableWrapperTitle"
        v-html="tableTitle"
      />
      <DataTable508
        v-bind="{chart, ...chart.props, ...$attrs}"
      />
      <div class="controls">
        <button
          type="button"
          :aria-label="'Download Table: ' + title"
          class="btn btn-sm btn-primary"
          @click="downloadCustomCSV()"
        >
          Download Table
        </button>
      </div>
    </div>
  </div>
</template>
<script>
import Highcharts from 'highcharts'
import text from '@/text'
import { saveAs } from 'file-saver'
import getCrimeTypeTitle, { formatFilename, addChartNote } from '@/chartTitleFormatter'

export default {
  name: 'ChartWithTableWrapper',
  props: {
    chart: {
      type: Object,
      required: true,
      validator: function (value) {
        return value.hasOwnProperty('key')
      }
    },
    title: {
      type: String,
      required: false,
      default: ''
    },
    subtitle: {
      type: Object,
      required: false,
      default: () => text.subtitle
    },
    forceTable: {
      type: Boolean,
      required: false,
      default: false
    }
  },
  data () {
    return {
      view: null
    }
  },
  computed: {
    localTitle () {
      if (this.$route.name === 'Multi-Year Trends Crime Type') {
        return getCrimeTypeTitle(this, this.chart.key)
      }
      let title = this.title || (this.getChartData(this.chart.key).title || '')
      if (title.length > 0 && !title.match(/(Rate|Percent|Number)/)) {
        title = `Rate of ${title}`
      }
      return title
    },
    tableTitle () {
      const chartData = this.getChartData(this.chart.key)

      if (chartData && (this.forceTable || this.view === 'table')) {
        let whitelist = ['quickGraphics', 'Multi-Year Trends Crime Type']
        return whitelist.includes(this.$route.name) ? getCrimeTypeTitle(this, this.chart.key) : (chartData.title || '')
      }
      return null
    }
  },
  methods: {
    downloadCustomCSV: function () {
      const url = window.location.origin
      let years = this.getChartData(this.chart.key).minYear === this.getChartData(this.chart.key).maxYear ? this.getChartData(this.chart.key).minYear : `${this.getChartData(this.chart.key).minYear}-${this.getChartData(this.chart.key).maxYear}`
      let subtitles = Object.keys(this.subtitle).reduce((acc, subtitleKey) => {
        let subtitleFields = this.subtitle[subtitleKey]
        let s = subtitleFields
        if (subtitleKey === 'unitFlag') {
          subtitleFields = [s[0], s[1] + s[2] + s[3] + s[4]] // Join 4 elements of the array so all are 1 string in the CSV
        }
        subtitleFields.forEach(datum => {
          datum = datum.replace(/<span style="color:#ce4646;">▲/g, '1')
          datum = datum.replace(/<span style="color:#ce4646;">✱/g, '2')
          datum = datum.replace(/(<[/]*span\s*[\w=>":;•▲*-]*)*([“”])*/gm, '')
          datum = datum.replace(/† /g, '')
          datum = datum.replace(/’/g, '\'') // Replace apostrophes with single quote for Excel
          if (datum.includes('Terms')) {
            let match = datum.match(/">\s*(.*?)\s*<\/a>/)[1]
            datum = datum.replace(/<a.*<\/a>/g, match)
          } else if (datum.includes('Source:')) {
            datum += ', ' + years
          } else {
            let matches = datum.match(/href="(.*)"\s/)
            if (matches && matches.length > 1) {
              datum = datum.replace(/<a.*<\/a>/g, matches[1])
              datum = datum.replace(' /', ' ' + url + '/')
            }
          }
          if (addChartNote(subtitleKey, [this.chart.key], this)) {
            acc.push(datum)
          }
        })
        return acc
      }, [])
      let subtitlesWithCommas = subtitles.map(subtitle => {
        return '"' + subtitle + '"'
      })
      subtitlesWithCommas = subtitlesWithCommas.join('\n')
      let csv = this.generateCSV(this.chart.key, 'string')
      const firstLine = '"' + formatFilename(this.localTitle) + '"'
      let downloadableCSV = firstLine + '\n' + csv + '\n' + subtitlesWithCommas + '. ' + window.location.href.split('#')[0]
      let csvBlob = new Blob([downloadableCSV], { type: 'data:text/csv;charset=utf-8' })
      saveAs(csvBlob, (formatFilename(this.localTitle) + '.csv'))
    },
    downloadImage: function (options) {
      // can only pass left and right padding
      let chartOptions = {
        paddingLeft: 80,
        paddingRight: 80
      }
      const chart = this.$refs['chartWithTable'].$children[0].$children[0].chart
      options = { filename: this.localTitle, subtitleArr: [], chartOptions: chartOptions, ...options }
      Highcharts.exportCharts([chart], options, 1) // highchartsExportFunctions.js
    }
  }
}
</script>

<style lang="scss" scoped>
.mobile-view-table {
  .chartWithTableWrapperTitle {
    color: #333;
    text-align: center;
    margin-bottom: .7em;
    line-height: 1.2em;
  }

  .controls {
    float: right;
  }
}
</style>
