<template>
  <div
    id="loading-modal"
    v-if="loadModalLogic()"
  >
    <div class="modal-backdrop fade show" />
    <div
      class="modal fade show"
      id="exampleModal"
      tabindex="-1"
      role="dialog"
      aria-labelledby="exampleModalLabel"
      aria-hidden="true"
      style="display:block;"
    >
      <div
        class="modal-dialog modal-dialog-centered"
        role="document"
      >
        <div class="modal-content">
          <div class="modal-body text-center">
            <h3>Loading...</h3>
            <div
              class="spinner-border"
              role="status"
            >
              <span class="sr-only">Loading...</span>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>
<script>
export default {
  name: 'LoadModal',
  methods: {
    loadModalLogic: function () {
      if (this.page.key.includes('Multi-Year Trends')) {
        return this.dataLoading
      } else {
        return !this.getRequestCache()
      }
    }
  }
}
</script>
