import { getDataAndSetOptions } from '@/utils'
import { setFilterOptions } from './sharedLoadData'
import { groupForAreaChart, groupForSmallMultAreaChart } from '@/highchartsFormatters'
export default async function crimeTypeLoadData (state, pageObject, hs) {
  // Step 1: Get data from cache, or retrieve CSV based on selected filters and set dropdown options
  let data = await getDataAndSetOptions(hs, 'Sex', 'Age', setFilterOptions)

  // Step 2: Get data once for each set of small multiples
  let personViolentSmallMult = hs.getFilter('victimizationType') === 'person' ? groupForSmallMultAreaChart(
    hs,
    data,
    hs.getFilter('unitSelector'),
    [
      { column: 'categoryDesc1', value: hs.getFilter('categoryDesc1') },
      { column: 'levelDesc1', value: hs.getFilter('levelDesc1') },
      { column: 'categoryDesc2', value: hs.getFilter('categoryDesc2') },
      { column: 'levelDesc2', value: hs.getFilter('levelDesc2') }
    ],
    {
      column: 'crimeType',
      values: ['Simple assault', 'Aggravated assault', 'Robbery', 'Rape/sexual assault', 'Violent crime excluding simple assault']
    }
  ) : null

  let householdPropertySmallMult = hs.getFilter('victimizationType') === 'property' ? groupForSmallMultAreaChart(
    hs,
    data,
    hs.getFilter('unitSelector'),
    [
      { column: 'categoryDesc1', value: hs.getFilter('categoryDesc1') },
      { column: 'levelDesc1', value: hs.getFilter('levelDesc1') },
      { column: 'categoryDesc2', value: hs.getFilter('categoryDesc2') },
      { column: 'levelDesc2', value: hs.getFilter('levelDesc2') }
    ],
    {
      column: 'crimeType',
      values: ['Burglary', 'Trespassing', 'Motor vehicle theft', 'Other theft']
    }
  ) : null

  // Step 3: Return an object with a key for each chart and a value with each chart's formatted data
  // Begin person charts
  let personVizObject = hs.getFilter('victimizationType') === 'person' ? {
    // Overall Violent Crime
    ViolentVictimization1994To2015: groupForAreaChart(
      hs,
      data,
      hs.getFilter('unitSelector'),
      [
        { column: 'crimeType', value: 'Violent victimization' },
        { column: 'categoryDesc1', value: hs.getFilter('categoryDesc1') },
        { column: 'levelDesc1', value: hs.getFilter('levelDesc1') },
        { column: 'categoryDesc2', value: hs.getFilter('categoryDesc2') },
        { column: 'levelDesc2', value: hs.getFilter('levelDesc2') }
      ],
      true
    ),
    // Violent Crime by Crime Type - Small Multiples
    SimpleAssault1994To2015: personViolentSmallMult['Simple assault'],
    SimpleAssault1994To2015_b: personViolentSmallMult['Simple assault'],
    AggravatedAssault1994To2015: personViolentSmallMult['Aggravated assault'],
    Robbery1994To2015: personViolentSmallMult['Robbery'],
    RapeAndSexualAssault1994To2015: personViolentSmallMult['Rape/sexual assault'],
    ViolentExcludeSimpleAssault1994To2015: personViolentSmallMult['Violent crime excluding simple assault'],
    // Personal Theft and Larceny
    PersonalTheftLarcency1994To2015: groupForAreaChart(
      hs,
      data,
      hs.getFilter('unitSelector'),
      [
        { column: 'crimeType', value: 'Personal theft/Larceny' },
        { column: 'categoryDesc1', value: hs.getFilter('categoryDesc1') },
        { column: 'levelDesc1', value: hs.getFilter('levelDesc1') },
        { column: 'categoryDesc2', value: hs.getFilter('categoryDesc2') },
        { column: 'levelDesc2', value: hs.getFilter('levelDesc2') }
      ],
      true
    )
  } : null
  // End person charts
  // Begin household charts
  let householdVizObject = hs.getFilter('victimizationType') === 'property' ? {
    PropertyVictimization1994To2015: groupForAreaChart(
      hs,
      data,
      hs.getFilter('unitSelector'),
      [
        { column: 'crimeType', value: 'Property victimization' },
        { column: 'categoryDesc1', value: hs.getFilter('categoryDesc1') },
        { column: 'levelDesc1', value: hs.getFilter('levelDesc1') },
        { column: 'categoryDesc2', value: hs.getFilter('categoryDesc2') },
        { column: 'levelDesc2', value: hs.getFilter('levelDesc2') }
      ],
      true
    ),
    MotorVehicleTheft1994To2015: householdPropertySmallMult['Motor vehicle theft'],
    Burglary1994To2015: householdPropertySmallMult['Burglary'],
    Trespassing1994To2015: householdPropertySmallMult['Trespassing'],
    Theft1994To2015: householdPropertySmallMult['Other theft']
  } : null
  // End household charts

  return personVizObject || householdVizObject
}
