export default function seriesColor (row, options = {}) {
  let crimeTypeColor = crimeTypeColorLookup[row['victimizationType']][row['crimeTypeNum']]
  if (typeof crimeTypeColor === 'undefined') {
    throw Error(`crimeTypeColorLookup does not have an entry for victimizationType:${row['victimizationType']} crimeTypeNum:${row['crimeTypeNum']}`)
  }
  let cat1Color = catColorLookup[row['catNum1']][row['levNum1']]
  let cat2Color = catColorLookup[row['catNum2']][row['levNum2']]
  if (options.crimeType) {
    return crimeTypeColor
  }

  if (options.Characteristic) {
    return cat1Color
  }

  if (options.Characteristic2) {
    return cat2Color
  }

  if (cat2Color !== '#406a89') {
    return cat2Color
  } else if (cat1Color !== '#406a89') {
    return cat1Color
  }

  return crimeTypeColor
}

export const catColorLookup = {
  1: { // all
    1: '#406a89'
  },
  2: { // sex
    1: '#4c8964', // male
    2: '#553079' // female
  },
  3: { // race ('Race/Hispanic origin')
    1: '#8c649b',
    2: '#406a89',
    3: '#889333',
    4: '#cc7e3b'
  },
  4: { // age
    1: '#fad25a', // 12 to 14
    2: '#f3b25d', // 15 to 17
    3: '#e9925f', // 18 to 20
    4: '#da745f', // 21 to 24
    5: '#c7575f', // 25 to 34
    6: '#af3c5d', // 35 to 49
    7: '#91285b', // 50 to 64
    8: '#682159' // 65 or older
  },
  // for household size- overlapping catNum needs to be resolved
  // ['#f7be7f', '#c08c72', '#885a66', '#512859']
  5: { // income
    1: '#caf6ce',
    2: '#a9dab2',
    3: '#8abe97',
    4: '#6ba37d',
    5: '#4c8964',
    6: '#2c6f4b',
    7: '#005734',
    8: '#c3c3c3'
  },
  6: { // population
    1: '#d0d772',
    2: '#a5bf93',
    3: '#7aa7b4',
    4: '#5885b0',
    5: '#3f5a89',
    6: '#272f62'
  },
  6.1: { // Household size
    1: '#f3b25d',
    2: '#c5805f',
    3: '#97515e',
    4: '#682159'
  },
  7: { // region
    1: '#566f98',
    2: '#924384',
    3: '#6ba37d',
    4: '#9a471cf7'
  },
  8: { // marital status
    1: '#edafdc',
    2: '#c68ab7',
    3: '#a16692',
    4: '#7d4470',
    5: '#5b234f'
  },
  9: { // reporting to police
    1: '#840047', // Yes, reported to the police
    2: '#add0c6', // No, did not report to police
    3: '#c3c3c3' // Do not know
  },
  10: { // weapon category
    1: '#add0c6',
    2: '#840047',
    3: '#566f98',
    4: '#cc7e3b',
    5: '#c7c7c7',
    6: '#9a9a9a'
  },
  11: { // medial trt
    1: '#add0c6',
    2: '#709fb7',
    3: '#840047'
  },
  12: { // victim services use
    1: '#840047',
    2: '#add0c6'
  },
  13: { // victim-offender
    1: '#144f64',
    2: '#439e94',
    3: '#e2a678',
    4: '#89a844',
    5: '#c7c7c7',
    6: '#9a9a9a'
  },
  14: { // recoded race ('Race/Hispanic origin2')
    1: '#8c649b',
    2: '#406a89',
    3: '#889333',
    4: '#840047',
    5: '#cc7e3b'
  },
  15: { // test for reasons for reporting yes
    1: '#8c649b',
    2: '#406a89',
    3: '#889333',
    4: '#840047',
    5: '#cc7e3b',
    6: '#cc7e3b',
    7: '#cc7e3b'
  },
  16: { // test for reasons for reporting no
    1: '#8c649b',
    2: '#406a89',
    3: '#889333',
    4: '#840047',
    5: '#cc7e3b',
    6: '#cc7e3b',
    7: '#cc7e3b'
  },
  17: { // test repeated for new MSA
    1: '#682159', // princ city
    2: '#c5805f', // outside princ city
    3: '#6ba37d' // outside MSA
  },
  18: { // test repeated for new injury
    1: '#840047', // yes injured
    2: '#add0c6' // no
  },
  19: { // test for new location of incident
    1: '#cc7e3b', // near victim's home
    2: '#b2df8a', // near other's home
    3: '#8a89a6', // commercial, etc
    4: '#144f64', // school
    5: '#e4ba88' // other
  }
}

export const crimeTypeColorLookup = {
  'Person': {
    1: '#406a89', // Violent
    2: '#89a844',
    3: '#439e94',
    4: '#af3c5d',
    5: '#cc7e3b',
    6: '#7d4470',
    7: '#fad25a'
  },
  'Property': {
    8: '#406a89',
    9.1: '#af3c5d',
    9.2: '#fad25a',
    10: '#439e94',
    11: '#cc7e3b'
  }
}
