<template>
  <div class="row chartholder small-multiples">
    <div class="col col-12">
      <h5 class="text-center col-12 col-md-10 offset-md-1">
        <span v-html="formattedTitle" />
      </h5>
      <div class="card-body smallMult harness-ui-chartwithtable">
        <notEligible
          v-if="chartIds[0] && this.getChartData(chartIds[0]) === false || (chartIds[0] && this.getChartData(chartIds[0]) && this.getChartData(chartIds[0])['giveAlert'] && (this.getChartData(chartIds[0])['giveAlert'] === true))"
        />
        <!--        v-show only when notEligible false breaks mult-yr pg -->
        <chartGrid
          v-show="view === 'charts' && chartIds[0] && this.getChartData(chartIds[0]) !== false && this.getChartData(chartIds[0]) && this.getChartData(chartIds[0])['giveAlert'] === false"
          :only="chartIds"
          :columns="columns ? columns : assignNumberColumns"
          :spread="false"
          ref="smallMultChartGrid"
        />
        <div
          class="row"
          v-if="view === 'tables'"
        >
          <div class="col-12 d-flex">
            <div
              class="harness-ui-datatable"
              tableclass="table-bordered table-striped table-hover"
            >
              <table
                class="harness-ui-table table table-bordered table-striped table-hover"
                v-if="chartData"
              >
                <thead>
                  <tr>
                    <th
                      v-for="(columnHeader, idx) in columnHeaders"
                      scope="col"
                      :key="idx"
                    >
                      {{ columnHeader }}
                    </th>
                  </tr>
                </thead>
                <tbody>
                  <tr
                    v-for="(row, rowIndex) in chartData"
                    :key="rowIndex"
                  >
                    <template v-for="(value, key) in row">
                      <th
                        v-if="key === 'Year'"
                        scope="row"
                        :key="key"
                      >
                        {{ value }}
                      </th>
                      <td
                        v-else
                        :key="key"
                      >
                        {{ value }}
                      </td>
                    </template>
                  </tr>
                </tbody>
              </table>
            </div>
          </div>
        </div>
        <!-- Small Multiple Legend -->
        <div
          class="row d-flex justify-content-center"
          v-show="this.getChartData(chartIds[0]) && this.getChartData(chartIds[0])['giveAlert'] === false"
        >
          <smallMultLegend
            v-show="view === 'charts' && this.getChartData(chartIds[0]) && this.getChartData(chartIds[0])['giveAlert'] === false"
            :chart-ids="this.chartIds"
            ref="smallMultLegend"
          />
        </div>

        <div class="row">
          <small
            class="col-12 pt-3"
            v-show="this.getChartData(chartIds[0]) && this.getChartData(chartIds[0])['giveAlert'] === false"
          ><span v-html="formattedSubtitle" /></small>
          <small
            v-html="userInstruction"
            class="col-12 pt-3 pb-3"
          />
        </div>
      </div>
      <div
        class="row"
        v-show="this.getChartData(chartIds[0]) && this.getChartData(chartIds[0])['giveAlert'] === false"
      >
        <div
          id="button-container"
          class="col-md-12 text-right"
        >
          <button
            role="button"
            :aria-label="view == 'chart' ? 'Show Tables' + chartIds : 'Show Charts' + chartIds"
            @click="toggleView"
            class="btn btn-sm btn-primary"
          >
            {{ view == 'charts' ? 'Show Table' : 'Show Charts' }}
          </button>
          <button
            role="button"
            :aria-label="'Download Table: ' + chartIds"
            @click="downloadMultipleCSVs"
            class="btn btn-sm btn-primary"
          >
            Download Table
          </button>
          <button
            role="button"
            :aria-label="'Download PNG: ' + chartIds"
            @click="downloadImage"
            class="btn btn-sm btn-primary"
          >
            Download PNG/image
          </button>
        </div>
      </div>
    </div>
  </div>
</template>
<script>
import Highcharts from 'highcharts'
import smallMultLegend from './charts/highcharts/smallMultLegend'
import text from '@/text'
import assignNumberColumns from '../assignColumns'
import notEligible from './charts/notEligible'
import { saveAs } from 'file-saver'
import { formatSubtitle, formatMinAndMaxYearsFromChart, formatFilename, addChartNote } from '../chartTitleFormatter'

export default {
  name: 'SmallMultiples',
  mixins: [assignNumberColumns],
  props: {
    'title': {
      type: String,
      required: true
    },
    'chartIds': {
      type: Array,
      required: true
    },
    'columns': {
      type: Number,
      required: false,
      default: null
    },
    'subtitle': {
      type: Object,
      required: false,
      default: () => text.subtitle
    },
    'userInstruction': {
      type: String,
      required: false,
      default: ''
    }
  },
  components: { notEligible, smallMultLegend },
  data () {
    return {
      view: 'charts'
    }
  },
  computed: {
    columnHeaders () {
      return this.chartData.length ? Object.keys(this.chartData[0]) : []
    },
    flagType () {
      let estType = ''
      this.chartIds.forEach(chartKey => {
        let chartData = this.getChartData(chartKey) || null
        if (chartData) {
          try {
            estType = chartData['estimateType']
          } catch (error) {
            throw String('There was an error in the formatted text from your tableAdapter function: ' + String(error))
          }
        }
      })
      return estType
    },
    formattedCSVappender () {
      if (this.page.key === 'Multi-Year Trends Crime Type' && this.title.includes('by crime type') && this.chartIds.toString().includes('Exclude')) {
        return ', segmented by simple vs. non_simple assault'
      } else {
        return ''
      }
    },
    formattedTitle () {
      if (this.page.key === 'quickGraphics' && this.chartIds.length > 0) {
        return this.title + formatMinAndMaxYearsFromChart(this, this.chartIds[0])
      } else {
        return this.title
      }
    },
    formattedSubtitle () {
      return formatSubtitle(this, true)
    },
    chartData () {
      let concatenatedChartData = []
      this.chartIds.forEach(chartKey => {
        let chartData = this.getChartData(chartKey) || null
        let tableAdapter = this.getChartProps(chartKey).tableAdapter || null
        if (chartData) {
          if (tableAdapter) {
            try {
              let reversedChartData = tableAdapter(this.getChartObject(chartKey), {}, chartData)
              let validatedChartData = this.validatedChartData(reversedChartData, chartKey)
              validatedChartData.forEach(datum => {
                concatenatedChartData.push(datum)
              })
            } catch (error) {
              throw String('There was an error in the formatted text from your tableAdapter function: ' + String(error))
            }
          }
        }
      })
      let nonZeroNumberFlags = concatenatedChartData.filter(row => row['Number Flag'] !== 0)
      let estimateTypeFlag = this.flagType === 'Count' ? 'Number Flag' : `${this.flagType} Flag`
      let nonZeroEstimateFlags = estimateTypeFlag !== 'Number Flag' ? concatenatedChartData.filter(row => row[estimateTypeFlag]) : []
      if (nonZeroNumberFlags.length === 0 || nonZeroEstimateFlags.length === 0) {
        concatenatedChartData.forEach(row => {
          if (nonZeroNumberFlags.length === 0) {
            delete row['Number Flag']
          }
          if (nonZeroEstimateFlags.length === 0) {
            delete row[estimateTypeFlag]
          }
        })
      }
      return [...concatenatedChartData, ...this.getRefLineData(Object.keys(concatenatedChartData[0]))]
    }
  },
  methods: {
    toggleView: function () {
      switch (this.view) {
        case 'charts':
          this.view = 'tables'
          break
        case 'tables':
          this.view = 'charts'
          break
      }
      this.$emit('view', this.view)
    },
    downloadMultipleCSVs: function () {
      const url = window.location.origin
      let csvList = []
      let years = this.getChartData(this.chartIds[0]).minYear === this.getChartData(this.chartIds[0]).maxYear ? this.getChartData(this.chartIds[0]).minYear : `${this.getChartData(this.chartIds[0]).minYear}-${this.getChartData(this.chartIds[0]).maxYear}`
      let headers = ''
      this.chartIds.forEach((chartId, index) => {
        let csv = this.generateCSV(chartId, 'string')
        csv = csv.split('\n')
        if (index === 0) {
          headers = csv[0]
          csv.shift()
          // csv = csv.reverse()
          csv = headers + '\n' + csv.join('\n')
        } else if (index !== 0) {
          csv.shift()
          csv = csv.join('\n')
        }
        csvList.push(csv)
      })
      let refLineData = this.getRefLineData(headers.split(','))
      if (refLineData.length) {
        let csv = this.getCSVFromObject(refLineData)
        csv = csv.split('\n')
        csv.shift()
        csvList.push(csv.join('\n'))
      }
      let subtitles = Object.keys(this.subtitle).reduce((acc, subtitleKey) => {
        let subtitleFields = this.subtitle[subtitleKey]
        let s = subtitleFields
        if (subtitleKey === 'unitFlag') {
          subtitleFields = [s[0], s[1] + s[2] + s[3] + s[4]] // Join 4 elements of the array so all are 1 string in the CSV
        }
        subtitleFields.forEach(datum => {
          datum = datum.replace(/<span style="color:#ce4646;">▲/g, '1')
          datum = datum.replace(/<span style="color:#ce4646;">✱/g, '2')
          datum = datum.replace(/(<[/]*span\s*[\w=>":;•▲*-]*)*([“”])*/gm, '')
          datum = datum.replace(/† /g, '')
          datum = datum.replace(/’/g, '\'') // Replace apostrophes with single quote for Excel
          if (datum.includes('Terms')) {
            let match = datum.match(/">\s*(.*?)\s*<\/a>/)[1]
            datum = datum.replace(/<a.*<\/a>/g, match)
          } else if (datum.includes('Source:')) {
            datum += ', ' + years
          } else {
            let matches = datum.match(/href="(.*)"\s/)
            if (matches && matches.length > 1) {
              datum = datum.replace(/<a.*<\/a>/g, matches[1])
              datum = datum.replace(' /', ' ' + url + '/')
            }
          }
          if (addChartNote(subtitleKey, this.chartIds, this)) {
            acc.push(datum)
          }
        })
        return acc
      }, [])
      let subtitlesWithCommas = subtitles.map(subtitle => {
        return '"' + subtitle + '"'
      })
      subtitlesWithCommas = subtitlesWithCommas.join('\n')
      const firstLine = '"' + formatFilename(this.formattedTitle) + '"'
      let downloadableCSV = firstLine + '\n'
      downloadableCSV += csvList.join('\n')
      downloadableCSV += '\n' + subtitlesWithCommas + '. ' + window.location.href.split('#')[0]
      let csvBlob = new Blob([downloadableCSV], { type: 'data:text/csv;charset=utf-8' })
      saveAs(csvBlob, (formatFilename(this.formattedTitle) + this.formattedCSVappender + '.csv'))
    },
    downloadImage: function (options) {
      // can only pass left and right padding
      let chartOptions = {
        paddingLeft: 80,
        paddingRight: 80
      }
      options = {
        pageType: this.pageKey === 'quickGraphics' ? 'quickGraphics' : 'customGraphics',
        filename: this.formattedTitle,
        subtitleArr: this.formattedSubtitle.split('<br/>'),
        chartOptions: chartOptions,
        ...options }
      let charts = []
      this.$refs['smallMultChartGrid'].$children.forEach(function (childElem) {
        charts.push(childElem.$children[0].chart)
      })
      this.$refs['smallMultLegend'].$children.forEach(function (childElem) {
        charts.push(childElem.chart)
      })
      let numRows = this.$refs['smallMultChartGrid'].chartRows.length
      Highcharts.exportCharts(charts, options, numRows)
    },
    validatedChartData (chartData, chartKey) {
      return this.validateChartData(chartData, chartKey)
    },
    getRefLineData (columnKeys) {
      let chartData = []
      if (this.getRequestCache()?.reference) {
       /*const estimateType = this.getFilter('unitSelector')
       const catDesc1 = this.getFilter('categoryDesc1')
       const catDesc2 = this.getFilter('categoryDesc2')*/
       /*if (estimateType === 'rate' && (catDesc1 !== 'All' || catDesc2 !== 'All')) {
        const levelDesc2 = this.getFilter('levelDesc2')
        const crimeType = this.getFilter('crimeType')
        const refLines = this.getRequestCache().reference.refSeries.refEstimates
          refLines.forEach(datum => {
            let row = Object.fromEntries(columnKeys.map(key => [key, '']))
            row['Year'] = datum[0]
            row[catDesc1] = 'All'
            if (catDesc2 !== 'All') {
              row[catDesc2] = levelDesc2
            }
            row['Crime Type'] = crimeType
            row['Rate'] = datum[1]
            chartData.push(row)
          })
       }*/
      }
      return chartData
    },
    getCSVFromObject (data) {
      const keys = Object.keys(data[0])

      let rows = []
      data.forEach(datum => {
        let row = []
        keys.forEach(key => {
          row.push('"' + String(datum[key]) + '"')
        })
        rows.push(row)
      })
      let csv = keys.join(',')
      rows.forEach(row => {
        csv += '\n'
        csv += row.join(',')
      })
      return csv
    }
  }
}
</script>
