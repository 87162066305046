import { getDataAndSetOptions, formatCustomGraphicsFilepath, readCSV } from '@/utils'
import { setFilterOptions, setRefFilters, addMinorRefFilters } from './sharedLoadData'
import { groupForLollipopMinorRefLine, groupForLollipop } from '@/highchartsFormatters'
import { getSingleYearCrimeMinorRefNameSingleCat, getSingleYearCrimeMinorRefNameDualCat } from '@/chartTitleFormatter'
export default async function crimeTypeLoadData (state, pageObject, hs) {
  let data = await getDataAndSetOptions(hs, 'All', 'All', setFilterOptions)

  // BEGIN Step 2: Format data for reference line. Crime type view only gets minor reflines.
  let cache = hs.getRequestCache()
  // Minor reference variables
  let minorRefData = null
  let minorRefFilepath = null
  let minorRefSeries = null
  let minorRefName = null
  // let cachedMinorRefData = null
  // let cachedMinorRefFilepath = null
  let minorRefFilters = setRefFilters(hs, ['categoryDesc2', 'levelDesc2', 'year'])

  let estimateCache = cache.estimate // This should always exist since estimate cache is initially set in getDataAndSetOptions

  if (hs.getFilter('categoryDesc1') !== 'All') {
    minorRefFilters = addMinorRefFilters(hs, minorRefFilters, ['categoryDesc1', 'levelDesc1'])
    minorRefFilepath = formatCustomGraphicsFilepath(hs, 'all', hs.getFilter('categoryDesc2'))
    if (hs.getFilter('categoryDesc2') === 'All') {
      minorRefName = getSingleYearCrimeMinorRefNameSingleCat(hs)
    } else {
      minorRefName = getSingleYearCrimeMinorRefNameDualCat(hs)
    }
  } else {
    // this still needs correction for crime type
    minorRefFilepath = formatCustomGraphicsFilepath(hs, 'all', 'all')
  }

  // Get data and filepath names from cache
  // if (cache && cache.hasOwnProperty('reference')) {
  //   cachedMinorRefData = cache.reference.minorRefData
  //   cachedMinorRefFilepath = cache.reference.minorRefFilepath
  // }

  // If filepath names don't match, go get new data
  // if (minorRefFilepath !== cachedMinorRefFilepath) {
  minorRefData = await readCSV(minorRefFilepath)
  // } else {
  //   minorRefData = cachedMinorRefData
  // }

  minorRefData = minorRefData.filter(el => hs.getFilter('crimeType').includes(el.crimeType))

  // Format ref series
  // if (hs.getFilter('categoryDesc1') !== 'All') {
  minorRefSeries = groupForLollipopMinorRefLine(hs, minorRefData, hs.getFilter('unitSelector'), minorRefFilters, minorRefName)
  // }

  // Set the request cache. Must set 'estimate' cache too.
  hs.setRequestCache({
    'estimate': estimateCache,
    'reference': {
      'minorRefData': minorRefData,
      'minorRefFilepath': minorRefFilepath
    }
  })
  // END Step 2

  return {
    CrimeType: groupForLollipop(
      hs,
      data,
      hs.getFilter('unitSelector'),
      [
        { column: 'year', value: hs.getFilter('year') },
        { column: 'categoryDesc1', value: hs.getFilter('categoryDesc1') },
        { column: 'categoryDesc2', value: hs.getFilter('categoryDesc2') },
        { column: 'levelDesc2', value: hs.getFilter('levelDesc2') },
        { column: 'crimeType', value: hs.getFilter('crimeType') }
      ],
      true, minorRefSeries, ''
    )
  }
}
