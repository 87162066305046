<template>
  <div
    class="container-fluid"
    id="toolOverview"
  >
    <div
      class="row shadedrow"
      id="overviewIntro"
    >
      <div
        class="container"
        id="overviewTop"
      >
        <div class="row my-5">
          <div class="col col-12 col-md-6  mb-lg-3">
            <h1 class="sg">
              Tool Overview <small class="pl-lg-3"><a
                href="/assets/NCVSDashboard_ToolOverview.pdf"
                class=""
                download
                style="whitespace: nowrap"
              >download <i class="fas fa-solid fa-file-pdf" /></a></small>
            </h1>
          </div>
          <div class="col col-12">
            <div class="row">
              <p class="col col-12 col-md-6">
                Note that graphics included below are for illustration purposes only. <br class="d-none d-xl-inline-block">For actual data, visit the                 <router-link
                  class=""
                  :to="{name: 'quickGraphics', hash: '#quickgraphicstop'}"
                >
                  Quick Graphics
                </router-link> or                   <router-link
                  class=""
                  :to="{name: 'customGraphics', hash: '#customgraphicstop'}"
                >
                  Custom Graphics
                </router-link> pages.
              </p>
              <p class="col col-12 col-md-6 d-flex align-self-end">
                <span><b>Have questions?</b><br><a href="mailto:askbjs@usdoj.gov?subject=N-DASH">askbjs@usdoj.gov <i class="fas fa-solid fa-envelope" /></a></span>
              </p>
            </div>
          </div>
        </div>
      </div>

      <div class="container mb-5">
        <!--
        <div class="col col-8 mt-lg-5 mb-lg-3">
          <h1 class="sg mt-4">
            Tool Overview
          </h1>
        </div>
-->
        <div id="overviewHeader" />
        <div class="row mb-3">
          <div class="col col-12">
            <ul class="pl-0">
              <!--
              <div class="row pl-md-5">
                <small class="col col-12">Note that graphics included below are for illustration purposes only. For actual data, visit the                 <router-link
                  class=""
                  :to="{name: 'quickGraphics', hash: '#quickgraphicstop'}"
                >
                  Quick Graphics
                </router-link> or                   <router-link
                  class=""
                  :to="{name: 'customGraphics', hash: '#customgraphicstop'}"
                >
                  Custom Graphics
                </router-link> pages.
                </small>
              </div>
-->

              <!--                dont use margin on first one-->
              <li class="mb-3 ">
                <h4>Analysis types</h4>
                <ul class="pl-0">
                  <li>
                    <div class="row pl-md-5">
                      <div class="col col-12 col-md-4 pl-md-0 d-flex align-items-start flex-column">
                        <h5>Multi-year trend comparison</h5>
                        <div class="exampleblock w-100">
                          <small class="examplelabel"> Example image:</small>
                          <img
                            class="w-100"
                            src="../../assets/exampleAreaPair.svg"
                            alt="Example Visualization: change over time"
                          >
                        </div>
                        <p class="d-flex align-self-end pt-2">
                          Compare estimates over time—across crime types or across groups within a demographic or incident characteristic.
                        </p>
                      </div>
                      <div class="col col-12 col-md-4 d-flex align-items-start flex-column">
                        <h5>Single-year <br class="d-none d-lg-block d-xl-none">comparison</h5>
                        <div class="exampleblock w-100">
                          <small class="examplelabel"> Example image:</small>
                          <img
                            class="w-100"
                            src="../../assets/examplelolli.svg"
                            alt="Example Visualization: single year comparison"
                          >
                        </div>
                        <p class="d-flex align-self-end pt-2">
                          Compare estimates for a single data year—of multiple crime types and a demographic or incident characteristic or of two different characteristics.
                        </p>
                      </div>
                      <div class="col col-12 col-md-4 d-flex align-items-start flex-column">
                        <h5>Year-to-year comparison</h5>
                        <div class="exampleblock w-100">
                          <small class="examplelabel"> Example image:</small>
                          <img
                            class="w-100"
                            src="../../assets/exampledumbbell.svg"
                            alt="Example Visualization: Year-to-Year Comparison"
                          >
                        </div>
                        <p class="d-flex align-self-end pt-2">
                          Compare estimates between two data years—for crime types with a demographic or incident characteristic or for two different characteristics.
                        </p>
                      </div>
                    </div>
                  </li>
                </ul>
              </li>
              <li class="mt-5 mb-3">
                <h4>Crime Categories</h4>
                <ul class="pl-0">
                  <li>
                    <div class="row">
                      <div class="col col-12 col-md-6 pl-md-5">
                        <h5 class="pl-0">
                          Personal victimizations
                        </h5>

                        <p>These crimes include violent crimes (rape/sexual assault, robbery, aggravated assault, and simple assault) and personal theft/larceny.</p>
                      </div>
                      <div class="col col-12 col-md-6 pl-md-5">
                        <h5 class="pl-0">
                          Property victimizations
                        </h5>

                        <p>Property crimes include burglary, trespassing, motor vehicle theft, and other theft.</p>
                      </div>
                    </div>
                  </li>
                </ul>
              </li>
              <li
                class="mt-5 mb-3"
                id="unitMeasure"
              >
                <h4>UNITS OF MEASUREMENT</h4>
                <ul class="pl-0">
                  <li>
                    <div class="row">
                      <div class="col col-12 col-md-4 pl-md-5">
                        <h5 class="pl-0">
                          Number of victimizations
                        </h5>
                        <p>This is an estimation of the number of personal or property victimizations that U.S. residents age 12 or older or households experienced in the selected year.</p>
                      </div>
                      <div class="col col-12 col-md-4 pl-md-5">
                        <h5 class="pl-0">
                          Rate of victimizations
                        </h5>
                        <p>Victimization rates are calculated by multiplying the number of victimizations by 1,000 and dividing that product by the number of persons age 12 or older. In the case of property victimizations, the number of victimizations is multiplied by 1,000 and the product is divided by the number of households.</p>
                      </div>
                      <div class="col col-12 col-md-4 pl-md-5">
                        <h5 class="pl-0">
                          Percentage of victimizations
                        </h5>
                        <p>Percentages show the portion of victimizations that occurred for a particular characteristic—that is, the number of victimizations for a characteristic divided by the total number of victimizations and multiplied by 100.</p>
                      </div>
                      <div class="col col-12 pl-md-5">
                        For more information, see “<a
                          href="/methodology#unitdef"
                          target="methodology"
                        >Units of Measurement and Interpreting Dashboard Results</a>” in the <a
                          href="/methodology"
                          target="methodology"
                        >User’s Guide</a>.
                      </div>
                    </div>
                  </li>
                </ul>
              </li>

              <li
                class="mt-5 mb-3"
                id="compViews"
              >
                <h4>Comparison views</h4>
                <ul class="pl-0">
                  <li>
                    <div class="row">
                      <div
                        class="col col-12 col-md-6 d-flex align-items-start flex-column pl-md-5"
                      >
                        <h5 class="pl-0">
                          Crime type view
                        </h5>
                        <p>The focus of comparison is on values between different crime types.<span class="d-none d-lg-block"><br></span></p>
                        <div class="w-100">
                          <div class="exampleblock">
                            <small class="examplelabel"> Example image:</small>
                            <img
                              class="w-100 d-flex align-self-end"
                              src="../../assets/exampleCrime.svg"
                              style="overflow: hidden"
                              alt="Example Visualization: Comparison by Crime Type"
                            >
                          </div>
                        </div>
                      </div>
                      <div
                        class="col col-12 col-md-6 pl-md-5"
                      >
                        <h5 class="pl-0">
                          Characteristics view
                        </h5>
                        <p>The focus of comparison is on different values within a characteristic of victimization.</p>
                        <div class="mt-auto">
                          <div class="exampleblock">
                            <small class="examplelabel"> Example image:</small>
                            <img
                              class="w-100"
                              src="../../assets/exampleChar.svg"
                              style="overflow: hidden"
                              alt="Example Visualization: Comparison by Characteristic"
                            >
                          </div>
                        </div>
                      </div>
                    </div>
                  </li>
                </ul>
              </li>
              <li
                class="mt-5 mb-3"
                id="charVic"
              >
                <h4>Characteristics of Victimizations</h4>
                <ul class="pl-0">
                  <li>
                    <div class="row">
                      <div class="col col-12  nb pl-md-5 ">
                        <p>A characteristic refers to information about the crime regarding either the demographics of the victim or household, such as the victim’s age, or details related to the incident, such as whether the crime was reported to the police. These are more broadly referred to as demographic or incident characteristics (see below for a full list).</p>
                        <p>
                          This tool allows for comparisons between groups within a specific characteristic, or filtering information by a characteristic. To compare between groups, users can select a category under Comparison Characteristic in the Display Settings area, such as examining the rate of simple assaults between different victim race/Hispanic origins. To filter results by a particular characteristic, select Filter Characteristic under the  Data Filter Settings area.
                        </p><p>
                          Once a category is selected, the associated selection value will auto-populate with a value (i.e., “65 or older”), which can then be changed, narrowing the data shown to the chosen group. Unit and Display Settings for charts will automatically update to those appropriate to the one of these two types selected.
                        </p>
                      </div>
                      <div class="col col-12 col-md-6 pl-md-5">
                        <h5
                          class="pl-0"
                          id="incident_char"
                        >
                          Incident characteristics
                        </h5>
                        <p>Includes: injury, location of incident, medical treatment for physical injuries, reporting to the police, victim-offender relationship, victim services use,  and weapon category (presence and type of weapon).</p>
                        <p>By default, incident characteristics are calculated as a percentage of the crime type.</p>
                      </div>
                      <div class="col col-12 col-md-6 pl-md-5">
                        <h5
                          class="pl-0"
                          id="demog_char"
                        >
                          Demographic characteristics
                        </h5>
                        <p>Includes: victim age, victim household income, victim household size, victim marital status, victim MSA (metropolitan statistical area) status, victim population size, victim race/Hispanic origin, victim region, and victim sex.</p>
                        <p>By default, demographic characteristics are calculated as rates.</p>
                        <p>When not altered by other selections, a menu selection of “All” receives demographic-specific settings.</p>
                      </div>
                    </div>
                  </li>
                </ul>
              </li>
              <li id="displayFilter">
                <h4>Display and filter settings</h4>
                <ul class="pl-0">
                  <li>
                    <div class="row">
                      <div
                        class="col col-12 col-md-6 pl-md-5"
                        id="displaysettings"
                      >
                        <h5>Display settings</h5>
                        <ul>
                          <li>
                            <b>Crime category</b>
                            <p class="pl-4">
                              Sets personal versus property victimizations. When altered, available crime types will automatically populate with the associated values. For charts that display a single crime type, the default value applied is the aggregate (“Violent victimizations” or “Property victimizations”), which can then be changed through the “CRIME TYPE” menu.
                            </p>
                          </li>
                          <li>
                            <b>Unit</b>
                            <p class="pl-4">
                              When the characteristic selected is a demographic variable, this setting will default to “Rate” to account for each group’s representation within the population. When an incident characteristic is selected, this setting will default to “Percent” for percent of the crime type in which these incidents occurred. A toggle to change unit to “Number” is available for all settings.
                            </p>
                          </li>
                          <li>
                            <b>Estimate flags</b>
                            <p class="pl-4">
                              A show/hide selection. Indicates “interpret data with caution.” See chart notes for symbol designations.
                            </p>
                          </li>
                          <li>
                            <b>Confidence intervals</b>
                            <p class="pl-4">
                              A show/hide selection for the  95% confidence interval.
                            </p>
                          </li>
                          <li>
                            <b>Reference lines</b>
                            <p class="pl-4">
                              A show/hide selection. Lines indicate the rate for “All” when making comparisons across demographics.
                            </p>
                          </li>
                        </ul>
                        <p>When a comparison characteristic or crime type selection is available in the Display Settings area, it determines the topic of comparison for the chart (rows or columns).</p>
                        <b class="d-inline-block pt-2">Default Setting Example:</b><br>
                        <div class="pl-md-4 pt-2">
                          <div class="exampleblock">
                            <small class="examplelabel">Default display settings:</small>
                            <div class="col col-9 col-lg-6 mb-4 pl-0">
                              <img
                                class="w-100 d-flex align-self-end"
                                src="../../assets/exampleViewSettingAll.svg"
                                alt="Default display settings (Characteristic Selection)"
                                title="Static image illustrating this tool's default setting for 'Characteristic Selection' menu"
                              >
                            </div>
                          </div>
                          <div class="exampleblock">
                            <small class="examplelabel">Result:</small>
                            <div class="col col-12 col-lg-10 pl-0">
                              <img
                                class="w-100 d-flex align-self-end mb-5"
                                src="../../assets/FilterAllChart.svg"
                                alt="Example Visualization using Default Display Settings"
                              >
                            </div>
                          </div>
                        </div>
                        <b id="appliedSet">Applied Setting Example:</b><br>
                        <div class="pl-md-4 pt-2 pb-4 pb-md-0">
                          <div class="exampleblock">
                            <small class="examplelabel">Applied display settings:</small>
                            <div class="col col-9 col-lg-6 mb-4 pl-0">
                              <img
                                class="w-100 d-flex align-self-end"
                                src="../../assets/exampleViewSettingSex.svg"
                                alt="Display Settings: Victim Sex"
                              >
                            </div>
                          </div>    <div class="exampleblock">
                            <small class="examplelabel">Result:</small>
                            <div class="col col-12 col-lg-10 pl-0">
                              <img
                                class="w-100 d-flex align-self-end"
                                src="../../assets/exampleViewFilter_b.svg"
                                alt="Example Visualization using View Setting: Victim Sex"
                              >
                            </div>
                          </div>
                        </div>
                      </div>
                      <div
                        class="col col-12 col-md-6 pl-md-5"
                        id="filtersettings"
                      >
                        <h5 class="pl-0">
                          Data filter settings
                        </h5>
                        <p>Menu items vary based on the chosen view. When a characteristic or crime type selection is available in the data filter settings area, the data used for the chart will be isolated to a selected crime type or characteristic value.</p>
                        <p>The title and data values for a chart will change, but the chart’s structure will not.</p>
                        <b class="d-inline-block pt-2">Unfiltered Example:</b> <div class="pl-md-4 pt-2">
                          <div class="exampleblock">
                            <small class="examplelabel">Default filter settings:</small>
                            <div class="col col-9 col-lg-8 mb-4 pl-0">
                              <img
                                class="w-100 d-flex align-self-end"
                                src="../../assets/filterAllAll.svg"
                                alt="Default Filter Settings"
                              >
                            </div>
                          </div>    <div class="exampleblock">
                            <small class="examplelabel">Result:</small>
                            <div class="col col-12 col-lg-10 pl-0">
                              <img
                                class="w-100 d-flex align-self-end mb-5"
                                src="../../assets/FilterAllChart.svg"
                                alt="Visualization with default filter settings"
                              >
                            </div>
                          </div>
                        </div>
                        <b>Filtered Example:</b>
                        <div class="pl-md-4 pt-2  pb-4 pb-md-0">
                          <div class="exampleblock">
                            <small class="examplelabel">Applied filter settings:</small>
                            <div class="col col-9 col-lg-8 mb-4 pl-0">
                              <img
                                class="w-100 d-flex align-self-end"
                                src="../../assets/filterIncome.svg"
                                alt="Filters with settings applied: Victim Household Income, $35,000 to $49,999"
                              >
                            </div>
                          </div>    <div class="exampleblock">
                            <small class="examplelabel">Result:</small>
                            <div class="col col-12 col-lg-10 pl-0">
                              <img
                                class="w-100 d-flex align-self-end"
                                src="../../assets/filterIncomeChart.svg"
                                alt="Visualization with filter settings applied: Victim Household Income, $35,000 to $49,999"
                              >
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>
                  </li>
                </ul>
              </li>
            </ul>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>
<script>
export default {
  name: 'ToolOverview'
}
</script>
