import Vue from 'vue'
import VueRouter from 'vue-router'
import home from '@/components/pages/home'
import toolOverview from '@/components/pages/toolOverview'
import terms from '@/components/pages/terms'
import methodology from '@/components/pages/methodology'
import interim from '@/components/pages/interim'
import multiYearTrendsCrimeType from '@/components/pages/multi-year-trends/crimeType'
import multiYearTrendsCharacteristic from '@/components/pages/multi-year-trends/characteristic'
import singleYearComparisonCrimeType from '@/components/pages/single-year-comparison/crimeType'
import singleYearComparisonCharacteristic from '@/components/pages/single-year-comparison/characteristic'
import yearToYearComparisonCrimeType from '@/components/pages/year-to-year-comparison/crimeType'
import yearToYearComparisonCharacteristic from '@/components/pages/year-to-year-comparison/characteristic'
import quickGraphics from '@/components/pages/quickGraphics'
import customGraphics from '@/components/pages/customGraphics'
Vue.use(VueRouter)

const router = new VueRouter({
  mode: 'history',
  base: process.env.BASE_URL,
  routes: [
    { path: '/', redirect: '/Home' },
    { path: '/Home', component: home, name: 'Home' },
    { path: '/ToolOverview', component: toolOverview, name: 'ToolOverview' },
    { path: '/terms', component: terms, name: 'Terms and Definitions' },
    { path: '/methodology', component: methodology, name: 'User’s Guide' },
    { path: '/quick-graphics', component: quickGraphics, name: 'quickGraphics' },
    { path: '/custom-graphics', component: customGraphics, name: 'customGraphics' },
    {
      path: '/multi-year-trends',
      name: 'Multi-Year Trends',
      component: interim,
      props: {
        name: 'Multi-Year Trends'
      },
      children: [
        {
          path: '/multi-year-trends/crimeType',
          name: 'Multi-Year Trends Crime Type',
          component: multiYearTrendsCrimeType
        },
        {
          path: '/multi-year-trends/characteristic',
          name: 'Multi-Year Trends Characteristic',
          component: multiYearTrendsCharacteristic
        }
      ]
    },
    {
      path: '/single-year-comparison',
      name: 'Single-Year Comparison',
      component: interim,
      props: {
        name: 'Single-Year Comparison'
      },
      children: [
        {
          path: '/single-year-comparison/crimeType',
          name: 'Single-Year Comparison Crime Type',
          component: singleYearComparisonCrimeType
        },
        {
          path: '/single-year-comparison/characteristic',
          name: 'Single-Year Comparison Characteristic',
          component: singleYearComparisonCharacteristic
        }
      ]
    },
    {
      path: '/year-to-year-comparison',
      name: 'Year-to-Year Comparison',
      component: interim,
      props: {
        name: 'Year-to-Year Comparison'
      },
      children: [
        {
          path: '/year-to-year-comparison/crimeType',
          name: 'Year-to-Year Comparison Crime Type',
          component: yearToYearComparisonCrimeType
        },
        {
          path: '/year-to-year-comparison/characteristic',
          name: 'Year-to-Year Comparison Characteristic',
          component: yearToYearComparisonCharacteristic
        }
      ]
    }
  ],
  scrollBehavior (to, from, savedPosition) {
    if (to.hash) {
      return {
        selector: to.hash
      }
    }
  }
})

router.afterEach((to, from) => {
  let revisedName = function (name) {
    if (name === 'quickGraphics') {
      return 'Quick Graphics'
    } else if (name === 'ToolOverview') {
      return 'Tool Overview'
    } else if (name === 'customGraphics') {
      return 'Custom Graphics Home'
    } else {
      return name
    }
  }
  // TODO: remove ternary and change page name from quickGraphics to Quick Graphics. This change will effect a lot of code
  document.title = 'NCVS Dashboard: ' + revisedName(to.name)
})

export default router
