<template>
  <a
    :id="id"
    class="infodotanchor"
    :innerText="tooltipText"
    :name="id"
    :aria-label="tooltipText"
    href="#"
  >
    <font-awesome-icon
      :id="`infoTooltipTrigger-${id}`"
      :icon="['fas', 'info-circle']"
      style="vertical-align: text-top;"
      size="sm"
    />
    <b-popover
      :target="`infoTooltipTrigger-${id}`"
      triggers="hover focus"
      placement="bottomleft"
      style="margin-bottom: 5px;"
    >
      <p
        class="popuptext"
        v-html="tooltipText"
      >{{ tooltipText }}</p>
    </b-popover>
  </a>
</template>
<script>
export default {
  name: 'InfoDot',
  props: {
    iconSize: {
      type: Boolean,
      required: false
    },
    id: {
      type: String,
      required: true
    },
    tooltipText: {
      type: String,
      required: true
    }
  }
}
</script>
