// vue dependencies
import Vue from 'vue'
import router from './router'
import store from './store'
import App from './App.vue'

// harness dependencies
import pages from './harness-pages/manifest'
import harness from '@rtidatascience/harness'
import { harnessUI } from '@rtidatascience/harness-ui'
// import harness from '../dev-packages/harness/src/harness'
// import { harnessUI } from '../dev-packages/harness-ui/src/harness-ui'

// highcharts dependencies
import Highcharts from 'highcharts'
import exporting from 'highcharts/modules/exporting'
import offlineExporting from 'highcharts/modules/offline-exporting'
import HighchartsVue from 'highcharts-vue'
import more from 'highcharts/highcharts-more'
import lollipop from 'highcharts/modules/lollipop'
import dumbbell from 'highcharts/modules/dumbbell'

// ie polyfill
import 'promise-polyfill/src/polyfill'
import 'whatwg-fetch'

// include styles and bootstrap javascript
import './styles/main.scss'
import $ from 'jquery'
import 'bootstrap'

// for infodots
import { library } from '@fortawesome/fontawesome-svg-core'
import {
  faRedoAlt,
  faInfoCircle
} from '@fortawesome/free-solid-svg-icons'
import { FontAwesomeIcon } from '@fortawesome/vue-fontawesome'

// include highchart export functions
import { getSVG, exportCharts } from './highchartsExportFunctions'

// import bootstrap-vue (used for popovers)
import { BootstrapVue } from 'bootstrap-vue'
window.$ = $

// Add bootstrap capabilities (used for popovers)
Vue.use(BootstrapVue)

// setup harness
Vue.use(harness, { store, pages })
Vue.use(harnessUI)
Vue.config.productionTip = false

// setup highcharts
more(Highcharts)
dumbbell(Highcharts)
lollipop(Highcharts)
exporting(Highcharts)
offlineExporting(Highcharts)
Vue.use(HighchartsVue)

// add bootstrap font to highcharts
Highcharts.setOptions({
  chart: {
    style: {
      fontFamily: '-apple-system,BlinkMacSystemFont,Segoe UI,Roboto,Helvetica Neue,Arial,Noto Sans,sans-serif,Apple Color Emoji,Segoe UI Emoji,Segoe UI Symbol,Noto Color Emoji;',
      fontSize: '12px'
    },
    yAxis: {
      labels: {
        style: {
          fontSize: '22px'
        }
      }
    }
  },
  lang: {
    thousandsSep: ','
  }
})

Highcharts.getSVG = getSVG

Highcharts.exportCharts = exportCharts

library.add(
  faRedoAlt,
  faInfoCircle)
Vue.component('font-awesome-icon', FontAwesomeIcon)

// setup vue instance
new Vue({
  router,
  store,
  render: h => h(App)
}).$mount('#app')

// $(function () {
//  $('[data-toggle="popover"]').popover()
// })
