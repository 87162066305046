import yearToYearCrimeType from '@/components/pages/year-to-year-comparison/crimeType'
import crimeTypeLoadData from './crimeTypeLoadData'
import categoryFactory, { categories, demographicFilters } from '../filters/categories'
import allOption from '../filters/all'
import { onOffOptions, demographicOptions, incidentOptions } from '../filters/toggle'
// import years from '../filters/years'
import { dumbbellTableAdapter, tableClasses } from '@/highchartsFormatters'
import { components } from '@rtidatascience/harness-ui'
import dumbbell from '@/components/charts/highcharts/dumbbell'
import ChartWithTableWrapper from '@/components/chartWithTableWrapper'
import crimeTypes from '../filters/crimeTypes'

export default class yearToYearComparisonCrimeType {
  title = 'Year-To-Year Comparison'
  key = 'Year-to-Year Comparison Crime Type'
  subtype = 'custom-graphics'
  pageComponent = yearToYearCrimeType
  pageProps = {}
  retrieveData = crimeTypeLoadData
  filters = function () {
    return {
      victimizationType: {
        label: 'Crime Category:',
        component: components['HarnessUiRadioGroup'],
        props: {
          synchronous: true, // Remember to call loadData in afterSet or elsewhere
          inline: true
        },
        options: [
          {
            key: 'person',
            label: 'Personal',
            default: true
          },
          {
            key: 'property',
            label: 'Property'
          }
        ],
        afterSet (action, hs) {
          hs.setOptionsForFilter('categoryDesc1', categories[hs.getFilter('victimizationType')], true)
          hs.setOptionsForFilter('categoryDesc2', categories[hs.getFilter('victimizationType')], true)
          hs.setOptionsForFilter('levelDesc2', allOption, true)
          let ctOptions = crimeTypes[hs.getFilter('victimizationType')].reduce((acc, ct) => { ct.default = true; acc.push(ct); return acc }, [])
          hs.setOptionsForFilter('crimeType', ctOptions, true)
          hs.loadData()
        }
      },
      unitFlag: {
        label: 'Estimate Flags:',
        component: components['HarnessUiRadioGroup'],
        props: {
          inline: true
        },
        options: onOffOptions('On')
      },
      unitSelector: {
        label: 'Unit <span id="unitdotspot"></span>:',
        component: components['HarnessUiRadioGroup'],
        props: {
          inline: true
        },
        options: demographicOptions()
      },
      confidenceInterval: {
        label: 'Confidence Intervals <span id="cidotspot"></span>:',
        component: components['HarnessUiRadioGroup'],
        props: {
          inline: true
        },
        options: onOffOptions('Off')
      },
      year2: {
        label: 'year selection <b>1</b>:',
        component: components['HarnessUiSelect'],
        programmatic: true,
        options: [
        ],
        afterSet (action, hs) {
          hs.disableOptions('year2', '2006')
        }
      },
      year: {
        label: 'year selection <b>2</b>:',
        component: components['HarnessUiSelect'],
        programmatic: true,
        options: [
        ],
        afterSet (action, hs) {
          hs.disableOptions('year', '2006')
        }
      },
      crimeType: {
        label: 'Crime Type: <small><i>(Select Multiple)</i>&nbsp;&nbsp</small><br /><small>(Click to expand/collapse)</small>',
        component: components['HarnessUiCheckboxGroup'],
        options: crimeTypes['person'].reduce((acc, ct) => { ct.default = true; acc.push(ct); return acc }, []),
        props: {
          multiple: true,
          collapse: true
        }
      },
      categoryDesc1: {
        label: 'Comparison Characteristic:',
        component: components['HarnessUiSelect'],
        options: categoryFactory('person'),
        afterSet (action, hs) {
          if (action.payload !== 'All') {
            hs.disableOptions('categoryDesc2', [hs.getFilter('categoryDesc1')])
          } else {
            hs.enableOptions('categoryDesc2', hs.getOptionsForFilter('categoryDesc2').map((x) => x.key))
            if (!demographicFilters.includes(hs.getFilter('categoryDesc2'))) {
              hs.setOptionsForFilter('unitSelector', incidentOptions(), true)
            }
          }

          if (demographicFilters.includes(action.payload)) {
            if (action.payload !== 'All') {
              hs.setOptionsForFilter('unitSelector', demographicOptions(), true)
            }
            if (hs.getFilter('unitSelector') === 'rate' && action.payload !== 'All') {
              // hs.enableOptions('referenceLine', [true])
            } else {
              hs.setOptionsForFilter('unitSelector', demographicOptions(), true)
              // hs.setFilter('referenceLine', false)
              // hs.disableOptions('referenceLine', [true])
            }
          } else {
            hs.setOptionsForFilter('unitSelector', incidentOptions(), true)
            // hs.setFilter('referenceLine', false)
            // hs.disableOptions('referenceLine', [true])
          }
        }
      },
      categoryDesc2: {
        label: 'Filter Characteristic:',
        component: components['HarnessUiSelect'],
        options: categoryFactory('person'),
        afterSet (action, hs) {
          if (action.payload !== 'All') {
            hs.disableOptions('categoryDesc1', [hs.getFilter('categoryDesc2')])
          } else {
            hs.enableOptions('categoryDesc1', hs.getOptionsForFilter('categoryDesc1').map((x) => x.key))
          }
        }
      },
      levelDesc2: {
        label: 'Filter Characteristic Value: <small><i>(select one)</i></small>',
        component: components['HarnessUiSelect'],
        programmatic: true,
        options: allOption
      }
    }
  }

  charts = function () {
    return {
      CrimeType: {
        component: ChartWithTableWrapper,
        props: {
          chartComponent: dumbbell,
          card: false,
          buttonPosition: 'bottom',
          tableClass: tableClasses,
          tableAdapter: dumbbellTableAdapter
        }
      }
    }
  }
}
