<template>
  <div id="bjsheader">
    <section
      class="usa-banner"
      aria-label="Official government website"
    >
      <!-- Begin official government website notice -->
      <div class="usa-accordion">
        <header class="usa-banner__header">
          <div class="usa-banner__inner">
            <div class="grid-col-auto">
              <img
                class="usa-banner__header-flag"
                src="../assets/us_flag_small.png"
                alt="U.S. flag"
              >
            </div>
            <div class="grid-col-fill tablet:grid-col-auto">
              <p class="usa-banner__header-text">
                An official website of the United States government, Department of Justice.
              </p>
              <p
                class="usa-banner__header-action"
                aria-hidden="true"
              >
                Here's how you know
              </p>
            </div>
            <button
              class="usa-accordion__button usa-banner__button"
              aria-expanded="false"
              aria-controls="gov-banner"
            >
              <span class="usa-banner__button-text">Here's how you know</span>
            </button>
          </div>
        </header>
        <div
          class="usa-banner__content usa-accordion__content"
          id="gov-banner"
          hidden=""
        >
          <div class="grid-row grid-gap-lg">
            <div class="usa-banner__guidance tablet:grid-col-6">
              <img
                class="usa-banner__icon usa-media-block__img"
                src="../assets/icon-dot-gov.svg"
                role="img"
                alt=""
                aria-hidden="true"
              >
              <div class="usa-media-block__body">
                <p>
                  <strong>Official websites use .gov</strong>
                  <br>
                  A <strong>.gov</strong> website belongs to an official government organization in the United States.
                </p>
              </div>
            </div>
            <div class="usa-banner__guidance tablet:grid-col-6">
              <img
                class="usa-banner__icon usa-media-block__img"
                src="../assets/icon-https.svg"
                role="img"
                alt=""
                aria-hidden="true"
              >
              <div class="usa-media-block__body">
                <p>
                  <strong>Secure .gov websites use HTTPS</strong>
                  <br>
                  A <strong>lock</strong> (
                  <span class="icon-lock">
                    <svg
                      xmlns="http://www.w3.org/2000/svg"
                      width="52"
                      height="64"
                      viewBox="0 0 52 64"
                      class="usa-banner__lock-image"
                      role="img"
                      aria-labelledby="banner-lock-title-default banner-lock-description-default"
                      focusable="false"
                    >
                      <title id="banner-lock-title-default">Lock</title>
                      <desc id="banner-lock-description-default">A locked padlock</desc>
                      <path
                        fill="#000000"
                        fill-rule="evenodd"
                        d="M26 0c10.493 0 19 8.507 19 19v9h3a4 4 0 0 1 4 4v28a4 4 0 0 1-4 4H4a4 4 0 0 1-4-4V32a4 4 0 0 1 4-4h3v-9C7 8.507 15.507 0 26 0zm0 8c-5.979 0-10.843 4.77-10.996 10.712L15 19v9h22v-9c0-6.075-4.925-11-11-11z"
                      />
                    </svg>
                  </span>
                  ) or <strong>https://</strong> means you’ve safely connected to the .gov website. Share sensitive information only on official, secure websites.
                </p>
              </div>
            </div>
          </div>
        </div>
      </div>
    </section>
    <!-- end official government website notice -->
    <header
      class="usa-header usa-header--extended"
      id="header"
    >
      <div class="usa-navbar">
        <div
          style="display:flex;flex-wrap: wrap;"
          class="region region-header"
        >
          <div
            class="usa-logo"
            id="logo"
          >
            <div class="ojp-seal">
              <a
                href="https://www.ojp.gov/"
                accesskey="1"
                title="Office of Justice Programs"
                aria-label="Office of Justice Programs"
                target="_blank"
              >
                <img
                  src="../assets/OJP-seal.svg"
                  alt="Office of Justice Programs seal"
                  height="98"
                  width="98"
                >
              </a>
            </div>
            <div class="ojp-office-logo">
              <a
                href="https://bjs.ojp.gov/"
                accesskey="2"
                title="Home"
                aria-label="Home"
                target="_blank"
              >
                <img
                  src="../assets/bjs-logo.svg"
                  alt="Home"
                >
              </a>
            </div>
          </div>
          <!-- end logo -->
          <!-- Developer note - replace sample title text with relevant values -->
          <div class="bjs-tool-title">
            <h1
              style="margin:15px 0px 0px 0px"
              class="hero__title"
            >
              <span>
                NCVS Dashboard</span>
            </h1>
            <p class="hero__subtitle">
              <span style="font-size:24px; margin-top:0px;"> (N-DASH)</span>
            </p>
          </div>
        </div>
      <!-- end region header -->
      </div>
    <!-- end navbar -->
    </header>
  </div>
</template>

<style lang="scss" scoped>

/*from BJS demo pg styles*/
         .bjs-tool-title h1, .bjs-tool-title .hero__subtitle {
             color:#fff;
             text-align:right;
    }
         .bjs-tool-title .hero__subtitle {
            margin-top:0;
    }
         /* Large devices (laptops/desktops, 992px and up) */
         @media only screen and (min-width: 992px) {
             .bjs-tool-title {
                 padding-left:8rem;
             }
    }

</style>

<script>
import $ from 'jquery'
// import '../styles/js_EYEIHSJWFcOfmLW2s72bMuiu5G1FwujXtJJh_JTFNFo.js.download'
// const jQuery = $

export default {
  name: 'BjsBanner',
  mounted () {
    let button = $('.usa-banner__button')

    var toggler = function (expand) {
      if (expand) {
        button.attr('aria-expanded', 'true')
        $('#gov-banner').removeAttr('hidden')
      } else {
        button.attr('aria-expanded', 'false')
        $('#gov-banner').attr('hidden', 'hidden')
      }
    }

    // Apply the click behavior to the toggle.
    button.click(function (e) {
      e.preventDefault()
      var expand = $(this).attr('aria-expanded') === 'false'
      toggler(expand)
    }).on('keydown', function (e) {
      // Keyboard accessibility.
      var expand = button.attr('aria-expanded') === 'false'
      if (e.which === 13) {
        e.preventDefault()
        e.stopPropagation()
        toggler(expand)
      } else if (!expand && e.which === 27) {
        // Handle escape key when menu is expanded.
        e.preventDefault()
        e.stopPropagation()
        toggler(false)
      }
    })
  }
}

</script>
