export default {
  computed: {
    assignNumberColumns () {
      // Returns the number of columns we're spreading the small multiple charts across

      // Determine if we're calling this function in smallMultipleHolders.vue
      // else we'll be calling in area.vue and iterate through to determine the number of SM charts
      let chartLength = 0

      if (this.chartIds !== undefined) {
        chartLength = this.chartIds.length
      } else {
        for (let [key] of Object.entries(this.charts)) {
          let chartData = this.getChartData(key)
          if (key.includes('charSmallMult') && chartData) {
            chartLength += 1
          }
        }
      }

      let numCols
      switch (chartLength) {
        case 1:
          numCols = 1
          break
        case 2:
          numCols = 2
          break
        case 3:
        case 5:
        case 6:
        case 9:
          numCols = 3
          break
        case 4:
        case 7:
        case 8:
        case 10:
        case 11:
        case 12:
          numCols = 4
          break
      }
      return numCols
    } } }
