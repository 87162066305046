import crimeType from '@/components/pages/multi-year-trends/crimeType'
import crimeTypeLoadData from './crimeTypeLoadData'
import categoryFactory, { categories } from '../filters/categories'

import allOption from '../filters/all'
import { onOffOptions, demographicOptions } from '../filters/toggle'
import { areaChartTableAdapter, tableClasses } from '@/highchartsFormatters'
import { components } from '@rtidatascience/harness-ui'
// import { components } from '../../../dev-packages/harness-ui/src/harness-ui'
import areaChart from '@/components/charts/highcharts/area'
import ChartWithTableWrapper from '@/components/chartWithTableWrapper'

export default class multiYearTrendsCrimeType {
  title = 'Multi Year Trends Crime Type'
  key = 'Multi-Year Trends Crime Type'
  subtype = 'custom-graphics'
  pageComponent = crimeType
  pageProps = {}
  retrieveData = crimeTypeLoadData
  filters = function () {
    return {
      victimizationType: {
        label: 'Crime Category:',
        component: components['HarnessUiRadioGroup'],
        props: {
          synchronous: true, // Remember to call loadData in afterSet or elsewhere
          inline: true
        },
        options: [
          {
            key: 'person',
            label: 'Personal',
            default: true
          },
          {
            key: 'property',
            label: 'Property'
          }
        ],
        afterSet (action, hs) {
          hs.setOptionsForFilter('categoryDesc1', categories[hs.getFilter('victimizationType')].map((x) => x), true)
          hs.setOptionsForFilter('categoryDesc2', categories[hs.getFilter('victimizationType')].map((x) => x), true)
          hs.setOptionsForFilter('levelDesc1', allOption, true)
          hs.setOptionsForFilter('levelDesc2', allOption, true)
          hs.loadData()
        }
      },
      unitSelector: {
        label: 'Unit <span id="unitdotspot"></span>:',
        component: components['HarnessUiRadioGroup'],
        props: {
          inline: true
        },
        options: demographicOptions()
      },
      unitFlag: {
        label: 'Estimate Flags:',
        component: components['HarnessUiRadioGroup'],
        props: {
          inline: true
        },
        options: onOffOptions('On')
      },
      confidenceInterval: {
        label: 'Confidence Intervals <span id="cidotspot"></span>:',
        component: components['HarnessUiRadioGroup'],
        props: {
          inline: true
        },
        options: onOffOptions('Off')
      },
      categoryDesc1: {
        label: 'Filter Characteristic <b>A</b>:',
        component: components['HarnessUiSelect'],
        options: categoryFactory('person'),
        afterSet (action, hs) {
          if (action.payload !== 'All') {
            hs.disableOptions('categoryDesc2', [hs.getFilter('categoryDesc1')])
          } else {
            hs.enableOptions('categoryDesc2', hs.getOptionsForFilter('categoryDesc2').map((x) => x.key))
          }
        }
      },
      levelDesc1: {
        label: 'Filter Characteristic <b>A</b> Value: <small><i>(select one)</i></small>',
        component: components['HarnessUiSelect'],
        programmatic: true,
        options: allOption
      },
      categoryDesc2: {
        label: 'Filter Characteristic <b>B</b>:',
        component: components['HarnessUiSelect'],
        options: categoryFactory('person'),
        afterSet (action, hs) {
          if (action.payload !== 'All') {
            hs.disableOptions('categoryDesc1', [hs.getFilter('categoryDesc2')])
          } else {
            hs.enableOptions('categoryDesc1', hs.getOptionsForFilter('categoryDesc1').map((x) => x.key))
          }
        }
      },
      levelDesc2: {
        label: 'Filter Characteristic <b>B</b> Value: <small><i>(select one)</i></small>',
        component: components['HarnessUiSelect'],
        programmatic: true,
        options: allOption
      }
    }
  }
  smallMultTitles = {
    violent: 'Violent Victimizations by Crime Type, 1994 to 2015 (Rate per 1,000 Persons)',
    property: 'Household Victimizations by Crime Type, 1994 to 2015 (Rate per 1,000 Persons)'
  }
  charts = function () {
    let personCharts = {
      ViolentVictimization1994To2015: {
        component: ChartWithTableWrapper,
        props: {
          chartComponent: areaChart,
          card: false,
          buttonPosition: 'bottom',
          tableClass: tableClasses,
          title: 'Violent Victimizations',
          tableAdapter: areaChartTableAdapter
          // yAxisTitle: 'Rate' // Adding this prop just so it shows up in table
        }
      },
      // begin small multiple
      RapeAndSexualAssault1994To2015: {
        component: areaChart,
        props: {
          smallMultiplesTitle: this.smallMultTitles['violent'],
          tableAdapter: areaChartTableAdapter,
          title: null,
          tableClass: tableClasses,
          exportingEnabled: false
        }
      },
      Robbery1994To2015: {
        component: areaChart,
        props: {
          smallMultiplesTitle: this.smallMultTitles['violent'],
          tableAdapter: areaChartTableAdapter,
          title: null,
          tableClass: tableClasses,
          exportingEnabled: false,
          hideYaxis: true
        }
      },
      AggravatedAssault1994To2015: {
        component: areaChart,
        props: {
          smallMultiplesTitle: this.smallMultTitles['violent'],
          tableAdapter: areaChartTableAdapter,
          title: null,
          tableClass: tableClasses,
          exportingEnabled: false,
          hideYaxis: true
        }
      },
      SimpleAssault1994To2015: {
        component: areaChart,
        props: {
          smallMultiplesTitle: this.smallMultTitles['violent'],
          tableAdapter: areaChartTableAdapter,
          title: null,
          tableClass: tableClasses,
          exportingEnabled: false,
          hideYaxis: true
        }
      },
      // end small multiple
      // start small multiple
      SimpleAssault1994To2015_b: {
        component: areaChart,
        props: {
          smallMultiplesTitle: this.smallMultTitles['violent'],
          tableAdapter: areaChartTableAdapter,
          title: null,
          tableClass: tableClasses,
          exportingEnabled: false
        }
      },
      ViolentExcludeSimpleAssault1994To2015: {
        component: areaChart,
        props: {
          smallMultiplesTitle: this.smallMultTitles['violent'],
          tableAdapter: areaChartTableAdapter,
          title: null,
          tableClass: tableClasses,
          exportingEnabled: false,
          hideYaxis: true
        }
      },
      // end small multiple
      PersonalTheftLarcency1994To2015: {
        component: ChartWithTableWrapper,
        props: {
          chartComponent: areaChart,
          tableAdapter: areaChartTableAdapter,
          title: 'Personal theft/Larceny Victimizations',
          tableClass: tableClasses,
          card: false,
          buttonPosition: 'bottom',
          exportingEnabled: false
        }
      }
    }
    let householdCharts = {
      PropertyVictimization1994To2015: {
        component: ChartWithTableWrapper,
        props: {
          chartComponent: areaChart,
          card: false,
          buttonPosition: 'bottom',
          tableClass: tableClasses,
          title: 'Property Victimizations',
          tableAdapter: areaChartTableAdapter
        }
      },
      // begin small multiple
      Burglary1994To2015: {
        component: areaChart,
        props: {
          smallMultiplesTitle: this.smallMultTitles['property'],
          tableAdapter: areaChartTableAdapter,
          title: null,
          tableClass: tableClasses,
          exportingEnabled: false
        }
      },
      Trespassing1994To2015: {
        component: areaChart,
        props: {
          smallMultiplesTitle: this.smallMultTitles['property'],
          tableAdapter: areaChartTableAdapter,
          title: null,
          tableClass: tableClasses,
          exportingEnabled: false,
          hideYaxis: true
        }
      },
      MotorVehicleTheft1994To2015: {
        component: areaChart,
        props: {
          smallMultiplesTitle: this.smallMultTitles['property'],
          tableAdapter: areaChartTableAdapter,
          title: null,
          tableClass: tableClasses,
          exportingEnabled: false,
          hideYaxis: true
        }
      },
      Theft1994To2015: {
        component: areaChart,
        props: {
          smallMultiplesTitle: this.smallMultTitles['property'],
          tableAdapter: areaChartTableAdapter,
          title: null,
          tableClass: tableClasses,
          exportingEnabled: false,
          hideYaxis: true
        }
      }
      // end small multiple
    }

    return { ...personCharts, ...householdCharts }
  }
}
