import { getDataAndSetOptions } from '@/utils'
import { setFilterOptions } from './sharedLoadData'
import { groupForDumbbell } from '@/highchartsFormatters'
export default async function crimeTypeLoadData (state, pageObject, hs) {
  let data = await getDataAndSetOptions(hs, 'Sex', 'Age', setFilterOptions)

  return {
    CrimeType: groupForDumbbell(
      hs,
      data,
      hs.getFilter('unitSelector'),
      [
        { column: 'year', value: hs.getFilter('year') && hs.getFilter('year2') },
        { column: 'year2', value: hs.getFilter('year2') },
        { column: 'categoryDesc1', value: hs.getFilter('categoryDesc1') },
        { column: 'categoryDesc2', value: hs.getFilter('categoryDesc2') },
        { column: 'levelDesc2', value: hs.getFilter('levelDesc2') },
        { column: 'crimeType', value: hs.getFilter('crimeType') }
      ],
      true
    )
  }
}
