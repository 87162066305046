<template>
  <div
    class="row sectionheader mb-4"
    :id="headerId"
  >
    <h4
      class="col col-12 col-md-9"
      :id="headerId"
    >
      {{ title }}
    </h4>
  </div>
</template>

<script>
export default {
  name: 'SectionHeader',
  props: {
    title: {
      type: String,
      required: true
    },
    headerId: {
      type: String,
      required: true
    },
    pageName: {
      type: String,
      required: true
    }
  }
}
</script>
