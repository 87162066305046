export default {
  computed: {
    plotOptions () {
      let colorByPoint = false
      let pointWidth = null
      // This checks if the chart is a standard bar or a grouped bar chart
      // This will break if any more chart types get overlaid on the bar chart
      if (this.getChartData(this.chart.key)) {
        colorByPoint = this.getChartData(this.chart.key).series.length === 2
        pointWidth = this.getChartData(this.chart.key).series.length === 2 ? 25 : pointWidth
      }
      return {
        colorByPoint: colorByPoint,
        pointWidth: pointWidth
      }
    }
  }
}
