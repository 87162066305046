<template>
  <div class="jumbotron jumbotron-fluid text-center eligible">
    <div class="row">
      <div class="col col-12 col-lg-10 offset-lg-1 align-self-center">
        <h5
          class="jumbotron-lead"
        >
          The combination of filters you have selected has zero results. <br>Please try removing filters until your query returns results, or use the 'Clear Filters' button above to start over.<br><br>
          Not all selection combinations result in valid charts. For example, location information is not available for years prior to 1995, and as use of a weapon results are only relevant for assaults that are aggravated in nature, a combination of weapon type and simple assault crime types will produce all zero results.
        </h5>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  name: 'NotEligible'
}
</script>
