export default {
  // HTML styling must exist inline so that it passes to svg export.
  subtitle: {
    confidenceInterval: [
      '“95% C.I.”: 95% confidence interval.',
      '“S.E.”: Standard error.'
    ],
    unitFlag: [
      'Estimate flag of “<span style="color:#ce4646;">▲</span>”: Interpret data with caution, based on 10 or fewer sample cases or the coefficient of variation is greater than 50%.',
      'Estimate flag of “<span style="color:#ce4646;">✱</span>”:',
      '<span class="small-multiples-subtitle-span"><span>•</span> For numbers, indicates estimate is equal to 0 victimizations.</span>',
      '<span class="small-multiples-subtitle-span"><span>•</span> For rates, indicates estimate rounds to less than .05 victimizations per 1,000.</span>',
      '<span class="small-multiples-subtitle-span"><span>•</span> For percents, indicates estimate rounds to less than .5 percent.</span>'
    ],
    raceNote: [
      '“Other” includes the racial categories of Asian, Native Hawaiian and Other Pacific Islander, American Indian and Alaska Native, and persons of two or more races. Race categories exclude persons of Hispanic/Latino origin. See <a href="/terms#race_origin" target="/terms">Terms and Definitions</a> for more information.',
      'For more information on race/Hispanic origin and changes to categories over time, see <a href="/methodology#collectRace" target="/methodology">“Collection and Reporting of Race/Hispanic origin Data”</a> section in the User’s Guide.'
    ],
    exception2006: [
      `† Estimates for 2006 should not be compared to other years. See <a href="/methodology#dataComp" target="/methodology">User’s Guide</a> for more information.`
    ],
    gen2016Note: [
      'In October 2018, BJS released a revised set of 2016 NCVS data. See <a href="/methodology#dataComp" target="/methodology">User’s Guide</a> for more information.'
    ],
    age2016Note: [
      'One limitation of the 2016 NCVS revised data file relates to youth 12-17 years old. A significant portion of youth crime occurs at school and therefore, an inherent limitation of the file is that the youth in the sample had less time in school based on the cases that were pulled for the revised file. Please use caution when examining crime estimates for youth using 2016 data.'
    ],
    householdIncomeNote: ['Income was imputed beginning in 2015, so no cases in 2015 or later have unknown income. See <a href="/terms#hh_inc" target="/terms#terms">Terms and Definitions</a> for more information.'],
    reportingReasonNote: [
      'In this tool, data on most important reasons for reporting to police are only found in the Quick Graphics section. See <a href="/terms#reportPol" target="/terms#terms">Terms and Definitions</a> for more information.'
    ],
    notReportingReasonNote: [
      'In this tool, data on most important reasons for not reporting to police are only found in the Quick Graphics section. See <a href="/terms#reportPol" target="/terms#terms">Terms and Definitions</a> for more information.'
    ],
    sourceNote: [
      'Source: Bureau of Justice Statistics, National Crime Victimization Survey'
    ]
  }
}
