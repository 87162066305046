<template>
  <div
    class="container staticPg"
    id="terms"
  >
    <div
      class="row"
      id="termsIntro"
    >
      <div
        class="container"
        id="termstop"
      >
        <div class="row mt-5">
          <div class="col col-12  mb-lg-3">
            <h1 class="sg">
              Terms & Definitions
            </h1>
          </div>
        </div>
      </div>
    </div>

    <div class="row">
      <div class="container staticPg">
        <div class="row mb-md-4">
          <div class="col col-12 col-md-8 mt-3 mb-5">
            <h5 id="Age">
              Age
            </h5>
            <div class="pl-4">
              <p>
                The
                respondent’s age on the last day of the month before the interview. The NCVS
                collects information on household members age 12 or older.
              </p>
              <p>
                The N-DASH uses the following coding for age:
              </p>
              <ul>
                <li>
                  12 to 14
                </li>

                <li>
                  15 to 17
                </li>

                <li>
                  18 to 20
                </li>

                <li>
                  21 to 24
                </li>

                <li>
                  25 to 34
                </li>

                <li>
                  35 to 49
                </li>

                <li>
                  50 to 64
                </li>

                <li>
                  65 or older
                </li>
              </ul>
            </div>

            <h5>Aggravated assault</h5>
            <div class="pl-4">
              <p>
                An
                attack or attempted attack with a weapon, regardless of whether the victim is
                injured, or an attack without a weapon when serious injury results.
              </p>
            </div>

            <h5>Assault</h5>
            <div class="pl-4">
              <p>
                The threat, attempt, or intentional infliction of bodily injury. Assault may be classified as aggravated or simple. Excludes rape, attempted rape, sexual assault, robbery, and attempted robbery. The severity of assault ranges from minor threats to near fatal incidents.
              </p>
            </div>
            <h5>Burglary</h5>
            <div class="pl-4">
              <!-- <p>
                              Burglary/trespassing, which was previously known as household burglary, includes unlawful or forcible entry or attempted entry of places, including a permanent residence, other residence (e.g., a hotel room or vacation residence), or other structure (e.g., a garage or shed). Includes victimizations where the offender stole, attempted to steal, or did not attempt to steal. Does not include trespassing on land.
                Includes only crimes where the offender committed or attempted a theft.
              </p>-->

              <p>Includes unlawful or forcible entry or attempted entry of places, including a permanent residence, other residence (e.g., a hotel room or vacation residence), or other structure (e.g., a garage or shed). Includes only crimes where the offender committed or attempted a theft.</p>
            </div>

            <h5 id="ci_description">
              Confidence intervals
            </h5>
            <div class="pl-4">
              <p>
                Confidence intervals are another measure of the margin of error. For rates and counts, a confidence interval around the estimate can be generated by multiplying the standard errors by ±1.96 (the t-score of a normal, two-tailed distribution that excludes 2.5% at either end of the distribution). Therefore, the 95% confidence interval around an estimate is the estimate ± (the standard error X 1.96). In other words, if different samples using the same procedures were taken from the U.S. population, 95% of the time the estimate would fall within that confidence interval.

                For percentage estimates, the logit transformation is used to improve coverage and to ensure the confidence limits are on the interval [0,1]. The logit transformation confidence interval is calculated as
                <img
                  class="d-inline-block CI_eq"
                  src="../../assets/CI_eq1b.svg"
                  alt="ln(p1-p)±1.96Var(p)p(1-p)"
                >
                . The upper and lower confidence limits are then back-transformed to the original scale. For example, the back-transformed lower limit would equal <img
                  class="d-inline-block CI_eq"
                  src="../../assets/CI_eq2b.svg"
                  alt="Lp=exp⁡(Llogit)1+exp⁡(Llogit)"
                > where <span
                  style="font-family: serif; font-size: 1.2em;
"
                ><i>L<sub>logit</sub></i></span> is the lower bound of the logit transformation confidence interval.
              </p>
            </div>

            <h5>Crime category</h5>
            <div class="pl-4">
              <p>
                Refers to personal victimizations (rape/sexual assault, robbery, aggravated assault, simple assault, and personal theft/larceny) or property victimizations (burglary, trespassing, motor vehicle theft, and other theft).
              </p>
            </div>

            <h5>Crime classification</h5>
            <div class="pl-4">
              <p>
                The NCVS classifies victimizations and incidents based on detailed characteristics of the event provided by the respondent. Neither victims nor interviewers classify crimes at the time of interview. During data processing, a computer program classifies each event into one type of crime based on the entries for some items on the survey questionnaire. This ensures that similar events will be classified using a standard procedure. The definition for each crime indicates the major characteristics required for classification. If an event can be classified as more than one type of crime, the NCVS uses a hierarchy that classifies the crime according to the most serious event that occurred. The hierarchy is as follows:
              </p>
              <ul>
                <li>rape</li>
                <li>sexual assault</li>
                <li>robbery</li>
                <li>aggravated assault and simple assault</li>
                <li>burglary</li>
                <li>trespassing</li>
                <li>motor vehicle theft</li>
                <li>other theft</li>
              </ul>
            </div>

            <h5 id="demog_char">
              Demographic characteristic
            </h5>
            <div class="pl-4">
              <p>
                A demographic characteristic refers to information about the demographics of the victim or household, such as the victim’s age. The N-DASH includes:
              </p>
              <ul>
                <li>victim age</li>
                <li>victim household income</li>
                <li>victim household size</li>
                <li>victim marital status</li>
                <li>victim MSA (metropolitan statistical area) status</li>
                <li>victim population size</li>
                <li>victim race/Hispanic origin</li>
                <li>victim region</li>
                <li>victim sex </li>
              </ul>
            </div>

            <h5>Estimate flag</h5>
            <div class="pl-4">
              <p>
                There are two types of estimate flags used in the N-DASH. One (<span style="color:#ce4646;">▲</span>) is used to flag estimates that are based on 10 or fewer sample cases or have a coefficient of variation of at least 50%. The other (<span style="color:#ce4646;">✱</span>) is used to flag estimates having a weighted number equal to 0 victimizations, rates that round to less than .05 per 1,000, or percentages that round to less than .5%.
              </p>
            </div>

            <h5>Hispanic</h5>
            <div class="pl-4">
              <p>
                A person who describes himself or herself as Mexican American, Chicano, Mexican, Mexicano, Puerto Rican, Cuban, Central American, South American, or from some other Spanish culture or origin, without considering race.
              </p>
            </div>

            <h5>Household</h5>
            <div class="pl-4">
              <p>
                A person or group of people meeting either of the following criteria:
              </p>
              <ul>
                <li>
                  people whose usual place of residence is the same housing unit, even if they are temporarily absent; or
                </li>
                <li>people staying in a housing unit who have no usual place of residence elsewhere</li>
              </ul>
            </div>

            <h5 id="hh_inc">
              Household income
            </h5>
            <div class="pl-4">
              <p>
                The total income of the household head and all members of the household for the 12 months preceding the interview. Includes wages, salaries, net income from businesses or farms, pensions, interest, dividends, rent, and any other form of monetary income. Income was imputed beginning in 2015, therefore, there is no Unknown category on the household income variable for 2015 or forward. For more information, see <a
                  href="https://www.ojp.gov/pdffiles1/bjs/grants/248563.pdf"
                  target="_blank"
                ><i>Imputing NCVS Income Data</i></a>.
              </p>

              <p>
                The N-DASH
                uses this coding for household income:
              </p>
              <ul>
                <li>
                  Less than $7,500
                </li>

                <li>
                  $7,500 to $14,999
                </li>

                <li>
                  $15,000 to $24,999
                </li>

                <li>
                  $25,000 to $34,999
                </li>

                <li>
                  $35,000 to $49,999
                </li>

                <li>
                  $50,000 to $74,999
                </li>

                <li>
                  $75,000 or more
                </li>

                <li>
                  Unknown <i><small>(only available for 1993-2014)</small></i>
                </li>
              </ul>
            </div>

            <h5>Household size</h5>
            <div class="pl-4">
              <p>
                The total number of people residing in the household. Includes household members under <span style="white-space: nowrap">age 12</span>.
              </p>

              <p>
                The N-DASH
                uses this coding for household size:
              </p>
              <ul>
                <li>
                  One
                </li>

                <li>
                  Two to three
                </li>

                <li>
                  Four to five
                </li>

                <li>
                  Six
                  or more
                </li>
              </ul>
            </div>

            <h5 id="incident_char">
              Incident characteristic
            </h5>
            <div class="pl-4">
              <p>An incident characteristic refers to information about the crime regarding details related to the incident, such as whether the crime was reported to the police. The N-DASH includes:</p>

              <ul>
                <li>injury</li>
                <li>location of incident</li>
                <li>medical treatment for physical injuries</li>
                <li>reporting to the police</li>
                <li>victim-offender relationship</li>
                <li>victim services use</li>
                <li>weapon category (presence and type of weapon)</li>
              </ul>
            </div>

            <h5>Injury</h5>
            <div class="pl-4">
              <p>
                A measure of whether bodily hurt or damage was sustained by a victim as a result of a criminal victimization. This applies only to personal victimization where there was contact between the victim and the offender. Injuries suffered from an attack during a crime incident include any and all physical (bodily) damage experienced by the victim (e.g., broken bones, bruises, cuts, and internal injuries). Emotional and psychological trauma are not included. The N-DASH uses this coding for injury:
              </p>

              <ul>
                <li>Not injured</li>
                <li>Injured</li>
              </ul>
            </div>

            <h5>Location of incident</h5>
            <div class="pl-4">
              <p>
                Specifies where the victimization occurred.<br>
                The N-DASH uses this coding for location of incident:
              </p>
              <ul>
                <li>At or near victim’s home – Includes victim’s residence; detached building on property; vacation or second home; hotel or motel room respondent was staying in; apartment building areas; or on street immediately adjacent to own home</li>
                <li>At or near friend’s, neighbor’s, or relative’s home – includes friend’s, neighbor’s or relative’s residence or other building on their property; their home’s yard, sidewalk or driveway; their apartment building areas; or on the street immediately adjacent to their home</li>
                <li>Commercial place, parking lot, other public area – includes inside a retail, office, or other commercial building; commercial, noncommercial, or apartment parking lot/garage; park or field; on the street; or on public transportation</li>
                <li>School – includes inside school building or on school property (school parking area, play area, school bus, etc.)</li>
                <li>Other location</li>
              </ul>
            </div>

            <h5>Marital status</h5>
            <div class="pl-4">
              <p>
                The status of an individual in relation to marriage, classified as follows:
              </p>
              <ul>
                <li>married, which includes persons in common-law unions and those who are living apart for reasons other than marital discord (e.g., employment and military service)</li>
                <li>separated or divorced, which includes married persons who are legally separated and those who are not living together because of marital discord</li>
                <li>widowed, or </li>
                <li>never married, which includes persons whose marriages have been annulled and those who are living together and not in a common-law union</li>
              </ul>

              <p>
                The N-DASH uses this coding for marital status:
              </p>
              <ul>
                <li>
                  Never married
                </li>

                <li>
                  Married
                </li>

                <li>
                  Widowed
                </li>

                <li>
                  Divorced
                </li>

                <li>
                  Separated
                </li>
              </ul>
            </div>

            <h5>
              Medical treatment for physical injuries
            </h5>
            <div class="pl-4">
              <p>
                Victims who were injured during an incident are asked whether they received any medical care for their injuries. Medical care includes treatment rendered by trained professionals, paraprofessionals, and nonprofessionals, and self-treatment.
              </p>

              <p>
                The N-DASH
                uses this coding for medical treatment for physical injuries:
              </p>
              <ul>
                <li>
                  Not injured
                </li>

                <li>
                  Not treated
                </li>

                <li>
                  Treated<span>
                    (at scene, home, medical office, or other location)</span>
                </li>
              </ul>
            </div>

            <h5>Motor vehicle theft</h5>
            <div class="pl-4">
              <p>
                The
                unlawful taking or attempted taking of a self-propelled road vehicle owned by
                another, with the intent of permanently or temporarily depriving the owner of
                possession. Excludes vehicle parts.
              </p>
            </div>

            <h5>Metropolitan Statistical Area (MSA)</h5>
            <div class="pl-4">
              <p>
                Classification of respondent residence based on the Office of Management and Budget (OMB) definition of metropolitan statistical areas (MSAs). The OMB defines MSA as a population nucleus of 50,000 or more, generally consisting of a city and its immediate suburbs, along with adjacent communities having a high degree of economic and social integration with the nucleus. A principal city is the largest city in each MSA. Additional cities qualify if specified requirements are met concerning population size and employment.<br>
                The N-DASH uses this coding for MSA:
              </p>

              <ul>
                <li>Principal city within MSA</li>
                <li>Not part of principal city within MSA</li>
                <li>Outside MSA</li>
              </ul>
            </div>

            <h5>Offender</h5>
            <div class="pl-4">
              <p>
                The
                perpetrator of a crime. This term usually applies to crimes involving direct
                contact between the victim and the offender.
              </p>
            </div>

            <h5>Other theft</h5>
            <div class="pl-4">
              <p>
                Includes other unlawful taking or attempted unlawful taking of property or cash without personal contact with the victim.
              </p>
            </div>

            <h5>
              Personal theft/larceny
            </h5>
            <div class="pl-4">
              <p>
                Includes purse-snatching or pocket-picking. Theft or attempted theft of property or cash directly from the
                victim by stealth, without force or threat of force.
              </p>
            </div>

            <h5 id="personal_vic">
              Personal victimization
            </h5>
            <div class="pl-4">
              <p>
                An
                offense category consisting of rape/sexual assault, personal robbery, aggravated assault and simple assault,
                and personal theft/larceny. Includes both attempted and completed
                crimes.
              </p>
            </div>

            <h5>Population size</h5>
            <div class="pl-4">
              <p>
                The size
                range for the place in which the housing unit is located. &quot;Not a
                place&quot; is a concentration of population that is either not legally bounded
                as an incorporated place having an active government or not delineated for
                statistical purposes as a census designated place with definite geographic
                boundaries, such as a city, town, or village. Population size is not available
                for years 1993-1995.
              </p>

              <p>
                The N-DASH
                uses this coding for population size:
              </p>
              <ul>
                <li>Not a place</li>

                <li>Under 100,000</li>

                <li>100,000 to 249,999</li>

                <li>250,000 to 499,999</li>

                <li>500,000 to 999,999</li>

                <li>1 million or more</li>
              </ul>
            </div>

            <h5 id="property_vic">
              Property victimization
            </h5>
            <div class="pl-4">
              <p>
                Includes
                burglary, trespassing, motor vehicle theft, or other theft. Includes both attempted
                and completed crimes. Property victimizations measure crimes against households. Each time a household is affected by a property crime, it is counted as a single victimization.
              </p>
            </div>

            <h5 id="race_origin">
              Race/Hispanic origin
            </h5>
            <div class="pl-4">
              <p>
                Beginning in 2003, BJS implemented methodological changes to reflect new guidelines from the Office of Management and Budget (OMB) for the collection and reporting of race and Hispanic origin data in government surveys. These implementations caused changes to the “Other” race category. Prior to 2003, the “Other” race category included American Indian/Aleut Eskimo, Asian/Pacific Islander, and other races. Since 2003, the “Other” race category has included American Indian/Alaska Native, Asian, Native Hawaiian/Other Pacific Islander, other races, and two or more races. For the National Crime Victimization Survey, respondents may self-identify with one or more racial categories. See also “<a
                  href="/methodology#collectRace"
                  target="methodology"
                >Collection and Reporting of Race/Hispanic Origin Data</a>” in the <a
                  href="/methodology"
                  target="methodology"
                ><i>User’s Guide</i></a>.
              </p>
              <b>Race</b>
              <div class="pl-3">
                <p>
                  Racial categories are defined by the Office of Management and Budget (OMB).
                  <br>In general, the N-DASH uses the following coding for race:
                </p>
                <ul>
                  <li>White</li>
                  <li>Black</li>
                  <li>Other (Asian, Native Hawaiian or Other Pacific Islander, American Indian or Alaska Native, or persons of two or more races)</li>
                </ul>
              </div>
              <b>Hispanic origin</b>
              <div class="pl-3">
                <p>
                  A classification based on Hispanic culture and origin, without considering race.
                </p>
              </div>

              <b>Race/Hispanic origin</b>
              <div class="pl-3">
                <p>Race and Hispanic origin are combined into one variable in this platform, using the following categories for <b>Custom Graphics:</b></p>
                <ul>
                  <li>White (non-Hispanic)</li>
                  <li>Black (non-Hispanic)</li>
                  <li>
                    Other (non-Hispanic and American Indian or Alaska Native; Asian; Native Hawaiian or Other Pacific Islander; or persons of two or more races)
                  </li>
                  <li>Hispanic</li>
                </ul>
                <p>Race and Hispanic origin are combined into one variable in this platform, using the following categories for <b>Quick Graphics:</b></p>
                <ul>
                  <li>White (non-Hispanic)</li>
                  <li>Black (non-Hispanic)</li>
                  <li>Asian/Native Hawaiian or Other Pacific Islander (non-Hispanic)</li>
                  <li>
                    American Indian or Alaska Native, and or persons of two or more races (non-Hispanic)
                  </li>
                  <li>Hispanic</li>
                </ul>
              </div>
            </div>

            <h5>Rape</h5>
            <div class="pl-4">
              <p>
                Unlawful penetration of a person against the will of the victim, with use or threatened use of force, or attempting such an act. Includes psychological coercion and physical force. Forced sexual intercourse means vaginal, anal, or oral penetration by the offender. Also includes incidents where penetration is from a foreign object, such as a bottle. Includes male and female victims, and heterosexual and same-sex rape. Attempted rape includes verbal threats of rape. Rape and sexual assault are combined into one victimization measure.
              </p>
            </div>

            <h5>Region</h5>
            <div class="pl-4">
              <p>
                Region of respondent residence.<br>
                The states have been divided into the following four groups or census regions:
              </p>
              <ul>
                <li>
                  <b>Midwest -</b> Includes the 12 states of Illinois, Indiana, Iowa, Kansas, Michigan, Minnesota, Missouri, Nebraska, North Dakota, Ohio, South Dakota, and Wisconsin
                </li>
                <li><b>Northeast -</b> Includes the 9 states of Connecticut, Maine, Massachusetts, New Hampshire, New Jersey, New York, Pennsylvania, Rhode Island, and Vermont</li>
                <li>
                  <b>South -</b> Includes the District of Columbia and the 16 states of Alabama, Arkansas, Delaware, Florida, Georgia, Kentucky, Louisiana, Maryland, Mississippi, North Carolina, Oklahoma, South Carolina,
                  Tennessee, Texas, Virginia, and West Virginia
                </li>
                <li><b>West -</b> Includes the 13 states of Alaska, Arizona, California, Colorado, Hawaii, Idaho, Montana, Nevada, New Mexico, Oregon, Utah, Washington, and Wyoming</li>
              </ul>
            </div>

            <h5 id="reportPol">
              Reporting to the Police
            </h5>
            <div class="pl-4">
              <p>
                Specifies whether the crime was reported to police, whether by the respondent or someone else.
              </p>

              <b>Reasons for reporting to police</b>
              <p>The NCVS asks respondents about 12 potential reasons for reporting a victimization to police. For ease of presentation, those data are collapsed into the six categories presented in this tool.</p>
              <ul>
                <li><b>Catch or punish offender -</b> Includes victims who wanted to find or catch offender or who wanted to punish the offender.</li>
                <li>
                  <b>Civic duty -</b> Includes victims who believed it was their duty to let the police know about crime, or who reported the incident because it was a crime.
                </li>
                <li>
                  <b>Crime not reported by the victim -</b> Includes crimes reported by other household members or someone official other than police; crimes in which police were at the scene or offender was a police officer, or someone else notifying the police.
                </li>
                <li>
                  <b>Protect the public -</b> Includes victims who wanted to stop this offender from committing crimes against anyone or wanted to improve police surveillance of respondent's home, area, etc.
                </li>

                <li>
                  <b>Self-protection -</b> Includes victims who wanted to stop or prevent this incident from happening, needed help after the incident due to injury, etc., or wanted to prevent further crimes against respondent/respondent's household by this offender.
                </li>
                <li>
                  <b>To recover loss -</b> Includes victims who wanted to recover property or collect insurance.
                </li>
                <li>
                  <b>Other -</b> Includes victims who said no one reason was more important, who provided another reason for reporting, or had unknown reasons for reporting.
                </li>
              </ul>
              <b>Reasons for <u>not</u> reporting to police</b>
              <p>The NCVS asks respondents about 19 potential reasons for not reporting a victimization to police. For ease of presentation, those data are collapsed into the six categories presented in this tool.</p>
              <ul>
                <li>
                  <b>Dealt with it another way -</b> Includes crime reported to another official (e.g., guard, apartment manager, or school official) or victims who took care of it themselves or informally.
                </li>
                <li>
                  <b>Did not want to get offender in trouble with law or was advised not to report</b>
                </li>
                <li>
                  <b>Fear of reprisal</b>
                </li>
                <li>
                  <b>Not important enough to respondent -</b> Includes victims who said it was a minor or unsuccessful crime, the offender(s) was a child, it was not clear the incident was criminal or that harm was intended, or insurance would not cover the losses.
                </li>
                <li>
                  <b>Police could not or would not do anything to help -</b> Includes victims who indicated they did not find out about the crime until too late, they could not find or identify the offender, they lacked proof of the incident, they thought police would not think it was important enough, they believed police would be inefficient or ineffective, they thought police would cause trouble for the victim, or the offender was a police officer.
                </li>
                <li>
                  <b>Other, unknown, or no single most important reason -</b> Includes victims who indicated they did not want to or could not take time to report, provided some other reason for not reporting, said no one reason was more important than another, or had unknown reasons for not reporting.
                </li>
              </ul>
            </div>

            <h5>Robbery</h5>
            <div class="pl-4">
              <p>
                The
                unlawful taking or attempted taking of property that is in the immediate
                possession of another, by force or threat of force, with or without a weapon,
                and with or without injury.
              </p>
            </div>

            <h5>Series victimization</h5>
            <div class="pl-4">
              <p>
                Series victimizations, or high-frequency repeat victimizations, are six or more similar but separate victimizations that occur with such frequency that the victim is unable to recall each individual event or describe each event in detail. BJS counts series victimizations using the victim’s estimate of the number of times victimizations occurred during the prior six months, up to a maximum of 10 victimizations. Including series victimizations in national estimates can substantially increase the number and rate of violent victimization; however, trends in violence are generally similar regardless of whether series victimizations are included. See <a
                  href="https://bjs.ojp.gov/library/publications/methods-counting-high-frequency-repeat-victimizations-national-crime"
                  target="_blank"
                ><i>Methods for Counting High-Frequency Repeat Victimizations in the National Crime Victimization Survey</i></a> for further discussion of the counting strategy and supporting research.
              </p>
            </div>

            <h5>Sex</h5>
            <div class="pl-4">
              <p>
                The
                respondent’s sex.
              </p>
            </div>

            <h5>Sexual assault</h5>
            <div class="pl-4">
              <p>
                Sexual assault encompasses a wide range of victimizations, separate from rape or attempted rape. Includes attacks or attempted attacks generally involving unwanted sexual contact between victim and offender, with or without force. Includes grabbing or fondling and verbal threats. Rape and sexual assault are combined into one victimization measure.
              </p>
            </div>

            <h5>Simple assault</h5>
            <div class="pl-4">
              <p>
                An attack or attempted attack without a weapon that results in no injury, minor injury (e.g., bruises, black eyes, cuts, scratches, and swelling), or an undetermined injury requiring fewer than two days of hospitalization.
              </p>
            </div>

            <h5 id="se_description">
              Standard error
            </h5>
            <div class="pl-4">
              <p>
                A
                measure of the sampling error associated with an estimate. The standard error
                of a sample estimate is a measure of the variation among a sample estimate and
                all of the possible sample estimates that could be obtained.
              </p>
            </div>

            <h5>Theft</h5>
            <div class="pl-4">
              <p>
                Also called “other theft”. The
                unlawful taking or attempted unlawful taking of property or cash without personal
                contact with the victim. Incidents involving theft of property from within a
                household are classified as theft if the offender has a legal right to be in
                the house (e.g., a maid, delivery person, or guest). The incident is classified
                as a burglary or trespassing if the offender has no legal right to be in the
                house.
              </p>
            </div>

            <h5>Trespassing</h5>
            <div class="pl-4">
              <!--    <p>
                           Burglary/trespassing, which was previously known as household burglary, includes unlawful or forcible entry or attempted entry of places, including a permanent residence, other residence (e.g., a hotel room or vacation residence), or other structure (e.g., a garage or shed). Includes victimizations where the offender stole, attempted to steal, or did not attempt to steal. Does not include trespassing on land.
                Includes crimes where the offender did not commit or attempt a theft. Excludes trespassing on land.
              </p>-->
              <p>Includes unlawful or forcible entry or attempted entry of places, including a permanent residence, other residence (e.g., a hotel room or vacation residence), or other structure (e.g., a garage or shed). Includes only crimes where the offender did not commit or attempt a theft. Does not include trespassing on land.</p>
            </div>

            <h5>Unit</h5>
            <div class="pl-4">
              <p>
                The N-DASH can display three different types of estimates: number of victimizations, rate of victimization, or percentage of victimizations for the characteristics selected. See “<a
                  href="/methodology#unitdef"
                  target="methodology"
                >Units of Measurement and Interpreting Dashboard Results</a>” in the <a
                  href="/methodology"
                  target="methodology"
                ><i>User’s Guide</i></a> for information on how these estimates are calculated and how to interpret them.
              </p>
            </div>

            <h5>Victim</h5>
            <div class="pl-4">
              <p>
                The
                recipient of a criminal act, usually used in relation to personal crimes but
                also applicable to households.
              </p>
            </div>

            <h5>Victimization</h5>
            <div class="pl-4">
              <p>
                A single
                victim or household that experienced a criminal incident. Criminal incidents or
                crimes are distinguished from victimizations in that one criminal incident may
                have multiple victims or victimizations. For violent crimes (rape/sexual
                assault, robbery, aggravated assault, and simple assault) and for personal theft/larceny, the count of victimizations is the number of times individuals experienced a violent crime. For crimes against households
                (burglary, trespassing, other theft, and motor vehicle theft), each household affected
                by a crime is counted as a single victimization.
              </p>
            </div>

            <h5>Victimization rate</h5>
            <div class="pl-4">
              <p>
                A
                measure of the occurrence of victimizations among a specified population group.
                For personal crimes, this is based on the number of victimizations per 1,000
                residents age 12 or older. For property crimes, the victimization rates are
                calculated using the number of incidents per 1,000 households.
              </p>

              <p>
                <b>Personal victimization
                  rate</b><br><span class="pl-4">
                  (Number of
                  victimizations × 1,000) /
                  Number of persons</span>
              </p>

              <p>
                <b>Household victimization
                  rate</b><br><span class="pl-4">
                  (Number of
                  victimizations × 1,000) /
                  Number of households</span>
              </p>
            </div>

            <h5>Victim services use</h5>
            <div class="pl-4">
              <p>
                A measure of whether victims received any help or advice from victim service agencies. This is asked of victims of both personal and property victimization. Victim service agencies are publicly or privately funded organizations that provide victims with support and services to aid their physical and emotional recovery, offer protection from future victimizations, guide them through the criminal justice system process, and assist them in obtaining restitution.
              </p>

              <p>
                The N-DASH
                uses this coding for victim services use:
              </p>
              <ul>
                <li>
                  Yes (services received from victim service agencies)
                </li>

                <li>
                  No
                  (no services received from victim service agencies)
                </li>
              </ul>
            </div>
            <h5>
              Victim-offender
              relationship
            </h5>
            <div class="pl-4">
              <p>
                A classification of a crime victim’s relationship to the offender for crimes involving direct contact between the two. For the analysis tool, this applies to crimes that involve contact between the victim and the offender (personal victimization). This distinction is not made for property victimization.
              </p>

              <p>
                The N-DASH
                uses this coding for the victim-offender relationship:
              </p>
              <ul>
                <li>
                  Intimate partners (i.e., current or
                  former spouses, boyfriends, and girlfriends)
                </li>

                <li>
                  Other relatives (i.e., parents, step-parents, children, step-children, brothers, sisters, and other relatives)
                </li>

                <li>
                  Well-known/casual acquaintances (i.e., friends or former friends, roommates or boarders, schoolmates, neighbors, people at work, and other known nonrelatives)
                </li>

                <li>
                  Strangers (i.e., anyone not previously known by the victim)
                </li>

                <li>
                  Don’t know relationship
                </li>

                <li>
                  Don’t know number of offenders
                </li>
              </ul>
            </div>

            <h5>
              Violent crime excluding
              simple assault
            </h5>
            <div class="pl-4">
              <p>
                Traditionally called serious violent victimization. Rape/sexual assault, personal robbery, or aggravated assault. Includes attempted and completed crimes. Murder is not measured by the NCVS because of an inability to question the victim.
              </p>
            </div>

            <h5>Violent victimization</h5>
            <div class="pl-4">
              <p>
                Rape/sexual assault, personal robbery, aggravated assault, or simple assault. Includes attempted and completed crimes. Excludes personal theft/larceny. Murder is not measured by the NCVS because of an inability to question the victim. Violent victimizations measure crimes against persons. Each time a person is affected by a violent crime, it is counted as a single victimization.
              </p>
            </div>

            <h5>Weapon category (presence and type of weapon)</h5>
            <div class="pl-4">
              <p>
                A
                measure of whether a weapon was present during the victimization. The
                respondent is asked whether the offender had a weapon. This applies only to personal victimizations where there was contact between the victim and the offender. Weapons include guns, knives, and other objects that are used as weapons (e.g., rocks
                and clubs).  By definition, neither simple
                assault nor personal theft involves a weapon.
              </p>

              <p>
                Users can obtain
                information about the type of weapon. The N-DASH uses this
                coding for weapon type:
              </p>
              <ul>
                <li>No weapon</li>

                <li>Firearm</li>

                <li>Knife</li>

                <li>Other weapon type</li>

                <li>
                  Unknown
                  weapon type
                </li>

                <li>
                  Don’t know if offender had weapon
                </li>
              </ul>
            </div>
          </div>
        </div>
        <!--      end row-->
      </div>
    </div>
  </div>
  <!--end container-->
</template>

<script>
export default {
  name: 'TermsAndDefinitions'
}

</script>
