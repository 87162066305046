<template>
  <div class="col col-12">
    <highcharts
      :options="chartOptions"
      ref="highchartsChart"
    />
  </div>
</template>

<script>
import { checkForFlags } from '@/chartTitleFormatter'
// import { capitalizeFirstLetter } from '@/utils'
export default {
  name: 'SmallMultLegend',
  props: {
    'chartIds': {
      type: Array,
      required: true
    }
  },
  computed: {
    checkCI () {
      return (this.filters['confidenceInterval'] && this.getFilter('confidenceInterval') === true) || false
    },
    checkUnitFlag () {
      return (this.filters['unitFlag'] && this.getFilter('unitFlag') === true && checkForFlags(this, this.chartIds)) || false
    },
    checkReferenceLine () {
      return (this.filters['referenceLine'] && this.getFilter('referenceLine') === true) || false
    },
    oneToggleOn () {
      return this.checkCI || this.checkUnitFlag || this.checkReferenceLine
    },
    makeRefName () {
      if (this.getRequestCache() && this.getRequestCache().reference) {
        return this.getRequestCache().reference.refSeries.refName
      } else {
        return null
      }
    },
    makeUnitName () {
      return '<span style="fill:#ce4646">' + '✱/▲ ' + '</span>' + 'Estimate flag'
    },
    chartOptions () {
      return {
        chart: {
          type: 'area',
          spacing: [0, 0, 0, 0],
          marginTop: 0,
          marginBottom: 0,
          spacingTop: 0,
          height: this.oneToggleOn ? 60 : 1 // Must be 1 pixel
        },
        credits: {
          enabled: false
        },
        title: {
          text: null
        },
        subtitle: {
          text: null
        },
        xAxis: {
          visible: false
        },
        yAxis: {
          visible: false
        },
        legend: {
          verticalAlign: 'middle',
          maxHeight: this.oneToggleOn ? 60 : 0
        },
        plotOptions: {
          series: {
            events: {
              legendItemClick: function (e) {
                e.preventDefault()
              }
            }
          }
        },
        series: [
          {
            name: '95% C.I.',
            data: [],
            type: 'arearange',
            lineColor: '#cebdb0',
            color: this.filters['confidenceInterval'] && this.getFilter('confidenceInterval') === true ? '#efe3dc' : '#fff', // fill color between lines,
            fillOpacity: this.filters['confidenceInterval'] && this.getFilter('confidenceInterval') === true ? 0.4 : 0.0, // opacity between lines
            opacity: this.filters['confidenceInterval'] && this.getFilter('confidenceInterval') === true ? 1.0 : 0.0, // opacity of lines
            showInLegend: this.checkCI,
            marker: {
              enabled: false,
              symbol: null,
              states: {
                hover: {
                  enabled: false
                }
              }
            }
          },
          {
            name: this.makeUnitName,
            data: [],
            type: 'line',
            lineWidth: 0,
            color: '#000',
            showInLegend: this.checkUnitFlag,
            marker: {
              enabled: false,
              symbol: null,
              states: {
                hover: {
                  enabled: false
                }
              }
            }
          },
          {
            name: this.makeRefName,
            className: 'Unit Flag',
            data: [],
            type: 'line',
            showInLegend: this.checkReferenceLine,
            color: '#606060',
            dashStyle: 'ShortDash',
            marker: {
              enabled: false,
              symbol: null,
              states: {
                hover: {
                  enabled: false
                }
              }
            }
          }
        ],
        exporting: {
          enabled: false
        }
      }
    }
  }
}
</script>
