<template>
  <div>
    <highcharts
      v-if="this.getChartData(this.chart.key) !== false"
      :options="chartOptions"
      ref="highchartsChart"
    />
    <notEligible
      v-if="this.getChartData(this.chart.key) === false"
    />
  </div>
</template>

<script>
import { mapGetters, mapActions } from 'vuex'
import chartSettings from './chartSettings'
import getCrimeTypeTitle, { checkForFlags } from '@/chartTitleFormatter'
import assignNumberColumns from '@/assignColumns'
import notEligible from '../notEligible'
import Highcharts from 'highcharts'
import { axisLabelFormatter, pluralsFix, labelLowerCase } from '@/utils'

const hex2rgba = (hex, alpha = 1) => {
  const [r, g, b] = hex.match(/\w\w/g).map(x => parseInt(x, 16))
  return `rgba(${r},${g},${b},${alpha})`
}

function numberWithCommas (x) {
  return x.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ',')
}

export default {
  name: 'AreaChart',
  mixins: [chartSettings, assignNumberColumns],
  props: {
    'height': {
      type: Number,
      required: false,
      default: null
    },
    'smallMultiplesTitle': {
      type: String,
      required: false,
      default: ''
    },
    'spacingTop': {
      type: Number,
      required: false,
      default: 80
    }
  },
  components: { notEligible },
  data () {
    return {
      chartRef: null
    }
  },
  computed: {
    ...mapGetters([
      'isMobileView',
      'refSeries',
      'mousedSmallMultipleName',
      'mousedSmallMultiplesTitle'
    ]),
    computedHideYaxis () {
      // SHOW axis in mobile view
      if (this.isMobileView) {
        return false
      }
      // if dynamic SM, determine which small multiples do not need to hide the Y axis
      if (!this.dynamic) {
        return this.hideYaxis
      } else {
        if (this.smallMultipleId % this.assignNumberColumns === 0) {
          return false
        } else {
          return this.hideYaxis
        }
      }
    },
    chartOptions () {
      let crimeSelected
      if (this.hs.filters['crimeType']) {
        crimeSelected = this.getFilter('crimeType')
      } else {
        crimeSelected = ''
      }
      let isCrimeType = this.getChartData(this.chart.key) ? this.getChartData(this.chart.key).crimeTypePage : null
      return {
        chart: {
          type: 'area',
          height: this.calculateHeight(),
          // small mults need top spacing top account for longest possible title ('treated at home....', for bottom aligned floating titles
          spacingTop: this.smallMultiplesTitle ? this.spacingTop : 10,
          spacingBottom: 0,
          // true is axis hidden
          spacingLeft: this.computedHideYaxis ? 69 : 0,
          spacingRight: 3,
          className: 'areaChart' + ' ' + this.smallMultiplesTitle ? this.getChartData(this.chart.key) ? this.getChartData(this.chart.key)['title'] ? this.getChartData(this.chart.key)['title'].length > 0 ? ((this.getChartData(this.chart.key)['title']).replace(/\s/g, '')).substring(0, 12) : '' : '' : '' : '',
          style: {
            overflow: 'visible'
          }
        },
        credits: {
          enabled: false
        },
        title: {
          // getCrimeTypeTitle returns an HTML snippet that includes a subtitle
          text: (this.smallMultiplesTitle && this.getChartData(this.chart.key)) ? this.getChartData(this.chart.key)['title'] : getCrimeTypeTitle(this, this.chart.key),
          // prevent title under burger menu, and this seems to enforce wrap check on width w title change
          // with adjustment for long race label on QG
          widthAdjust: this.smallMultiplesTitle ? (this.hs.pageKey === 'quickGraphics' ? -80 : -60) : -100,
          align: 'center',
          // only float for small mults, the others can adjust as needed for long titles w/o issue
          floating: this.smallMultiplesTitle,
          // bottom align for small mult titles that act as column headers
          verticalAlign: this.smallMultiplesTitle ? 'bottom' : null,
          style: this.smallMultiplesTitle ? {
            fontWeight: 600,
            fontSize: '1.03rem',
            letterSpacing: '.03rem'
          } : {
            fontWeight: 500,
            fontSize: '1.5rem'
          },
          // for small mults, this is the chart height minus the spacing top, minus 10. Else, 10
          y: this.smallMultiplesTitle ? -(this.calculateHeight() - this.spacingTop) : 10,
          x: !this.computedHideYaxis ? 35 : 0
        },
        subtitle: {
          text: this.smallMultiplesTitle ? null : this.formattedSubtitle || null,
          align: 'left',
          verticalAlign: 'bottom',
          style: {
            fontSize: '11px'
          },
          useHTML: true
          // this has to remain floating in order to ensure
          // floating: true,
          // y: 40
        },
        yAxis: [
          {
            title: {
              //              text: !this.computedHideYaxis ? this.chartSettings.yAxisTitleText : null
              text: !this.computedHideYaxis ? this.calculateYAxisText(crimeSelected, isCrimeType) : null
            },
            labels: {
              enabled: !this.computedHideYaxis,
              formatter: function () {
                return axisLabelFormatter(this, this.chart.series[1].name)
              }
            },
            max: this.calculateMaxAxisToShow(),
            // tickInterval: this.calculateMaxAxisToShow() / 4,
            endOnTick: true,
            // potential fix for inconsistent axis problem
            alignTicks: false,
            margin: 0
          },
          {
            title: {
              // text: this.chartSettings.yAxisTitleText
              text: this.calculateYAxisText(crimeSelected, isCrimeType)
            },
            max: this.getChartData(this.chart.key) ? this.getChartData(this.chart.key)['Flag'] : null,
            height: 2,
            top: '99%',
            visible: false,
            // potential fix for inconsistent axis problem
            alignTicks: false,
            endOnTick: true
          }
        ],
        xAxis: {
          type: 'datetime',
          dateTimeLabelFormats: {
            year: '%y'
          },
          tickInterval: 5,
          minTickInterval: 'year',
          offset: 20,
          max: this.getChartData(this.chart.key) && this.getChartData(this.chart.key)['maxYear'] ? this.getChartData(this.chart.key)['maxYear'] : null,
          crosshair: {
            width: 1,
            color: '#1a4480'
          },
          labels: {
            // The arrow syntax allows us to keep a reference to the formatter context (fnCtx),
            // while also allowing us to reference the component context (this) within the function
            formatter: (fnCtx) => {
              // Show the whole year for the first tick and last tick (if not a small multiple)
              if (fnCtx.isFirst || fnCtx.isLast) {
                return Highcharts.dateFormat('%Y', Date.UTC(fnCtx.value))
              } else {
                return '’' + Highcharts.dateFormat('%y', Date.UTC(fnCtx.value))
              }
            },
            style: {
              fontWeight: 'normal',
              fontSize: '11px',
              whiteSpace: 'nowrap',
              overflow: 'visible',
              textOverflow: 'none'
            }
            // overflow: 'allow'
          },
          title: {
            text: 'Year'
          },
          minorTicks: true,
          minorGridLineDashStyle: 'longdash'
          // minorTickLength: 10
        },
        tooltip: {
          crosshairs: false,
          shared: true,
          valueSuffix: this.chartSettings.valueSuffix,
          headerFormat: this.chartSettings.tooltipHeader,
          shadow: false,
          borderWidth: this.smallMultiplesTitle ? 0 : 1,
          animation: false,
          backgroundColor: 'rgba(255,255,255,0.8)',
          showSeries: function () {
            let show
            if (this.smallMultiplesTitle) {
              show = this.mousedSmallMultipleName === this.chart.key
            } else {
              show = true
            }
            return show
          }.bind(this),
          formatter: function () {
            let points = this.points
            let estimatePoint = points[0]
            let showSeries = estimatePoint.series.tooltipOptions.showSeries()
            let is06 = estimatePoint.key === 2006 ? '†' : ''
            let retStr = '<b>' + estimatePoint.key + is06 + '</b><br>'
            let pointValue
            let bulletValue
            let unit = estimatePoint.series.options.name === 'Count' ? 'Number' : estimatePoint.series.options.name
            if (showSeries) {
              points.forEach(point => {
                if (point.series.options.className !== 'refEstimates') {
                  bulletValue = '<span style="color:' + point.series.color + ';fill-opacity:' + point.series.opacity + '">● </span>'
                  pointValue = point.series.name === '95% C.I.'
                    ? '<b>' + (unit !== 'Number' ? Number(point.point.low).toFixed(2).toLocaleString() : Number(point.point.low).toLocaleString()) + ' - ' + (unit !== 'Number' ? Number(point.point.high).toFixed(2).toLocaleString() : Number(point.point.high).toLocaleString()) + '</b><br>'
                    : point.series.name === 'S.E.'
                      ? '<b>' + (unit !== 'Number' ? Number(point.point.y).toFixed(2).toLocaleString() : Number(point.point.y).toLocaleString()) + '</b><br>'
                      : '<b>' + (unit !== 'Number' ? Number(point.point.y).toFixed(1).toLocaleString() : Number(point.point.y).toLocaleString()) + '</b><br>'
                  let name = point.series.name === 'Count' ? 'Number' : point.series.name === 'Percent' && this.smallMultiplesTitle ? point.series.chart.userOptions.yAxis[1].title.text : point.series.name
                  retStr += bulletValue + name + ': ' + pointValue
                } else { retStr += '' }
              })
            } else {
              pointValue = (unit === 'Number' || unit === 'Count') ? numberWithCommas(Number(estimatePoint.point.y).toFixed(0).toLocaleString()) + '<br>' : unit === 'Rate' ? Number(estimatePoint.point.y).toFixed(1).toLocaleString() : Number(estimatePoint.point.y).toFixed(1).toLocaleString()
              retStr = `${unit}: ${pointValue}`
            }
            return retStr
          },
          positioner: this.smallMultiplesTitle ? function (labelWidth, labelHeight, point) {
            if (point.plotX < (this.chart.plotSizeX / 2)) {
              return {
                x: this.chart.plotSizeX - labelWidth + this.chart.plotLeft,
                y: this.chart.plotTop
              }
            } else {
              return {
                x: this.chart.plotLeft,
                y: this.chart.plotTop
              }
            }
          } : null
        },
        plotOptions: {
          series: {
            events: {
              legendItemClick: function (e) {
                e.preventDefault()
              }
            }
          }
        },
        series: [
          {
            visible: this.hs.filters['referenceLine'] && this.getFilter('referenceLine') === true,
            data: this.hs.filters['referenceLine'] ? this.getRefValue('refEstimates') : null,
            name: this.hs.filters['referenceLine'] ? this.getRefValue('refName') : null,
            className: 'refEstimates',
            type: 'line',
            dashStyle: 'ShortDash',
            showInLegend: this.hs.filters['referenceLine'] !== undefined && this.getFilter('referenceLine') === true,
            zIndex: 2,
            marker: {
              enabled: false,
              symbol: null,
              states: {
                hover: {
                  enabled: false
                }
              }
            },
            tooltip: {
              enabled: false
            },
            states: {
              inactive: {
                opacity: 1
              }
            },
            color: '#606060',
            lineWidth: 1.5,
            events: {
              mouseOut: function (e) {
                this.seriesMouseOut(e)
              }.bind(this)
            },
            point: {
              events: {
                mouseOver: function (e) {
                  this.pointMouseOver(e)
                }.bind(this)
              }
            },
            zoneAxis: 'x',
            zones: [{
              value: 2006
            },
            {
              color: 'rgba(255, 255, 255, 0)'
            },
            {
              value: 2007
            }]
          },
          {
            data: this.getChartData(this.chart.key) ? this.getChartData(this.chart.key)['estimates'] : [],
            name: this.chartSettings.valuePrefix,
            className: 'estimates',
            type: 'area',
            zIndex: -1,
            marker: {
              enabled: false
            },
            showInLegend: false,
            color: this.getChartData(this.chart.key) ? this.getChartData(this.chart.key)['color'] : '#406a89',
            fillOpacity: 1,
            dataLabels: {
              enabled: true,
              formatter: function () {
                if (this.point.x === 2006) {
                  return '†'
                }
              },
              style: {
                fontSize: this.smallMultiplesTitle ? '12px' : '15px'
              }
            },
            events: {
              mouseOut: function (e) {
                this.seriesMouseOut(e)
              }.bind(this)
            },
            point: {
              events: {
                mouseOver: function (e) {
                  this.pointMouseOver(e)
                }.bind(this)
              }
            },
            zoneAxis: 'x',
            zones: [{
              value: 2006,
              color: this.getChartData(this.chart.key) ? this.getChartData(this.chart.key)['color'] : '#406a89'
            },
            { // shape extends behind ALL further years!
              color: this.getChartData(this.chart.key) ? this.getChartData(this.chart.key)['color'] : '#406a89',
              dashStyle: 'ShortDot',
              strokeDasharray: '2,3',
              fillColor: hex2rgba(this.getChartData(this.chart.key) ? this.getChartData(this.chart.key)['color'] : '#406a89', 0.80)
            },
            {// this establishes that there's a break again- creates a shape going forward that sits BEHIND the other coming zones, so must be opacity 0
              value: 2007,
              color: hex2rgba(this.getChartData(this.chart.key) ? this.getChartData(this.chart.key)['color'] : '#406a89', 0),
              dashStyle: 'Solid',
              fillColor: hex2rgba(this.getChartData(this.chart.key) ? this.getChartData(this.chart.key)['color'] : '#406a89', 0)
            },
            {
              value: 2015,
              color: this.getChartData(this.chart.key) ? this.getChartData(this.chart.key)['color'] : '#406a89',
              dashStyle: 'Solid',
              fillColor: hex2rgba(this.getChartData(this.chart.key) ? this.getChartData(this.chart.key)['color'] : '#406a89', 1.0)
            },
            {
              // this is the block that actually styles 2016
              // actually make all transparent, since lower opacity shapes already under there
              color: hex2rgba(this.getChartData(this.chart.key) ? this.getChartData(this.chart.key)['color'] : '#406a89', (this.page.key.includes('Crime') && this.getFilter('categoryDesc1') && this.getFilter('categoryDesc1') === 'Age' && this.getFilter('levelDesc1') && (this.getFilter('levelDesc1') === '12 to 14' || this.getFilter('levelDesc1') === '15 to 17')) || (this.page.key.includes('Crime') && this.getFilter('categoryDesc2') && this.getFilter('categoryDesc2') === 'Age' && this.getFilter('levelDesc2') && (this.getFilter('levelDesc2') === '12 to 14' || this.getFilter('levelDesc2') === '15 to 17')) || (this.page.key.includes('Characteristic') && this.getFilter('categoryDesc1') && this.getFilter('categoryDesc1') === 'Age' && (this.getChartData(this.chart.key)['title'].includes('14') || this.getChartData(this.chart.key)['title'].includes('15'))) || (this.page.key.includes('Characteristic') && this.getFilter('categoryDesc2') && this.getFilter('categoryDesc2') === 'Age' && this.getFilter('levelDesc2') && (this.getFilter('levelDesc2') === '12 to 14' || this.getFilter('levelDesc2') === '15 to 17')) ? 0 : 1),
              // the dotted stroke is already there undeneath from 2006 part, so only setting solid stroke to visible or not for this.
              dashStyle: 'Solid',
              fillColor: hex2rgba(this.getChartData(this.chart.key) ? this.getChartData(this.chart.key)['color'] : '#406a89', (this.page.key.includes('Crime') && this.getFilter('categoryDesc1') && this.getFilter('categoryDesc1') === 'Age' && this.getFilter('levelDesc1') && (this.getFilter('levelDesc1') === '12 to 14' || this.getFilter('levelDesc1') === '15 to 17')) || (this.page.key.includes('Crime') && this.getFilter('categoryDesc2') && this.getFilter('categoryDesc2') === 'Age' && this.getFilter('levelDesc2') && (this.getFilter('levelDesc2') === '12 to 14' || this.getFilter('levelDesc2') === '15 to 17')) || (this.page.key.includes('Characteristic') && this.getFilter('categoryDesc1') && this.getFilter('categoryDesc1') === 'Age' && (this.getChartData(this.chart.key)['title'].includes('14') || this.getChartData(this.chart.key)['title'].includes('15'))) || (this.page.key.includes('Characteristic') && this.getFilter('categoryDesc2') && this.getFilter('categoryDesc2') === 'Age' && this.getFilter('levelDesc2') && (this.getFilter('levelDesc2') === '12 to 14' || this.getFilter('levelDesc2') === '15 to 17')) ? 0 : 1)
            },
            { // this establishes that there's a break again- creates a shape going forward that sits BEHIND the other coming zones, so must be opacity 0
              value: 2017,
              color: hex2rgba(this.getChartData(this.chart.key) ? this.getChartData(this.chart.key)['color'] : '#406a89', 0),
              dashStyle: 'Solid',
              fillColor: hex2rgba(this.getChartData(this.chart.key) ? this.getChartData(this.chart.key)['color'] : '#406a89', 0)
            },
            { // this will re-establish styles for any year AFTER 2017
              color: this.getChartData(this.chart.key) ? this.getChartData(this.chart.key)['color'] : '#406a89',
              dashStyle: 'Solid',
              fillColor: hex2rgba(this.getChartData(this.chart.key) ? this.getChartData(this.chart.key)['color'] : '#406a89', 1)
            }]
          },
          {
            visible: true, // Always set to true so value shows in tooltip
            data: this.getChartData(this.chart.key) ? this.getChartData(this.chart.key)['range'] : [],
            name: '95% C.I.',
            className: 'c_i',
            type: 'arearange',
            linkedTo: ':previous',
            color: this.filters['confidenceInterval'] && this.getFilter('confidenceInterval') === true ? '#d8b9a7' : '#fff', // now sets just the stroke
            fillColor: this.filters['confidenceInterval'] && this.getFilter('confidenceInterval') === true ? 'rgba(239, 227, 220, 0.4)' : '#fff', // new fill between lines
            fillOpacity: this.filters['confidenceInterval'] && this.getFilter('confidenceInterval') === true ? 0.4 : 0.0, // opacity between lines
            opacity: this.filters['confidenceInterval'] && this.getFilter('confidenceInterval') === true ? 1.0 : 0.0, // opacity of lines
            lineWidth: 1,
            zIndex: 0,
            marker: {
              enabled: false,
              symbol: null,
              states: {
                hover: {
                  enabled: false
                }
              }
            },
            states: {
              inactive: {
                opacity: 1
              }
            },
            showInLegend: this.filters['confidenceInterval'] && this.getFilter('confidenceInterval') === true,
            tooltip: {
              valueSuffix: ''
            },
            events: {
              mouseOut: function (e) {
                this.seriesMouseOut(e)
              }.bind(this)
            },
            point: {
              events: {
                mouseOver: function (e) {
                  this.pointMouseOver(e)
                }.bind(this)
              }
            },
            zoneAxis: 'x',
            zones: [{
              value: 2006
            },
            {
              fillColor: 'rgba(255, 255, 255, 0)',
              color: 'rgba(255, 255, 255, 0)'
            },
            {
              value: 2007
            }]
          },
          {
            visible: true, // Always set to true so value shows in tooltip
            data: this.getChartData(this.chart.key) ? this.getChartData(this.chart.key)['s_e'] : [],
            name: 'S.E.',
            type: 'line',
            className: 's_e',
            lineWidth: 0,
            linkedTo: ':previous',
            fillOpacity: 0,
            color: 'hsla(0, 0%, 100%, 0)',
            tooltip: {
              valueSuffix: '',
              formatter: function () {
                return this.series.name + ': <b>' + this.point.s_e + '</b>'
              }
            },
            events: {
              mouseOut: function (e) {
                this.seriesMouseOut(e)
              }.bind(this)
            },
            point: {
              events: {
                mouseOver: function (e) {
                  this.pointMouseOver(e)
                }.bind(this)
              }
            },
            zIndex: 0,
            marker: {
              enabled: false,
              symbol: null,
              states: {
                hover: {
                  enabled: false
                }
              }
            },
            showInLegend: false,
            yAxis: 1
          },
          {
            visible: this.filters['unitFlag'] && this.getFilter('unitFlag') === true,
            data: this.getChartData(this.chart.key) ? this.getChartData(this.chart.key)['flags'] : [],
            name: this.chartSettings.valuePrefix + ' flag',
            className: 'flags',
            type: 'line',
            lineWidth: 0,
            linkedTo: ':previous',
            fillOpacity: 1,
            color: '#000',
            zIndex: 0,
            marker: {
              enabled: false,
              symbol: null,
              states: {
                hover: {
                  enabled: false
                }
              }
            },
            states: {
              inactive: {
                opacity: 1
              }
            },
            enableMouseTracking: false,
            showInLegend: this.filters['unitFlag'] && this.getFilter('unitFlag') === true && checkForFlags(this, [this.chart.key]),
            tooltip: {
              pointFormat: ''
            },
            dataLabels: {
              verticalAlign: 'bottom',
              color: '#ce4646',
              y: 0,
              enabled: true,
              formatter: function () {
                if (this.y === 0) {
                  return ''
                } else if (this.y === 1) {
                  return '▲'
                } else if (this.y === 2) {
                  return '✱'
                }
              }
            },
            yAxis: 1
          }
        ],
        legend: {
          enabled: !this.smallMultiplesTitle,
          maxHeight: 60,
          //          labelFormatter: function () {
          //            // change to use 'estimate' rather than specific unit
          //            let flagName = 'Estimate flag'
          //            return flagName
          //          }
          labelFormatter: function () {
            let flagName = this.name === 'Count flag' ? 'Number flag' : this.name
            if (flagName === 'Percent flag' || flagName === 'Rate flag' || flagName === 'Number flag') {
              return '<span style="fill:#ce4646">' + '✱/▲ ' + '</span>' + 'Estimate Flag'
            } else {
              return flagName
            }
          }

        },
        exporting: {
          enabled: this.exportingEnabled
        }
      }
    }
  },
  methods: {
    ...mapActions([
      'changeSmallMultiplesCursor',
      'smallMultiplesMouseOut'
    ]),
    pointMouseOver: function (e) {
      if (this.smallMultiplesTitle) {
        this.changeSmallMultiplesCursor({
          smallMultipleName: this.chart.key,
          smallMultiplesTitle: this.smallMultiplesTitle,
          smallMultiplesCursorEvent: e
        })
      }
    },
    seriesMouseOut: function (e) {
      if (this.smallMultiplesTitle) {
        this.smallMultiplesMouseOut()
      }
    },
    getSeriesIndex (seriesName) {
      let idx = this.chartOptions.series.findIndex(s => s.name === seriesName)
      return idx
    },
    getRefValue (property) {
      return this.hs.getRequestCache()['reference'] ? this.hs.getRequestCache().reference.refSeries[property] : []
    },
    calculateMaxAxisToShow (that) {
      let maxValues = []
      // referenceLine, estimate, and confidenceInterval
      if (this.getChartData(this.chart.key)) {
        // mu edit 3/15/22: force use with ci max as default
        maxValues.push(this.getChartData(this.chart.key)['rangeMax'])
        maxValues.push(this.getChartData(this.chart.key)['max'])
        if (this.hs.filters['referenceLine'] && this.getFilter('referenceLine') === true) {
          maxValues.push(this.hs.getRequestCache().reference.refSeries['refMax'])
        }
        if (this.hs.filters['confidenceInterval'] && this.getFilter('confidenceInterval') === true) {
          maxValues.push(this.getChartData(this.chart.key)['rangeMax'])
        }
      } else {
        return null
      }
      return this.hs.getMax(maxValues)
    },
    calculateHeight () {
      if (this.height) return this.height
      if (this.page.key.includes('Characteristic') && this.getFilter('categoryDesc1') === 'All') return 530
      else if (this.smallMultiplesTitle) return 300
      // else if (this.filters['unitFlag'] !== undefined && this.getFilter('unitFlag') && checkForFlags(this, [this.chart.key]) === false) return 430
      else return 530
    },
    calculateYAxisText: function (crimeSelected, isCrimeType) {
      let yAxisText
      if (this.getChartData(this.chart.key) && this.getChartData(this.chart.key).estimateType === 'Percent' && this.hs.pageKey !== 'quickGraphics') {
        // revison for row percents
        // check for cat2, as quick graphics don't have filters
        yAxisText = 'Percent of ' + pluralsFix(crimeSelected) + (this.getFilter('categoryDesc2') !== 'All' ? ', per ' + labelLowerCase(this.getFilter('categoryDesc2')) : '')
      } else {
        yAxisText = this.chartSettings.yAxisTitleText.replace('<br/>', ' ')
      }
      return yAxisText
    }

  },
  mounted () {
    if (this.smallMultiplesTitle) {
      this.chartRef = this.$refs['highchartsChart'].chart
      let estimateSeriesIdx = this.getSeriesIndex(this.chartSettings.valuePrefix)
      // let e
      this.cursorSubscription = this.$store.subscribe((mutation, state) => {
        if (this.smallMultiplesTitle === this.mousedSmallMultiplesTitle && mutation.type.includes('CHANGE_SMALL_MULTIPLES_CURSOR_VALUE')) {
          const pointIdx = Math.abs(this.chartRef.xAxis[0].dataMin - mutation.payload)
          const point = this.chartRef.series[estimateSeriesIdx].points[pointIdx]
          if (point) {
            // Remove hover state from estimate series. Otherwise, the point sticks around
            this.chartRef.series[estimateSeriesIdx].setAllPointsToState()
            // Draw the crosshair
            this.chartRef.xAxis[0].drawCrosshair(state.smallMultiplesCursorEvent, point)
            // Draw the tooltip (refreshing the tooltip also calls setState('hover') on the series points)
            this.chartRef.tooltip.refresh([point])
          }
        } else if (this.smallMultiplesTitle === this.mousedSmallMultiplesTitle && mutation.type.includes('SMALL_MULTIPLES_MOUSE_OUT')) {
          // Reset hover state, crosshair, and tooltip
          this.chartRef.series[estimateSeriesIdx].setAllPointsToState()
          this.chartRef.xAxis[0].drawCrosshair()
          this.chartRef.tooltip.hide()
        }
      })
    }
  },
  beforeDestroy () {
    // Close subscription
    if (this.cursorSubscription) {
      this.cursorSubscription()
    }
  }
}
</script>
