import text from '@/text'
import { formatSubtitle } from '@/chartTitleFormatter'
import { pluralsFix } from '@/utils'

export default {
  computed: {
    chartSettings () {
      let valueSuffix = ''
      let valuePrefix = this.getChartData(this.chart.key) ? this.getChartData(this.chart.key)['estimateType'] : ''
      valuePrefix = valuePrefix.charAt(0).toUpperCase() + valuePrefix.slice(1)
      let yAxisTitleText = valuePrefix === 'Count' ? 'Number' : valuePrefix
      let tooltipHeader = '<b>{point.key}</b>'
      let allEmph = this.getChartData(this.chart.key) && (this.page.title === 'Multi Year Trends Characteristic') && this.getChartData(this.chart.key)['descriptions'][0]['categoryDesc2'] !== 'All' ? 'all ' : ''
      let crimeTypeLabel = this.getChartData(this.chart.key) && (this.page.title === 'Multi Year Trends Characteristic') ? allEmph + this.getChartData(this.chart.key)['descriptions'][0]['crimeType'] : ''
      if (this.getChartData(this.chart.key) && this.page.key === 'quickGraphics' && this.getChartData(this.chart.key)['descriptions']) {
        crimeTypeLabel = allEmph + this.getChartData(this.chart.key)['descriptions'][0]['crimeType']
      }

      if (this.getChartData(this.chart.key)) {
        if (yAxisTitleText === 'Percent') {
          valueSuffix = '%'
          if (this.page.key.includes('Crime') || (this.page.key === 'quickGraphics' && this.getChartData(this.chart.key)['descriptions'] && this.getChartData(this.chart.key)['descriptions'][0] && this.getChartData(this.chart.key)['descriptions'][0]['categoryDesc1'] === 'Victim services use')
          ) {
            yAxisTitleText += ' of crime type'
            //          } else if
            //              (this.page.key === 'quickGraphics' && this.getChartData(this.chart.key) && this.getChartData(this.chart.key).series && this.getChartData(this.chart.key).series[0].custom[0].categoryDesc2 && this.getChartData(this.chart.key).series[0].custom[0].levelDesc1 && this.getChartData(this.chart.key).series[0].custom[0].categoryDesc2 !== ('All')) {
            //            // These get used for 'reporting to the police quick graphics that are only of the 'yes' or 'no's.
            //            yAxisTitleText += ' of ' + pluralsFix(this.getChartData(this.chart.key).series[0].custom[0].crimeType) + ', ' + this.getChartData(this.chart.key).series[0].custom[0].categoryDesc1 + ' = ' + this.getChartData(this.chart.key).series[0].custom[0].levelDesc1
          } else if (crimeTypeLabel.length > 0) {
            // only the area charts have crimeTypeLabel
            yAxisTitleText += ' of ' + pluralsFix(crimeTypeLabel)
          } else {
            // crimeTypeLabel only exists here for the area charts. Dumbbell and lolli add this in yAxis of chart
            // 1/27/23- area charts will need y axis edit in chart vue also
            // crime type is no longer added here
            yAxisTitleText += ' of '
            tooltipHeader = yAxisTitleText
          }
        } else if (yAxisTitleText === 'Rate' || yAxisTitleText === 'rate') {
          if (this.victimizationType === 'person' || (Object.keys(this.filters).includes('victimizationType') && this.getFilter('victimizationType') === 'person')) {
            yAxisTitleText = yAxisTitleText + ' per 1,000 persons<br/>age 12 or older'
          } else {
            yAxisTitleText = yAxisTitleText + ' per 1,000 households'
          }
        }
      }
      return {
        valueSuffix: valueSuffix,
        valuePrefix: valuePrefix,
        yAxisTitleText: yAxisTitleText.charAt(0).toUpperCase() + yAxisTitleText.slice(1),
        tooltipHeader: tooltipHeader + '<br /> '
      }
    },
    formattedSubtitle () {
      return formatSubtitle(this)
    }
  },
  props: {
    'chart': {
      type: Object,
      required: true
    },
    'title': {
      type: String,
      required: false,
      default: ''
    },
    'subtitle': {
      type: Object,
      required: false,
      default: () => text.subtitle
    },
    'exportingEnabled': {
      type: Boolean,
      required: false,
      default: false
    },
    'hideYaxis': {
      type: Boolean,
      required: false,
      default: false
    },
    'dynamic': {
      type: Boolean,
      required: false,
      default: false
    },
    'smallMultipleId': {
      type: Number,
      required: false,
      default: null
    },
    'victimizationType': {
      required: false,
      default: '',
      type: String
    }
  },
  methods: {
    afterChartCreated: function () {
      let containerElem = document.getElementById(this.chart.key + '_container')
      if (containerElem) {
        containerElem.title = ''
      }
    }
  },
  updated () {
    if (this.$refs['highchartsChart']) {
      this.$refs['highchartsChart'].chart.reflow()
    }
  }
}
