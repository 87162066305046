<template>
  <div class="button-row text-right">
    <div class="col-12 text-right">
      <button
        id="clear-button"
        class="btn btn-primary btn-sm harness-ui-filtergrid-clearbutton pull-right"
        @click="initializeDefaults()"
      >
        Clear Filters
      </button>
    </div>
  </div>
</template>
<script>
export default {
  name: 'ClearButton'
}
</script>
