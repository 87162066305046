export let unitFlagMixin = {
  computed: {
    checkForUnitFlags () {
      let chartIds = Object.keys(this.charts)
      for (let i = 0; i < chartIds.length; i++) {
        if (this.getChartData(chartIds[i]) && this.getChartData(chartIds[i]).hasFlags) {
          return ['unitFlag']
        }
      }
      return []
    }
  }
}
