<template>
  <div
    class="container-fluid"
    id="customgraphics"
  >
    <div
      class="row shadedrow"
      id="customGraphicsIntro"
    >
      <div
        class="container"
        id="customgraphicstop"
      >
        <div class="row my-5">
          <div class="col col-12  mb-lg-3">
            <h1 class="sg">
              Custom Graphics
            </h1>
          </div>
          <div class="col col-12 col-md-6">
            <p>
              Custom graphics allow you to analyze data on <a
                href="/terms#personal_vic"
                target="terms"
              >personal crime victimizations</a> and <a
                href="/terms#property_vic"
                target="terms"
              >property crime victimizations</a>. You can filter data through a selection of <a
                href="/terms#demog_char"
                target="terms"
              >demographic characteristics</a> and <a
                href="/terms#incident_char"
                target="terms"
              >incident characteristics</a> and view <a
                href="/methodology#unitdef"
                target="methodology"
              >victimization counts, rates, or percentages</a> (as applicable), customized based on your data selections.
            </p>
          </div>
          <div class="col col-12 col-md-6">
            <p>
              On each custom graphics page, there are customizable <b>Display Settings</b> and <b>Data Filter Settings</b>. Use Display Settings to create data charts by comparison characteristic and type of crime. Use Data Filter Settings to narrow data by characteristic, crime type, and year(s). You can also download the charts and tables you create.
            </p>
            <p>
              Select one of the six pages below to get started.
            </p>
          </div>
        </div>
      </div>
    </div>

    <div class="row mb-5">
      <div class="container my-5">
        <div class="row partialblock titles">
          <div class="col col-12 ">
            <div class="row">
              <div class="col col-12 col-md d-flex mr-4">
                <div
                  class="row pt-3"
                  id="to"
                >
                  <div class="col col-12 pgbutton">
                    <h5 class="mt-2 pb-0 pl-0">
                      Multi-year trend comparison
                    </h5>
                    <p class="pgbttnp">
                      Compare estimates over time—across crime types or across groups within a demographic or incident characteristic.
                    </p>
                    <!--
                    <img
                      src="../../assets/exampleAreaPairCG.svg"
                      alt="Example Visualization: change over time"
                      class="w-100"
                    >
-->
                    <p class="mb-2">
                      <small>Compare across:</small>
                    </p>
                    <div class="row d-flex">
                      <div class="col col-12 col-lg-6 d-flex align-self-stretch">
                        <router-link
                          class="d-flex"
                          :to="{name: 'Multi-Year Trends Crime Type'}"
                        >
                          <div class="row  align-self-stretch">
                            <div class="col">
                              <b>Crime Type</b>
                              <p>Ex. Examine violent victimization rates for Hispanic youth over time</p>
                            </div>
                          </div>
                        </router-link>
                      </div>
                      <div class="col col-12 col-lg-6 d-flex align-self-stretch">
                        <router-link
                          class="d-flex"
                          :to="{name: 'Multi-Year Trends Characteristic'}"
                        >
                          <div class="row align-self-stretch">
                            <div class="col">
                              <b>Characteristic</b>
                              <p>Ex. Examine the number of robberies by whether or not the victimization was reported to police for females over time</p>
                            </div>
                          </div>
                        </router-link>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
              <!--                end multi year box-->

              <div class="col col-12 col-md d-flex mr-4 pt-3 pt-md-0">
                <div
                  class="row pt-3"
                  id="to"
                >
                  <div class="col col-12 pgbutton">
                    <h5 class="mt-2 pb-0 pl-0">
                      Single-year comparison
                    </h5>
                    <p class="pgbttnp">
                      Compare estimates for a single data year—of multiple crime types and a demographic or incident characteristic or of two different characteristics.
                    </p>
                    <!--
                    <img
                      src="../../assets/examplelolliCGEqh.svg"
                      alt="Example Visualization: single year comparison"
                      class="w-100"
                    >
-->
                    <p class="mb-2">
                      <small>Compare across:</small>
                    </p>
                    <div class="row d-flex">
                      <div class="col col-12 col-lg-6 d-flex align-self-stretch">
                        <router-link
                          class="d-flex"
                          :to="{name: 'Single-Year Comparison Crime Type'}"
                        >
                          <div class="row">
                            <div class="col">
                              <b>Crime Type</b>
                              <p>Ex. Compare percentages in 1993 of rape or sexual assault and simple assault victimizations by whether medical treatment was received for an injury</p>
                            </div>
                          </div>
                        </router-link>
                      </div>
                      <div class="col col-12 col-lg-6 d-flex align-self-stretch">
                        <router-link
                          class="d-flex"
                          :to="{name: 'Single-Year Comparison Characteristic'}"
                        >
                          <div class="row">
                            <div class="col">
                              <b>Characteristic</b>
                              <p>Ex. Compare percentages in 2015 of household burglaries for which victim services were used by household income</p>
                            </div>
                          </div>
                        </router-link>
                      </div>
                    </div>
                  </div>
                </div>
              </div>

              <!--                end single year box-->

              <div class="col col-12 col-md d-flex mr-4 pt-3 pt-md-0 ">
                <div
                  class="row pt-3"
                  id="to"
                >
                  <div class="col col-12 pgbutton">
                    <h5 class="mt-2 pb-0 pl-0">
                      Year-to-year comparison
                    </h5>
                    <p class="pgbttnp">
                      Compare estimates between two data years—for crime types with a demographic or incident characteristic or for two different characteristics.
                    </p>
                    <!--
                    <img
                      src="../../assets/exampledumbbellCG.svg"
                      alt="Example Visualization: Year-to-Year Comparison"
                      class="w-100"
                    >
-->
                    <p class="mb-2">
                      <small>Compare across:</small>
                    </p>
                    <div class="row">
                      <div class="col col-12 col-lg-6 d-flex align-self-stretch">
                        <router-link
                          class="d-flex"
                          :to="{name: 'Year-to-Year Comparison Crime Type'}"
                        >
                          <div class="row">
                            <div class="col">
                              <b>Crime Type</b>
                              <p>Ex. Compare violent victimization rates by marital status from 2000 to 2018 where the offender was a stranger</p>
                            </div>
                          </div>
                        </router-link>
                      </div>
                      <div class="col col-12 col-lg-6 d-flex align-self-stretch">
                        <router-link
                          class="d-flex"
                          :to="{name: 'Year-to-Year Comparison Characteristic'}"
                        >
                          <div class="row">
                            <div class="col">
                              <b>Characteristic</b>
                              <p>Ex. Compare the number of aggravated assaults from 1996 to 2005 by population size and whether or not a weapon was used</p>
                            </div>
                          </div>
                        </router-link>
                      </div>
                    </div>
                    <div class="row" />
                  </div>
                </div>
              </div>
            <!--                end year to year box-->
            </div>
          </div>
        </div>

        <!--  <div class="row partialblock images">
          <div class="col col-12 col-lg-10 offset-lg-1">
            <div class="row">
              <div class="col col-12 col-md d-flex mr-4">
                <div
                  class="row p-3"
                  id="to"
                >
                  <div class="col col-12">
                    <img
                      src="../../assets/exampleAreaPairHome.svg"
                      alt="Example Visualization: change over time"
                      class="w-100"
                    >
                  </div>
                </div>
              </div>

        <div class="col col-12 col-md d-flex mr-4">
          <div
            class="row p-3"
            id="to"
          >
            <div class="col col-12">
              <img
                src="../../assets/examplelolliCGEqh.svg"
                alt="Example Visualization: single year comparison"
                class="w-100"
              >
            </div>
          </div>
        </div>

        <div class="col col-12 col-md d-flex mr-4">
          <div
            class="row p-3"
            id="to"
          >
            <div class="col col-12">
              <img
                src="../../assets/exampledumbbellCG.svg"
                alt="Example Visualization: Year-to-Year Comparison"
                class="w-100"
              >
            </div>
          </div>
        </div>

      </div>
    </div>

  </div> -->

        <!--
        <div class="row partialblock examples">
          <div class="col col-12 col-lg-10 offset-lg-1">
            <div class="row">
              <div class="col col-12 col-md d-flex mr-4">
                <div
                  class="row p-3"
                  id="to"
                >
                  <div class="col col-12 align-self-end">
                    <p class="pt-2 mb-2">
                      Compare across:
                    </p>

                    <router-link
                      class=""
                      :to="{name: 'Multi-Year Trends Crime Type'}"
                    >
                      <b>Crime Type</b>
                    </router-link>
                    <p>Ex. Examine violent victimization rates for Hispanic youth over time</p>
                    <router-link
                      class=""
                      :to="{name: 'Multi-Year Trends Characteristic'}"
                    >
                      <b>Characteristic</b>
                    </router-link>
                    <p>Ex. Examine the number of robberies by whether or not the victimization was reported to police for females over time</p>
                  </div>
                </div>
              </div>

              <div class="col col-12 col-md d-flex mr-4">
                <div
                  class="row p-3"
                  id="to"
                >
                  <div class="col col-12 align-self-end">
                    <p class="pt-2 mb-2">
                      Compare across:
                    </p>
                    <div class="row">
                      <router-link
                        class=""
                        :to="{name: 'Single-Year Comparison Crime Type'}"
                      >
                        <div class="row">
                          <div class="col">
                            <div class="col pt-3">
                              <b>Crime Type</b>
                              <p>Ex. Compare percentages in 1993 of rape or sexual assault and simple assault victimizations by whether medical treatment was received for an injury</p>
                            </div>
                          </div>
                        </div>
                      </router-link>
                    </div>

                    <router-link
                      class=""
                      :to="{name: 'Single-Year Comparison Characteristic'}"
                    >
                      <b>Characteristic</b>
                    </router-link>
                    <p>Ex. Compare percentages in 2015 of household burglaries for which victim services were used by household income</p>
                  </div>
                </div>
              </div>

              <div class="col col-12 col-md d-flex mr-4">
                <div
                  class="row p-3"
                  id="to"
                >
                  <div class="col col-12 align-self-end">
                    <p class="pt-2 mb-2">
                      Compare across:
                    </p>

                    <router-link
                      class=""
                      :to="{name: 'Year-to-Year Comparison Crime Type'}"
                    >
                      <b>Crime Type</b>
                    </router-link>
                    <p>Ex. Compare violent victimization rates by marital status from 2000 to 2018 where the offender was a stranger</p>
                    <router-link
                      class=""
                      :to="{name: 'Year-to-Year Comparison Characteristic'}"
                    >
                      <b>Characteristic</b>
                    </router-link>
                    <p>Ex. Compare number of aggravated assaults from 1996 to 2005 by population size and whether or not a weapon was used</p>
                  </div>
                </div>
              </div>

            </div>
          </div>
        </div>
-->
        <!--        end of main container-->
      </div>
    </div>
  </div>
</template>

<script>
import $ from 'jquery'
export default {
  name: 'CustomGraphics',
  components: {},
  methods: {
    navigate (routeHash) {
      if (this.$route.hash === routeHash) {
        var headerElem = $(routeHash)
        $('html,body').animate({
          scrollTop: headerElem.offset().top
        }, 'slow')
      }
    }
  }
}

</script>
