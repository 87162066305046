import characteristic from '@/components/pages/multi-year-trends/crimeType'
import characteristicLoadData from './characteristicLoadData'
import categoryFactory, { categories, demographicFilters } from '../filters/categories'
import allOption from '../filters/all'
import crimeTypes from '../filters/crimeTypes'
import { onOffOptions, demographicOptions, incidentOptions } from '../filters/toggle'
import { areaChartTableAdapter, tableClasses } from '@/highchartsFormatters'
import { components } from '@rtidatascience/harness-ui'
import areaChart from '@/components/charts/highcharts/area'

export default class multiYearTrendsCharacteristic {
  title = 'Multi Year Trends Characteristic'
  key = 'Multi-Year Trends Characteristic'
  subtype = 'custom-graphics'
  pageComponent = characteristic
  pageProps = {}
  retrieveData = characteristicLoadData
  filters = function () {
    return {
      victimizationType: {
        label: 'Crime Category:',
        component: components['HarnessUiRadioGroup'],
        props: {
          synchronous: true, // Remember to call loadData in afterSet or elsewhere
          inline: true
        },
        options: [
          {
            key: 'person',
            label: 'Personal',
            default: true
          },
          {
            key: 'property',
            label: 'Property'
          }
        ],
        beforeSet (action, hs) {
          const { payload } = action
          hs.setOptionsForFilter('categoryDesc1', categories[payload], true)
          hs.setOptionsForFilter('categoryDesc2', categories[payload], true)
          hs.setOptionsForFilter('levelDesc1', allOption, true)
          hs.setOptionsForFilter('levelDesc2', allOption, true)
          hs.setOptionsForFilter('crimeType', crimeTypes[payload], true)
        },
        afterSet (action, hs) {
          hs.loadData()
        }
      },
      unitFlag: {
        label: 'Estimate Flags:',
        component: components['HarnessUiRadioGroup'],
        props: {
          inline: true
        },
        options: onOffOptions('On')
      },
      confidenceInterval: {
        label: 'Confidence Intervals <span id="cidotspot"></span>:',
        component: components['HarnessUiRadioGroup'],
        props: {
          inline: true
        },
        options: onOffOptions('Off')
      },
      referenceLine: {
        label: 'Reference Lines <span id="reflinedotspot"></span>:',
        component: components['HarnessUiRadioGroup'],
        props: {
          inline: true
        },
        options: onOffOptions('Off', 'On')
      },
      unitSelector: {
        label: 'Unit <span id="unitdotspot"></span>:',
        component: components['HarnessUiRadioGroup'],
        props: {
          inline: true
        },
        options: demographicOptions(),
        afterSet (action, hs) {
          if (action.payload === 'count') {
            hs.setFilter('referenceLine', false)
            hs.disableOptions('referenceLine', [true])
          }
          if (action.payload === 'rate' && hs.getFilter('categoryDesc1') !== 'All' && demographicFilters.includes(hs.getFilter('categoryDesc1'))) {
            hs.enableOptions('referenceLine', [true])
          } else {
            hs.setFilter('referenceLine', false)
            hs.disableOptions('referenceLine', [true])
          }
        }
      },
      categoryDesc1: {
        label: 'Comparison Characteristic:',
        component: components['HarnessUiSelect'],
        options: categoryFactory('person'),
        afterSet (action, hs) {
          if (action.payload !== 'All') {
            hs.disableOptions('categoryDesc2', [hs.getFilter('categoryDesc1')])
          } else {
            hs.enableOptions('categoryDesc2', hs.getOptionsForFilter('categoryDesc2').map((x) => x.key))
          }

          if (demographicFilters.includes(action.payload)) {
            hs.setOptionsForFilter('unitSelector', demographicOptions(), true)
            if (hs.getFilter('unitSelector') === 'rate' && demographicFilters.includes(hs.getFilter('categoryDesc2'))) {
              hs.enableOptions('referenceLine', [true])
            } else {
              hs.setFilter('referenceLine', false)
              hs.disableOptions('referenceLine', [true])
            }
          } else {
            hs.setOptionsForFilter('unitSelector', incidentOptions(), true)
            hs.setFilter('referenceLine', false)
            hs.disableOptions('referenceLine', [true])
          }
        }
      },
      // this is not shown on this page view
      levelDesc1: {
        label: 'Comparison Characteristic Value: (select one)',
        component: components['HarnessUiSelect'],
        programmatic: true,
        options: allOption
      },
      categoryDesc2: {
        label: 'Filter Characteristic:',
        component: components['HarnessUiSelect'],
        options: categoryFactory('person'),
        afterSet (action, hs) {
          if (action.payload !== 'All') {
            hs.disableOptions('categoryDesc1', [hs.getFilter('categoryDesc2')])
          } else {
            hs.enableOptions('categoryDesc1', hs.getOptionsForFilter('categoryDesc1').map((x) => x.key))
          }
        }
      },
      levelDesc2: {
        label: 'Filter Characteristic Value: <small><i>(select one)<i><small>',
        component: components['HarnessUiSelect'],
        programmatic: true,
        options: categoryFactory('person')
      },
      crimeType: {
        label: 'Crime Type:',
        component: components['HarnessUiSelect'],
        options: crimeTypes['person']

      }
    }
  }
  charts = function () {
    let characteristicCharts = {}
    for (let i = 0; i < 11; i++) {
      characteristicCharts['charSmallMult' + i] = {
        component: areaChart,
        props: {
          smallMultiplesTitle: 'charSmallMult',
          tableAdapter: areaChartTableAdapter,
          title: null,
          tableClass: tableClasses,
          exportingEnabled: false,
          hideYaxis: (i > 0),
          dynamic: true,
          smallMultipleId: i,
          userInstruction: null
        }
      }
    }
    return characteristicCharts
  }
}
