<template>
  <div class="statnotefooter">
    <hr>
    <p>
      Data presentations for some estimates and categories may differ from those presented in BJS publications. For more guidance on the interpretation of graphics in this tool, see <a
        href="/methodology#unitdef"
        target="/methodology"
      > User’s Guide</a>.
    </p>
    <dateLastUpdated />
  </div>
</template>
<script>
import dateLastUpdated from './dateLastUpdated.vue'
export default {
  name: 'StatNote',
  components: { dateLastUpdated }
}
</script>
