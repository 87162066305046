<template>
  <div class="container-fluid cgpg twoyr crime">
    <div
      v-show="this.getRequestCache()"
    >
      <div
        class="row mb-5"
        id="filterbankrow"
      >
        <div class="container">
          <div class="row">
            <div class="col col-12 mt-4">
              <h1 class="sg">
                Custom Graphics: Year-to-Year Comparison: Crime Type
              </h1>
            </div>
          </div>
          <div class="row filterbank pt-3 pb-4">
            <div class="col col-12 col-md-8 pr-md-5 pr-lg-5 viewsettings">
              <h6 class="mb-3">
                Display Settings
                <InfoDot
                  :tooltip-text="helpertext.displaySettingsCrimePg"
                  :id="'displaydot'"
                />
              </h6>
              <div class="row">
                <div class="col col-12 col-lg-6">
                  <filterGrid
                    :only="['victimizationType']"
                    :label-position="'horizontal'"
                    :label-column-size="5"
                    :clear-button="false"
                  />
                  <filterGrid
                    :only="['crimeType','categoryDesc1']"
                    :label-position="'vertical'"
                    :columns="1"
                    :clear-button="false"
                  />
                </div>
                <div class="col col-12 col-lg-6 pt-3 pt-lg-0">
                  <filterGrid
                    :only="['unitSelector', 'confidenceInterval'].concat(this.checkForUnitFlags)"
                    :label-position="'horizontal'"
                    :label-column-size="5"
                    :clear-button="false"
                  />
                </div>
              </div>
            </div>
            <div class="col col-12 col-md-4 pt-4 pt-md-0 pl-md-4 pl-lg-5 filtersettings">
              <h6 class="mb-3">
                Data Filter Settings
                <InfoDot
                  :tooltip-text="helpertext.dataFilterCrimePgWyear"
                  :id="'filterdot'"
                />
              </h6>
              <filterGrid
                :only="['categoryDesc2', 'levelDesc2']"
                :label-position="'vertical'"
                :columns="1"
                :clear-button="false"
              />
              <hr>
              <filterGrid
                :only="['year2', 'year']"
                :label-position="'vertical'"
                :columns="2"
                :clear-button="false"
              />
              <small class="d-block mt-2">
                <i>
                  Data for 2006 are not comparable to other years.
                </i>
              </small>
            </div>
            <clearButton />
            <div class="col-12 mt-3">
              <small class="statsignote pt-2">BJS did not test whether estimate differences in the N-DASH are statistically significant; therefore, please do not draw inferences when comparing. <a
                href="/terms#ci_description"
                target="/terms"
              >Confidence intervals</a> and <a
                href="/terms#se_description"
                target="/terms"
              >standard errors</a> are two measures of the accuracy of an estimate. For more information, see <a
                href="/methodology#methodology"
                target="/methodology"
              >User’s Guide</a>. The dashboard is best viewed with Google Chrome or Mozilla Firefox. Though the dashboard has been optimized for viewing on computers and mobile devices, one’s device size may affect the display of graphics.
              </small>
            </div>
          </div>
        </div>
      </div>
      <div
        class="row"
        id="allchartsrow"
      >
        <div class="container">
          <chartHolder
            :chart-ids="['CrimeType']"
            :force-table="isMobileView"
          />
          <StatNote />
        </div>
      </div>
    </div>
    <loadModal />
    <InfoDot
      :tooltip-text="helpertext.unit"
      :id="'unitdot'"
    />
    <InfoDot
      :tooltip-text="helpertext.confidenceInterval"
      :id="'cidot'"
    />
  </div>
</template>

<script>
import chartHolder from '../../chartHolder'
import loadModal from '../../loadModal'
import clearButton from '../../clearButton'
import StatNote from '../../statNote'
import { unitFlagMixin } from '../mixins'
import helpertext from '@/helpertext'
import InfoDot from '../../infoDot'
import $ from 'jquery'
import { mapGetters } from 'vuex'
let cicontents = ''
let unitcontents = ''
export default {
  name: 'CustomYearToYear',
  mixins: [unitFlagMixin],
  components: {
    chartHolder,
    loadModal,
    clearButton,
    StatNote,
    InfoDot
  },
  data () {
    return {
      helpertext
    }
  },
  computed: {
    ...mapGetters([
      'isMobileView'
    ])
  },
  beforeRouteEnter (to, from, next) {
    next(vm => {
      vm.loadData()
    })
  },
  beforeUpdate () {
    cicontents = $('#cidot')
    unitcontents = $('#unitdot')
    $('#unitdot').appendTo('#unitdotspot')
    $('#cidot').appendTo('#cidotspot')
  },
  updated () {
    $(cicontents).appendTo('#cidotspot')
    $(unitcontents).appendTo('#unitdotspot')
  }
}
</script>
