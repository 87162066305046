export function setFilterOptions (hs, data) {
  let filterKeys = Object.keys(hs.filters)
  // Set programmatic filter options
  filterKeys.forEach(key => {
    if (hs.filters[key].programmatic) {
      // let options = hs.getDistinctValues(data, key)
      // I have replaced above with this below to correct the sort order issue- but table adapter needs fix now
      let options = [...new Set(data.map(d => d[key]))]
      if (key === 'year2') {
        options = hs.getDistinctValues(data, 'year')
      }
      let formattedOptions = []
      let currFilter = hs.getFilter(key)

      options.forEach(option => {
        formattedOptions.push({
          key: option,
          label: option,
          default: currFilter === option
        })
      })

      // if (filterKey === 'year') { year2Options = options }
      let defaultOption = formattedOptions.filter(option => option.default === true)[0]

      if (!defaultOption && key === 'year') {
        formattedOptions[options.length - 1].default = true
      } else if (!defaultOption) {
        formattedOptions[0].default = true
      }

      hs.setOptionsForFilter(key, formattedOptions, true)
    }
  })
  // hs.setOptionsForFilter('year2', year2Options, true)
}
