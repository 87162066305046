<template>
  <div
    id="hometop"
    class="container-fluid"
  >
    <div class="row shadedrow">
      <div class="container">
        <div
          class="row mt-lg-5"
          id="herorow"
        >
          <div
            class="col col-3 d-flex pt-lg-3 pt-xl-0"
            id="brandimg"
          >
            <img
              class=""
              src="../assets/DotStormFrontSmall.png"
              role="presentation"
            >
          </div>
          <div class="col col-8 mt-lg-5 mb-lg-3">
            <h1 class="mt-4">
              National Crime Victimization Survey Data Dashboard (N-DASH)
            </h1>
          </div>
          <div class="d-none d-md-block col-12 col-md-3">
          <!--            placeholder for responsive alignment-->
          </div>
          <div class="col col-12 col-md-8 mb-lg-5">
            <!--              <p>Data dashboard for the National Crime Victimization Survey</p>-->
            <p>
              This dynamic analysis tool allows you to examine National Crime Victimization Survey (NCVS) data on both personal and property victimization, by select victim, household, and incident characteristics.
            </p>
            <p>The NCVS is the nation’s primary source of information on criminal victimization. It is an annual data collection sponsored by the Bureau of Justice Statistics. The NCVS collects information from a nationally representative sample of U.S. households on nonfatal crimes, reported and not reported to the police, against persons age 12 or older.</p>
            <dateLastUpdated />
          </div>
        </div>
      <!--          end row-->
      </div>
    <!--        end container-->
    </div>
    <!--        end shaded-->
    <div class="row">
      <div class="container">
        <div class="row my-5">
          <div class="col col-12 col-md-10 offset-md-1 pl-md-0">
            <h2 class="mt-4">
              This tool
            </h2>
          </div>
          <div class="col col-12 col-md-10 offset-md-1 pl-md-0">
            <p class="mb-0">
              This tool presents estimates from the NCVS as interactive data visualizations, providing users with a variety of views and analysis types to investigate questions of interest. On the Quick Graphics and Custom Graphics pages, you can view or create and download charts and tables.
            </p>
          </div>
          <div class="col col-12 col-md-5 offset-md-1 pl-md-0 d-flex align-self-end">
            <p class="mt-2">
              Select one of the three options below to get started.
            </p>
          </div>
          <div class="col col-12 col-md-5 pr-md-0">
            <p class=" d-md-flex align-self-end justify-content-end ">
              <span><b>Have questions?</b><br><a href="mailto:askbjs@usdoj.gov?subject=N-DASH">askbjs@usdoj.gov <i class="fas fa-solid fa-envelope" /></a></span>
            </p>
          </div>
        </div>
      </div>
    </div>
    <div class="row mb-5">
      <div class="container mb-5">
        <div class="row">
          <div class="col col-12 col-md-10 offset-md-1">
            <div class="row">
              <div class="col col-12 col-md d-md-flex mr-4 align-items-stretch pb-5 pb-md-0">
                <router-link
                  class="row p-3 px-md-1 px-lg-3"
                  id="to"
                  :to="{name: 'ToolOverview', hash: '#overviewTop'}"
                >
                  <div class="col col-12 pgbutton">
                    <div class="svgholder">
                      <svg
                        version="1.1"
                        id="Layer_1"
                        xmlns="http://www.w3.org/2000/svg"
                        xmlns:xlink="http://www.w3.org/1999/xlink"
                        x="0px"
                        y="0px"
                        viewBox="0 0 234.2 55.7"
                        style="enable-background:new 0 0 234.2 55.7;"
                        xml:space="preserve"
                      >
                        <polygon
                          class="st0"
                          points="0.3,55.7 0,54.7 121.9,12 122.1,12.1 146.1,25.2 172.6,20.7 181.3,30.9 219,4.4 234.2,21.7 233.4,22.4 218.9,5.8 181.2,32.2 172.2,21.8 145.9,26.3 145.7,26.2 121.8,13.1 "
                        />
                        <g>
                          <circle
                            class="st0"
                            cx="121.2"
                            cy="12.1"
                            r="2.4"
                          />
                        </g>
                        <g>
                          <circle
                            class="st0"
                            cx="219.5"
                            cy="5.1"
                            r="2.4"
                          />
                        </g>
                        <g>
                          <circle
                            class="st0"
                            cx="181.2"
                            cy="31.5"
                            r="2.4"
                          />
                        </g>
                        <g>
                          <circle
                            class="st0"
                            cx="173.1"
                            cy="21.7"
                            r="2.4"
                          />
                        </g>
                      </svg>
                    </div>
                    <h5 class="mt-5 pl-0">
                      Tool Overview
                    </h5>
                  </div>
                  <div class="col col-12 d-flex align-items-center">
                    <p>An introduction to this tool's use, terms, and functions</p>
                  </div>
                  <div class="col col-12 d-flex align-self-end">
                    <img
                      src="../assets/tooloverview_homeimg3.png"
                      alt="abstact image of charts to indicate tool overview"
                      class="w-100"
                    >
                  </div>
                </router-link>
              </div>
              <div class="col col-12 col-md d-md-flex mr-4 align-items-stretch pb-5 pb-md-0">
                <router-link
                  class="row p-3 px-md-1 px-lg-3"
                  id="qg"
                  :to="{name: 'quickGraphics', hash: '#quickgraphicstop'}"
                >
                  <div class="col col-12 pgbutton">
                    <!--
                    <div class="svgholder">
                      <svg><use href="#Layer_1" /></svg>
                    </div>
-->
                    <div class="svgholder">
                      <svg
                        version="1.1"
                        id="Layer_1"
                        xmlns="http://www.w3.org/2000/svg"
                        xmlns:xlink="http://www.w3.org/1999/xlink"
                        x="0px"
                        y="0px"
                        viewBox="0 0 234.2 55.7"
                        style="enable-background:new 0 0 234.2 55.7;"
                        xml:space="preserve"
                      >
                        <polygon
                          class="st0"
                          points="0.3,55.7 0,54.7 121.9,12 122.1,12.1 146.1,25.2 172.6,20.7 181.3,30.9 219,4.4 234.2,21.7 233.4,22.4 218.9,5.8 181.2,32.2 172.2,21.8 145.9,26.3 145.7,26.2 121.8,13.1 "
                        />
                        <g>
                          <circle
                            class="st0"
                            cx="121.2"
                            cy="12.1"
                            r="2.4"
                          />
                        </g>
                        <g>
                          <circle
                            class="st0"
                            cx="219.5"
                            cy="5.1"
                            r="2.4"
                          />
                        </g>
                        <g>
                          <circle
                            class="st0"
                            cx="181.2"
                            cy="31.5"
                            r="2.4"
                          />
                        </g>
                        <g>
                          <circle
                            class="st0"
                            cx="173.1"
                            cy="21.7"
                            r="2.4"
                          />
                        </g>
                      </svg>
                    </div>
                    <h5 class="mt-5 pl-0">
                      Quick Graphics
                    </h5>
                  </div>
                  <div class="col col-12 d-flex align-items-center">
                    <p>A selection of prefabricated interactive charts covering key topics of interest<br></p>
                  </div>
                  <div class="col col-12 d-flex align-self-end">
                    <img
                      src="../assets/QG_homeimg2.png"
                      alt="abstract image of charts to indicate quick graphics"
                      class="w-100"
                    >
                  </div>
                </router-link>
              </div>
              <div class="col col-12 col-md d-md-flex align-items-stretch">
                <router-link
                  class="row p-3 px-md-1 px-lg-3"
                  id="cg"
                  :to="{name: 'customGraphics', hash: '#customgraphicstop'}"
                >
                  <div class="col col-12 pgbutton">
                    <div class="svgholder">
                      <svg
                        version="1.1"
                        id="Layer_1"
                        xmlns="http://www.w3.org/2000/svg"
                        xmlns:xlink="http://www.w3.org/1999/xlink"
                        x="0px"
                        y="0px"
                        viewBox="0 0 234.2 55.7"
                        style="enable-background:new 0 0 234.2 55.7;"
                        xml:space="preserve"
                      >
                        <polygon
                          class="st0"
                          points="0.3,55.7 0,54.7 121.9,12 122.1,12.1 146.1,25.2 172.6,20.7 181.3,30.9 219,4.4 234.2,21.7 233.4,22.4 218.9,5.8 181.2,32.2 172.2,21.8 145.9,26.3 145.7,26.2 121.8,13.1 "
                        />
                        <g>
                          <circle
                            class="st0"
                            cx="121.2"
                            cy="12.1"
                            r="2.4"
                          />
                        </g>
                        <g>
                          <circle
                            class="st0"
                            cx="219.5"
                            cy="5.1"
                            r="2.4"
                          />
                        </g>
                        <g>
                          <circle
                            class="st0"
                            cx="181.2"
                            cy="31.5"
                            r="2.4"
                          />
                        </g>
                        <g>
                          <circle
                            class="st0"
                            cx="173.1"
                            cy="21.7"
                            r="2.4"
                          />
                        </g>
                      </svg>
                    </div>
                    <h5 class="mt-5 pl-0">
                      Custom Graphics
                    </h5>
                  </div>
                  <div class="col col-12 d-flex align-items-center">
                    <p>Create custom interactive charts based on select topics of interest<br></p>
                  </div>
                  <div class="col col-12 d-flex align-self-end">
                    <!--
                    <img
                      src="../assets/filterIncomeChartHome.svg"
                      alt="Visualization with filter settings applied: Victim Household Income, $35,000 to $49,999"
                      class="w-100"
                    >
-->
                    <img
                      src="../assets/CG_homeimg.png"
                      alt="abstract image of charts and hands to indicate custom graphics"
                      class="w-100"
                    >
                  </div>
                </router-link>
              </div>
            </div>
          </div>
        </div>
      </div>
    <!--        end container-->
    </div>
  <!--        end row-->
  </div>
<!--end component-->
</template>

<script>
import dateLastUpdated from './dateLastUpdated.vue'
export default {
  name: 'HomeTop',
  components: { dateLastUpdated }
}

</script>
