import _ from 'lodash'
import seriesColor from './seriesColor'
import { getSingleYearCharacteristicTitle, getSingleYearCrimeTypeTitle, getTwoYearCharacteristicTitle, getTwoYearCrimeTypeTitle, transformToSentenceCase } from './chartTitleFormatter'
import { demographicFilters } from './harness-pages/filters/categories'
import { capitalizeFirstLetter, labelLowerCase, pluralsFix } from './utils'
/**
 * @param  {Object} hs The instantiated hs object
 * @param  {Array} data The initial data for this grouping
 * @param  {String} estimateType Name of the estimate type selection field ('count', 'percent', 'rate')
 * @param  {Array} filters an array of objects with attributes for column and value. The data will be filtered to where each column's value returns equality
*/

// potential fix for low color issue with high color NaN dumbbell charts
const hex2rgba = (hex, alpha = 1) => {
  const [r, g, b] = hex.match(/\w\w/g).map(x => parseInt(x, 16))
  return `rgba(${r},${g},${b},${alpha})`
}

export function groupForAreaChart (hs, data, estimateType, filters, useCrimeType = false) {
  let filtered = data
  filters.forEach(filter => {
    filtered = filtered.filter(datum => datum[filter.column] === filter.value)
    filtered.sort((a, b) => a.catNum1 - b.catNum1)
    filtered.sort((a, b) => a.catNum2 - b.catNum2)
    filtered.sort((a, b) => a.levelNum1 - b.levelNum1)
    filtered.sort((a, b) => a.levelNum2 - b.levelNum2)
  })
  let filterValueForSort = getFilterValueFromArray(filters, 'categoryDesc2')
  filtered = filtered.filter(datum => datum.categoryDesc2 === filterValueForSort).sort((a, b) => (b.year) - (a.year))
  if (!filtered.length) {
    return false
  }
  let areaChartObject = {
    'estimates': [],
    'range': [],
    'flags': [],
    's_e': [],
    'max': hs.getMax(filtered.filter(f => !isNaN(f[estimateType])), estimateType),
    'rangeMax': hs.getMax(filtered.filter(f => !isNaN(f[estimateType + 'UB'])), estimateType + 'UB'),
    'estimateType': estimateType,
    'counts': [],
    'descriptions': [],
    'minYear': 9999,
    'maxYear': 0,
    'singleAreaChart': true,
    'vicType': filtered[0].victimizationType
  }
  filtered.forEach(datum => {
    if (!isNaN(datum[estimateType])) {
      areaChartObject.estimates.push([datum['year'], datum[estimateType]])
      areaChartObject.range.push([datum['year'], datum[estimateType + 'LB'], datum[estimateType + 'UB']])
      areaChartObject.flags.push([datum['year'], parseFloat(datum[estimateType + 'Flag'])])
      areaChartObject.s_e.push([datum['year'], parseFloat(datum[estimateType + 'SE'])])
      areaChartObject.counts.push({
        'year': datum['year'],
        'count': datum['count'],
        'countFlag': datum['countFlag'],
        'ciLB': datum['countLB'],
        'ciUB': datum['countUB'],
        'se': datum['countSE']
      })
      areaChartObject.descriptions.push({
        'year': datum['year'],
        'categoryDesc1': datum['categoryDesc1'],
        'categoryDesc2': datum['categoryDesc2'],
        'levelDesc1': datum['levelDesc1'],
        'levelDesc2': datum['levelDesc2'],
        'crimeType': datum['crimeType']
      })
      areaChartObject.minYear = datum['year'] < areaChartObject.minYear ? datum['year'] : areaChartObject.minYear
      areaChartObject.maxYear = datum['year'] > areaChartObject.maxYear ? datum['year'] : areaChartObject.maxYear
    }
  })

  let percentOfAll = false
  if (estimateType === 'percent' && getFilterValueFromArray(filters, 'categoryDesc1') !== 'All' && getFilterValueFromArray(filters, 'categoryDesc2') !== 'All') {
    percentOfAll = true
  }

  // Added reverse() to get the data points sequence correct
  areaChartObject.percentOfAll = percentOfAll
  areaChartObject.estimates = areaChartObject.estimates.reverse()
  areaChartObject.range = areaChartObject.range.reverse()
  areaChartObject.flags = areaChartObject.flags.reverse()
  areaChartObject.s_e = areaChartObject.s_e.reverse()
  areaChartObject.counts = areaChartObject.counts.reverse()
  areaChartObject.descriptions = areaChartObject.descriptions.reverse()
  areaChartObject.color = seriesColor(filtered[0], { crimeType: useCrimeType })
  areaChartObject.hasFlags = areaChartObject.flags.some(el => el[1] > 0)
  return areaChartObject
}

/**
 * @param  {Object} hs The instantiated hs object
 * @param  {Array} data The initial data for this grouping
 * @param  {String} estimateType Name of the estimate type selection field ('count', 'percent', 'rate')
 * @param  {Array} filters an array of objects with attributes for column and value. The data will be filtered to where each column's value returns equality
*/
export function groupForAreaChartRefLine (hs, data, estimateType, filters, refName) {
  let filtered = data
  filters.forEach(filter => {
    filtered = filtered.filter(datum => {
      if (Array.isArray(filter.value)) {
        return filter.value.includes(datum[filter.column])
      }
      return datum[filter.column] === filter.value
    })
  })
  let filterValueForSort = getFilterValueFromArray(filters, 'categoryDesc2')
  filtered = filtered.filter(datum => datum.categoryDesc2 === filterValueForSort).sort((a, b) => (b.year) - (a.year))
  let refObject = {
    'refName': refName,
    'refEstimates': [],
    'refMax': hs.getMax(filtered.filter(f => !isNaN(f[estimateType])), estimateType)
  }
  filtered.forEach(datum => {
    refObject.refEstimates.push([datum['year'], datum[estimateType]])
  })

  refObject.refEstimates = refObject.refEstimates.reverse()
  return refObject
}

/**
 * @param  {Object} hs The instantiated hs object
 * @param  {Array} data The initial data for this grouping
 * @param  {String} estimateType Name of the estimate type selection field ('count', 'percent', 'rate')
 * @param  {Array} filters an array of objects with attributes for column and value. The data will be filtered to where each column's value returns equality
 * @param  {Object} smallMultObject an object with attributes for column and values. The data will first be filtered by the values to find the max y-axis value
 */
export function groupForSmallMultAreaChart (hs, data, estimateType, filters, smallMultObject) {
  let filtered = data
  filtered = filtered.filter(datum => smallMultObject.values.includes(datum[smallMultObject.column]))
  filtered.sort((a, b) => a.catNum1 - b.catNum1)
  filtered.sort((a, b) => a.catNum2 - b.catNum2)
  filtered.sort((a, b) => a.levelNum1 - b.levelNum1)
  filtered.sort((a, b) => a.levelNum2 - b.levelNum2)

  filters.forEach(filter => {
    filtered = filtered.filter(datum => {
      if (Array.isArray(filter.value)) {
        return filter.value.includes(datum[filter.column])
      }
      return datum[filter.column] === filter.value
    })
  })

  if (!filtered.length) {
    let smallMultAreaChartFalseObject = {}
    smallMultObject.values.forEach(chartTitle => {
      smallMultAreaChartFalseObject[chartTitle] = false
    })
    return smallMultAreaChartFalseObject
  }
  let estSet = [...new Set(filtered.map(d => d[estimateType]))]
  let smallMultAreaChartObject = {}
  let percentOfAll = false
  if (estimateType === 'percent' && getFilterValueFromArray(filters, 'categoryDesc1') !== 'All' && getFilterValueFromArray(filters, 'categoryDesc2') !== 'All') {
    percentOfAll = true
  }
  let areaChartObject = {
    'estimates': [],
    'range': [],
    'flags': [],
    's_e': [],
    'max': hs.getMax(filtered.filter(f => !isNaN(f[estimateType])), estimateType),
    'rangeMax': hs.getMax(filtered.filter(f => !isNaN(f[estimateType + 'UB'])), estimateType + 'UB'),
    'estimateType': estimateType.charAt(0).toUpperCase() + estimateType.slice(1),
    'title': null,
    'color': null,
    'counts': [],
    'descriptions': [],
    'minYear': 9999,
    'maxYear': 0,
    'giveAlert': estSet.every(d => d === 0 || isNaN(d)),
    'percentOfAll': percentOfAll,
    'singleAreaChart': false,
    'isCrimeSmallMult': smallMultObject.column === 'crimeType',
    'vicType': filtered[0].victimizationType
    // test for small mult crime type table issue
  }

  let smallMultFiltered
  let colors = []
  let filterValueForSort = getFilterValueFromArray(filters, 'categoryDesc2')
  let alertArray = []
  smallMultObject.values.forEach(value => {
    // Need to deep clone here since areaChartObject is in nested structure
    smallMultAreaChartObject[value] = _.cloneDeep(areaChartObject)

    smallMultFiltered = filtered
    smallMultFiltered = smallMultFiltered.filter(datum => datum[smallMultObject.column] === value)
    if (smallMultFiltered.length === 0) {
      throw Error(`No data exists for given filter ${smallMultObject.column} == ${value} ("${value}" is a parameter to this chart, but no data exists for it)`)
    }
    smallMultFiltered = smallMultFiltered.filter(datum => datum.categoryDesc2 === filterValueForSort).sort((a, b) => b.year - a.year)
    smallMultAreaChartObject[value]['title'] = value === 'All' ? '' : value
    let options = {}
    if (hs.pageKey.includes('Characteristic') && hs.getFilter('categoryDesc1') !== 'All' && hs.getFilter('categoryDesc2') !== 'All') {
      options.Characteristic = true
    } else {
      options.Characteristic = false
    }
    smallMultAreaChartObject[value]['color'] = seriesColor(smallMultFiltered[0], options)
    colors.push(seriesColor(smallMultFiltered[0], options))
    smallMultFiltered.forEach(datum => {
      if (!isNaN(datum[estimateType])) {
        smallMultAreaChartObject[value].estimates.push([datum['year'], datum[estimateType]])
        smallMultAreaChartObject[value].flags.push([datum['year'], datum[estimateType + 'Flag']])
        smallMultAreaChartObject[value].s_e.push([datum['year'], datum[estimateType + 'SE']])
        smallMultAreaChartObject[value].range.push([datum['year'], datum[estimateType + 'LB'], datum[estimateType + 'UB']])
        smallMultAreaChartObject[value].counts.push({
          'year': datum['year'],
          'count': datum['count'],
          'countFlag': datum['countFlag'],
          'ciLB': datum['countLB'],
          'ciUB': datum['countUB'],
          'se': datum['countSE']
        })
        smallMultAreaChartObject[value].descriptions.push({
          'year': datum['year'],
          'categoryDesc1': datum['categoryDesc1'],
          'categoryDesc2': datum['categoryDesc2'],
          'levelDesc1': datum['levelDesc1'],
          'levelDesc2': datum['levelDesc2'],
          'crimeType': datum['crimeType']
        })
        smallMultAreaChartObject[value].minYear = datum['year'] < smallMultAreaChartObject[value].minYear ? datum['year'] : smallMultAreaChartObject[value].minYear
        smallMultAreaChartObject[value].maxYear = datum['year'] > smallMultAreaChartObject[value].maxYear ? datum['year'] : smallMultAreaChartObject[value].maxYear
      }
    })
    // Added reverse() to get the data points sequence correct
    smallMultAreaChartObject[value].estimates = smallMultAreaChartObject[value].estimates.reverse()
    smallMultAreaChartObject[value].range = smallMultAreaChartObject[value].range.reverse()
    smallMultAreaChartObject[value].flags = smallMultAreaChartObject[value].flags.reverse()
    smallMultAreaChartObject[value].s_e = smallMultAreaChartObject[value].s_e.reverse()
    smallMultAreaChartObject[value].counts = smallMultAreaChartObject[value].counts.reverse()
    smallMultAreaChartObject[value].descriptions = smallMultAreaChartObject[value].descriptions.reverse()
    smallMultAreaChartObject[value].hasFlags = smallMultAreaChartObject[value].flags.some(el => el[1] > 0)
    alertArray.push(smallMultAreaChartObject[value].giveAlert)
  })
  smallMultObject.values.forEach(value => {
    smallMultAreaChartObject[value].giveAlert = alertArray.every(el => el === true)
  })
  // Need to check for duplicates so that the charts aren't all the same color based on the filter
  // If there are duplicates, reassign colors based on crime type
  if (new Set(colors).size !== colors.length) {
    Object.keys(smallMultAreaChartObject).forEach(key => {
      smallMultFiltered = filtered
      smallMultFiltered = smallMultFiltered.filter(datum => datum[smallMultObject.column] === key)
      smallMultFiltered = smallMultFiltered.filter(datum => datum.categoryDesc2 === filterValueForSort).sort((a, b) => b.year - a.year)
      smallMultAreaChartObject[key]['color'] = seriesColor(smallMultFiltered[0], { crimeType: true })
    })
  }
  return smallMultAreaChartObject
}

/**
 * Filters data and formats it for highcharts lollipop
 * @param  {Array} data The initial data for this grouping
 * @param  {String} estimateType Name of the estimate type selection field ('count', 'percent', 'rate')
 * @param  {Array} filters an array of objects with attributes for column and value. The data will be filtered to where each column's value returns equality
 */
export function groupForLollipop (hs, data, estimateType, filters, useCrimeType, minorRef, majorRef) {
  const characteristicPage = hs.pageKey.includes('Characteristic')
  const crimeTypePage = hs.pageKey.includes('Crime Type')
  const qgPage = hs.pageKey === 'quickGraphics'
  const isReason = getFilterValueFromArray(filters, 'categoryDesc2').includes('Reason')
  let filtered = data
  let options = {}
  let vicType = data[0].victimizationType
  if (crimeTypePage && getFilterValueFromArray(filters, 'categoryDesc1') === 'All' && getFilterValueFromArray(filters, 'categoryDesc2') === 'All') {
    options.CrimeType = true
  } else if (characteristicPage && getFilterValueFromArray(filters, 'categoryDesc1') === 'All' && getFilterValueFromArray(filters, 'categoryDesc2') !== 'All') {
    options.Characteristic2 = true
  } else {
    options.Characteristic = true
  }

  filters.forEach(filter => {
    filtered = filtered.filter(datum => {
      if (Array.isArray(filter.value)) {
        return filter.value.includes(datum[filter.column])
      }
      return datum[filter.column] === filter.value
    })
    filtered.sort((a, b) => a.catNum1 - b.catNum1)
    filtered.sort((a, b) => a.catNum2 - b.catNum2)
    filtered.sort((a, b) => a.levelNum1 - b.levelNum1)
    filtered.sort((a, b) => a.levelNum2 - b.levelNum2)
  })
  if (!filtered.length) {
    return false
  }
  let estSet = [...new Set(filtered.map(d => d[estimateType]))]
  let minorgroupForSort = getFilterValueFromArray(filters, 'categoryDesc1')
  let emptiesCulled = filtered.filter(d => !isNaN(d[estimateType]))
  if (!emptiesCulled.length) {
    return false
  }
  let charttitle
  if (hs.pageKey === 'quickGraphics') {
    charttitle = null
  } else {
    charttitle = characteristicPage ? getSingleYearCharacteristicTitle(hs) : getSingleYearCrimeTypeTitle(hs)
  }
  let majorGroupCategories = []
  if (characteristicPage) {
    majorGroupCategories = [...new Set(emptiesCulled.map(d => d.levelDesc2))]
  } else if (crimeTypePage) {
    majorGroupCategories = [...new Set(emptiesCulled.map(d => d.crimeType))]
  } else { majorGroupCategories = [...new Set(emptiesCulled.map(d => d.levelDesc2))] }
  let minorGroupCategories = [...new Set(emptiesCulled.map(d => d.levelDesc1))]
  let groupedSeries = []
  let minorwidth
  // be aware that the secondary (char B) will become the MAJOR group when there are two
  if (minorgroupForSort === 'Victim-offender relationship' || minorgroupForSort === 'Weapon category' || minorgroupForSort === 'Location of incident' || minorgroupForSort === 'MSA status') {
    minorwidth = 240
  } else if (minorgroupForSort === 'Race/Hispanic origin' || minorgroupForSort === 'Household income' || minorgroupForSort === 'Household size' || minorgroupForSort === 'Marital status') {
    minorwidth = 150
  } else if (minorgroupForSort === 'Population size') {
    minorwidth = 165
  } else if (minorgroupForSort === 'All') {
    minorwidth = 20
  } else if (minorgroupForSort === 'Victim services use') {
    minorwidth = 60
  } else {
    minorwidth = 100
  }
  let allseriesflagsmax = []
  let estimateSet = null
  let ciSet = null
  let flagsSet = null
  let allCiSets = []
  let allEstimateSets = []
  let minYear = 9999
  let maxYear = 0
  minorGroupCategories.forEach(function (d, i) {
    filtered.sort((a, b) => a.levelNum1 - b.levelNum1)
    filtered.sort((a, b) => a.levelNum2 - b.levelNum2)
    minYear = filtered[0].year
    maxYear = filtered[0].year
    let filteredset = filtered.filter(datum => datum['levelDesc1'] === d)
    let thiscolor = seriesColor(filteredset[0], options)
    let filteredEsts = filteredset.map(function (datum) {
      let setcolor = thiscolor
      if (getFilterValueFromArray(filters, 'categoryDesc1') === 'All') {
        let cat2 = getFilterValueFromArray(filters, 'categoryDesc2')
        if (cat2 !== 'All' && characteristicPage) {
          setcolor = seriesColor(datum)
        }
        if (cat2 === 'All' || crimeTypePage) {
          setcolor = seriesColor(datum, { crimeType: true, Characteristic2: false })
        }
      }

      return {
        name: crimeTypePage ? datum['crimeType'] : datum['levelDesc2'],
        y: isNaN(datum[estimateType]) ? 0 : datum[estimateType],
        color: isNaN(datum[estimateType]) ? 'rgba(255, 255, 255, 0)' : setcolor,
        dashStyle: ((datum['levelDesc1'] === '12 to 14' || datum['levelDesc2'] === '12 to 14' || datum['levelDesc1'] === '15 to 17' || datum['levelDesc2'] === '15 to 17')) && datum['year'] === 2016 && (datum['categoryDesc1'] === 'Age' || datum['categoryDesc2'] === 'Age') ? 'ShortDot' : 'Solid'
      }
    })
    let filteredCI = filteredset.map(function (datum) {
      return {
        name: crimeTypePage ? datum['crimeType'] : datum['levelDesc2'],
        low: isNaN(datum[estimateType + 'LB']) ? 0 : datum[estimateType + 'LB'],
        high: isNaN(datum[estimateType + 'UB']) ? 0 : datum[estimateType + 'UB']
      }
    })
    let filteredFlags = filteredset.map(function (datum) {
      return {
        name: crimeTypePage ? datum['crimeType'] : datum['levelDesc2'],
        y: isNaN(datum[estimateType + 'Flag']) ? 0 : datum[estimateType + 'Flag']
      }
    })

    let flagsvals = filteredFlags.map(function (d) { return isNaN(d.y) ? 0 : d.y })
    let singleseriesflagsmax = Math.max(...flagsvals) || 0
    allseriesflagsmax.push(singleseriesflagsmax)

    estimateSet = {
      name: d,
      className: 'estimates' + ' ' + d + 'est',
      custom: filteredset,
      data: filteredEsts,
      overflow: 'allow',
      dataLabels: {
        // fix for reporting data labels
        enabled: !(hs.pageKey === 'quickGraphics' && isReason === true),
        align: 'right',
        format: d === 'All' ? '' : '{series.name}',
        crop: false,
        overflow: 'allow',
        allowOverlap: true,
        x: function () {
          return -(this.plotX + 80)
        },
        verticalAlign: 'middle',
        padding: -30,
        color: '#272727',
        style: {
          textAnchor: 'end',
          fontSize: '11.7px',
          // adjustment from just minorwidth to account for negative padding and fix location of incident label cropping
          width: minorwidth > 100 ? minorwidth - 30 : minorwidth
        },
        className: 'est_label' + ' ' + d + 'estlab'
      },
      visible: true,
      estType: estimateType,
      showInLegend: false,
      type: 'lollipop'
    }
    ciSet = {
      visible: hs.getFilter('confidenceInterval') === true,
      name: '95% C.I.',
      className: 'c_i',
      custom: d,
      data: filteredCI,
      dataLabels: {
        enabled: false
      },
      enableMouseTracking: false,
      type: 'columnrange',
      zIndex: -1,
      color: '#efe3dc8a',
      showInLegend: i === 0 && hs.getFilter('confidenceInterval') === true
    }
    flagsSet = {
      visible: hs.filters['unitFlag'] && hs.getFilter('unitFlag') === true,
      name: capitalizeFirstLetter(estimateType) + ' flag',
      className: 'flags' + ' ' + d + 'flag',
      custom: d,
      data: filteredFlags,
      crop: false,
      overflow: 'allow',
      allowOverlap: true,
      type: 'column',
      strokeOpacity: 0,
      fillOpacity: 1,
      opacity: 1,
      color: 'rgba(255, 255, 255, 0)',
      zIndex: 5,
      marker: {
        enabled: true,
        states: {
          hover: {
            enabled: false
          }
        }
      },
      enableMouseTracking: false,
      dataLabels: {
        overflow: 'allow',
        crop: false,
        align: 'center',
        verticalAlign: 'middle',
        color: '#ce4646',
        enabled: true,
        formatter: function () {
          if (this.y === 0) {
            return ' '
          } else if (this.y === 1) {
            return '▲'
          } else if (this.y === 2) {
            return '✱'
          } else {
            return ' '
          }
        },
        y: -2,
        x: -4,
        style: {
          opacity: 1
        },
        className: 'flag_label'
      },
      yAxis: 1,
      // The unit flag legend gets formatted within labelFormatter() in the lollipop component
      showInLegend: i === 0 && hs.getFilter('unitFlag') === true
    }

    if (hs.filters['referenceLine'] && characteristicPage) {
      let majorRefSet = {
        visible: hs.getFilter('referenceLine') && (demographicFilters.includes(hs.getFilter('categoryDesc2'))) === true,
        data: majorRef ? majorRef.majorRefEstimates : null,
        name: majorRef ? majorRef.majorRefName : '',
        className: 'majorrefline',
        type: 'line',
        lineWidth: 1,
        dashStyle: 'LongDashDot',
        enableMouseTracking: false,
        marker: {
          enabled: false
        },
        color: majorRef ? '#414141' : 'rgba(255, 255, 255, 0)',
        yAxis: 1,
        showInLegend: i === 0 && hs.getFilter('referenceLine') && majorRef !== null === true
      }
      groupedSeries.push(majorRefSet)
    }
    groupedSeries.push(estimateSet, ciSet, flagsSet)
    allEstimateSets.push(estimateSet)
    allCiSets.push(ciSet)
  })

  if (hs.filters['referenceLine']) {
    groupedSeries = [minorRef.series, minorRef.legendSeries, ...groupedSeries]
  }
  // check to see if any in all of the series have flags, then set legend show for first
  let maxallseriesflagsmax = Math.max(...allseriesflagsmax)
  let flagseries = groupedSeries.filter(function (d) { return d.name === capitalizeFirstLetter(estimateType) + ' flag' })

  if (maxallseriesflagsmax === 0) {
    flagseries[0].showInLegend = false
  }

  let percentOfAll = false
  if (characteristicPage && estimateType === 'percent' && getFilterValueFromArray(filters, 'categoryDesc1') !== 'All' && getFilterValueFromArray(filters, 'categoryDesc2') !== 'All') {
    percentOfAll = true
  }
  let formattedData = {
    categories: majorGroupCategories,
    categories2: minorGroupCategories,
    series: groupedSeries,
    characteristicPage: characteristicPage,
    crimeTypePage: crimeTypePage,
    qgPage: qgPage,
    isReason: isReason,
    percentOfAll: percentOfAll,
    estimateType: estimateType.charAt(0).toUpperCase() + estimateType.slice(1),
    // adjustment for length of age2016 notes breaking layout
    // height increase for quick graphics added to prevent labels not redrawing on increasing resize
    height: minorGroupCategories.length * majorGroupCategories.length > 8 ? 350 + ((minorGroupCategories.length * majorGroupCategories.length) * (hs.pageKey === 'quickGraphics' ? 25 : (getFilterValueFromArray(filters, 'categoryDesc1') === 'Location of incident' && (getFilterValueFromArray(filters, 'categoryDesc2') !== 'All' || !characteristicPage)) ? 45 : 22)) : 350 + ((minorGroupCategories.length * majorGroupCategories.length) * 35),
    minorwidth: minorwidth,
    title: charttitle,
    maxMajorRefValue: majorRef ? majorRef.majorRefEstimates[0] : null,
    // ...ForTable is for passing 'all, all' rates to tables
    maxMajorRefValueForTable: majorRef && majorRef.majorRefEstimatesForTable ? majorRef.majorRefEstimatesForTable[0] : '',
    maxMinorRefValue: minorRef && minorRef.series ? hs.getMax([].concat.apply([], minorRef.series.data)) : null,
    maxEstimateValue: getMaxFromSets(hs, allEstimateSets, 'y'),
    maxCiValue: getMaxFromSets(hs, allCiSets, 'high'),
    hasFlags: allseriesflagsmax.some(el => el > 0) || false,
    minYear: minYear,
    maxYear: maxYear,
    giveAlert: estSet.every(d => d === 0 || isNaN(d)),
    vicType: vicType
  }
  return formattedData
}

// Get the max value from the lollipop sets
function getMaxFromSets (hs, sets, maxProp) {
  let possibleMaxValues = []
  sets.forEach(set => {
    set.data.forEach(d => {
      possibleMaxValues.push(d[maxProp])
    })
  })
  return hs.getMax(possibleMaxValues)
}
// Only show the minor ref line when both categoryDesc variables are not equal to all
function showMinorRefLine (hs) {
  let show = false
  if (hs.filters['referenceLine'] && hs.getFilter('referenceLine') === true) {
    // this might need to check for crime type vs char view. cat2 does not need to be all for crime type to show
    if (hs.pageKey.includes('Characteristic')) {
      if (hs.getFilter('categoryDesc1') !== 'All' && hs.getFilter('categoryDesc2') !== 'All') {
        show = true
      }
    } else if (hs.pageKey.includes('Crime Type')) {
      if (hs.getFilter('categoryDesc1') !== 'All') {
        show = true
      }
    }
  }
  return show
}
export function groupForLollipopMinorRefLine (hs, data, estimateType, filters, refName) {
  let filtered = data
  filters.forEach(filter => {
    filtered = filtered.filter(datum => datum[filter.column] === filter.value)
  })

  let filterValueForSort = getFilterValueFromArray(filters, 'categoryDesc2')
  filtered = filtered.filter(datum => datum.categoryDesc2 === filterValueForSort)

  let refObject = {
    'refName': refName,
    'refEstimates': []
  }
  filtered.forEach(datum => {
    refObject.refEstimates.push([datum[estimateType], datum[estimateType]])
  })
  let minorRefSeries = {
    id: 'minorrefline',
    name: refObject.refName,
    className: 'minorrefline',
    custom: refObject.refEstimates,
    data: refObject.refEstimates,
    visible: showMinorRefLine(hs),
    showInLegend: false,
    estType: estimateType,
    type: 'errorbar',
    styledMode: true,
    stacking: 'normal',
    plotOptions: {
      column: {
        // this is what keeps this series at major level y-axis
        // with data, this should get the data for 'all' for whatever the minor-level group category is
        grouping: false
      }
    },
    dataLabels: {
      enabled: false
    },
    borderWidth: 0,
    pointHeight: 12,
    groupPadding: -0.1,
    zIndex: 5,
    color: '#fff',
    whiskerColor: '#8d8d8d',
    whiskerLength: '90%',
    whiskerDashStyle: 'ShortDot',
    lineWidth: 2,
    marker: {
      enabled: false
    },
    enableMouseTracking: false
  }
  // The minor reference line is created using an errorbar type chart, which does not allow a
  // symbol in the legend. We add an empty series just for the legend and link it to the minorRef series
  const legendSeries = {
    name: refObject.refName,
    className: 'minorrefline-legend',
    data: [],
    type: 'line',
    color: '#8d8d8d',
    dashStyle: 'ShortDot',
    marker: {
      symbol: '',
      fillColor: 'transparent'
    },
    linkedTo: 'minorrefline',
    showInLegend: showMinorRefLine(hs)
  }
  return { series: minorRefSeries, legendSeries }
}

export function groupForLollipopMajorRefLine (hs, data, estimateType, filters, majorRefName) {
  if (demographicFilters.includes(hs.getFilter('categoryDesc2'))) {
    let filtered = data
    filters.forEach(filter => {
      filtered = filtered.filter(datum => datum[filter.column] === filter.value)
    })

    let majorRefObject = {
      'majorRefName': majorRefName,
      'majorRefEstimates': []
    }
    filtered.forEach(datum => {
      majorRefObject.majorRefEstimates.push(datum[estimateType])
    })
    return majorRefObject
  } else {
    let filtered = data
    filters.forEach(filter => {
      filtered = filtered.filter(datum => datum[filter.column] === filter.value)
    })

    let majorRefObject = {
      'majorRefName': majorRefName,
      'majorRefEstimates': [],
      'majorRefEstimatesForTable': []
    }
    filtered.forEach(datum => {
      majorRefObject.majorRefEstimatesForTable.push(datum[estimateType])
    })
    return majorRefObject
  }
}

export function groupForDumbbell (hs, data, estimateType, filters, useCrimeType = false) {
  const characteristicPage = hs.pageKey.includes('Characteristic')
  const crimeTypePage = hs.pageKey.includes('Crime Type')
  let filtered = data
  let options = {}
  let vicType = data[0].victimizationType
  if (characteristicPage && getFilterValueFromArray(filters, 'categoryDesc1') === 'All' && getFilterValueFromArray(filters, 'categoryDesc2') === 'All') {
    options.CrimeType = true
  } else if (characteristicPage && getFilterValueFromArray(filters, 'categoryDesc1') === 'All' && getFilterValueFromArray(filters, 'categoryDesc2') !== 'All') {
    options.Characteristic2 = true
  } else {
    options.Characteristic = true
  }

  filters.forEach(filter => {
    if (filter.column !== 'year' && filter.column !== 'year2') {
      filtered = filtered.filter(datum => {
        if (Array.isArray(filter.value)) {
          return filter.value.includes(datum[filter.column])
        }
        return datum[filter.column] === filter.value
      })
      filtered.sort((a, b) => a.catNum1 - b.catNum1)
      filtered.sort((a, b) => a.catNum2 - b.catNum2)
      filtered.sort((a, b) => a.levelNum1 - b.levelNum1)
      filtered.sort((a, b) => a.levelNum2 - b.levelNum2)
    } else {
      filtered = filtered.filter(datum => datum['year'] === hs.getFilter('year') || datum['year'] === hs.getFilter('year2'))
      filtered.sort((a, b) => a.year - b.year)
    }
  })
  if (!filtered.length || (hs.getFilter('year') === hs.getFilter('year2'))) {
    return false
  }
  let emptiesCulled = filtered.filter(d => !isNaN(d[estimateType]))
  if (!emptiesCulled.length) {
    return false
  }
  let year1Ests = filtered.filter(d => d['year'] === hs.getFilter('year'))
  let year1Set = [...new Set(year1Ests.map(d => d[estimateType]))]
  let year2Ests = filtered.filter(d => d['year'] === hs.getFilter('year2'))
  let year2Set = [...new Set(year2Ests.map(d => d[estimateType]))]
  let minorgroupForSort = getFilterValueFromArray(filters, 'categoryDesc1')
  let charttitle = characteristicPage ? getTwoYearCharacteristicTitle(hs) : getTwoYearCrimeTypeTitle(hs)

  let majorGroupCategories = []
  if (characteristicPage) {
    majorGroupCategories = [...new Set(emptiesCulled.map(d => d.levelDesc2))]
  } else if (crimeTypePage) {
    majorGroupCategories = [...new Set(emptiesCulled.map(d => d.crimeType))]
  }
  let minorGroupCategories = [...new Set(emptiesCulled.map(d => d.levelDesc1))]
  let groupedSeries = []
  let minorwidth
  if (minorgroupForSort === 'Victim-offender relationship' || minorgroupForSort === 'Weapon category' || minorgroupForSort === 'Location of incident' || minorgroupForSort === 'MSA status') {
    minorwidth = 250
  } else if (minorgroupForSort === 'Race/Hispanic origin' || minorgroupForSort === 'Household income' || minorgroupForSort === 'Household size' || minorgroupForSort === 'Marital status') {
    minorwidth = 160
  } else if (minorgroupForSort === 'Population size') {
    minorwidth = 165
  } else if (minorgroupForSort === 'All') {
    minorwidth = 20
  } else if (minorgroupForSort === 'Victim services use') {
    minorwidth = 60
  } else {
    minorwidth = 100
  }
  let allseriesflagsmax = []
  let pairedyearset = []
  let firstyear = filtered.filter(d => d.year === hs.getFilter('year'))
  firstyear.forEach(function (d, i) {
    let pairedyears = filtered.filter(function (n) {
      if (n.categoryDesc1 === d.categoryDesc1 && n.categoryDesc2 === d.categoryDesc2 && n.levelDesc1 === d.levelDesc1 && n.levelDesc2 === d.levelDesc2 && n.crimeType === d.crimeType) { return n }
    })
    pairedyearset.push(pairedyears)
  })

  let priorseries
  minorGroupCategories.forEach(function (d, i) {
    pairedyearset.sort((a, b) => a[0].levelNum1 - b[0].levelNum1)
    pairedyearset.sort((a, b) => a[0].levelNum2 - b[0].levelNum2)
    let filteredset = pairedyearset.filter(datum => datum[0]['levelDesc1'] === d)
    let thiscolor = seriesColor(filteredset[0][0], options)
    let filteredEsts = filteredset.map(function (datum) {
      let setcolor
      if (getFilterValueFromArray(filters, 'categoryDesc1') === 'All' && getFilterValueFromArray(filters, 'categoryDesc2') !== 'All' && characteristicPage) {
        setcolor = seriesColor(datum[0])
      } else if (getFilterValueFromArray(filters, 'categoryDesc1') === 'All' && crimeTypePage) {
        setcolor = seriesColor(datum[0], { crimeType: true, Characteristic2: false })
      } else {
        setcolor = thiscolor
      }
      let symbolsetter

      if (datum[0][estimateType] > datum[1][estimateType]) {
        symbolsetter = 'triangle-down'
      } else if (datum[0][estimateType] < datum[1][estimateType]) {
        symbolsetter = 'triangle'
      } else {
        symbolsetter = 'circle'
      }
      return {
        name: datum[0]['levelDesc1'] + datum[0]['levelDesc2'],
        low: isNaN(datum[0][estimateType]) ? 0 : datum[0][estimateType],
        high: isNaN(datum[1][estimateType]) ? 0 : datum[1][estimateType],
        color: isNaN(datum[1][estimateType]) ? 'rgba(255, 255, 255, 0)' : setcolor,
        connectorColor: isNaN(datum[0][estimateType]) || isNaN(datum[1][estimateType]) ? 'rgba(255, 255, 255, 0)' : setcolor,
        lowColor: isNaN(datum[0][estimateType]) ? 'rgba(255, 255, 255, 0)' : hex2rgba(setcolor),
        marker: {
          symbol: symbolsetter,
          lineColor: '#fff',
          lineWidth: 1,
          radius: 6
        },
        lowerGraphic: {
          symbol: 'circle'
        },
        dashStyle: ((datum[0]['levelDesc1'] === '12 to 14' || datum[0]['levelDesc2'] === '12 to 14' || datum[0]['levelDesc1'] === '15 to 17' || datum[0]['levelDesc2'] === '15 to 17')) && (datum[0]['year'] === 2016 || datum[1]['year'] === 2016) && (datum[0]['categoryDesc1'] === 'Age' || datum[0]['categoryDesc2'] === 'Age') ? 'ShortDot' : 'Solid'
      }
    })

    let earlyCI = filteredset.map(function (datum, i) {
      return {
        name: datum[0]['levelDesc1'] + datum[0]['levelDesc2'],
        x: i,
        low: isNaN(datum[0][estimateType + 'LB']) ? 0 : datum[0][estimateType + 'LB'],
        high: isNaN(datum[0][estimateType + 'UB']) ? 0 : datum[0][estimateType + 'UB'],
        color: 'rgba(185, 85, 0, 0.13)'
      }
    })
    let lateCI = filteredset.map(function (datum, i) {
      return {
        name: datum[1]['levelDesc1'] + datum[1]['levelDesc2'],
        x: i,
        low: isNaN(datum[1][estimateType + 'LB']) ? 0 : datum[1][estimateType + 'LB'],
        high: isNaN(datum[1][estimateType + 'UB']) ? 0 : datum[1][estimateType + 'UB'],
        color: 'rgba(0, 0, 0, 0.12)'
      }
    })

    let comboCI = earlyCI.concat(lateCI)

    let filteredFlags = filteredset.map(function (datum) {
      return {
        name: datum[0]['levelDesc1'] + datum[0]['levelDesc2'],
        y: isNaN(datum[0][estimateType + 'Flag']) ? 0 : datum[0][estimateType + 'Flag'],
        y2: isNaN(datum[1][estimateType + 'Flag']) ? 0 : datum[1][estimateType + 'Flag']
      }
    })

    let flagsvals = filteredFlags.map(function (d) { return isNaN(d.y) ? 0 : d.y })
    let singleseriesflagsmax = Math.max(...flagsvals) || 0
    allseriesflagsmax.push(singleseriesflagsmax)

    function capitalizeFirstLetter (string) {
      return string.charAt(0).toUpperCase() + string.slice(1)
    }

    let estimateSet = {
      name: d,
      className: 'estimates' + ' ' + d + 'est',
      custom: filteredset,
      data: filteredEsts,
      overflow: 'allow',
      dataLabels:
          {
            enabled: true,
            formatter: function (d) {
              let hityet

              if (priorseries !== this.series.name + '_series') {
                this.point.alreadyhit = false
                hityet = 'no'
              }

              if (this.point.alreadyhit === undefined || this.point.alreadyhit === false) {
                hityet = 'no'
              } else {
                hityet = 'thinkso?'
                this.setstyle = {
                  color: this.point.color
                }
              }

              if (this.y !== this.point.low) {
                priorseries = this.series.name + '_series'
                this.point.alreadyhit = true
                this.setstyle = {
                  color: '#0027ff'
                }
                return this.series.name === 'All' ? '' : this.series.name
              } else if (this.y === this.point.low && this.y === this.point.high && hityet === 'no') {
                priorseries = this.series.name + '_series'
                this.point.alreadyhit = true
                return this.series.name === 'All' ? '' : this.series.name
              } else if (this.y === this.point.low && this.y === this.point.high && this.point.alreadyhit === true) {
                this.point.alreadyhit = false
                this.setstyle = {
                  color: this.point.color
                }
                return '⬤'
              } else if (this.y === this.point.low) {
                // fix for NaN for higher year but val for lower year
                if (this.point.color === 'rgba(255, 255, 255, 0)') {
                  this.point.color = this.point.lowColor
                }
                return '⬤'
              } else {
                return ''
              }
            },
            crop: false,
            allowOverlap: true,
            x: 0,
            y: 0,
            zIndex: 2,
            xLow: 30,
            yLow: -1,
            xHigh: function () {
              return -(this.plotX + 80)
            },
            verticalAlign: 'middle',
            align: 'center',
            padding: -30,
            color: '#272727',
            position: 'center',
            style: {
              textAnchor: 'end',
              // location label crop fix
              width: minorwidth > 100 ? minorwidth - 30 : minorwidth,
              fontSize: '1em'
            },
            className: 'est_label' + ' ' + d + 'estlab',
            // needed to ensure PNG export renders correctly
            backgroundColor: '#ffffff'
          },
      visible: true,
      estType: estimateType,
      showInLegend: i === 0,
      type: 'dumbbell'
    }
    let earlyCiSet = {
      visible: hs.getFilter('confidenceInterval') === true,
      name: '95% C.I.',
      className: 'c_i',
      custom: d,
      data: comboCI,
      dataLabels: {
        enabled: false
      },
      enableMouseTracking: false,
      type: 'columnrange',
      zIndex: -1,
      // no color on series. color on points to have two, and transparent legend symbol
      color: 'rgba(255, 255, 255, 0)',
      showInLegend: i === 0 && hs.getFilter('confidenceInterval') === true
    }
    let flagsSet = {
      visible: hs.filters['unitFlag'] && hs.getFilter('unitFlag') === true,
      name: capitalizeFirstLetter(estimateType) + ' flag',
      className: 'flags' + ' ' + d + 'flag',
      custom: d,
      data: filteredFlags,
      crop: false,
      overflow: 'allow',
      allowOverlap: true,
      type: 'column',
      strokeOpacity: 0,
      fillOpacity: 1,
      opacity: 1,
      color: 'rgba(255, 255, 255, 0)',
      zIndex: 5,
      marker: {
        enabled: true,
        states: {
          hover: {
            enabled: false
          }
        }
      },
      enableMouseTracking: false,
      dataLabels: {
        overflow: 'allow',
        crop: false,
        align: 'center',
        verticalAlign: 'middle',
        color: '#ce4646',
        enabled: true,
        formatter: function () {
          if (this.y === 0 && this.y2 === 0) {
            return ' '
          } else if (this.y === 1 || this.y2 === 1) {
            return '▲'
          } else if (this.y === 2 || this.y === 2) {
            return '✱'
          } else {
            return ' '
          }
        },
        y: -2,
        x: -4,
        style: {
          opacity: 1
        },
        className: 'flag_label'
      },
      yAxis: 1,
      showInLegend: i === 0 && hs.getFilter('unitFlag') === true
    }
    groupedSeries.push(estimateSet, earlyCiSet, flagsSet)
  })
  // check to see if any in all of the series have flags, then set legend show for first
  let maxallseriesflagsmax = Math.max(...allseriesflagsmax)
  let flagseries = groupedSeries.filter(function (d) { return d.name === capitalizeFirstLetter(estimateType) + ' flag' })
  if (maxallseriesflagsmax === 0) { flagseries[0].showInLegend = false }

  let minYear = null
  let maxYear = null
  if (hs.getFilter('year') <= hs.getFilter('year2')) {
    minYear = hs.getFilter('year')
    maxYear = hs.getFilter('year2')
  } else {
    minYear = hs.getFilter('year2')
    maxYear = hs.getFilter('year')
  }

  let percentOfAll = false
  if (characteristicPage && estimateType === 'percent' && getFilterValueFromArray(filters, 'categoryDesc1') !== 'All' && getFilterValueFromArray(filters, 'categoryDesc2') !== 'All') {
    percentOfAll = true
  }

  let formattedData = {
    categories: majorGroupCategories,
    categories2: minorGroupCategories,
    series: groupedSeries,
    characteristicPage: characteristicPage,
    crimeTypePage: crimeTypePage,
    percentOfAll: percentOfAll,
    estimateType: capitalizeFirstLetter(estimateType),
    height: minorGroupCategories.length * majorGroupCategories.length > 8 ? 350 + ((minorGroupCategories.length * majorGroupCategories.length) * (hs.pageKey === 'quickGraphics' ? 25 : (getFilterValueFromArray(filters, 'categoryDesc1') === 'Location of incident' && (getFilterValueFromArray(filters, 'categoryDesc2') !== 'All' || !characteristicPage)) ? 45 : 22)) : 350 + ((minorGroupCategories.length * majorGroupCategories.length) * 35),
    minorwidth: minorwidth,
    title: charttitle,
    hasFlags: allseriesflagsmax.some(el => el > 0) || false,
    minYear: minYear,
    maxYear: maxYear,
    giveAlert: year1Set.every(d => d === 0 || isNaN(d)) || year2Set.every(d => d === 0 || isNaN(d)),
    vicType: vicType
  }
  return formattedData
}

/**
 * @param {Array} filters Array of objects with attributes column and value
 * @param {String} filterName Filter's column name to look for in array
 */
export function getFilterValueFromArray (filters, filterName) {
  let filter = filters.filter(filter => { return filter.column === filterName })
  return filter.length ? filter[0].value : 'All'
}

export function areaChartTableAdapter (chart, filters, data) {
  let year = data.estimates[0][0] // we expect the estimates to be filtered
  let final = []
  let level1NotAll = false
  let level2NotAll = false
  let numberFlagPresent = false
  let estimateFlagPresent = false
  let singleAreaChart = data.singleAreaChart
  let isCrimeSmallMult = data.isCrimeSmallMult
  let estimateType = capitalizeFirstLetter(data.estimateType)
  estimateType = estimateType === 'Count' ? 'Number' : estimateType
  let estimateTypeLabel = estimateType
  let vicType = data.vicType
  const cat1Unique = [...new Set(data.descriptions.map(datum => datum.categoryDesc1))]
  let dropCat1 = cat1Unique.length === 1
  let level1Header = dropCat1 ? cat1Unique[0] === 'Race/Hispanic origin2' ? 'Race/Hispanic origin' : cat1Unique[0] : 'Level 1'
  const cat2Unique = [...new Set(data.descriptions.map(datum => datum.categoryDesc2))]
  let dropCat2 = cat2Unique.length === 1
  let level2Header = dropCat2 ? cat2Unique[0] : 'Level 2'
  const crimeTypeUnique = [...new Set(data.descriptions.map(datum => datum.crimeType))]

  if (estimateTypeLabel === 'Percent') {
    let crimeType = [...new Set(crimeTypeUnique)][0]
    if (data.percentOfAll) {
      estimateTypeLabel = `Percent of ${pluralsFix(crimeType.toLowerCase())} per ${labelLowerCase(level2Header)}`
    } else {
      if (isCrimeSmallMult) { estimateTypeLabel = 'Percent of crime type' } else { estimateTypeLabel = `Percent of ${pluralsFix(crimeType.toLowerCase())}` }
    }
  }
  if (estimateTypeLabel === 'Rate') {
    vicType === 'Property'
      ? estimateTypeLabel = 'Rate per 1000 households'
      : estimateTypeLabel = 'Rate per 1000 persons age 12 or older'
  }
  data.estimates.forEach((datum, idx) => {
    let row = { 'Year': year }
    if (!dropCat1) {
      row['Category 1'] = data.descriptions[idx].categoryDesc1 === 'Race/Hispanic origin2' ? 'Race/Hispanic origin' : data.descriptions[idx].categoryDesc1
    }
    row[level1Header] = data.descriptions[idx].levelDesc1
    if (!dropCat2) {
      row['Category 2'] = data.descriptions[idx].categoryDesc2
    }
    row[level2Header] = data.descriptions[idx].levelDesc2
    row['Crime Type'] = transformToSentenceCase(data.descriptions[idx].crimeType)
    row['Number'] = isNaN(data.counts[idx].count) ? '' : data.counts[idx].count.toLocaleString()
    row['Number CI Lower Bound'] = isNaN(data.counts[idx].ciLB) ? '' : data.counts[idx].ciLB.toLocaleString()
    row['Number CI Upper Bound'] = isNaN(data.counts[idx].ciUB) ? '' : data.counts[idx].ciUB.toLocaleString()
    row['Number SE'] = isNaN(data.counts[idx].se) ? '' : data.counts[idx].se.toLocaleString()
    row['Number Flag'] = data.counts[idx].countFlag
    if (estimateType !== 'Number') {
      row[estimateTypeLabel] = isNaN(datum[1]) ? '' : Number(datum[1]).toFixed(1).toLocaleString()
      row[estimateType + ' CI Lower Bound'] = isNaN(data.range[idx][1]) ? '' : Number(data.range[idx][1]).toFixed(2).toLocaleString()
      row[estimateType + ' CI Upper Bound'] = isNaN(data.range[idx][2]) ? '' : Number(data.range[idx][2]).toFixed(2).toLocaleString()
      row[estimateType + ' SE'] = isNaN(data.s_e[idx][1]) ? '' : Number(data.s_e[idx][1]).toFixed(2).toLocaleString()
      row[estimateType + ' Flag'] = data.flags[idx][1]
    }
    year++

    if (data.descriptions[idx].categoryDesc1 !== 'All' || row[level1Header] !== 'All') {
      level1NotAll = true
    }
    if (data.descriptions[idx].categoryDesc2 !== 'All' || row[level2Header] !== 'All') {
      level2NotAll = true
    }
    if (data.counts[idx].countFlag !== 0) {
      numberFlagPresent = true
    }
    if (data.flags[idx][1] !== 0 && estimateType !== 'Number') {
      estimateFlagPresent = true
    }
    final.push(row)
  })
  if (!level2NotAll) {
    final.forEach(row => {
      delete row['Category 2']
      delete row[level2Header]
      if (!level1NotAll) {
        delete row['Category 1']
        delete row[level1Header]
      }
    })
  }
  if (singleAreaChart && (!numberFlagPresent || !estimateFlagPresent)) {
    final.forEach(row => {
      if (!numberFlagPresent) {
        delete row['Number Flag']
      }
      if (!estimateFlagPresent) {
        delete row[estimateType + ' Flag']
      }
    })
  }
  final = final.sort((a, b) => a.Year - b.Year)
  return final
}

export function lollipopTableAdapter (chart, filters, data) {
  let characteristicLollipop = data.characteristicPage
  let crimeTypeLollipop = data.crimeTypePage
  let minorRefLinePresent = data.maxMinorRefValue !== -Infinity
  let se = data.estimateType.toLowerCase() + 'SE'
  let estimateType = capitalizeFirstLetter(data.estimateType)
  estimateType = estimateType === 'Count' ? 'Number' : estimateType
  let estimateTypeLabel = estimateType
  let isReason = data.isReason
  let qgPage = data.qgPage
  let displayRefLines = (characteristicLollipop || crimeTypeLollipop) && estimateType === 'Rate' && minorRefLinePresent
  let seLabel = estimateType + ' SE'
  let ciUbLabel = estimateType + ' CI Upper Bound'
  let ciLbLabel = estimateType + ' CI Lower Bound'
  let final = []
  let level1NotAll = false
  let level2NotAll = false
  let numberFlagPresent = false
  let estimateFlagPresent = false
  let rowCount = 0
  let crimeTypeCount = 0
  let confidenceIntervals = []
  let ciRowCount = 0
  let minorRefLines = []
  let unitFlags = []
  let vicType = data.vicType
  const lollipopSeriesIncrementer = characteristicLollipop ? 4 : 3 // For skipping over 95% CI and Unit Flag series
  const rowCountIncrementer = 1
  const dataPointStartRow = characteristicLollipop ? 3 : crimeTypeLollipop ? 2 : 0
  const ciStartRow = characteristicLollipop ? 4 : crimeTypeLollipop ? 3 : 1
  const unitFlagStartRow = characteristicLollipop ? 5 : crimeTypeLollipop ? 4 : 2
  const refLineStartRow = 0
  const crimeTypeCountIncrementer = 1

  let cat1Values = []
  let cat2Values = []
  let crimeTypes = []
  // fix to remove duplicitive ci series used for styling
  data.series[dataPointStartRow].data.forEach((_, i) => {
    for (let j = dataPointStartRow; j < data.series.length; j += lollipopSeriesIncrementer) {
      cat1Values.push(data.series[j].custom[i].categoryDesc1)
      cat2Values.push(data.series[j].custom[i].categoryDesc2)
      crimeTypes.push(data.series[j].custom[i].crimeType)
    }
  })
  const cat1Unique = [...new Set(cat1Values)]
  let dropCat1 = cat1Unique.length === 1
  let level1Header = dropCat1 ? cat1Unique[0] : 'Level 1'
  const cat2Unique = [...new Set(cat2Values)]
  let dropCat2 = cat2Unique.length === 1
  let level2Header = dropCat2 ? cat2Unique[0] : 'Level 2'
  const showBothRefLineTableRows = (dropCat1 && cat1Unique[0] !== 'All') && (dropCat2 && cat2Unique[0] !== 'All')
  const showMajorRefLineTableRows = !showBothRefLineTableRows && ((dropCat1 && cat1Unique[0] !== 'All') || (dropCat2 && cat2Unique[0] !== 'All'))
  if (estimateTypeLabel === 'Percent') {
    if (crimeTypeLollipop) {
      estimateTypeLabel = 'Percent of Crime Type'
    } else if (characteristicLollipop) {
      let crimeType = [...new Set(crimeTypes)][0]
      // appears percentOfAll had been to emphasize percentOfAll[secondaryThing], not that it is a percent of 'All' !
      if (data.percentOfAll) {
        estimateTypeLabel = `Percent of ${pluralsFix(crimeType.toLowerCase())} per ${labelLowerCase(level2Header)}`
      } else {
        estimateTypeLabel = `Percent of ${pluralsFix(crimeType.toLowerCase())}`
      }
    } else if (qgPage) {
      if (isReason) {
        estimateTypeLabel = `${chart.props.title.split(', by')[0]}`
      } else {
        estimateTypeLabel = `Percent of ${pluralsFix(data.series[0].custom[0].crimeType)}`
      }
    }
  }
  if (estimateTypeLabel === 'Rate') {
    vicType === 'Property'
      ? estimateTypeLabel = 'Rate per 1000 households'
      : estimateTypeLabel = 'Rate per 1000 persons age 12 or older'
  }

  while (ciRowCount < data.series[ciStartRow].data.length) {
    let ciCount = ciStartRow
    let unitFlagCount = unitFlagStartRow
    while (ciCount < data.series.length) {
      let ciLow = data.series[ciCount].data[ciRowCount].low === 0 ? '' : data.series[ciCount].data[ciRowCount].low
      let ciHigh = data.series[ciCount].data[ciRowCount].high === 0 ? '' : data.series[ciCount].data[ciRowCount].high
      let confidenceInterval = [ciLow, ciHigh]
      confidenceIntervals.push(confidenceInterval)
      unitFlags.push(data.series[unitFlagCount].data[ciRowCount].y)
      if ((characteristicLollipop || crimeTypeLollipop) && minorRefLinePresent) {
        let refLine = data.series[refLineStartRow].data.length !== 1 ? data.series[refLineStartRow].data[ciRowCount][0] : data.series[refLineStartRow].data[0][0]
        minorRefLines.push(refLine)
      }
      ciCount += lollipopSeriesIncrementer
      unitFlagCount += lollipopSeriesIncrementer
    }
    ciRowCount++
  }

  let rowCheckRowCount = 0
  let rowCheckCrimeTypeCount = 0
  while (rowCheckCrimeTypeCount < data.series[dataPointStartRow].data.length) {
    let dataPointCount = dataPointStartRow
    while (dataPointCount < data.series.length) {
      if (data.series[dataPointCount].custom[rowCheckCrimeTypeCount].categoryDesc1 !== 'All' || data.series[dataPointCount].custom[rowCheckCrimeTypeCount].levelDesc1 !== 'All') {
        level1NotAll = true
      }
      if (data.series[dataPointCount].custom[rowCheckCrimeTypeCount].categoryDesc2 !== 'All' || data.series[dataPointCount].custom[rowCheckCrimeTypeCount].levelDesc2 !== 'All') {
        level2NotAll = true
      }
      if (data.series[dataPointCount].custom[rowCheckCrimeTypeCount].countFlag !== 0) {
        numberFlagPresent = true
      }
      if (unitFlags[rowCheckRowCount] !== 0 && estimateType !== 'Number') {
        estimateFlagPresent = true
      }
      dataPointCount += lollipopSeriesIncrementer
      rowCheckRowCount += rowCountIncrementer
    }
    rowCheckCrimeTypeCount += crimeTypeCountIncrementer
  }

  function pushRowData ({ data, options, refLine = null }) {
    const dataRow = data.series[options.dataPointCount].custom[crimeTypeCount]
    let estimateValue = isNaN(dataRow[estimateType.toLowerCase()]) ? '' : Number(dataRow[estimateType.toLowerCase()]).toFixed(1).toLocaleString()
    if (refLine === 'minor') {
      estimateValue = isNaN(minorRefLines[rowCount]) ? '' : Number(minorRefLines[rowCount]).toFixed(2).toLocaleString()
    } else if (refLine === 'major') {
      // revision to correct wrong vals for 'All,All' rates in table view ?
      estimateValue = isNaN(data.maxMajorRefValue) ? Number(data.maxMajorRefValueForTable).toFixed(2).toLocaleString() : Number(data.maxMajorRefValue).toFixed(2).toLocaleString()
    }
    let row = {}
    row['Year'] = dataRow.year
    if (!dropCat1) {
      row['Category 1'] = dataRow.categoryDesc1
    }
    row[level1Header] = refLine ? 'All' : dataRow.levelDesc1
    if (!dropCat2) {
      row['Category 2'] = dataRow.categoryDesc2
    }
    row[level2Header] = refLine === 'major' ? 'All' : dataRow.levelDesc2
    row['Crime Type'] = transformToSentenceCase(dataRow.crimeType)
    row['Number'] = isNaN(dataRow.count) || refLine ? '' : dataRow.count.toLocaleString()
    row['Number CI Lower Bound'] = isNaN(dataRow.countLB) || refLine ? '' : dataRow.countLB.toLocaleString()
    row['Number CI Upper Bound'] = isNaN(dataRow.countUB) || refLine ? '' : dataRow.countUB.toLocaleString()
    row['Number SE'] = isNaN(dataRow.countSE) || refLine ? '' : dataRow.countSE.toLocaleString()
    row['Number Flag'] = refLine ? '' : isNaN(dataRow.countFlag) ? '' : dataRow.countFlag
    if (estimateType !== 'Number') {
      row[estimateTypeLabel] = estimateValue
      row[ciLbLabel] = refLine ? '' : Number(confidenceIntervals[rowCount][0]).toFixed(2).toLocaleString()
      row[ciUbLabel] = refLine ? '' : Number(confidenceIntervals[rowCount][1]).toFixed(2).toLocaleString()
      row[seLabel] = isNaN(dataRow[se]) || refLine ? '' : Number(dataRow[se]).toFixed(2).toLocaleString()
      row[estimateType + ' Flag'] = refLine ? '' : unitFlags[rowCount]
    }
    final.push(row)
  }

  while (crimeTypeCount < data.series[dataPointStartRow].data.length) {
    let dataPointCount = dataPointStartRow
    while (dataPointCount < data.series.length) {
      let displayRefLineRow = crimeTypeLollipop ? dataPointCount === data.series.length - lollipopSeriesIncrementer : dataPointCount === ((data.series.length - lollipopSeriesIncrementer) + 1)
      let options = {
        'dataPointCount': dataPointCount
      }
      // data row
      pushRowData({ data: data, options: options })
      let displayMajorRefRow = !characteristicLollipop || showBothRefLineTableRows || showMajorRefLineTableRows
      let displayMinorRefRow = !characteristicLollipop || showBothRefLineTableRows
      if (displayMinorRefRow) {
        // minor ref line
        if (displayRefLineRow && displayRefLines && level1NotAll) {
          pushRowData({ data: data, options: options, refLine: 'minor' })
        }
      }
      if (displayMajorRefRow) {
        // major ref line
        if (displayRefLineRow && (crimeTypeCount === data.series[dataPointStartRow].data.length - crimeTypeCountIncrementer) && displayRefLines && !crimeTypeLollipop) {
          pushRowData({ data: data, options: options, refLine: 'major' })
        }
      }
      dataPointCount += lollipopSeriesIncrementer
      rowCount += rowCountIncrementer
    }
    crimeTypeCount += crimeTypeCountIncrementer
  }
  if (!level2NotAll) {
    final.forEach(row => {
      delete row['Category 2']
      delete row[level2Header]
      if (!level1NotAll) {
        delete row['Category 1']
        delete row[level1Header]
      }
    })
  }
  if (!numberFlagPresent || !estimateFlagPresent) {
    final.forEach(row => {
      if (!numberFlagPresent) {
        delete row['Number Flag']
      }
      if (!estimateFlagPresent) {
        delete row[estimateType + ' Flag']
      }
    })
  }
  return final
}

export function dumbbellTableAdapter (chart, filters, data) {
  let characteristicLollipop = data.characteristicPage
  let crimeTypeLollipop = data.crimeTypePage
  let se = data.series[0].estType.toLowerCase() + 'SE'
  let flag = data.series[0].estType + 'Flag'
  let estimateType = data.series[0].estType
  let estimateTypeLabel = capitalizeFirstLetter(data.series[0].estType)
  estimateTypeLabel = estimateTypeLabel === 'Count' ? 'Number' : estimateTypeLabel
  let seLabel = estimateTypeLabel + ' SE'
  let ciUbLabel = estimateTypeLabel + ' CI Upper Bound'
  let ciLbLabel = estimateTypeLabel + ' CI Lower Bound'
  let final = []
  let level1NotAll = false
  let level2NotAll = false
  let numberFlagPresent = false
  let estimateFlagPresent = false
  let year1 = data.series[0].custom[0][0].year
  let year2 = data.series[0].custom[0][1].year
  let confidenceIntervals = []
  let rowCount = 0
  const rowCountIncrementer = 1
  const dataPointCountInrementer = 3
  const ciCountIncrementer = 3
  let crimeTypeCount = 0
  const crimeTypeCountIncrementer = 1
  let year1Count = 0
  let year2Count = data.series[1].data.length / 2

  let cat1Values = []
  let cat2Values = []
  let crimeTypes = []
  let vicType = data.vicType
  let catCrimeTypeCount = 0
  while (catCrimeTypeCount < data.series[0].data.length) {
    let catPointCount = 0
    while (catPointCount < data.series.length) {
      cat1Values.push(data.series[catPointCount].custom[catCrimeTypeCount][0].categoryDesc1)
      cat2Values.push(data.series[catPointCount].custom[catCrimeTypeCount][0].categoryDesc2)
      crimeTypes.push(data.series[catPointCount].custom[catCrimeTypeCount][0].crimeType)
      catPointCount += dataPointCountInrementer
    }
    catCrimeTypeCount += crimeTypeCountIncrementer
  }
  const cat1Unique = [...new Set(cat1Values)]
  let dropCat1 = cat1Unique.length === 1
  let level1Header = dropCat1 ? cat1Unique[0] : 'Level 1'
  const cat2Unique = [...new Set(cat2Values)]
  let dropCat2 = cat2Unique.length === 1
  let level2Header = dropCat2 ? cat2Unique[0] : 'Level 2'
  // eslint-disable-next-line no-unused-vars
  const crimeTypeUnique = [...new Set(crimeTypes)]

  if (estimateTypeLabel === 'Percent') {
    // TODO: need to add data var to distinguish if char or crime type page
    // might as well add vars for crime types
    if (crimeTypeLollipop) {
      estimateTypeLabel = 'Percent of crime type'
    } else if (characteristicLollipop) {
      let crimeType = [...new Set(crimeTypes)][0]
      if (data.percentOfAll) {
        estimateTypeLabel = `Percent of ${pluralsFix(crimeType.toLowerCase())} per ${labelLowerCase(level2Header)}`
      } else {
        estimateTypeLabel = `Percent of ${pluralsFix(crimeType.toLowerCase())}`
      }
    }
  }
  if (estimateTypeLabel === 'Rate') {
    vicType === 'Property'
      ? estimateTypeLabel = 'Rate per 1000 households'
      : estimateTypeLabel = 'Rate per 1000 persons age 12 or older'
  }

  while (year2Count < data.series[1].data.length) {
    let ciCount = 1
    while (ciCount < data.series.length) {
      let year1CiLow = data.series[ciCount].data[year1Count].low === 0 ? '' : data.series[ciCount].data[year1Count].low
      let year1CiHigh = data.series[ciCount].data[year1Count].high === 0 ? '' : data.series[ciCount].data[year1Count].high
      let year2CiLow = data.series[ciCount].data[year2Count].low === 0 ? '' : data.series[ciCount].data[year2Count].low
      let year2CiHigh = data.series[ciCount].data[year2Count].high === 0 ? '' : data.series[ciCount].data[year2Count].high
      let confidenceInterval = {
        'year1': [year1CiLow, year1CiHigh],
        'year2': [year2CiLow, year2CiHigh]
      }
      confidenceIntervals.push(confidenceInterval)
      ciCount += ciCountIncrementer
    }
    year1Count++
    year2Count++
  }

  function getRowData (data, row, options) {
    row['Number'] = isNaN(data.series[options.dataPointCount].custom[crimeTypeCount][options.year].count) ? '' : data.series[options.dataPointCount].custom[crimeTypeCount][options.year].count.toLocaleString()
    row['Number CI Lower Bound'] = isNaN(data.series[options.dataPointCount].custom[crimeTypeCount][options.year].countLB) ? '' : data.series[options.dataPointCount].custom[crimeTypeCount][options.year].countLB.toLocaleString()
    row['Number CI Upper Bound'] = isNaN(data.series[options.dataPointCount].custom[crimeTypeCount][options.year].countUB) ? '' : data.series[options.dataPointCount].custom[crimeTypeCount][options.year].countUB.toLocaleString()
    row['Number SE'] = isNaN(data.series[options.dataPointCount].custom[crimeTypeCount][options.year].countSE) ? '' : data.series[options.dataPointCount].custom[crimeTypeCount][options.year].countSE.toLocaleString()
    row['Number Flag'] = isNaN(data.series[options.dataPointCount].custom[crimeTypeCount][options.year].countFlag) ? '' : data.series[options.dataPointCount].custom[crimeTypeCount][options.year].countFlag
    row['Number SE'] = isNaN(data.series[options.dataPointCount].custom[crimeTypeCount][options.year].countSE) ? '' : data.series[options.dataPointCount].custom[crimeTypeCount][options.year].countSE
    if (estimateTypeLabel !== 'Number') {
      row[estimateTypeLabel] = isNaN(data.series[options.dataPointCount].custom[crimeTypeCount][options.year][estimateType]) ? '' : Number(data.series[options.dataPointCount].custom[crimeTypeCount][options.year][estimateType]).toFixed(1).toLocaleString()
      if (options.year === 0) {
        row[ciLbLabel] = Number(confidenceIntervals[rowCount].year1[0]).toFixed(2).toLocaleString()
        row[ciUbLabel] = Number(confidenceIntervals[rowCount].year1[1]).toFixed(2).toLocaleString()
      } else {
        row[ciLbLabel] = Number(confidenceIntervals[rowCount].year2[0]).toFixed(2).toLocaleString()
        row[ciUbLabel] = Number(confidenceIntervals[rowCount].year2[1]).toFixed(2).toLocaleString()
      }

      row[seLabel] = isNaN(data.series[options.dataPointCount].custom[crimeTypeCount][options.year][se]) ? '' : Number(data.series[options.dataPointCount].custom[crimeTypeCount][options.year][se]).toFixed(2).toLocaleString()
      row[transformToSentenceCase(estimateType) + ' Flag'] = isNaN(data.series[options.dataPointCount].custom[crimeTypeCount][options.year][flag]) ? '' : data.series[options.dataPointCount].custom[crimeTypeCount][options.year][flag]
    }
    return row
  }

  while (crimeTypeCount < data.series[0].data.length) {
    let dataPointCount = 0
    while (dataPointCount < data.series.length) {
      for (let i = 0; i < 2; i++) {
        let isYear1 = i === 0
        let row = {}
        if (isYear1) {
          row['Year'] = year1
        } else {
          row['Year'] = year2
        }
        if (!dropCat1) {
          row['Category 1'] = data.series[dataPointCount].custom[crimeTypeCount][0].categoryDesc1
        }
        row[level1Header] = data.series[dataPointCount].custom[crimeTypeCount][0].levelDesc1
        if (!dropCat2) {
          row['Category 2'] = data.series[dataPointCount].custom[crimeTypeCount][0].categoryDesc2
        }
        row[level2Header] = data.series[dataPointCount].custom[crimeTypeCount][0].levelDesc2
        row['Crime Type'] = transformToSentenceCase(data.series[dataPointCount].custom[crimeTypeCount][0].crimeType)
        let options = {
          'dataPointCount': dataPointCount,
          'year': 0
        }
        if (isYear1) {
          row = getRowData(data, row, options)
        } else {
          options.year = 1
          row = getRowData(data, row, options)
        }
        if (data.series[dataPointCount].custom[crimeTypeCount][0].categoryDesc1 !== 'All' || row[level1Header] !== 'All') {
          level1NotAll = true
        }
        if (data.series[dataPointCount].custom[crimeTypeCount][0].categoryDesc2 !== 'All' || row[level2Header] !== 'All') {
          level2NotAll = true
        }
        if (data.series[dataPointCount].custom[crimeTypeCount][0].countFlag !== 0 || data.series[dataPointCount].custom[crimeTypeCount][1].countFlag !== 0) {
          numberFlagPresent = true
        }
        if ((data.series[dataPointCount].custom[crimeTypeCount][0][flag] !== 0 || data.series[dataPointCount].custom[crimeTypeCount][1][flag] !== 0) && estimateType !== 'Number') {
          estimateFlagPresent = true
        }
        final.push(row)
      }
      dataPointCount += dataPointCountInrementer
      rowCount += rowCountIncrementer
    }
    crimeTypeCount += crimeTypeCountIncrementer
  }
  if (!level2NotAll) {
    final.forEach(row => {
      delete row['Category 2']
      delete row[level2Header]
      if (!level1NotAll) {
        delete row['Category 1']
        delete row[level1Header]
      }
    })
  }
  if (!numberFlagPresent || !estimateFlagPresent) {
    final.forEach(row => {
      if (!numberFlagPresent) {
        delete row['Number Flag']
      }
      if (!estimateFlagPresent) {
        delete row[transformToSentenceCase(estimateType) + ' Flag']
      }
    })
  }
  return final
}

export const tableClasses = 'table-bordered table-striped table-hover'
