export default function categoryFactory (type) {
  function clone (ob) {
    return {
      key: ob.key,
      label: ob.label,
      default: ob.default || false
    }
  }
  return categories[type].map(cat => clone(cat))
}

export const categories = {
  'person': [
    {
      key: 'All',
      label: 'All',
      default: true
    },
    {
      key: 'Age',
      label: 'Victim age'
    },
    {
      key: 'Household income',
      label: 'Victim household income'
    },
    {
      key: 'Marital status',
      label: 'Victim marital status'
    },
    {
      key: 'MSA status',
      label: 'Victim MSA status'
    },
    {
      key: 'Population size',
      label: 'Victim population size'
    },
    {
      key: 'Race/Hispanic origin',
      label: 'Victim race/Hispanic origin'
    },
    {
      key: 'Region',
      label: 'Victim region'
    },
    {
      key: 'Sex',
      label: 'Victim sex'
    },
    {
      key: 'Injury',
      label: 'Injury'
    },
    {
      key: 'Location of incident',
      label: 'Location of incident'
    },
    {
      key: 'Medical treatment for physical injuries',
      label: 'Medical treatment for physical injuries'
    },
    {
      key: 'Reporting to the police',
      label: 'Reporting to the police'
    },
    {
      key: 'Victim-offender relationship',
      label: 'Victim-offender relationship'
    },
    {
      key: 'Victim services use',
      label: 'Victim services use'
    },
    {
      key: 'Weapon category',
      label: 'Weapon category'
    }
  ],
  'property': [
    {
      key: 'All',
      label: 'All',
      default: true
    },
    {
      key: 'Household income',
      label: 'Victim household income'
    },
    {
      key: 'Household size',
      label: 'Victim household size'
    },
    {
      key: 'MSA status',
      label: 'Victim MSA status'
    },
    {
      key: 'Population size',
      label: 'Victim population size'
    },
    {
      key: 'Region',
      label: 'Victim region'
    },
    {
      key: 'Location of incident',
      label: 'Location of incident'
    },
    {
      key: 'Reporting to the police',
      label: 'Reporting to the police'
    },
    {
      key: 'Victim services use',
      label: 'Victim services use'
    }
  ]
}
export const demographicFilters = ['All', 'Sex', 'Race/Hispanic origin', 'Age', 'Household income', 'Population size', 'Region', 'Marital status', 'Household size', 'MSA status']
