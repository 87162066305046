import * as d3 from 'd3'
/**
 * Gets the data matching the file keys and stores it in the cache. Then, runs specfied setFilterOptionsFunc
 * @param {Object} hs harness helper
 * @param {String} defaultKeyOne default key for categoryDesc1
 * @param {String} defaultKeyTwo default key for categoryDesc2
 * @param {Function} setFilterOptionsFunc function to use for this page
 */
export async function getDataAndSetOptions (hs, defaultKeyOne, defaultKeyTwo, setFilterOptionsFunc) {
  let fileKeyOne = hs.getFilter('categoryDesc1') || defaultKeyOne
  let fileKeyTwo = hs.getFilter('categoryDesc2') || defaultKeyTwo
  let filepath = formatCustomGraphicsFilepath(hs, fileKeyOne, fileKeyTwo)

  // NOTE: The commented out code below exists so the multi-year trends pages ALWAYS set the request cache in the load data function.
  // This allows the loadModal on multiYearTrends pages to display on a filter change. We made this edit so that the app
  // is more responsiveness to user changes on older computers whose loadData and DOM updates take longer to run. The loadData and
  // DOM updates go so quickly on singleYearComparison and yearToYearComparison pages (1 viz per page) that the loadModal does not even appear!

  // let data = null
  // let cachedFilepath = null
  let refCache = null
  let cache = hs.getRequestCache()
  // if (cache && cache.hasOwnProperty('estimate')) {
  //   data = cache.estimate.data
  //   // cachedFilepath = cache.estimate.filepath
  // }

  if (cache && cache.hasOwnProperty('reference')) {
    refCache = cache.reference
  }

  // Make request for new data when filename changes
  // if (filepath !== cachedFilepath) {
  let data = await readCSV(filepath)
  cache = {
    'estimate': {
      'data': data,
      'filepath': filepath
    }
  }
  if (refCache) {
    cache['reference'] = refCache
  }
  hs.setRequestCache(cache)
  setFilterOptionsFunc(hs, data)
  // }
  return data
}

/**
 * Formats the path to a custom graphics data file
 * @param {Object} hs harness helper
 * @param {String} fileKeyOne first part of filename
 * @param {String} fileKeyTwo second part of filename
 */
export function formatCustomGraphicsFilepath (hs, fileKeyOne, fileKeyTwo) {
  return '/data/custom-graphics/' +
    hs.getFilter('victimizationType') + '/' +
    fileKeyOne.toLowerCase().replace(/\s+/g, '').replace(/\//g, '') +
    '_' +
    fileKeyTwo.toLowerCase().replace(/\s+/g, '').replace(/\//g, '')
}

/**
 * Reads CSV and parses each column to correct data format
 * @param {String} filepath path to data file
 */
export async function readCSV (filepath) {
  let formattedData = await d3.csv(filepath + '.csv')
    .then(function (response) {
      let data = response
      data.forEach(d => parseDataColumns(d))
      // data.sort((a, b) => a.crimeTypeNum - b.crimeTypeNum)
      data.sort((a, b) => a.catNum1 - b.catNum1)
      data.sort((a, b) => a.catNum2 - b.catNum2)
      data.sort((a, b) => a.levelNum1 - b.levelNum1)
      data.sort((a, b) => a.levelNum2 - b.levelNum2)
      return data
    })
  return formattedData
}

function parseDataColumns (d) {
  d.catNum1 = parseFloat(d.catNum1)
  d.catNum2 = parseFloat(d.catNum2)
  d.levNum1 = parseInt(d.levNum1)
  d.levNum2 = parseInt(d.levNum2)
  d.year = parseInt(d.year)
  d.count = parseFloat(d.count)
  d.countLB = parseFloat(d.countLB)
  d.countUB = parseFloat(d.countUB)
  d.countFlag = parseFloat(d.countFlag)
  d.countSE = parseFloat(d.countSE)
  d.percent = parseFloat(d.percent)
  d.percentLB = parseFloat(d.percentLB)
  d.percentUB = parseFloat(d.percentUB)
  d.percentSE = parseFloat(d.percentSE)
  d.percentFlag = parseFloat(d.percentFlag)
  d.rate = parseFloat(d.rate)
  d.rateLB = parseFloat(d.rateLB)
  d.rateUB = parseFloat(d.rateUB)
  d.rateSE = parseFloat(d.rateSE)
  d.rateFlag = parseFloat(d.rateFlag)
}

export function capitalizeFirstLetter (string) {
  return string.charAt(0).toUpperCase() + string.slice(1)
}

export function axisLabelFormatter (that, estType) {
  if (!['rate', 'percent'].includes(estType.toLowerCase())) {
    if (that.value <= 999999) {
      return that.value / 1000 + 'K'
    } else if (that.value >= 1000000) {
      return that.value / 1000000 + 'M'
    }
  } else {
    return that.value
  }
}

export function pluralsFix (crimeTypeIn) {
  let crimeLowered = crimeTypeIn.toLowerCase()
  if (crimeLowered === 'robbery') {
    return 'robbery victimizations'
  } else if (crimeLowered === 'violent crime excluding simple assault') {
    return 'violent crimes excluding simple assaults'
  } else if (crimeLowered === 'personal theft/larceny') {
    return 'personal theft/larceny victimizations'
  } else { return crimeLowered + 's' }
}

export function labelLowerCase (str) {
  str = str.toLowerCase()
  // Exceptions: the below list should be uppercase
  var i, j, uppers
  uppers = ['northeast', 'midwest', 'south', 'west', 'white', 'black', 'hispanic', 'msa']
  for (i = 0, j = uppers.length; i < j; i++) {
    str = str.replace(new RegExp(uppers[i], 'g'),
      function (txt) {
        return capitalizeFirstLetter(txt)
        // return txt.toLowerCase()
      })
  }
  // str = capitalizeFirstLetter(str)
  str = str.replace('MidWest', 'Midwest')
  str = str.replace('Msa', 'MSA')
  return str
}
