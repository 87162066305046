/**
 * Return an array of on/off options.
 * @param {String} defaultOptionLabel The default option to set.
 * @param {String} disabledOptionOnLoad (Optional) An option to disable on page load. The option will also appear as disabled when "Clear Filters" is clicked.
 */
export function onOffOptions (defaultOptionLabel, disabledOptionOnLoad = null) {
  return [
    {
      key: true,
      label: 'On',
      disabled: disabledOptionOnLoad === 'On',
      default: defaultOptionLabel === 'On'
    },
    {
      key: false,
      label: 'Off',
      disabled: disabledOptionOnLoad === 'Off',
      default: defaultOptionLabel === 'Off'
    }
  ]
}

export function demographicOptions (defaultOption = 'Rate') {
  return [
    {
      key: 'count',
      label: 'Number',
      default: defaultOption === 'Number'
    },
    {
      key: 'rate',
      label: 'Rate',
      default: defaultOption === 'Rate'
    }
  ]
}

export function incidentOptions (defaultOption = 'Percent') {
  return [
    {
      key: 'count',
      label: 'Number',
      default: defaultOption === 'Number'
    },
    {
      key: 'percent',
      label: 'Percent',
      default: defaultOption === 'Percent'
    }
  ]
}
