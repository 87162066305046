<template>
  <div
    class="container staticPg"
    id="methodology"
  >
    <div
      class="row"
      id="ugIntro"
    >
      <div
        class="container"
        id="ugtop"
      >
        <div
          class="row my-5"
          id="contRow"
        >
          <div class="col col-12  mb-lg-3">
            <h1 class="sg">
              National Crime Victimization Survey Data Dashboard <br class="printonly">(N-DASH) User’s Guide <small class="pl-lg-3"><a
                href="/assets/NCVSDashboard_UserGuide.pdf"
                class=""
                download
                style="whitespace: nowrap"
              >download <i class="fas fa-solid fa-file-pdf" /></a></small>
            </h1>
          </div>
        </div>
      </div>
    </div>

    <div class="row">
      <div class="col col-12 mb-5">
        <!--
        <h1 class="my-5">
          National Crime Victimization Survey Data Dashboard (N-DASH) User’s Guide
        </h1>
-->
        <div
          class="row"
          id="TOC"
        >
          <div class="col col-12">
            <h5> Contents:</h5>
            <div class="row">
              <ul class="col col-12 col-md-6">
                <li>
                  <a href="#abNDASH">About the N-DASH</a>
                </li>
                <li>
                  <a href="#vicdef">Definition of Victimization</a>
                </li>
                <li>
                  <a href="#unitdef">Units of Measurement and Interpreting Dashboard Results</a>
                </li>
                <li>
                  <a href="#qg">Quick Graphics</a>
                </li>
                <li>
                  <a href="#cg">Custom Graphics</a>
                </li>
                <li>
                  <a href="#downloadOptions">Download Options</a>
                </li>
              </ul>
              <ul class="col col-12 col-md-6">
                <li>
                  <a href="#interactGraphics">Interacting With N-DASH Graphics</a>
                </li>
                <li>
                  <a href="#accuracy">Accuracy of Estimates</a>
                </li>
                <li>
                  <a href="#collectRace">Collection and Reporting of Race/Hispanic Origin Data</a>
                </li>
                <li>
                  <a href="#dataComp">Data Considerations</a>
                </li>
                <li>
                  <a href="#quest">For Questions About the Tool</a>
                </li>
                <li>
                  <a href="#cite">How To Cite Data From the Tool</a>
                </li>
              </ul>
            </div>
          </div>
        </div>
        <h4
          class="my-4"
          id="abNDASH"
        >
          1. About the N-DASH
        </h4>
        <div class="mt-4 ml-4 col col-12 col-md-8 mb-5">
          <p>
            This dashboard enables you to examine information from the National Crime Victimization Survey (NCVS), the nation’s primary source of data on nonfatal crimes reported and not reported to police. The NCVS collects information on nonfatal victimizations against persons age 12 or older from a nationally representative sample of U.S. households. These sample estimates are then weighted to represent the population. <a
              href="https://bjs.ojp.gov/data-collection/ncvs"
              target="_blank"
            >Click here for more information on the NCVS.</a>
          </p>
          <p>The dashboard features data on nonfatal criminal victimization from 1993 to the most recent year that NCVS data are available, including:</p>

          <ul>
            <li>
              violent victimization (rape/sexual assault, robbery, aggravated assault, and simple assault)
            </li>
            <li>
              property victimization (burglary, trespassing, motor vehicle theft, and other theft)
            </li>
            <li>
              personal theft/larceny (attempted and completed purse-snatching and pocket-picking)
            </li>
          </ul>

          <p>
            You can analyze victimization counts, rates, and percentages by select victim and household characteristics, weapon category (presence and type of weapon), victim services use, medical treatment, victim-offender relationship, injury, location of incident, and crimes reported and not reported to police.
          </p>
          <p>
            The site includes <a
              href="/quick-graphics"
              target="/quick-graphics"
            >Quick Graphics</a> that feature a selection of preset data combinations and <a
              href="/custom-graphics"
              target="/custom-graphics"
            >Custom Graphics</a> that allow you to choose from the available data and presentation options. These views be accessed using the navigation bar at the top of the dashboard.
          </p>
        </div>

        <h4 id="vicdef">
          2. Definition of Victimization
        </h4>
        <div
          class="mt-4 ml-4 col col-12 col-md-8 mb-5"
          id="vicdefCont"
        >
          <p>Victimization is the basic unit of analysis used throughout this data visualization tool. A victimization refers to a single incident that a victim or household experienced. Crime incidents are distinguished from victimizations in that one crime may have multiple victims.</p>
          <p>For violent crimes (rape/sexual assault, robbery, aggravated assault, and simple assault) and for personal theft/larceny, the count of victimizations is the number of times individuals experienced a crime. For crimes against households (burglary, trespassing, motor vehicle theft, and other theft), each time a household is affected by a crime is counted as a single victimization.</p>
          <p>For violent victimizations and personal larceny, the victim, household, and incident characteristics included in this analysis tool are:</p>

          <ul>
            <li>
              victim age
            </li>
            <li>
              victim household income
            </li>
            <li>
              victim marital status
            </li>
            <li>
              victim MSA (metropolitan statistical area) status
            </li>
            <li>
              victim population size
            </li>
            <li>
              victim race/Hispanic origin
            </li>
            <li>
              victim region
            </li>
            <li>
              victim sex
            </li>
            <li>
              injury
            </li>
            <li>
              location of incident
            </li>
            <li>
              medical treatment for physical injuries
            </li>
            <li>
              reporting to the police
            </li>
            <li>
              victim-offender relationship
            </li>
            <li>
              victim services use
            </li>
            <li>
              weapon category (presence and type of weapon)
            </li>
          </ul>

          <p>
            For property victimizations, the household
            characteristics included in the data visualization tool are:
          </p>

          <ul>
            <li>
              victim household income
            </li>
            <li>
              victim household size
            </li>
            <li>
              victim MSA status (metropolitan statistical area)
            </li>
            <li>
              victim population size
            </li>
            <li>
              victim region
            </li>
            <li>
              location of incident
            </li>
            <li>
              reporting to the police
            </li>
            <li>
              victim services use
            </li>
          </ul>

          <p>
            In this tool, data on most important reasons for reporting and not reporting violent and property crime to police are only found in the <a
              href="/quick-graphics"
              target="/quick-graphics"
            >Quick Graphics</a> section.
          </p>
        </div>

        <h4 id="unitdef">
          3. UNITS OF MEASUREMENT AND INTERPRETING DASHBOARD RESULTS
        </h4>
        <div class="mt-4 ml-4 col col-12 col-md-8 mb-5">
          <p>The N-DASH offers three types of weighted NCVS estimates: numbers (or counts), rates, and percentages. Within a given year, estimates can be produced as a total for all victimizations or for specific subgroup populations. Each type of estimate is calculated differently and should be interpreted accordingly. An explanation of each type is below:</p>
          <ul>
            <li>
              <p>Number of victimizations</p>
              <p>
                This is an estimation of the number of (personal or property) victimizations that U.S. residents age 12 or older or households experienced in the selected year. When interpreting estimates of the number of victimizations for subgroups, you must consider that these estimates do not account for different demographic population sizes, which may be related to the incidence of victimization. For more information on estimates that standardize across populations, see “Rates of victimization” below.
              </p>
            </li>
            <li>
              <p>Rates of victimization</p>
              <p>
                Rates of victimization are calculated by multiplying the number of victimizations by 1,000 and dividing that product by the number of persons age 12 or older (in the case of property victimizations, the product is divided by the number of households). Rates provide a standard way to measure crime levels across population groups of different sizes. Populations can differ between subgroups and can vary over time for the same subgroup. Standardized rates are particularly helpful for making comparisons across demographic characteristics, like victim sex or race/Hispanic origin.
              </p>
              <p>
                For example, the 2018 rate of violent victimization was 17.5 victimizations per 1,000 persons age 12 or older in the Northeast region of the United States and 19.3 per 1,000 in the South. These rates are similar in magnitude. However, looking at the number of victimizations for these two regions, the South has more than twice the number of victimizations compared with the Northeast (2,005,830 versus 843,660).
              </p>
            </li>
            <li>
              <p>Percentages of victimization</p>
              <p>
                Percentages show the portion of victimizations that occurred for a particular characteristic (i.e., the number of victimizations for a characteristic divided by the total number of victimizations and multiplied by 100). Percentages help illustrate the distribution of victimizations for a specific characteristic, particularly when comparing across incident characteristics, such as victim-offender relationship or reporting to police.
              </p>
            </li>

            <p>
              In cases where the number is equal to 0 victimizations, the rate rounds to less than 0.05 victimizations per 1,000, or the percentage rounds to less than 0.5 percent, the estimate is noted with a '<span style="color:#ce4646">✱</span>' symbol."
            </p>
          </ul>
          <p>Note that the N-DASH does not reflect tests of statistical significance. Differences that appear statistically significant may not be. Measures of the error around the estimate, including standard errors and confidence intervals, can be turned on or off for each graphic.</p>

          <p>
            In <a
              href="/quick-graphics"
              target="/quick-graphics"
            >Quick Graphics</a>, the graphics are preset and show rates or percentages as applicable. Estimates, including numbers, can also be viewed in a table using the download options.
          </p>
          <p>
            In <a
              href="/custom-graphics"
              target="/custom-graphics"
            >Custom Graphics</a>, the results of your analysis will display as a chart with the option to view or download in table format. To prevent misinterpretation of the data, display options are limited based on the characteristics selected. If you choose to filter or view data by demographic characteristics, such as sex and race/Hispanic origin, you will only be able to toggle between the number of victimizations and the rate of victimization. Should you filter or view data using an incident characteristic, such as reporting to police, you will only be able to toggle between the number of victimizations and the percentage of victimizations.
          </p>
        </div>

        <h4 id="qg">
          4. Quick Graphics
        </h4>
        <div class="mt-4 ml-4 col col-12 col-md-8 mb-5">
          <p>
            The <a
              href="/quick-graphics"
              target="/quick-graphics"
            >Quick Graphics</a> page provides an overview of the types of data available in the N-DASH. These graphics feature long-term trends in and snapshots of violent victimization, weapon category (presence and type of weapon), property victimization, reporting to the police, and victim service use. You can also find information on violent victimization by victim-offender relationship.
          </p>
        </div>

        <h4 id="cg">
          5. Custom Graphics
        </h4>
        <div class="mt-4 ml-4 col col-12 col-md-8 mb-5">
          <p>
            The <a
              href="/custom-graphics"
              target="/custom-graphics"
            >Custom Graphics</a> page enables you to analyze data on a subset of personal and property crime victimizations. Personal crime victimizations include rape/sexual assault, robbery, aggravated assault, simple assault, and personal theft/larceny victimizations. Violent crime excluding simple assault includes rape/sexual assault, robbery, and aggravated assault. Property crime victimizations include burglary, trespassing, motor vehicle theft, and other theft.
          </p>
          <p>
            The <a
              href="/custom-graphics"
              target="/custom-graphics"
            >Custom Graphics</a> page offers various options for comparisons: Single-Year Comparison, Multi-Year Trends, or Year-to-Year Comparison.
          </p>

          <h5>5.1. Single-Year Comparison and Multi-Year Trends</h5>
          <div class="mt-2 ml-4">
            <p>a. Compare across crime types</p>
            <ul>
              <li>
                View victimization counts, rates, or percentages for different subpopulations (demographic or incident characteristics) by crime type.
              </li>
              <li>
                Under Display Settings, you may select personal or property for crime category; turn on or off estimate flags, confidence intervals, or reference lines (where applicable, learn more <a
                  href="#toggleOptions"
                >here</a>); and whether results are displayed as a count, rate, or percentage (where applicable). Where applicable, there are drop-down menus for comparison characteristics and crime type.
              </li>
              <li>
                Under Data Filter Settings, you may select one or two different characteristics to filter victimization results (e.g., Victim sex = “Male” and Reporting to the police = “Yes, reported to the police” would return only data in which the victim was male and the crime was reported to the police). Where applicable, you can also select the year of data.
                <ul>
                  <li>Demographic characteristics include victim sex, race/Hispanic origin, age, household income, household size, MSA, population size, region, and marital status.</li>
                  <li>Incident characteristics include reporting to the police, injury, location of incident, medical treatment for physical injuries, victim services use, victim-offender relationship, and weapon category (presence and type of weapon).</li>
                </ul>
              </li>
              <li>
                A chart for the overall rate, number, or percentage will be displayed, along with charts broken out by personal crime type, simple assault versus violent crime excluding simple assault, and for personal theft/larceny. When Property is selected under Crime Category, a chart for the overall estimate will be displayed, along with charts broken out by  burglary, trespassing, motor vehicle theft, other theft.
              </li>
            </ul>
            <p>
              See “<a href="#downloadOptions">Download Options</a>” and “<a href="#interactGraphics">Interacting with N-DASH Graphics</a>” for information on the tooltip, toggle options, Show Table option, and Download Table options.
            </p>
            <p>b. Compare across demographics or incident characteristics</p>
            <ul>
              <li>
                Compare victimization counts, rates, or percentages across groups (across demographic or incident characteristics).
              </li>
              <li>
                Under Display Settings, you may select personal or property for crime category; turn on or off estimate flags, confidence intervals, or reference lines (where applicable, learn more <a
                  href="#toggleOptions"
                >here</a>); and whether results are displayed as a count, rate, or percentage (where applicable). You can also compare victimizations across an incident or demographic characteristic that you select within the Comparison Characteristic drop-down menu. For example, if you choose “Victim sex,” you will be comparing victimizations between females and males and have a separate chart for each.
              </li>
              <li>
                Under Data Filter Settings, you can filter results by an incident or demographic characteristic. First select the characteristic under Filter Characteristic, then the value of the characteristic you want to filter. For example, you could select “Victim Region,” and for the value, you could select “West,” which would filter victimizations in only the Western region of the United States. You can also filter by crime type.
              </li>
            </ul>
            <p>
              See “<a href="#downloadOptions">Download Options</a>” and “<a href="#interactGraphics">Interacting with N-DASH Graphics</a>” for information on the tooltip, toggle, Show Table, and Download Table options.
            </p>
          </div>
          <h5>5.2 Year-to-Year Comparison</h5>
          <div class="mt-2 ml-4">
            <p>This tab allows for comparisons of counts, rates, or percentages between different years for a particular crime type or subpopulation.</p>
            <ul>
              <li>
                Under Display Settings, you can select personal or property for crime category; turn on or off estimate flags and confidence intervals (learn more about toggle options <a
                  href="#toggleOptions"
                >here</a>); and whether results are displayed as a count, rate, or percentage (where applicable). You can also choose the crime types for which you would like to see data. Under the drop-down menu, you can select all or only some crime types. A chart will be generated with a set of data for each crime type you select.
              </li>
              <li>
                You can also choose an incident and/or demographic characteristic to help populate the chart, via the Comparison Characteristic drop-down menu.
              </li>
            </ul>
            <p>
              See “<a href="#downloadOptions">Download Options</a>” and “<a href="#interactGraphics">Interacting with N-DASH Graphics</a>” for information on the tooltip, toggle options, Show Table option, and Download Table options.
            </p>
          </div>
        </div>

        <h4 id="downloadOptions">
          6. Download Options
        </h4>

        <div class="mt-4 ml-4 col col-12 col-md-8 mb-5">
          <p>
            For every graphic in the dashboard, you have the ability to download an image of the chart or download the data presented in the graphic in spreadsheet format (.csv file). In <a
              href="/custom-graphics"
              target="/custom-graphics"
            >Custom Graphics</a>, you can create custom charts or download custom tables or charts (for more information, see “<a
              href="#cg"
            >Custom Graphics</a>”).
          </p>

          <ul>
            <li>
              To download the data presented in the graphic, select “Download Table” on the bottom right-hand corner of the chart. You can also view the chart data in table form by clicking the “Show Table” button in the same area.
            </li>
            <li>To download a chart image, select “Download PNG/image,” also located at the bottom right-hand corner of the chart.</li>
            <li>You can also download the following sections of the site in PDF form: Tool Overview, User's Guide.</li>
          </ul>
        </div>

        <h4 id="interactGraphics">
          7. Interacting with N-DASH Graphics
        </h4>

        <div class="mt-4 ml-4 col col-12 col-md-8 mb-5">
          <p>a. Tooltip</p>
          <ul>
            <li>
              You can view information for specific data points by hovering the cursor over the trend line or point. The tooltip will display the year of the observation chosen by the cursor, data point (number, rate, or percentage, where applicable), 95% confidence interval, and standard error. For year-to-year comparison charts, the tooltip will display information for both years that are selected.
            </li>
          </ul>
          <p id="toggleOptions">
            b. Toggle options
          </p>
          <ul>
            <li>
              Each Custom Graphics page will have toggle options at the top of the page under Display Settings. The toggle options included are to show/hide confidence intervals, estimate flags, and reference lines. Flags are automatically turned on, whereas confidence interval and reference line options are automatically turned off.
            </li>
            <li>Confidence intervals will be shown as a shaded area on the chart and illustrate the reliability of an estimate.</li>
            <li>Flags are shown for data points where the estimate is based on 10 or fewer sample cases and where the coefficient of variation is 50% or greater. These estimates should be interpreted with caution.</li>
            <li id="reflines">
              Reference lines allow for comparisons across data series or points with overall estimates of victimization. Reference lines will be different for various charts depending on the characteristics selected and crime types shown. For example, a chart showing rates of violent victimizations by sex and reporting to police would include a reference line showing the rate of all violent victimizations and a reference line for each “reporting to police” option for all sexes. Reference lines will be labeled at the bottom of the chart specifying what is measured.
            </li>
            <li>Each chart will have the option to view data in terms of count, rate, and percentage. Data based on demographic characteristics, however, will only be viewed as count or rate, whereas data on incident characteristics will only be able to toggle between count and percentage.</li>
          </ul>
        </div>

        <h4 id="accuracy">
          8. Accuracy of Estimates
        </h4>

        <div class="mt-4 ml-4 col col-12 col-md-8 mb-5">
          <p>
            Estimates derived from a sample survey such as the NCVS are subject to sampling error and nonsampling error. The accuracy of an estimate is a measure of its total error (i.e., the sum of all the errors affecting the estimate). For more information, see <a
              href="https://bjs.ojp.gov/sites/g/files/xyckuh236/files/media/document/ncvstd16.pdf"
              target="_blank"
            ><i>National Crime Victimization Survey, 2016 Technical Documentation</i> (NCJ 251442, BJS, December 2017)</a>.
          </p>
          <p>
            To produce estimates for the NCVS, each year BJS selects a representative sample from the entire population. With sample surveys, the estimates derived reflect information from the sample, which is then weighted to represent the population of interest. Because of this, sample estimates are subject to sampling error. In addition, when selecting the sample, it is possible to obtain different samples that are also nationally representative even when using the same design and selection procedures. The estimates derived from any one of these samples would differ from one another due to sampling variability or sampling error. Sampling error is often quantified with the standard error as described below.
          </p>
          <p>
            In addition to sampling error, the estimates in this analysis tool are subject to nonsampling error. Sources of nonsampling error include nonresponse error, respondent recall error, poorly worded questions, interviewer and mode effects on response, and data entry error. Although substantial care is taken in the NCVS to reduce the sources of nonsampling error throughout all of the survey operations by means of a quality assurance program, quality controls, operational controls, and error-correcting procedures, an unquantified amount of nonsampling error still remains. These nonsampling errors can be difficult to measure.
          </p>

          <ul>
            <li id="se_description">
              <p>Standard error</p>
              <p>Any time national estimates are derived from a sample rather than the entire population, as is the case with the NCVS, it is important to be cautious when drawing conclusions about the size of one population estimate in comparison with another or about whether the population estimates are changing over time.</p>
              <p>Although one estimate may be larger than another, estimates based on responses from a sample of the population each have some degree of sampling error. The sampling error of an estimate depends on several factors, including the amount of variation in the responses, the size and representativeness of the sample, and the size of the subgroup for which the estimate is computed.</p>
              <p>
                One measure of the sampling error associated with an estimate is the standard error, which measures the variation between a sample estimate and the average of all possible estimates that could be obtained by taking different samples from the same population.
              </p>
              <p>
                In general, an estimate with a smaller standard error provides a more reliable approximation of the true value than an estimate with a higher standard error. Estimates with relatively large standard errors are associated with less precision and reliability and should be interpreted with caution.
              </p>
              <p>
                For complex sample designs, several methods can be used to generate standard errors around point estimates (e.g., numbers, percentages, and rates). In this tool, generalized variance function (GVF) parameters were used for variance estimation. The U.S. Census Bureau produces GVF parameters for BJS, which account for aspects of the NCVS’s complex sample design. For more information on GVFs, see <a
                  href="https://bjs.ojp.gov/sites/g/files/xyckuh236/files/media/document/ncvstd16.pdf"
                  target="_blank"
                ><i>National Crime Victimization Survey, 2016 Technical Documentation</i> (NCJ 251442, BJS, December 2017)</a>.
              </p>
              <p>
                The coefficient of variation (CV) is a measure of an estimate’s reliability. The CV is the ratio of the standard error to the estimate. In this data visualization tool, BJS calculated the CV for all estimates. In cases where the CV is greater than 50% or when the estimate is based on 10 or fewer sample cases, the estimate is noted with a "<span style="color:#ce4646">▲</span>" symbol (i.e., interpret with caution, based on 10 or fewer sample cases, or the coefficient of variation is greater than 50%).
              </p>
            </li>
            <li id="ci_description">
              <p>Confidence intervals</p>

              <p>
                Confidence intervals are another measure of the margin of error. For rates and counts, a confidence interval around the estimate can be generated by multiplying the standard errors by ±1.96 (the t-score of a normal, two-tailed distribution that excludes 2.5% at either end of the distribution). Therefore, the 95% confidence interval around an estimate is the estimate ± (the standard error X 1.96). In other words, if different samples using the same procedures were taken from the U.S. population, 95% of the time the estimate would fall within that confidence interval.

                For percentage estimates, the logit transformation is used to improve coverage and to ensure the confidence limits are on the interval [0,1]. The logit transformation confidence interval is calculated as
                <img
                  class="d-inline-block CI_eq"
                  src="../../assets/CI_eq1b.svg"
                  alt="ln(p1-p)±1.96Var(p)p(1-p)"
                >
                . The upper and lower confidence limits are then back-transformed to the original scale. For example, the back-transformed lower limit would equal <img
                  class="d-inline-block CI_eq"
                  src="../../assets/CI_eq2b.svg"
                  alt="Lp=exp⁡(Llogit)1+exp⁡(Llogit)"
                > where <span
                  style="font-family: serif; font-size: 1.2em;
"
                ><i>L<sub>logit</sub></i></span> is the lower bound of the logit transformation confidence interval.
              </p>
            </li>
          </ul>

          <p>BJS did not test whether differences in numbers, rates, or percentages in this data visualization tool are statistically significant. Please use caution and do not draw inferences when comparing estimates.</p>
        </div>

        <h4 id="collectRace">
          9. Collection and reporting of race/Hispanic origin data
        </h4>

        <div class="mt-4 ml-4 col col-12 col-md-8 mb-5">
          <p>
            In 1997 the Office of Management and Budget introduced new guidelines for the collection and reporting of race/Hispanic origin data in government surveys. BJS implemented these methodological changes for all demographic surveys on January 1, 2003. As a result, BJS now allows individuals to choose more than one racial category; in prior years, individuals were asked to select a single primary race. BJS also now asks individuals whether they are of Hispanic origin before asking about their race, and now asks people directly if they are Spanish, Hispanic, or Latino. See <a
              href="/terms"
              target="/terms"
            ><i>Terms & Definitions</i></a> for more information on race and origin in the NCVS.
          </p>
          <p>
            Because detailed NCVS data by race are not available for all years and because sample sizes for some race groups are small and subject to data reliability concerns, <a
              href="/custom-graphics"
              target="/custom-graphics"
            >Custom Graphics</a> include a limited selection of race groups as options for comparability purposes. There is a <a
              href="/quick-graphics#violentVictimizationByRaceHispanic"
              target="/quick-graphics"
            >Quick Graphic</a> that displays more detailed victimization data by race.
          </p>
        </div>

        <h4 id="dataComp">
          10. Data Considerations
        </h4>

        <div class="mt-4 ml-4 col col-12 col-md-8 mb-5">
          <h5>
            2006
          </h5>
          <p>
            NCVS data for 2006 are not comparable with NCVS data for other years. See <a
              href="https://bjs.ojp.gov/content/pub/pdf/cv07.pdf"
              target="_blank"
            ><i>Criminal Victimization, 2007</i> (NCJ 224390, BJS, December 2008)</a> for more information.
          </p>
          <h5>2018</h5>
          <p>
            In October 2018, BJS released a revised set of 2016 NCVS data. See  <a
              href="https://bjs.ojp.gov/content/pub/pdf/cv16re.pdf"
              target="_blank"
            ><i>Criminal Victimization, 2016: Revised</i> (NCJ 252121, BJS, October 2018)</a> for more information. One particular limitation of the 2016 NCVS revised data file relates to youth ages 12-17 years old. A significant portion of youth crime occurs at school and therefore, an inherent limitation of the file is that the youth in the sample had less time in school based on the cases that were included in the revised file. Please use caution when examining crime estimates for youth.
          </p>
          <h5>
            2020
          </h5>
          <p><b><i>The impact of COVID-19 on 2020 NCVS data</i></b></p>
          <p>
            Due to increasing risks related to COVID-19, BJS, in coordination with the U.S. Census Bureau, modified typical NCVS interviewing operations for portions of the year 2020. To address the impact of these modified field operations, the 2020 data were examined to determine what adjustments were needed to ensure comparability with past and future years of NCVS data. Following this examination, it was determined that the 2020 NCVS data are comparable with other data years. For more information, see <a
              href="https://bjs.ojp.gov/sites/g/files/xyckuh236/files/media/document/cv20.pdf"
              target="_blank"
            ><i>Criminal Victimization, 2020</i> (NCJ 301775, BJS, October 2021)</a> and the <a
              href="https://bjs.ojp.gov/data-collection/ncvs"
              target="_blank"
            >National Crime Victimization Survey data collection page</a>.
          </p>

          <p><b><i>Outlier weights in the NCVS</i></b></p>
          <p>
            The NCVS uses analysis weights to account for differential probabilities of selecting sample members and varying response rates. This helps ensure that estimates produced from the sample are representative of the target population. While efforts are made during the NCVS weighting process to limit the size of weighting adjustment factors, it is still possible for some households or individuals to be assigned a large weight relative to other members of the sample. When these households or persons with large weights also report a large number of victimizations, they can have an undue, or “extreme,” influence on victimization estimates and negatively affect the precision of estimates, particularly for rare crime types or small subpopulations.
            Beginning with the 2020 datafile, BJS incorporated an additional factor to moderate the impact of extreme cases on NCVS estimates. After exploring several methods for identifying and adjusting outlier weights, BJS applied a framework that identifies extreme cases, applies an adjustment, and reallocates the excess weight to nonoutlier cases. For more information, see <a
              href="https://bjs.ojp.gov/sites/g/files/xyckuh236/files/media/document/cv20.pdf"
              target="_blank"
            ><i>Criminal Victimization, 2020</i> (NCJ 301775, BJS, October 2021)</a> and the <a
              href="https://bjs.ojp.gov/data-collection/ncvs"
              target="_blank"
            >National Crime Victimization Survey data collection page</a>.
          </p>
        </div>
        <h4 id="quest">
          11. For questions about the tool
        </h4>

        <div class="mt-4 ml-4 col col-12 col-md-8 mb-5">
          <p>The dashboard is best viewed with Google Chrome or Mozilla Firefox. Though the dashboard has been optimized for viewing on computers and mobile devices, one’s device size may affect the display of graphics.</p>
          <p>
            Please direct any comments or questions about the about the NCVS dashboard to:
          </p>
          <p class="pl-4">
            <a href="mailto:askbjs@usdoj.gov?subject=N-DASH">askbjs@usdoj.gov</a><br>
          </p>
          <p>Please include “N-DASH” in the subject line of the e-mail.</p>
          <p>Inquiries can also be directed to:</p>
          <p class="pl-4">
            Bureau of Justice Statistics<br>
            U.S. Department of Justice<br>
            810 7th Street, NW<br>
            Washington, DC 20531<br>
            <br>
            (202) 307-0765
          </p>
        </div>

        <h4 id="cite">
          12. How to cite data from the tool
        </h4>

        <div
          class="mt-4 ml-4 col col-12 col-md-8 mb-5"
          id="citeCont"
        >
          <p>When publishing data from BJS’s N-DASH in any form, including graphics, the recommended citation is:</p>
          <p class="pl-4">
            Bureau of Justice Statistics. (Insert table/chart title). Generated using the NCVS Dashboard (N-DASH) at www.bjs.ojp.gov. (Insert date generated).
          </p>
          <p>Please use the following information when citing the NCVS dashboard:</p>
          <p class="pl-4">
            Bureau of Justice Statistics<br>
            Tool title: National Crime Victimization Survey Data Dashboard (N-DASH)<br>
            Data source: BJS, National Crime Victimization Survey<br>
            Date accessed (or generated): [Insert date here]
          </p>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  name: 'UserSGuide'
}

</script>
