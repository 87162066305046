<template>
  <p>
    Site last updated: {{ dateLastUpdated }}
  </p>
</template>
<script>
export default {
  name: 'DateLastUpdated',
  computed: {
    dateLastUpdated () {
      return process.env.VUE_APP_DATE_LAST_UPDATED || new Date().toLocaleDateString()
    }
  }
}
</script>
