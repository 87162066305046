const crimeTypes = {
  'person': [
    {
      key: 'Violent victimization',
      label: 'Violent victimizations',
      default: true
    },
    {
      key: 'Rape/sexual assault',
      label: 'Rape/sexual assault'
    },
    {
      key: 'Robbery',
      label: 'Robbery'
    },
    {
      key: 'Aggravated assault',
      label: 'Aggravated assault'
    },
    {
      key: 'Simple assault',
      label: 'Simple assault'
    },
    {
      key: 'Violent crime excluding simple assault',
      label: 'Violent crime excluding simple assault'
    },
    {
      key: 'Personal theft/Larceny',
      label: 'Personal theft/larceny'
    }
  ],
  'property': [
    {
      key: 'Property victimization',
      label: 'Property victimizations',
      default: true
    },
    {
      key: 'Burglary',
      label: 'Burglary'
    },
    {
      key: 'Trespassing',
      label: 'Trespassing'
    },
    {
      key: 'Motor vehicle theft',
      label: 'Motor vehicle theft'
    },
    {
      key: 'Other theft',
      label: 'Other theft'
    }
  ]
}

export default crimeTypes
