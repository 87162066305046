import quickGraphics from '@/components/pages/quickGraphics'
import quickGraphicsLoadData from './quickGraphicsLoadData'
// import barchart from '@/components/charts/highcharts/barchart'
import lollipop from '@/components/charts/highcharts/lollipop'
import areaChart from '@/components/charts/highcharts/area'
import { areaChartTableAdapter, lollipopTableAdapter, tableClasses } from '@/highchartsFormatters'
// import exampleChart from '@/components/charts/exampleChart'
import { components } from '@rtidatascience/harness-ui'
// import { components } from '../../../dev-packages/harness-ui/src/harness-ui'
import ChartWithTableWrapper from '@/components/chartWithTableWrapper'
import { onOffOptions } from '../filters/toggle'

export default class QuickGraphics {
  title = 'Quick Graphics'
  key = 'quickGraphics'
  pageComponent = quickGraphics
  pageProps = {}
  retrieveData = quickGraphicsLoadData
  filters = function () {
    return {
      confidenceInterval: {
        label: 'Confidence Intervals',
        component: components['HarnessUiRadioGroup'],
        props: {
          inline: true
        },
        options: onOffOptions('On')
      },
      // We define this filter but don't show it on the page
      unitFlag: {
        label: 'Estimate Flags',
        component: components['HarnessUiRadioGroup'],
        props: {
          inline: true
        },
        options: onOffOptions('On')
      }
    }
  }
  // these appear to be overruled by titles in quickGraphics.vue
  smallMultTitles = {
    violent: 'Rape/Sexual Assault, Robbery, Aggravated Assault, and Simple Assault',
    violentWeapon: 'Violent Victimizations By Weapon Category',
    violentRaceHispanicOrigin: 'Violent Victimizations by Race/Hispanic origin',
    property: 'Burglary, Trespassing, Motor Vehicle Theft, and Other Theft',
    violentPerson: 'Rape/Sexual Assault, Robbery, Aggravated Assault, and Simple Assault with Victim Services Use',
    propertyPerson: 'Burglary, Trespassing, Motor Vehicle Theft, and Other Theft with Victim Services Use',
    personViolentReportPolice: 'Violent Victimizations by Reporting to the Police',
    householdPropertyReportPolice: 'Property Victimizations by Reporting to the Police'
  }

  charts = function () {
    return {
      /* Violent Victimization */
      ViolentVictimization1994To2015: {
        component: ChartWithTableWrapper,
        props: {
          chartComponent: areaChart,
          card: false,
          buttonPosition: 'bottom',
          tableClass: tableClasses,
          title: 'Rate of violent victimizations',
          tableAdapter: areaChartTableAdapter,
          victimizationType: 'person'
        }
      },
      // begin small multiple
      RapeAndSexualAssault1994To2015: {
        component: areaChart,
        props: {
          smallMultiplesTitle: this.smallMultTitles['violent'],
          tableAdapter: areaChartTableAdapter,
          title: 'Rape/sexual Assault',
          tableClass: tableClasses,
          exportingEnabled: false,
          hideYaxis: false,
          victimizationType: 'person'
        }
      },
      Robbery1994To2015: {
        component: areaChart,
        props: {
          smallMultiplesTitle: this.smallMultTitles['violent'],
          tableAdapter: areaChartTableAdapter,
          title: 'Robbery',
          tableClass: tableClasses,
          exportingEnabled: false,
          hideYaxis: true,
          victimizationType: 'person'
        }
      },
      AggravatedAssault1994To2015: {
        component: areaChart,
        props: {
          smallMultiplesTitle: this.smallMultTitles['violent'],
          tableAdapter: areaChartTableAdapter,
          title: 'Aggravated assault',
          tableClass: tableClasses,
          exportingEnabled: false,
          hideYaxis: true,
          victimizationType: 'person'
        }
      },
      SimpleAssault1994To2015: {
        component: areaChart,
        props: {
          smallMultiplesTitle: this.smallMultTitles['violent'],
          tableAdapter: areaChartTableAdapter,
          title: 'Simple Assault',
          tableClass: tableClasses,
          exportingEnabled: false,
          hideYaxis: true,
          victimizationType: 'person'
        }
      },
      // end small multiple
      // begin weapon type small multiple
      ViolentWeaponNoWeapon1994To2015: {
        component: areaChart,
        props: {
          smallMultiplesTitle: this.smallMultTitles['violentWeapon'],
          tableAdapter: areaChartTableAdapter,
          title: 'No weapon',
          tableClass: tableClasses,
          exportingEnabled: false,
          victimizationType: 'person'
        }
      },
      ViolentWeaponFirearm1994To2015: {
        component: areaChart,
        props: {
          smallMultiplesTitle: this.smallMultTitles['violentWeapon'],
          tableAdapter: areaChartTableAdapter,
          title: 'Firearm',
          tableClass: tableClasses,
          exportingEnabled: false,
          hideYaxis: true,
          victimizationType: 'person'
        }
      },
      ViolentWeaponKnife1994To2015: {
        component: areaChart,
        props: {
          smallMultiplesTitle: this.smallMultTitles['violentWeapon'],
          tableAdapter: areaChartTableAdapter,
          title: 'Knife',
          tableClass: tableClasses,
          exportingEnabled: false,
          hideYaxis: true
        }
      },
      ViolentWeaponOtherWeaponType1994To2015: {
        component: areaChart,
        props: {
          smallMultiplesTitle: this.smallMultTitles['violentWeapon'],
          tableAdapter: areaChartTableAdapter,
          title: 'Other weapon type',
          tableClass: tableClasses,
          exportingEnabled: false,
          hideYaxis: false,
          victimizationType: 'person'
        }
      },
      ViolentWeaponUnknownWeaponType1994To2015: {
        component: areaChart,
        props: {
          smallMultiplesTitle: this.smallMultTitles['violentWeapon'],
          tableAdapter: areaChartTableAdapter,
          title: 'Unknown weapon type',
          tableClass: tableClasses,
          exportingEnabled: false,
          hideYaxis: true,
          victimizationType: 'person'
        }
      },
      ViolentWeaponUnknownIfOffenderHadWeapon1994To2015: {
        component: areaChart,
        props: {
          smallMultiplesTitle: this.smallMultTitles['violentWeapon'],
          tableAdapter: areaChartTableAdapter,
          title: 'Unknown if offender had weapon',
          tableClass: tableClasses,
          exportingEnabled: false,
          hideYaxis: true,
          victimizationType: 'person'
        }
      },
      // end small multiple
      // begin Race/Hispanic origin small multiple
      ViolentRaceHispanicOriginWhite2003To2015: {
        component: areaChart,
        props: {
          smallMultiplesTitle: this.smallMultTitles['violentRaceHispanicOrigin'],
          tableAdapter: areaChartTableAdapter,
          title: 'White',
          tableClass: tableClasses,
          exportingEnabled: false,
          victimizationType: 'person'
        }
      },
      ViolentRaceHispanicOriginBlack2003To2015: {
        component: areaChart,
        props: {
          smallMultiplesTitle: this.smallMultTitles['violentRaceHispanicOrigin'],
          tableAdapter: areaChartTableAdapter,
          title: 'Black',
          tableClass: tableClasses,
          exportingEnabled: false,
          hideYaxis: true,
          victimizationType: 'person'
        }
      },
      ViolentRaceHispanicOriginHispanic2003To2015: {
        component: areaChart,
        props: {
          smallMultiplesTitle: this.smallMultTitles['violentRaceHispanicOrigin'],
          tableAdapter: areaChartTableAdapter,
          title: 'Hispanic',
          tableClass: tableClasses,
          exportingEnabled: false,
          hideYaxis: true,
          victimizationType: 'person'
        }
      },
      ViolentRaceHispanicOriginAsian2003To2015: {
        component: areaChart,
        props: {
          smallMultiplesTitle: this.smallMultTitles['violentRaceHispanicOrigin'],
          tableAdapter: areaChartTableAdapter,
          title: 'Asian/Native Hawaiian or Other Pacific Islander',
          tableClass: tableClasses,
          exportingEnabled: false,
          hideYaxis: false,
          victimizationType: 'person',
          spacingTop: 133,
          height: 340
        }
      },
      ViolentRaceHispanicOriginOther2003To2015: {
        component: areaChart,
        props: {
          smallMultiplesTitle: this.smallMultTitles['violentRaceHispanicOrigin'],
          tableAdapter: areaChartTableAdapter,
          // title: 'Native Hawaiian, Other Pacific Islander, American Indian, Alaska Native, persons of two or more races',
          tableClass: tableClasses,
          exportingEnabled: false,
          hideYaxis: true,
          victimizationType: 'person',
          spacingTop: 133,
          height: 340
        }
      },
      // end small multiple
      /* Property Victimization */
      HouseholdPropertyVictimization1994to2015: {
        component: ChartWithTableWrapper,
        props: {
          chartComponent: areaChart,
          tableAdapter: areaChartTableAdapter,
          card: false,
          title: 'Rate of property victimizations',
          tableClass: tableClasses,
          buttonPosition: 'bottom',
          victimizationType: 'household'
        }
      },
      // begin small multiple
      HouseholdBurglaryVictimization1994to2015: {
        component: areaChart,
        props: {
          smallMultiplesTitle: this.smallMultTitles['property'],
          tableAdapter: areaChartTableAdapter,
          title: 'Burglary',
          tableClass: tableClasses,
          exportingEnabled: false,
          victimizationType: 'household'
        }
      },
      HouseholdTrespassingVictimization1994to2015: {
        component: areaChart,
        props: {
          smallMultiplesTitle: this.smallMultTitles['property'],
          tableAdapter: areaChartTableAdapter,
          title: 'Trespassing',
          tableClass: tableClasses,
          exportingEnabled: false,
          hideYaxis: true,
          victimizationType: 'household'
        }
      },
      HouseholdMotorVehicleTheftVictimization1994to2015: {
        component: areaChart,
        props: {
          smallMultiplesTitle: this.smallMultTitles['property'],
          tableAdapter: areaChartTableAdapter,
          title: 'Motor Vehicle Theft',
          tableClass: tableClasses,
          exportingEnabled: false,
          hideYaxis: true,
          victimizationType: 'household'
        }
      },
      HouseholdTheftVictimization1994to2015: {
        component: areaChart,
        props: {
          smallMultiplesTitle: this.smallMultTitles['property'],
          tableAdapter: areaChartTableAdapter,
          title: 'Other Theft',
          tableClass: tableClasses,
          exportingEnabled: false,
          hideYaxis: true,
          victimizationType: 'household'
        }
      },
      /* Person Reporting to the Police */
      /* Start small multiple */
      PersonViolentReportPoliceYes1994to2015: {
        component: areaChart,
        props: {
          smallMultiplesTitle: this.smallMultTitles['personViolentReportPolice'],
          tableAdapter: areaChartTableAdapter,
          title: 'Yes',
          tableClass: tableClasses,
          exportingEnabled: false,
          victimizationType: 'person'
        }
      },
      PersonViolentReportPoliceNo1994to2015: {
        component: areaChart,
        props: {
          smallMultiplesTitle: this.smallMultTitles['personViolentReportPolice'],
          tableAdapter: areaChartTableAdapter,
          title: 'No',
          tableClass: tableClasses,
          exportingEnabled: false,
          hideYaxis: true,
          victimizationType: 'person'
        }
      },
      PersonViolentReportPoliceDontKnow1994to2015: {
        component: areaChart,
        props: {
          smallMultiplesTitle: this.smallMultTitles['personViolentReportPolice'],
          tableAdapter: areaChartTableAdapter,
          title: 'Don\'t know',
          tableClass: tableClasses,
          exportingEnabled: false,
          hideYaxis: true,
          victimizationType: 'person'
        }
      },
      /* end small multiple */
      /* new reasons for reporting or not charts */
      ViolentVictimizationByReasonforReportingToPolice2018: {
        component: ChartWithTableWrapper,
        props: {
          chartComponent: lollipop,
          card: false,
          title: 'Percent of violent victimizations reported to police, by most important reason for reporting',
          buttonPosition: 'bottom',
          tableClass: tableClasses,
          hideYaxis: true,
          tableAdapter: lollipopTableAdapter,
          victimizationType: 'person'
        }
      },
      ViolentCrimeExcludingSimpleAssaultByReasonforReportingToPolice2018: {
        component: ChartWithTableWrapper,
        props: {
          chartComponent: lollipop,
          card: false,
          title: 'Percent of violent crime excluding simple assault victimizations reported to police, by most important reason for reporting',
          buttonPosition: 'bottom',
          tableClass: tableClasses,
          hideYaxis: true,
          tableAdapter: lollipopTableAdapter,
          victimizationType: 'person'
        }
      },
      SimpleAssaultByReasonforReportingToPolice2018: {
        component: ChartWithTableWrapper,
        props: {
          chartComponent: lollipop,
          card: false,
          title: 'Percent of simple assault victimizations reported to police, by most important reason for reporting',
          buttonPosition: 'bottom',
          tableClass: tableClasses,
          hideYaxis: true,
          tableAdapter: lollipopTableAdapter,
          victimizationType: 'person'
        }
      },
      ViolentVictimizationByReasonforNotReportingToPolice2018: {
        component: ChartWithTableWrapper,
        props: {
          chartComponent: lollipop,
          card: false,
          title: 'Percent of violent victimizations not reported to police, by most important reason for not reporting',
          buttonPosition: 'bottom',
          tableClass: tableClasses,
          tableAdapter: lollipopTableAdapter,
          victimizationType: 'person'
        }
      },
      ViolentCrimeExcludingSimpleAssaultByReasonforNotReportingToPolice2018: {
        component: ChartWithTableWrapper,
        props: {
          chartComponent: lollipop,
          card: false,
          title: 'Percent of violent crime excluding simple assault victimizations not reported to police, by most important reason for not reporting',
          buttonPosition: 'bottom',
          tableClass: tableClasses,
          tableAdapter: lollipopTableAdapter,
          victimizationType: 'person'
        }
      },
      SimpleAssaultByReasonforNotReportingToPolice2018: {
        component: ChartWithTableWrapper,
        props: {
          chartComponent: lollipop,
          card: false,
          title: 'Percent of simple assault victimizations not reported to police, by most important reason for not reporting',
          buttonPosition: 'bottom',
          tableClass: tableClasses,
          tableAdapter: lollipopTableAdapter,
          victimizationType: 'person'
        }
      },
      PropertyByReasonforReportingToPolice2018: {
        component: ChartWithTableWrapper,
        props: {
          chartComponent: lollipop,
          card: false,
          title: 'Percent of property victimizations reported to police, by most important reason for reporting',
          buttonPosition: 'bottom',
          tableClass: tableClasses,
          tableAdapter: lollipopTableAdapter,
          victimizationType: 'property'
        }
      },
      PropertyByReasonforNotReportingToPolice2018: {
        component: ChartWithTableWrapper,
        props: {
          chartComponent: lollipop,
          card: false,
          title: 'Percent of property victimizations not reported to police, by most important reason for not reporting',
          buttonPosition: 'bottom',
          tableClass: tableClasses,
          tableAdapter: lollipopTableAdapter,
          victimizationType: 'property'
        }
      },

      /* Household - Reporting to the Police */
      // start small multiple
      HouseholdPropertyReportPoliceYes1994to2015: {
        component: areaChart,
        props: {
          smallMultiplesTitle: this.smallMultTitles['householdPropertyReportPolice'],
          tableAdapter: areaChartTableAdapter,
          title: 'Yes',
          tableClass: tableClasses,
          exportingEnabled: false,
          victimizationType: 'household'
        }
      },
      HouseholdPropertyReportPoliceNo1994to2015: {
        component: areaChart,
        props: {
          smallMultiplesTitle: this.smallMultTitles['householdPropertyReportPolice'],
          tableAdapter: areaChartTableAdapter,
          title: 'No',
          tableClass: tableClasses,
          exportingEnabled: false,
          hideYaxis: true,
          victimizationType: 'household'
        }
      },
      HouseholdPropertyReportPoliceDontKnow1994to2015: {
        component: areaChart,
        props: {
          smallMultiplesTitle: this.smallMultTitles['householdPropertyReportPolice'],
          tableAdapter: areaChartTableAdapter,
          title: 'Don\'t know',
          tableClass: tableClasses,
          exportingEnabled: false,
          hideYaxis: true,
          victimizationType: 'household'
        }
      },
      // end small multiple
      /* Victim Service Use */
      // begin small multiple
      PersonVictimServicesRapeAndSexualAssault1994To2015: {
        component: areaChart,
        props: {
          smallMultiplesTitle: this.smallMultTitles['violentPerson'],
          tableAdapter: areaChartTableAdapter,
          title: 'Rape/sexual assault',
          tableClass: tableClasses,
          exportingEnabled: false,
          victimizationType: 'person'
        }
      },
      PersonVictimServicesRobbery1994To2015: {
        component: areaChart,
        props: {
          smallMultiplesTitle: this.smallMultTitles['violentPerson'],
          tableAdapter: areaChartTableAdapter,
          title: 'Robbery',
          tableClass: tableClasses,
          exportingEnabled: false,
          hideYaxis: true,
          victimizationType: 'person'
        }
      },
      PersonVictimServicesAggravatedAssault1994To2015: {
        component: areaChart,
        props: {
          smallMultiplesTitle: this.smallMultTitles['violentPerson'],
          tableAdapter: areaChartTableAdapter,
          title: 'Aggravated assault',
          tableClass: tableClasses,
          exportingEnabled: false,
          hideYaxis: true,
          victimizationType: 'person'
        }
      },
      PersonVictimServicesSimpleAssault1994To2015: {
        component: areaChart,
        props: {
          smallMultiplesTitle: this.smallMultTitles['violentPerson'],
          tableAdapter: areaChartTableAdapter,
          title: 'Simple assault',
          tableClass: tableClasses,
          exportingEnabled: false,
          hideYaxis: true,
          victimizationType: 'person'
        }
      },
      // end small multiple
      /* Victim-Offender Relationship */
      ViolentVictimizationByVictimOffenderRelationship2018: {
        component: ChartWithTableWrapper,
        props: {
          chartComponent: lollipop,
          card: false,
          title: 'Percent of violent victimizations by victim-offender relationship',
          buttonPosition: 'bottom',
          tableClass: tableClasses,
          tableAdapter: lollipopTableAdapter,
          victimizationType: 'person'
        }
      }
    }
  }
}
