<template>
  <div
    class="container-fluid"
    id="quickgraphics"
  >
    <div
      class="row shadedrow"
      id="quickGraphicsIntro"
    >
      <div
        class="container"
        id="quickgraphicstop"
      >
        <div class="row mt-5 mb-3 d-flex ">
          <div class="col col-12 mb-lg-3">
            <h1 class="sg">
              Quick Graphics
            </h1>
          </div>

          <div class="col col-12 col-lg pt-4 pb-1">
            <div
              class="row d-flex justify-content-between"
              id="tableOfContents"
            >
              <small class="col col-12 nb pb-md-2">
                <!--                <h5 class="w-auto d-inline-block">Contents</h5>-->
                <i class="">(click to show section)</i>
              </small>
              <ul
                class="nav nav-tab"
                role="tablist"
              >
                <li
                  class="col col-12 col-lg d-lg-flex"
                >
                  <a
                    id="violentVictimizationTab"
                    data-toggle="tab"
                    href="#violentVictimizationHolder"
                    type="button"
                    aria-controls="violentVictimizationHolder"
                    aria-selected="true"
                    class="toc-link mt-auto w-100 active"
                    @click="selectTab('violentVictimizationHolder')"
                  >
                    Violent Victimization
                  </a>
                </li>
                <li
                  class="col col-12 col-lg d-lg-flex"
                >
                  <a
                    id="propertyVictimizationTab"
                    data-toggle="tab"
                    href="#propertyVictimizationHolder"
                    type="button"
                    role="tab"
                    aria-controls="propertyVictimizationHolder"
                    class="toc-link mt-auto w-100"
                    @click="selectTab('propertyVictimizationHolder')"
                  >
                    Property Victimization
                  </a>
                </li>
                <li
                  class="col col-12 col-lg d-lg-flex"
                >
                  <a
                    id="reportingPoliceTab"
                    data-toggle="tab"
                    href="#reportingPoliceHolder"
                    type="button"
                    role="tab"
                    aria-controls="reportingPoliceHolder"
                    class="toc-link mt-auto w-100"
                    @click="selectTab('reportingPoliceHolder')"
                  >
                    Reporting to Police
                  </a>
                </li>
                <li
                  class="col col-12 col-lg d-lg-flex"
                >
                  <a
                    id="victimOffenderTab"
                    data-toggle="tab"
                    href="#victimOffenderHolder"
                    type="button"
                    role="tab"
                    aria-controls="victimOffenderHolder"
                    class="toc-link mt-auto w-100"
                    @click="selectTab('victimOffenderHolder')"
                  >
                    Victim-Offender Relationship
                  </a>
                </li>
                <li
                  class="col col-12 col-lg d-lg-flex"
                >
                  <a
                    id="weaponUseTab"
                    data-toggle="tab"
                    href="#weaponUseHolder"
                    type="button"
                    role="tab"
                    aria-controls="weaponUseHolder"
                    class="toc-link mt-auto w-100"
                    @click="selectTab('weaponUseHolder')"
                  >
                    Weapon Use
                  </a>
                </li>
                <li
                  class="col col-12 col-lg d-lg-flex"
                >
                  <a
                    id="victimServiceUseTab"
                    data-toggle="tab"
                    href="#victimServiceUseHolder"
                    type="button"
                    role="tab"
                    aria-controls="victimServiceUseHolder"
                    class="toc-link mt-auto w-100"
                    @click="selectTab('victimServiceUseHolder')"
                  >
                    Victim Services Use
                  </a>
                </li>
              </ul>
            </div>
          </div>
          <div class="d-none d-lg-block col col-1">
          <!--    placeholder for alignment-->
          </div>
          <!-- <div class="col col-12 col-md filterbank pl-md-5">
            <h5 class="pl-0">
              Display Settings
            </h5>
            <div>
              <filterGrid
                :only="['confidenceInterval']"
                :label-position="'horizontal'"
                :clear-button="false"
              />
            </div>
          </div> -->
        </div>
        <small class="d-block pb-3 statsignote pt-2">BJS did not test whether estimate differences in the N-DASH are statistically significant; therefore, please do not draw inferences when comparing. <a
          href="/terms#ci_description"
          target="/terms"
        >Confidence intervals</a> and <a
          href="/terms#se_description"
          target="/terms"
        >standard errors</a> are two measures of the accuracy of an estimate. For more information, see <a
          href="/methodology#methodology"
          target="/methodology"
        >User’s Guide</a>. The dashboard is best viewed with Google Chrome or Mozilla Firefox. Though the dashboard has been optimized for viewing on computers and mobile devices, one’s device size may affect the display of graphics.
        </small>
      </div>
    </div>

    <div class="row mt-5">
      <div class="container mt-lg-4">
        <div v-if="this.getRequestCache()">
          <div
            class="row tab-content"
          >
            <!-- Violent Victimization -->
            <div
              v-if="selectedTab === 'violentVictimizationHolder'"
              class="col col-12 section show"
              id="violentVictimizationHolder"
              role="tabpanel"
              aria-labelledby="violentVictimizationTab"
            >
              <sectionHeader
                :title="'Violent victimization'"
                :header-id="'violentVictimizationHeader'"
                :page-name="'quickGraphics'"
              />
              <chartHolder
                :chart-ids="['ViolentVictimization1994To2015']"
                :force-table="isMobileView"
              />
              <!--                ok-->
              <smallMultiplesHolder
                :title="'Rate of rape/sexual assault, robbery, aggravated assault, and simple assault victimizations'"
                :chart-ids="['RapeAndSexualAssault1994To2015', 'Robbery1994To2015', 'AggravatedAssault1994To2015', 'SimpleAssault1994To2015']"
                :user-instruction="'An individual larger chart view of any of these smaller comparison charts can be generated in Custom Graphics via the multi-year-trends/characteristic view, by selecting ‘Personal’ under ‘Display Settings: CRIME CATEGORY,’ and the crime type of interest under ‘Data Filter Settings: CRIME TYPE.’'"
              />
              <smallMultiplesHolder
                :title="'Rate of violent victimizations by race/Hispanic origin'"
                id="violentVictimizationByRaceHispanic"
                :chart-ids="['ViolentRaceHispanicOriginWhite2003To2015', 'ViolentRaceHispanicOriginBlack2003To2015', 'ViolentRaceHispanicOriginHispanic2003To2015',
                             'ViolentRaceHispanicOriginAsian2003To2015', 'ViolentRaceHispanicOriginOther2003To2015']"
                :columns="3"
                :user-instruction="`<p>This Quick Graphic displays more detailed race/Hispanic origin data than the Custom Graphics in this tool, in which the ‘Asian/Native Hawaiian or Other Pacific Islander’ and ‘American Indian or Alaska Native and persons of two or more races’ categories seen here are merged into an “Other” category to enhance data reliability. Race categories exclude persons of Hispanic/Latino origin. See <a href='/terms#race_origin' target='/terms'>Terms & Definitions</a> for more information. For more information on race/Hispanic origin and changes to categories over time, see <a href='/methodology#collectRace' target='/methodology'>“Collection and Reporting of Race/Hispanic origin Data”</a> section in the User’s Guide.</p>`"
              />
            </div>

            <!-- Property Victimization -->
            <div
              v-if="selectedTab === 'propertyVictimizationHolder'"
              class="col col-12 section show"
              id="propertyVictimizationHolder"
              role="tabpanel"
              aria-labelledby="propertyVictimizationTab"
            >
              <sectionHeader
                :title="'Property victimization'"
                :header-id="'propertyVictimizationHeader'"
                :page-name="'quickGraphics'"
              />
              <chartHolder
                :chart-ids="['HouseholdPropertyVictimization1994to2015']"
                :force-table="isMobileView"
              />
              <!--                ok-->
              <smallMultiplesHolder
                :title="'Rate of burglary, trespassing, motor vehicle theft, and other theft victimizations'"
                :chart-ids="['HouseholdBurglaryVictimization1994to2015', 'HouseholdTrespassingVictimization1994to2015', 'HouseholdMotorVehicleTheftVictimization1994to2015', 'HouseholdTheftVictimization1994to2015']"
                :columns="4"
                :user-instruction="'An individual larger chart view of any of these smaller comparison charts can be generated in Custom Graphics via the multi-year-trends/characteristic view, by selecting ‘Property’ under ‘Display Settings: CRIME CATEGORY,’ and under ‘Data Filter Settings:’ selecting the crime type of interest for ‘CRIME TYPE.’'"
              />
            </div>

            <!-- Reporting to Police -->
            <div
              v-if="selectedTab === 'reportingPoliceHolder'"
              class="col col-12 section show"
              id="reportingPoliceHolder"
              role="tabpanel"
              aria-labelledby="reportingPoliceTab"
            >
              <sectionHeader
                :title="'Reporting to police'"
                :header-id="'reportingPoliceHeader'"
                :page-name="'quickGraphics'"
              />
              <smallMultiplesHolder
                :title="'Percent of violent victimizations by reporting to the police'"
                :chart-ids="['PersonViolentReportPoliceYes1994to2015', 'PersonViolentReportPoliceNo1994to2015', 'PersonViolentReportPoliceDontKnow1994to2015']"
                :columns="3"
              />

              <chartHolder
                :chart-ids="['ViolentVictimizationByReasonforReportingToPolice2018']"
                :force-table="isMobileView"
              />

              <chartHolder
                :chart-ids="['ViolentCrimeExcludingSimpleAssaultByReasonforReportingToPolice2018']"
                :force-table="isMobileView"
              />

              <chartHolder
                :chart-ids="['SimpleAssaultByReasonforReportingToPolice2018']"
                :force-table="isMobileView"
              />

              <chartHolder
                :chart-ids="['ViolentVictimizationByReasonforNotReportingToPolice2018']"
                :force-table="isMobileView"
              />

              <chartHolder
                :chart-ids="['ViolentCrimeExcludingSimpleAssaultByReasonforNotReportingToPolice2018']"
                :force-table="isMobileView"
              />

              <chartHolder
                :chart-ids="['SimpleAssaultByReasonforNotReportingToPolice2018']"
                :force-table="isMobileView"
              />

              <smallMultiplesHolder
                :title="'Percent of property victimizations by reporting to the police'"
                :chart-ids="['HouseholdPropertyReportPoliceYes1994to2015', 'HouseholdPropertyReportPoliceNo1994to2015', 'HouseholdPropertyReportPoliceDontKnow1994to2015']"
                :columns="3"
              />

              <chartHolder
                :chart-ids="['PropertyByReasonforReportingToPolice2018']"
                :force-table="isMobileView"
              />

              <chartHolder
                :chart-ids="['PropertyByReasonforNotReportingToPolice2018']"
                :force-table="isMobileView"
              />
            </div>

            <!-- Victim-Offender Relationship -->
            <div
              v-if="selectedTab === 'victimOffenderHolder'"
              class="col col-12 section show"
              id="victimOffenderHolder"
              role="tabpanel"
              aria-labelledby="victimOffenderTab"
            >
              <sectionHeader
                :title="'Victim-offender relationship'"
                :header-id="'victimOffenderHeader'"
                :page-name="'quickGraphics'"
              />
              <chartHolder
                :chart-ids="['ViolentVictimizationByVictimOffenderRelationship2018']"
                :force-table="isMobileView"
              />
            </div>
            <!-- Weapon Use (new) -->
            <div
              v-if="selectedTab === 'weaponUseHolder'"
              class="col col-12 section show"
              id="weaponUseHolder"
              role="tabpanel"
              aria-labelledby="weaponUseTab"
            >
              <sectionHeader
                :title="'Weapon Use'"
                :header-id="'weaponUseHeader'"
                :page-name="'quickGraphics'"
              />
              <smallMultiplesHolder
                :title="'Percent of violent victimizations by weapon category'"
                :chart-ids="['ViolentWeaponUnknownIfOffenderHadWeapon1994To2015', 'ViolentWeaponFirearm1994To2015', 'ViolentWeaponKnife1994To2015',
                             'ViolentWeaponNoWeapon1994To2015', 'ViolentWeaponOtherWeaponType1994To2015', 'ViolentWeaponUnknownWeaponType1994To2015']"
                :columns="3"
              />
            </div>
            <!-- Victim Services Use -->
            <div
              v-if="selectedTab === 'victimServiceUseHolder'"
              class="col col-12 section show"
              id="victimServiceUseHolder"
              role="tabpanel"
              aria-labelledby="victimServiceUseTab"
            >
              <sectionHeader
                :title="'Victim services use'"
                :header-id="'victimServiceUseHeader'"
                :page-name="'quickGraphics'"
              />
              <smallMultiplesHolder
                :title="'Percent of rape/sexual assault, robbery, aggravated assault, and simple assault victimizations with victim services use'"
                :chart-ids="['PersonVictimServicesRapeAndSexualAssault1994To2015', 'PersonVictimServicesRobbery1994To2015', 'PersonVictimServicesAggravatedAssault1994To2015', 'PersonVictimServicesSimpleAssault1994To2015']"
              />
            </div>
          </div>
        </div>
      </div>
    </div>

    <div class="row bt">
      <div class="container">
        <div class="row mt-5 mb-3">
          <div class="col col-12 col-md-8">
            <p class="text-right">
              To investigate in more detail, see:
            </p>
          </div>
          <div class="col col-12 col-md-4">
            <div
              id="cg2"
              class="row mb-4"
            >
              <div class="col nav-item">
                <div class="col p-3 d-flex align-items-stretch">
                  <div class="col col-12 pgbutton">
                    <router-link
                      class="nav-link navbar-link"
                      :to="{name: 'customGraphics', hash: '#customgraphicstop'}"
                    >
                      <h3 class="mt-5">
                        Custom Graphics
                      </h3>
                    </router-link>
                  </div>
                </div>
              </div>
            </div>
          </div>
          <StatNote />
        </div>
      </div>
    <!--        end container-->
    </div>
    <loadModal />
  </div>
  <!--end of main container-->
</template>

<script>
import sectionHeader from '../sectionHeader'
import chartHolder from '../chartHolder'
import smallMultiplesHolder from '../smallMultiplesHolder'
import loadModal from '../loadModal'
import StatNote from '../statNote'
import $ from 'jquery'
import { mapGetters } from 'vuex'
export default {
  name: 'QuickGraphics',
  components: {
    sectionHeader,
    chartHolder,
    smallMultiplesHolder,
    loadModal,
    StatNote
  },
  data () {
    return {
      selectedTab: 'violentVictimizationHolder'
    }
  },
  computed: {
    ...mapGetters([
      'isMobileView'
    ])
  },
  methods: {
    navigate (routeHash) {
      if (this.$route.hash === routeHash) {
        var headerElem = $(routeHash)
        $('html,body').animate({ scrollTop: headerElem.offset().top }, 'slow')
      }
    },
    selectTab (tab) {
      this.selectedTab = tab
    }
  },
  beforeRouteEnter (to, from, next) {
    next(vm => {
      if (!vm.getRequestCache()) {
        vm.loadData()
      }
    })
  }
}
</script>
