<template>
  <div
    class="container-fluid"
    id="homepg"
  />
</template>
<script>
export default {
  name: 'Home'
}
</script>
