import yearToYearCharacteristic from '@/components/pages/year-to-year-comparison/characteristic'
import characteristicLoadData from './characteristicLoadData'
import categoryFactory, { categories, demographicFilters } from '../filters/categories'
import crimeTypes from '../filters/crimeTypes'
import { onOffOptions, demographicOptions, incidentOptions } from '../filters/toggle'
// import years from '../filters/years'
import { dumbbellTableAdapter, tableClasses } from '@/highchartsFormatters'
import { components } from '@rtidatascience/harness-ui'
import dumbbell from '@/components/charts/highcharts/dumbbell'
import ChartWithTableWrapper from '@/components/chartWithTableWrapper'

export default class yearToYearComparisonCharacteristic {
  title = 'Year-To-Year Comparison'
  key = 'Year-to-Year Comparison Characteristic'
  subtype = 'custom-graphics'
  pageComponent = yearToYearCharacteristic
  pageProps = {}
  retrieveData = characteristicLoadData
  filters = function () {
    return {
      victimizationType: {
        label: 'Crime Category:',
        component: components['HarnessUiRadioGroup'],
        props: {
          synchronous: true, // Remember to call loadData in afterSet or elsewhere
          inline: true
        },
        options: [
          {
            key: 'person',
            label: 'Personal',
            default: true
          },
          {
            key: 'property',
            label: 'Property'
          }
        ],
        afterSet (action, hs) {
          hs.setOptionsForFilter('categoryDesc1', categories[hs.getFilter('victimizationType')], true)
          hs.setOptionsForFilter('categoryDesc2', categories[hs.getFilter('victimizationType')], true)
          hs.setOptionsForFilter('crimeType', crimeTypes[hs.getFilter('victimizationType')], true)
          hs.loadData()
        }
      },
      unitFlag: {
        label: 'Estimate Flags:',
        component: components['HarnessUiRadioGroup'],
        props: {
          inline: true
        },
        options: onOffOptions('On')
      },
      unitSelector: {
        label: 'Unit <span id="unitdotspot"></span>:',
        component: components['HarnessUiRadioGroup'],
        props: {
          inline: true
        },
        options: demographicOptions()
      },
      confidenceInterval: {
        label: 'Confidence Intervals <span id="cidotspot"></span>:',
        component: components['HarnessUiRadioGroup'],
        props: {
          inline: true
        },
        options: onOffOptions('Off')
      },

      crimeType: {
        label: 'Crime Type:',
        component: components['HarnessUiSelect'],
        options: crimeTypes['person']
      },
      year2: {
        label: 'year selection <b>1</b>:',
        component: components['HarnessUiSelect'],
        programmatic: true,
        options: [],
        afterSet (action, hs) {
          hs.disableOptions('year2', '2006')
        }
      },
      year: {
        label: 'year selection <b>2</b>:',
        component: components['HarnessUiSelect'],
        programmatic: true,
        options: [],
        afterSet (action, hs) {
          hs.disableOptions('year', '2006')
        }
      },
      categoryDesc1: {
        label: 'Comparison Characteristic <b>A</b>:',
        component: components['HarnessUiSelect'],
        options: categoryFactory('person'),
        afterSet (action, hs) {
          if (action.payload !== 'All') {
            hs.disableOptions('categoryDesc2', [hs.getFilter('categoryDesc1')])
          } else {
            hs.enableOptions('categoryDesc2', hs.getOptionsForFilter('categoryDesc2').map((x) => x.key))
            // if the filter for catDesc2 is not included in the demographic filters
            if (!demographicFilters.includes(hs.getFilter('categoryDesc2'))) {
              hs.setOptionsForFilter('unitSelector', incidentOptions(), true)
            }
          }
          if (demographicFilters.includes(action.payload)) {
            if (action.payload !== 'All') {
              hs.setOptionsForFilter('unitSelector', demographicOptions(), true)
            }
            if (action.payload === 'All' && hs.getFilter('categoryDesc2') === 'All') {
              hs.setOptionsForFilter('unitSelector', demographicOptions(), true)
            }
            if (action.payload === 'All' && hs.getFilter('categoryDesc2') !== 'All') {
              // here this will need to set unit based upon setting of char2
              hs.getOptionsForFilter('categoryDesc2')
              if (!demographicFilters.includes(hs.getFilter('categoryDesc2'))) {
                hs.setOptionsForFilter('unitSelector', incidentOptions(), true)
              } else {
                hs.setOptionsForFilter('unitSelector', demographicOptions(), true)
              }
            }
          } else {
            hs.setOptionsForFilter('unitSelector', incidentOptions(), true)
          }
        }
      },
      categoryDesc2: {
        label: 'Comparison Characteristic <b>B</b>:',
        component: components['HarnessUiSelect'],
        options: categoryFactory('person'),
        afterSet (action, hs) {
          if (action.payload !== 'All') {
            hs.disableOptions('categoryDesc1', [hs.getFilter('categoryDesc2')])
          } else {
            hs.enableOptions('categoryDesc1', hs.getOptionsForFilter('categoryDesc1').map((x) => x.key))
          }
          if (hs.getFilter('categoryDesc1') === 'All' && demographicFilters.includes(hs.getFilter('categoryDesc2')) === false) {
            hs.setOptionsForFilter('unitSelector', incidentOptions(), true)
            if (hs.filters['referenceLine']) {
              hs.setFilter('referenceLine', false)
              hs.disableOptions('referenceLine', [true])
            }
          }
        }
      }
    }
  }

  charts = function () {
    return {
      Characteristic: {
        component: ChartWithTableWrapper,
        props: {
          chartComponent: dumbbell,
          card: false,
          buttonPosition: 'bottom',
          tableClass: tableClasses,
          tableAdapter: dumbbellTableAdapter
        }
      }
    }
  }
}
