<template>
  <!-- ************** Begin Footer **************** -->
  <div class="footerComponent">
    <!--    extra wrapping div is here to maintain cascade for bjs styles-->
    <!-- Footer Section -->
    <footer class="usa-footer usa-footer--big">
      <div class="grid-container usa-footer-return-to-top">
        <a
          id="return-to-top"
          class="show"
          href="#"
        >Top</a>
      </div>
      <div class="usa-footer__primary-section">
        <div class="grid-container footer__wrapper">
          <div class="usa-footer-primary__top padding-bottom-5">
            <h3 class="usa-footer__agency-heading tablet:display-inline-block">
              Bureau of Justice Statistics
            </h3>
          </div>
          <nav
            class="usa-footer-nav mobile-lg:grid-col-12"
            aria-label="footer navigation"
          >
            <div class="region region-footer-menu">
              <div class="grid-row grid-gap">
                <div class="mobile-lg:grid-col-6 desktop:grid-col-3">
                  <section
                    aria-labelledby="section-about-bjs"
                    class="usa-footer__primary-content usa-footer__primary-content--collapsible display-block"
                  >
                    <h4
                      id="section-about-bjs"
                      class="usa-footer__primary-link"
                    >
                      About BJS
                    </h4>
                    <ul class="add-list-reset usa-list--unstyled">
                      <li class="usa-footer__secondary-link">
                        <a
                          href="https://bjs.ojp.gov/about"
                          target="_blank"
                        >About BJS</a>
                      </li>
                      <li class="usa-footer__secondary-link">
                        <a
                          href="https://bjs.ojp.gov/about/activities-programs"
                          target="_blank"
                        >Statistical Programs</a>
                      </li>
                      <li class="usa-footer__secondary-link">
                        <a
                          href="https://bjs.ojp.gov/about-bjs-website"
                          target="_blank"
                        >About BJS Website</a>
                      </li>
                    </ul>
                  </section>
                </div>
                <div class="mobile-lg:grid-col-6 desktop:grid-col-3">
                  <section
                    aria-labelledby="section-data-integrity"
                    class="usa-footer__primary-content usa-footer__primary-content--collapsible display-block"
                  >
                    <h4
                      id="section-data-integrity"
                      class="usa-footer__primary-link"
                    >
                      Data Integrity
                    </h4>
                    <ul class="add-list-reset usa-list--unstyled">
                      <li class="usa-footer__secondary-link">
                        <a
                          href="https://bjs.ojp.gov/sites/g/files/xyckuh236/files/media/document/bjs_data_protection_guidelines.pdf"
                          target="_blank"
                        >BJS Data Protection Guidelines</a>
                      </li>
                      <li class="usa-footer__secondary-link">
                        <a
                          href="https://bjs.ojp.gov/bjs-data-quality-guidelines"
                          target="_blank"
                        >BJS Data Quality Guidelines</a>
                      </li>
                      <li class="usa-footer__secondary-link">
                        <a
                          href="https://bjs.ojp.gov/about/principles-and-practices"
                          target="_blank"
                        >BJS Statistical Principles and Practices</a>
                      </li>
                      <li class="usa-footer__secondary-link">
                        <a
                          href="https://bjs.ojp.gov/sites/g/files/xyckuh236/files/media/document/scientificintegrity.pdf"
                          target="_blank"
                        >Scientific Integrity Statement</a>
                      </li>
                    </ul>
                  </section>
                </div>
                <div class="mobile-lg:grid-col-6 desktop:grid-col-3">
                  <section
                    aria-labelledby="section-partners"
                    class="usa-footer__primary-content usa-footer__primary-content--collapsible display-block"
                  >
                    <h4
                      id="section-partners"
                      class="usa-footer__primary-link"
                    >
                      Partners
                    </h4>
                    <ul class="add-list-reset usa-list--unstyled">
                      <li class="usa-footer__secondary-link">
                        <a
                          href="https://www.icpsr.umich.edu/web/pages/NACJD/index.html"
                          class="ext"
                          data-extlink=""
                          target="_blank"
                        >
                          National Archive of Criminal Justice Data
                          <svg
                            focusable="false"
                            class="ext"
                            role="img"
                            aria-label="(link is external)"
                            xmlns="http://www.w3.org/2000/svg"
                            viewBox="0 0 80 40"
                          >
                            <!--
                          <metadata>
                            <sfw xmlns="http://ns.adobe.com/SaveForWeb/1.0/">
                              <slicesourcebounds
                                y="-8160"
                                x="-8165"
                                width="16389"
                                height="16384"
                                bottomleftorigin="true"
                              />
                              <optimizationsettings>
                                <targetsettings
                                  targetsettingsid="0"
                                  fileformat="PNG24Format"
                                >
                                  <png24format
                                    transparency="true"
                                    filtered="false"
                                    interlaced="false"
                                    nomattecolor="false"
                                    mattecolor="#FFFFFF"
                                  />
                                </targetsettings>
                              </optimizationsettings>
                            </sfw>
                          </metadata>
-->
                            <title>(link is external)</title>
                            <path d="M48 26c-1.1 0-2 0.9-2 2v26H10V18h26c1.1 0 2-0.9 2-2s-0.9-2-2-2H8c-1.1 0-2 0.9-2 2v40c0 1.1 0.9 2 2 2h40c1.1 0 2-0.9 2-2V28C50 26.9 49.1 26 48 26z" />
                            <path d="M56 6H44c-1.1 0-2 0.9-2 2s0.9 2 2 2h7.2L30.6 30.6c-0.8 0.8-0.8 2 0 2.8C31 33.8 31.5 34 32 34s1-0.2 1.4-0.6L54 12.8V20c0 1.1 0.9 2 2 2s2-0.9 2-2V8C58 6.9 57.1 6 56 6z" />
                          </svg>
                        </a>
                      </li>
                      <li class="usa-footer__secondary-link">
                        <a
                          href="https://www.ojp.gov/ncjrs/new-ojp-resources"
                          target="_blank"
                        >National Criminal Justice Reference Service</a>
                      </li>
                      <li class="usa-footer__secondary-link">
                        <a
                          href="https://www.fbi.gov/"
                          target="_blank"
                        >Federal Bureau of Investigation</a>
                      </li>
                    </ul>
                  </section>
                </div>
                <div class="mobile-lg:grid-col-6 desktop:grid-col-3">
                  <section
                    aria-labelledby="section-resources"
                    class="usa-footer__primary-content usa-footer__primary-content--collapsible display-block"
                  >
                    <h4
                      id="section-resources"
                      class="usa-footer__primary-link"
                    >
                      Resources
                    </h4>
                    <ul class="add-list-reset usa-list--unstyled">
                      <li class="usa-footer__secondary-link">
                        <a
                          href="https://bjs.ojp.gov/frequently-asked-questions"
                          target="_blank"
                        >FAQs</a>
                      </li>
                      <li class="usa-footer__secondary-link">
                        <a
                          href="https://bjs.ojp.gov/glossary"
                          target="_blank"
                        >Glossary</a>
                      </li>
                      <li class="usa-footer__secondary-link">
                        <a
                          href="https://bjs.ojp.gov/related-links"
                          target="_blank"
                        >Related Links</a>
                      </li>
                      <li class="usa-footer__secondary-link">
                        <a
                          href="https://bjs.ojp.gov/subscribe"
                          target="_blank"
                        >Subscribe</a>
                      </li>
                    </ul>
                  </section>
                </div>
              </div>
            </div>
          </nav>
          <div class="footer__social">
            <a
              class="usa-link-facebook margin-right-2 ext"
              href="https://www.facebook.com/BJSgov/"
              data-extlink=""
              target="_blank"
            >
              <span class="usa-sr-only">Facebook</span><span class="f">&nbsp;</span>
              <svg
                focusable="false"
                class="ext"
                role="img"
                aria-label="(link is external)"
                xmlns="http://www.w3.org/2000/svg"
                viewBox="0 0 80 40"
              >
                <!--
              <metadata>
                <sfw xmlns="http://ns.adobe.com/SaveForWeb/1.0/">
                  <slicesourcebounds
                    y="-8160"
                    x="-8165"
                    width="16389"
                    height="16384"
                    bottomleftorigin="true"
                  />
                  <optimizationsettings>
                    <targetsettings
                      targetsettingsid="0"
                      fileformat="PNG24Format"
                    >
                      <png24format
                        transparency="true"
                        filtered="false"
                        interlaced="false"
                        nomattecolor="false"
                        mattecolor="#FFFFFF"
                      />
                    </targetsettings>
                  </optimizationsettings>
                </sfw>
              </metadata>
-->
                <title>(link is external)</title>
                <path d="M48 26c-1.1 0-2 0.9-2 2v26H10V18h26c1.1 0 2-0.9 2-2s-0.9-2-2-2H8c-1.1 0-2 0.9-2 2v40c0 1.1 0.9 2 2 2h40c1.1 0 2-0.9 2-2V28C50 26.9 49.1 26 48 26z" />
                <path d="M56 6H44c-1.1 0-2 0.9-2 2s0.9 2 2 2h7.2L30.6 30.6c-0.8 0.8-0.8 2 0 2.8C31 33.8 31.5 34 32 34s1-0.2 1.4-0.6L54 12.8V20c0 1.1 0.9 2 2 2s2-0.9 2-2V8C58 6.9 57.1 6 56 6z" />
              </svg>
            </a>
            <a
              class="usa-link-twitter margin-right-2 ext"
              href="https://twitter.com/BJSgov"
              data-extlink=""
              target="_blank"
            >
              <span class="usa-sr-only">Twitter</span><span class="t">&nbsp;</span>
              <svg
                focusable="false"
                class="ext"
                role="img"
                aria-label="(link is external)"
                xmlns="http://www.w3.org/2000/svg"
                viewBox="0 0 80 40"
              >
                <!--
              <metadata>
                <sfw xmlns="http://ns.adobe.com/SaveForWeb/1.0/">
                  <slicesourcebounds
                    y="-8160"
                    x="-8165"
                    width="16389"
                    height="16384"
                    bottomleftorigin="true"
                  />
                  <optimizationsettings>
                    <targetsettings
                      targetsettingsid="0"
                      fileformat="PNG24Format"
                    >
                      <png24format
                        transparency="true"
                        filtered="false"
                        interlaced="false"
                        nomattecolor="false"
                        mattecolor="#FFFFFF"
                      />
                    </targetsettings>
                  </optimizationsettings>
                </sfw>
              </metadata>
-->
                <title>(link is external)</title>
                <path d="M48 26c-1.1 0-2 0.9-2 2v26H10V18h26c1.1 0 2-0.9 2-2s-0.9-2-2-2H8c-1.1 0-2 0.9-2 2v40c0 1.1 0.9 2 2 2h40c1.1 0 2-0.9 2-2V28C50 26.9 49.1 26 48 26z" />
                <path d="M56 6H44c-1.1 0-2 0.9-2 2s0.9 2 2 2h7.2L30.6 30.6c-0.8 0.8-0.8 2 0 2.8C31 33.8 31.5 34 32 34s1-0.2 1.4-0.6L54 12.8V20c0 1.1 0.9 2 2 2s2-0.9 2-2V8C58 6.9 57.1 6 56 6z" />
              </svg>
            </a>
          </div>
        </div>
      </div>
      <div class="usa-footer__secondary-section">
        <div class="grid-container">
          <div class="grid-row usa-footer__logos-container ">
            <div class="usa-footer-logo usa-width-one-half  desktop:grid-col-6 desktop:border-right border-base-dark">
              <a
                href="https://www.ojp.gov/"
                target="_blank"
              >
                <img
                  class=""
                  src="../styles/ojp-full.svg"
                  alt="U.S. Department of Justice, Office of Justice Programs Logo"
                >
              </a>
            </div>
            <div class="desktop:grid-col-6">
              <div class="usa-footer__partner-logos 1">
                <div class="grid-row">
                  <div class="grid-col-4 flex-align-center usa-footer__partner-logo margin-bottom-3">
                    <a
                      href="https://bja.ojp.gov/"
                      aria-label="Bureau of Justice Administration"
                      target="_blank"
                    >
                      <img
                        src="../styles/bja.svg"
                        alt="Bureau of Justice Administration Logo"
                      >
                    </a>
                  </div>
                  <div class="grid-col-4 flex-align-center usa-footer__partner-logo margin-bottom-3">
                    <a
                      href="https://bjs.ojp.gov/"
                      aria-label="Bureau of Justice Statistics"
                      target="_blank"
                    >
                      <img
                        src="../styles/bjs.svg"
                        alt="Bureau of Justice Statistics Logo"
                      >
                    </a>
                  </div>
                  <div class="grid-col-4 flex-align-center usa-footer__partner-logo margin-bottom-3">
                    <a
                      href="https://nij.ojp.gov/"
                      aria-label="National Institute of Justice"
                      target="_blank"
                    >
                      <img
                        src="../styles/nij.svg"
                        alt="National Institute of Justice Logo"
                      >
                    </a>
                  </div>
                  <div class="grid-col-4 flex-align-center usa-footer__partner-logo">
                    <a
                      href="https://ojjdp.ojp.gov/"
                      aria-label="Office of Juvenile Justice and Delinquency Prevention"
                      target="_blank"
                    >
                      <img
                        src="../styles/ojjdp.svg"
                        alt="Office of Juvenile Justice and Delinquency Prevention Logo"
                      >
                    </a>
                  </div>
                  <div class="grid-col-4 flex-align-center usa-footer__partner-logo">
                    <a
                      href="https://ovc.ojp.gov/"
                      aria-label="Office for Victims of Crime"
                      target="_blank"
                    >
                      <img
                        src="../styles/ovc.svg"
                        alt="Office for Victims of Crime Logo"
                      >
                    </a>
                  </div>
                  <div class="grid-col-4 flex-align-center usa-footer__partner-logo">
                    <a
                      href="https://smart.ojp.gov/"
                      aria-label="Office of Sex Offender Sentencing, Monitoring, Apprehending, Registering, and Tracking"
                      target="_blank"
                    >
                      <img
                        src="../styles/smart.svg"
                        alt="Office of Sex Offender Sentencing, Monitoring, Apprehending, Registering, and Tracking Logo"
                      >
                    </a>
                  </div>
                </div>
              </div>
            </div>
          </div>
          <div class="usa-footer-contact-links usa-width-one-half">
            <address>
              <p class="usa-footer-contact-heading">
                810 7th St. NW, Washington, D.C. 20531
              </p>
            </address>
          </div>
        </div>
        <div class="wide-nav">
          <nav
            aria-labelledby="block-bja-secondaryfooterlinkmenu-menu"
            id="block-bja-secondaryfooterlinkmenu"
            class="navigation menu--secondary-footer-link-menu"
          >
            <h2
              class="visually-hidden"
              id="block-bja-secondaryfooterlinkmenu-menu"
            >
              Secondary Footer link menu
            </h2>
            <ul class="menu">
              <li class="menu-item">
                <a
                  href="https://www.justice.gov/accessibility/accessibility-information"
                  target="_blank"
                >Accessibility</a>
              </li>
              <li class="menu-item">
                <a
                  href="https://www.justice.gov/open/plain-writing-act"
                  target="_blank"
                >Plain Language</a>
              </li>
              <li class="menu-item">
                <a
                  href="https://www.justice.gov/doj/privacy-policy"
                  target="_blank"
                >Privacy Policy</a>
              </li>
              <li class="menu-item">
                <a
                  href="https://www.justice.gov/legalpolicies"
                  target="_blank"
                >Legal Policies and Disclaimer</a>
              </li>
              <li class="menu-item">
                <a
                  href="https://www.justice.gov/jmd/eeo-program-status-report"
                  target="_blank"
                >No FEAR Act</a>
              </li>
              <li class="menu-item">
                <a
                  href="https://www.ojp.gov/program/freedom-information-act/overview"
                  target="_blank"
                >Freedom of Information Act</a>
              </li>
              <li class="menu-item">
                <a
                  href="https://usa.gov/"
                  target="_blank"
                >USA.gov</a>
              </li>
              <li class="menu-item">
                <a
                  href="https://www.justice.gov/"
                  target="_blank"
                >Justice.gov</a>
              </li>
            </ul>
          </nav>
        </div>
      </div>
    </footer>
  <!-- end footer -->
  </div>
  <!-- ************** End Footer ****************** -->
</template>

<script>

export default {
  name: 'BjsFooter'
}

</script>
