<template>
  <div class="row chartholder">
    <div class="col col-12 col-lg-10 offset-lg-1">
      <chartGrid
        :only="chartIds"
        :force-table="forceTable"
      />
    </div>
  </div>
</template>

<script>
export default {
  name: 'ChartHolder',
  props: {
    'chartIds': {
      type: Array,
      required: true
    },
    forceTable: {
      type: Boolean,
      required: false,
      default: false
    }
  }
}
</script>
