import Vue from 'vue'
import Vuex from 'vuex'

Vue.use(Vuex)

export default new Vuex.Store({
  state: {
    isMobileView: null,
    smallMultipleName: null,
    smallMultiplesTitle: null,
    smallMultiplesCursorValue: null,
    smallMultiplesCursorEvent: null
  },
  mutations: {
    SET_IS_MOBILE_VIEW (state, payload) {
      state.isMobileView = payload
    },
    CHANGE_SMALL_MULTIPLE_NAME (state, payload) {
      state.smallMultipleName = payload
    },
    CHANGE_SMALL_MULTIPLES_TITLE (state, payload) {
      state.smallMultiplesTitle = payload
    },
    CHANGE_SMALL_MULTIPLES_CURSOR_VALUE (state, payload) {
      state.smallMultiplesCursorValue = payload
    },
    CHANGE_SMALL_MULTIPLES_CURSOR_EVENT (state, payload) {
      state.smallMultiplesCursorEvent = payload
    },
    // Acts as an event
    SMALL_MULTIPLES_MOUSE_OUT (state, payload) {}
  },
  actions: {
    setIsMobileView ({ commit, state }, payload) {
      commit('SET_IS_MOBILE_VIEW', payload)
    },
    changeSmallMultiplesCursor ({ commit, state }, payload) {
      if (payload.smallMultipleName !== state.smallMultipleName) {
        commit('CHANGE_SMALL_MULTIPLE_NAME', payload.smallMultipleName)
      }
      if (payload.smallMultiplesTitle !== state.smallMultiplesTitle) {
        commit('CHANGE_SMALL_MULTIPLES_TITLE', payload.smallMultiplesTitle)
      }
      commit('CHANGE_SMALL_MULTIPLES_CURSOR_EVENT', payload.smallMultiplesCursorEvent)
      commit('CHANGE_SMALL_MULTIPLES_CURSOR_VALUE', payload.smallMultiplesCursorEvent.target.x)
    },
    smallMultiplesMouseOut ({ commit, state }, payload) {
      commit('SMALL_MULTIPLES_MOUSE_OUT', true)
    }
  },
  getters: {
    isMobileView (state, getters) {
      return state.isMobileView
    },
    mousedSmallMultipleName (state, getters) {
      return state.smallMultipleName
    },
    mousedSmallMultiplesTitle (state, getters) {
      return state.smallMultiplesTitle
    }
  },
  modules: {
  }
})
