export function setFilterOptions (hs, data) {
  let filterKeys = Object.keys(hs.filters)

  // Set programmatic filter options
  filterKeys.forEach(filterKey => {
    if (hs.filters[filterKey].programmatic) {
      // let options = hs.getDistinctValues(data, filterKey)
      // I have replaced above with this below to correct the sort order issue- but table adapter needs fix now
      let options = [...new Set(data.map(d => d[filterKey]))]
      let formattedOptions = []
      let currFilter = hs.getFilter(filterKey)
      options.forEach(option => {
        formattedOptions.push({
          key: option,
          label: option,
          default: currFilter === option
        })
      })
      let defaultOption = formattedOptions.filter(option => option.default === true)[0]
      if (!defaultOption && filterKey === 'year') {
        formattedOptions[options.length - 1].default = true
      } else if (!defaultOption) {
        formattedOptions[0].default = true
      }
      hs.setOptionsForFilter(filterKey, formattedOptions, true)
    }
  })
}

export function setRefFilters (hs, filters) {
  let minorRefFilters = filters.reduce((acc, filter) => {
    acc.push({
      column: filter,
      value: hs.getFilter(filter)
    })
    return acc
  }, [])
  return minorRefFilters
}

export function addMinorRefFilters (hs, minorRefFilters, filters) {
  filters.forEach(filter => {
    minorRefFilters.push(
      {
        column: filter,
        value: filter === 'categoryDesc2' ? hs.getFilter(filter) : 'All'
      }
    )
  })
  return minorRefFilters
}
