import quickGraphics from './quickGraphics/quickGraphics'
import multiYearTrendsCrimeType from './multiYearTrends/crimeType'
import multiYearTrendsCharacteristic from './multiYearTrends/characteristic'
import singleYearComparisonCrimeType from './singleYearComparison/crimeType'
import singleYearComparisonCharacteristic from './singleYearComparison/characteristic'
import yearToYearComparisonCrimeType from './yearToYearComparison/crimeType'
import yearToYearComparisonCharacteristic from './yearToYearComparison/characteristic'
const pages = [
  quickGraphics,
  multiYearTrendsCrimeType,
  multiYearTrendsCharacteristic,
  singleYearComparisonCrimeType,
  singleYearComparisonCharacteristic,
  yearToYearComparisonCrimeType,
  yearToYearComparisonCharacteristic
] // add pages to this array
export default pages
