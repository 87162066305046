<template>
  <div>
    <notEligible
      :chart="chart"
      v-if="(this.getChartData(this.chart.key) === false) || (this.getChartData(this.chart.key) && this.getChartData(this.chart.key)['giveAlert'] && (this.getChartData(this.chart.key)['giveAlert'] === true))"
    />
    <highcharts
      v-else
      v-show="this.getChartData(this.chart.key) !== false"
      :options="chartOptions"

      ref="highchartsChart"
      :callback="afterChartCreated"
    />
  </div>
</template>

<script>
import chartSettings from './chartSettings'
import plotOptions from './plotOptions'
import notEligible from '../notEligible'
import { axisLabelFormatter, pluralsFix, labelLowerCase } from '@/utils'
import $ from 'jquery'
import { transformToSentenceCase } from '@/chartTitleFormatter'

export default {
  name: 'Lollipop',
  mixins: [chartSettings, plotOptions],
  components: { notEligible },
  computed: {
    chartOptions () {
      let charA
      if (this.hs.filters['categoryDesc1']) {
        charA = this.getFilter('categoryDesc1')
      // Quick Graphics charts don't have filters to pass in the same ways as the custom graphics charts, so it must be grabbed from chart data
      } else if (this.hs.pageKey === 'quickGraphics' && this.getChartData(this.chart.key) && this.getChartData(this.chart.key).series[0].custom[0].categoryDesc1) {
        charA = this.getChartData(this.chart.key).series[0].custom[0].categoryDesc1
      } else {
        charA = ''
      }

      let charB
      if (this.hs.filters['categoryDesc2']) {
        charB = this.getFilter('categoryDesc2')
      // Quick Graphics charts don't have filters to pass in the same ways as the custom graphics charts, so it must be grabbed from chart data
      } else {
        if (this.hs.pageKey === 'quickGraphics' && this.getChartData(this.chart.key) && this.getChartData(this.chart.key).series[0].custom[0].categoryDesc2) {
          charB = this.getChartData(this.chart.key).series[0].custom[0].categoryDesc2
        } else {
          charB = ''
        }
      }
      // let crimeSelected = this.hs.filters['crimeType'] ? this.getFilter('crimeType') : ''
      let crimeSelected
      if (this.hs.filters['crimeType']) {
        crimeSelected = this.getFilter('crimeType')
      // Quick Graphics charts don't have filters to pass in the same ways as the custom graphics charts, so it must be grabbed from chart data
      } else if (this.hs.pageKey === 'quickGraphics') {
        crimeSelected = this.getChartData(this.chart.key) && this.getChartData(this.chart.key).series[0].custom[0].crimeType
      } else {
        crimeSelected = ''
      }

      let isCrimeType = this.getChartData(this.chart.key) ? this.getChartData(this.chart.key).crimeTypePage : null
      let allEmph = charA !== 'All' && charB !== 'All' ? 'all ' : ''
      let isQuick = this.hs.pageKey === 'quickGraphics'
      return {
        chart: {
          type: 'lollipop',
          inverted: true,
          spacingBottom: 30,
          spacingRight: 30,
          marginRight: 50,
          // margin top for floating title to keep plot area heights correct with text wrap
          // marginTop: 70,
          className: 'lollipop',
          height: this.getChartData(this.chart.key) ? this.getChartData(this.chart.key).height : 300
          // possible culprit render problem
          // animation: false
        },
        credits: {
          enabled: false
        },
        title: {
          text: this.localTitle,
          style: {
            fontWeight: 500,
            fontSize: '1.5rem'
          }
          // floating: true,
          // x: 0
          // verticalAlign: 'top'
          // y: 0
        },
        subtitle: {
          text: this.subtitle ? this.formattedSubtitle : null,
          align: 'left',
          verticalAlign: 'bottom',
          style: {
            fontSize: 11,
            height: 170
          },
          useHTML: true,
          // floating: true,
          y: 0,
          lineWidth: 0,
          lineColor: 'rgba(255, 255, 255, 0)'
        },
        xAxis: [{
          type: 'category',
          categories: this.categories,
          offset: this.getChartData(this.chart.key) ? this.getChartData(this.chart.key).minorwidth : 80,
          lineColor: 'rgba(255, 255, 255, 0)',
          lineWidth: 0,
          minorGridLineWidth: 0,
          minorTickLength: 0,
          tickLength: 0,
          labels: {
            formatter: function () {
              if (this.value === 'All' && charA !== 'All') {
                return ''
              } else {
                return transformToSentenceCase(this.value)
              }
            },
            style: {
              fontSize: '14px',
              fontWeight: 'bold',
              color: '#272727'
            }
          }
        }],
        yAxis: [
          {
            title: {
              // 'categoryDesc2 !== ('All')' check is to use just revised the yAxisTitleText without further alterations for reasons for reporting
              text: this.calculateYAxisText(allEmph, crimeSelected, isCrimeType)
            },
            labels: {
              style: {
                fontSize: '12px',
                textOverflow: 'none'
              },
              formatter: function () {
                return axisLabelFormatter(this, this.chart.series[0].userOptions.estType)
              }
            },
            overflow: 'allow',
            // If reference line is a filter on this page, then calculate max axis
            max: this.filters['referenceLine'] ? this.calculateMaxAxisValueToShow : null,
            tickInterval: this.tickInterval,
            plotLines: [{
              value: this.getMajorRefValue,
              dashStyle: 'LongDashDot',
              color: '#414141'
            }]
            // height: 50
          },
          {
            title: {
              text: ''
            },
            max: 3,
            // height: 2,
            width: 2,
            // top: 0,
            left: '-1%',
            visible: false,
            labels: {
              enabled: false
            }
          },
          {
            title: {
              // text: this.chartSettings.yAxisTitleText.replace('<br/>', ' ')
              //              text: (this.getChartData(this.chart.key) && this.getChartData(this.chart.key).estimateType === 'Percent' && isCrimeType === false) ? this.chartSettings.yAxisTitleText.replace('<br/>', ' ') + allEmph + crimeSelected.toLowerCase() : this.chartSettings.yAxisTitleText.replace('<br/>', ' ')
              // isn't this just the upper label where text should be identical to lower?
              text: this.calculateYAxisText(allEmph, crimeSelected, isCrimeType)
            },
            labels: {
              style: {
                fontSize: '12px'
              },
              formatter: function () {
                return axisLabelFormatter(this, this.chart.series[0].userOptions.estType)
              }
            },
            linkedTo: 0,
            opposite: true,
            visible: this.getChartData(this.chart.key) && this.getChartData(this.chart.key).height > 900
          }
        ],
        series: this.series,
        legend: {
          enabled: true,
          labelFormatter: function () {
            let flagName = this.name === 'Count flag' ? 'Number flag' : this.name
            if (flagName === 'Percent flag' || flagName === 'Rate flag' || flagName === 'Number flag') {
              return '<span style="fill:#ce4646">' + '✱/▲ ' + '</span>' + 'Estimate flag'
            } else if (this.userOptions.className === 'minorrefline-legend') {
              let words = flagName.split(/[\s]+/)
              let numWordsPerLine = 8
              let str = []
              for (var word in words) {
                if (word > 0 && word % numWordsPerLine === 0) {
                  str.push('<br>')
                }
                str.push(words[word])
              }
              flagName = str.join(' ')
              return flagName
            } else {
              return flagName
            }
          }
          // maxHeight: 60,
        },
        plotOptions: {
          lollipop: {
            // colorByPoint: this.plotOptions.colorByPoint
          },
          series: {
            marker: {
              symbol: 'circle'
            },
            connectorWidth: 3.5,
            groupPadding: this.categories && this.categories.length > 1 ? 0.13 : 0,
            events: {
              legendItemClick: function (e) {
                e.preventDefault()
              }
            }
          }
        },
        exporting: {
          enabled: this.exportingEnabled
        },
        tooltip: {
          backgroundColor: '#fff',
          borderColor: '#b9b9b9',
          valueDecimals: 2,
          headerFormat: this.chartSettings.tooltipHeader,
          valueSuffix: this.chartSettings.valueSuffix,
          shared: false,
          formatter: function (i) {
            let ptIn = this.point.index
            let unit = this.series.options.estType
            let unitLabel = unit.charAt(0).toUpperCase() + unit.slice(1)
            unitLabel = unitLabel === 'Count' ? 'Number' : unitLabel
            if (this.series.options.estType === 'percent') {
            // let allEmph = charA !== 'All' && charB !== 'All' ? 'all ' : ''
              if (isCrimeType === false) {
                // now includes customization for reason for reporting charts
                //                unitLabel = (isQuick === true && charB.includes('Reason')) ? this.series.chart.yAxis[0].axisTitle.textStr : 'Percent of ' + allEmph + pluralsFix(crimeSelected)
                // revision to label for row percent not table percent
                unitLabel = (isQuick === true && charB.includes('Reason')) ? this.series.chart.yAxis[0].axisTitle.textStr : 'Percent of ' + pluralsFix(crimeSelected) + (charB === 'All' || charA === 'All' ? '' : ', ' + labelLowerCase(charB) + '= ' + this.point.category)
              } else if (isCrimeType === true) {
                if (this.point.category !== 'All') { unitLabel = 'Percent of ' + allEmph + pluralsFix(this.point.category) }
              }
            }
            let charBfill = isCrimeType ? 'Crime type' : charB
            // eliminate second row for filtered reason charts
            let formattedSeriesName
            if (this.x === 'All') {
              formattedSeriesName = ''
            } else if (isQuick === true && charB.includes('Reason')) {
              formattedSeriesName = charBfill + ': <b>' + this.x + '</b>'
            } else {
              formattedSeriesName = charBfill + ': <b>' + transformToSentenceCase(this.x) + '</b><br/>' + (this.series.name === 'All' ? '' : charA + ': <b>' + this.series.name + '</b>')
            }
            // let formattedSeriesName = (this.x === 'All' ? '' : charBfill + ': <b>' + this.x + '</b><br/>') + (this.series.name === 'All' ? '' : charA + ': <b>' + this.series.name + '</b>')
            if (formattedSeriesName.endsWith(': ')) {
              formattedSeriesName = formattedSeriesName.replace(': ', '')
            }
            const point = this.series.options.custom[ptIn]
            if (unit === 'count') {
              return formattedSeriesName + '<br/>' +
                unitLabel + ': <b>' + point[unit].toLocaleString() + '</b><br/>95% C.I.: <b>' +
                point[unit + 'LB'].toLocaleString() + ' - ' +
                point[unit + 'UB'].toLocaleString() + '</b><br/> S.E.: <b>' +
                point[unit + 'SE'].toLocaleString() + '</b>'
            } else {
              return formattedSeriesName + '<br/>' +
                unitLabel + ': <b>' +
                (isNaN(point[unit]) ? 'N/A' : Number(point[unit]).toFixed(1).toLocaleString()) + '</b><br/>95% C.I.: <b>' +
                (isNaN(point[unit + 'LB']) ? 'N/A' : Number(point[unit + 'LB']).toFixed(2).toLocaleString()) + ' - ' +
                (isNaN(point[unit + 'UB']) ? 'N/A' : Number(point[unit + 'UB']).toFixed(2).toLocaleString()) + '</b><br/> S.E.: <b>' +
                (isNaN(point[unit + 'SE']) ? 'N/A' : Number(point[unit + 'SE']).toFixed(2).toLocaleString()) + '</b>'
            }
          }
        },
        dataLabels: {
          color: 'red',
          // lolli data labels need to be hidden to not overlap in export on reporting reason charts
          // enabled: false,
          format: '{point.value}'
        }
      }
    },
    tickInterval () {
      // scale units
      let steps = [1000000, 100000, 10000, 1000, 100, 10, 1, 0]
      let filtered = steps.filter((step) => this.max > Math.abs(step))
      if (filtered.length) {
        return filtered[0]
      }
      return null
    },
    categories () {
      if (this.getChartData(this.chart.key)) {
        return this.getChartData(this.chart.key).categories || this.getChartData(this.chart.key).reduce((acc, datum) => { acc.push(datum[0]); return acc }, [])
      }
      return null
    },
    series () {
      if (this.getChartData(this.chart.key)) {
        if (this.getChartData(this.chart.key).series) {
          return this.getChartData(this.chart.key).series.map((s, i) => {
            if (s.name === '95% C.I.') {
              s.visible = this.getFilter('confidenceInterval')
              if (i === 1) s.showInLegend = this.getFilter('confidenceInterval')
            }
            return s
          })
        } else {
          return [{ name: '', data: this.getChartData(this.chart.key) }]
        }
      }
      return null
    },
    localTitle () {
      return this.getTitle()
    },
    calculateMaxAxisValueToShow () {
      // The lollipop chart does an ok job of calculating the max y axis value across all series,
      // but it sometimes forgets so that's why we call this computed function...
      if (this.getChartData(this.chart.key)) {
        return this.getMax([
          this.getChartData(this.chart.key).maxEstimateValue,
          this.getFilter('referenceLine') === true ? this.getChartData(this.chart.key).maxMajorRefValue : null,
          this.getFilter('referenceLine') === true ? this.getChartData(this.chart.key).maxMinorRefValue : null,
          this.getFilter('confidenceInterval') === true ? this.getChartData(this.chart.key).maxCiValue : null
        ])
      }
      return null
    },
    getMajorRefValue () {
      if (this.getChartData(this.chart.key)) {
        if (this.filters['referenceLine'] && this.getFilter('referenceLine') === true) {
          return this.getChartData(this.chart.key).maxMajorRefValue
        }
      }
      return null
    }
  },
  methods: {
    getTitle: function () {
      let titleValue = null
      if (this.getChartData(this.chart.key)) {
        titleValue = this.hs.pageKey === 'quickGraphics' ? this.title + ', ' + this.getChartData(this.chart.key).minYear : this.getChartData(this.chart.key)['title']
      } else {
        titleValue = this.title
      }
      return titleValue
    },
    calculateYAxisText: function (allEmph, crimeSelected, isCrimeType) {
      let yAxisText
      if (this.getChartData(this.chart.key) && this.getChartData(this.chart.key).estimateType === 'Percent' && isCrimeType === false) {
        if (this.hs.pageKey === 'quickGraphics') {
          if (this.getChartData(this.chart.key).series && this.getChartData(this.chart.key).series[0].custom[0].categoryDesc2 && this.getChartData(this.chart.key).series[0].custom[0].levelDesc1 && this.getChartData(this.chart.key).series[0].custom[0].categoryDesc2 !== ('All')) {
            // These get used for 'reporting to the police quick graphics that are only of the 'yes' or 'no's.
            yAxisText = this.chartSettings.yAxisTitleText += ' of ' + pluralsFix(this.getChartData(this.chart.key).series[0].custom[0].crimeType) + ', ' + this.getChartData(this.chart.key).series[0].custom[0].categoryDesc1 + ' = ' + this.getChartData(this.chart.key).series[0].custom[0].levelDesc1
          } else {
            yAxisText = this.chartSettings.yAxisTitleText + ' ' + pluralsFix(this.getChartData(this.chart.key).series[0].custom[0].crimeType)
          }
        } else {
          // revison for row percents
          yAxisText = this.chartSettings.yAxisTitleText.replace('<br/>', ' ') + pluralsFix(crimeSelected) + (this.getFilter('categoryDesc2') !== 'All' ? ', per ' + labelLowerCase(this.getFilter('categoryDesc2')) : '')
        }
      } else {
        yAxisText = this.chartSettings.yAxisTitleText.replace('<br/>', ' ')
      }
      return yAxisText
    }
  },
  mounted () {
    // Re-flow for when toggle between table and chart
    $('.harness-ui-togglebutton').on('click', function () {
      this.$refs['highchartsChart'].chart.reflow()
    }.bind(this))
  }
}
</script>
