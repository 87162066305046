import { capitalizeFirstLetter } from '@/utils'
export let smallMultSpanStyle = 'style="font-size:.85em; font-weight:300;"'

export function formatMinAndMaxYearsFromChart (that, chartKey) {
  let chartData = that.getChartData(chartKey)
  if (chartData) {
    if (chartData.minYear === chartData.maxYear) {
      return ', ' + chartData.minYear
    } else {
      return ', ' + chartData.minYear + '-' + chartData.maxYear
    }
  } else {
    return '...'
  }
}

export function addChartNote (key, chartIds, that) {
  let retValue = false
  switch (key) {
    case 'unitFlag':
      if (that.filters['unitFlag'] && that.getFilter('unitFlag')) {
        retValue = checkForFlags(that, chartIds)
      }
      break
    case 'raceNote':
      if ((that.filters.categoryDesc1 && that.getFilter('categoryDesc1') === 'Race/Hispanic origin') || (that.filters.categoryDesc2 && that.getFilter('categoryDesc2') === 'Race/Hispanic origin')) {
        retValue = true
      }
      break
    case 'age2016Note':
      if ((that.filters.categoryDesc1 && that.getFilter('categoryDesc1') === 'Age') || (that.filters.categoryDesc2 && that.getFilter('categoryDesc2') === 'Age')) {
        retValue = true
      }
      break
    default:
      retValue = true
  }
  return retValue
}

export default function getCrimeTypeTitle (that, chartKey) {
  let crimeTitle = that.title + formatMinAndMaxYearsFromChart(that, chartKey)
  if (that.page.key === 'Multi-Year Trends Crime Type') {
    let mainTitle = formatTitleString(stripAllFromTitle(crimeTitle))
    let subTitleOne = formatTitleString(stripAllFromTitle(that.getFilter('categoryDesc1') + ': ' + that.getFilter('levelDesc1')))
    let subTitleTwo = formatTitleString(stripAllFromTitle(that.getFilter('categoryDesc2') + ': ' + that.getFilter('levelDesc2')))
    crimeTitle = transformToSentenceCase(unitReplaced(that) + ' of ' + mainTitle) + '<br/><span ' + smallMultSpanStyle + '>' + transformToSentenceCase(subTitleOne) + '</span><br/><span ' + smallMultSpanStyle + '>' + transformToSentenceCase(subTitleTwo) + '</span>'
  }
  return crimeTitle
}

export function getSmallMultCrimeTypeTitle (that, chartKey) {
  let victimizationTypeTitle = that.getFilter('victimizationType') === 'person' ? 'Violent' : 'Property'
  let mainTitle = formatTitleString(stripAllFromTitle(victimizationTypeTitle + ' Victimizations by crime type' + formatMinAndMaxYearsFromChart(that, chartKey)))
  let subTitleOne = formatTitleString(stripAllFromTitle(that.getFilter('categoryDesc1') + ': ' + that.getFilter('levelDesc1')))
  let subTitleTwo = formatTitleString(stripAllFromTitle(that.getFilter('categoryDesc2') + ': ' + that.getFilter('levelDesc2')))
  return transformToSentenceCase(unitReplaced(that) + ' of ' + mainTitle) + '<br/><span ' + smallMultSpanStyle + '>' + transformToSentenceCase(subTitleOne) + '</span><br/><span ' + smallMultSpanStyle + '>' + transformToSentenceCase(subTitleTwo) + '</span>'
}

export function getSmallMultCharacteristicTitle (that, chartKey) {
  let chartData = that.getChartData(chartKey)
  let mainTitle = formatTitleString(stripAllFromTitle(that.getFilter('crimeType').replace(' victimization', '') + ' Victimizations by ' + that.getFilter('categoryDesc1') + ', ' + chartData.minYear + ' to ' + chartData.maxYear))
  let subTitle = formatTitleString(stripAllFromTitle(that.getFilter('categoryDesc2') + ': ' + that.getFilter('levelDesc2')))
  return transformToSentenceCase(unitReplaced(that) + ' of ' + mainTitle) + '<br/><span ' + smallMultSpanStyle + '>' + transformToSentenceCase(subTitle) + '</span>'
}

export function getSingleYearCharacteristicTitle (that) {
  let mainTitle = unitReplaced(that) + ' of ' + that.getFilter('crimeType').replace(' victimization', '') + ' Victimizations by ' + that.getFilter('categoryDesc2') +
' by ' + that.getFilter('categoryDesc1') + ', ' + that.getFilter('year')
  let exception06 = that.getFilter('year') === 2006 ? '†' : ''
  return transformToSentenceCase(formatTitleString(stripAllFromTitle(mainTitle + exception06)))
}

export function getSingleYearCrimeTypeTitle (that) {
  let mainTitle = formatTitleString(stripAllFromTitle('Victimizations by Crime Type by ' + that.getFilter('categoryDesc1') + ', ' + that.getFilter('year')))
  let subTitle = formatTitleString(stripAllFromTitle(that.getFilter('categoryDesc2') + ': ' + that.getFilter('levelDesc2')))
  let exception06 = that.getFilter('year') === 2006 ? '†' : ''
  return transformToSentenceCase(unitReplaced(that) + ' of ' + mainTitle + exception06) + '<br/><span ' + smallMultSpanStyle + '>' + transformToSentenceCase(subTitle) + '</span>'
}

export function getTwoYearCharacteristicTitle (that) {
  let bothyears = [that.getFilter('year'), that.getFilter('year2')].sort((a, b) => a - b)
  let mainTitle = unitReplaced(that) + ' of ' + that.getFilter('crimeType').replace(' victimization', '') + ' Victimizations by ' + that.getFilter('categoryDesc2') +
  ' by ' + that.getFilter('categoryDesc1') + ', ' + bothyears[0] + ' vs. ' + bothyears[1]
  return transformToSentenceCase(formatTitleString(stripAllFromTitle(mainTitle)))
}

export function getTwoYearCrimeTypeTitle (that) {
  let bothyears = [that.getFilter('year'), that.getFilter('year2')].sort((a, b) => a - b)
  let mainTitle = formatTitleString(stripAllFromTitle('Victimizations by Crime Type by ' + that.getFilter('categoryDesc1') + ', ' + bothyears[0] + ' vs. ' + bothyears[1]))
  let subTitle = formatTitleString(stripAllFromTitle(that.getFilter('categoryDesc2') + ': ' + that.getFilter('levelDesc2')))
  return transformToSentenceCase(unitReplaced(that) + ' of ' + mainTitle + '<br/><span ' + smallMultSpanStyle + '>') + transformToSentenceCase(subTitle) + '</span>'
}

// --- Title Formatting --- //
// Function to provide uppercase to the string and include custom separator list
// to allow for capitalization around special characters such as hypens, colons, etc...
// Function also has a "must-be lowercase" list
function transformToUpperCase (str, separators) {
  separators = separators || [ ' ' ]
  var regex = new RegExp('(^|[' + separators.join('') + '])(\\w)', 'g')
  str = str.toLowerCase().replace(regex, function (x) { return x.toUpperCase() })

  // Exceptions: the below list should be lowercase
  var i, j, lowers
  lowers = ['To', 'The', 'For', 'By', 'A', 'Than', 'Or', 'Of', 'If', 'Had', 'Vs.', 'With'] // or More?
  for (i = 0, j = lowers.length; i < j; i++) {
    str = str.replace(new RegExp('\\s' + lowers[i] + '\\s', 'g'),
      function (txt) {
        return txt.toLowerCase()
      })
  }

  return str
}

function unitReplaced (that) {
  let unitPrefix = (that.filters['unitSelector'] && that.getFilter('unitSelector') !== undefined) ? transformToUpperCase(that.getFilter('unitSelector')) : that.chartSettings?.valuePrefix ? that.chartSettings.valuePrefix : 'Rate'
  let unitFixed = unitPrefix === 'Count' ? 'Number' : unitPrefix
  return unitFixed
}

// Calls specific separators for our Title use case.
function formatTitleString (string) {
  string = transformToUpperCase(string, ['-', '/', ' ', ',', ':'])
  string = string.replaceAll('MidWest', 'Midwest')
  string = string.replaceAll('Msa', 'MSA')
  return string
}

// --- Reference Line Titles --- //
// Multi Year Characteristic: Reference line formatting
export function getMultiYearCharRefNameCat (that) {
  let refName = getMultiYearCharRefNameBase(that) + ', ' + that.getFilter('categoryDesc2') + ': ' + that.getFilter('levelDesc2')
  return transformToSentenceCase(refName)
}

export function getMultiYearCharRefNameBase (that) {
  let refPrefix = that.getFilter('levelDesc2') === 'All' ? 'All ' : ''
  let refName = refPrefix + that.getFilter('crimeType').replace('victimization', '') + ' Victimizations'
  return transformToSentenceCase(refName)
}

// Single Year Characteristic: Reference Line formatting
export function getSingleYearCharMajorRefName (that) {
  let refName = 'All ' + that.getFilter('crimeType') + getCrimeSuffix(that)
  return transformToSentenceCase(refName)
}

export function getSingleYearCharMinorRefNameDualCat (that) {
  let refName = that.getFilter('crimeType') + ' (by ' + that.getFilter('categoryDesc2') + ', ' + 'all ' + that.getFilter('categoryDesc1') + ')'
  return transformToSentenceCase(refName)
}

export function getSingleYearCharMinorRefNameSingleCat (that) {
  let refName = that.getFilter('crimeType') + ' (for all ' + that.getFilter('categoryDesc1') + ')'
  return transformToSentenceCase(refName)
}

function getCrimeSuffix (that) {
  let crimeSuffix = that.getFilter('crimeType') !== 'Violent victimization' && that.getFilter('crimeType') !== 'Property victimization' ? ' victimizations' : 's'
  return crimeSuffix
}

// Single Year Crime Type: Reference Line

export function getSingleYearCrimeMinorRefNameSingleCat (that) {
  let refName = 'Victimizations by Crime Type' + ' (for all ' + that.getFilter('categoryDesc1') + ')'
  return transformToSentenceCase(refName)
}

export function getSingleYearCrimeMinorRefNameDualCat (that) {
  let refName = 'Victimizations by Crime Type' + ' (for all ' + that.getFilter('categoryDesc1') + ', ' + that.getFilter('categoryDesc2') + ': ' + that.getFilter('levelDesc2') + ')'
  return transformToSentenceCase(refName)
}

// --- Reference Line Formatting --- //
// We want sentence case ref line formatting with the following expections - Race and Region levels
export function transformToSentenceCase (str) {
  str = str.toLowerCase()

  // Exceptions: the below list should be uppercase
  var i, j, uppers
  uppers = ['northeast', 'midwest', 'south', 'west', 'white', 'black', 'hispanic', 'msa']
  for (i = 0, j = uppers.length; i < j; i++) {
    str = str.replace(new RegExp(uppers[i], 'g'),
      function (txt) {
        return capitalizeFirstLetter(txt)
      })
  }
  str = capitalizeFirstLetter(str)
  str = str.replaceAll('MidWest', 'Midwest')
  str = str.replaceAll('Msa', 'MSA')
  return str
}

export function getSmallMultUserInstruct (that) {
  let prefix = 'An individual larger chart view of any of these smaller comparison charts can be generated '
  if (that.hs.pageKey.includes('Characteristic')) {
    if (that.getFilter('categoryDesc1') !== 'All') {
      if (that.getFilter('categoryDesc2') === 'All') {
        // comparison(cat1)=!All, filter(cat2) =All
        return prefix + 'within this view by selecting "All" in the ‘Display Settings: COMPARISON CHARACTERISTIC:’ drop menu, and, under ‘Data Filter Settings:’ selecting ‘' + that.getFilter('categoryDesc1') + '’ for FILTER CHARACTERISTIC:’, and the desired ‘' + that.getFilter('categoryDesc1') + '’ category of interest for ‘FILTER CHARACTERISTIC VALUE:’'
      } else {
        // comparison(cat1)=!All, filter(cat2) =!All
        if (that.getFilter('crimeType') === 'Violent victimization' || that.getFilter('crimeType') === 'Personal theft/Larceny') {
          // !All/(Violent/Personal Theft)
          return prefix + 'via Custom Graphics <a href="/multi-year-trends/crimeType">here</a>, by selecting the desired ‘' + that.getFilter('categoryDesc1') + '’ and ‘' + that.getFilter('categoryDesc2') + '’ categories of interest under ‘Data Filter Settings:’, and locating the chart for ‘' + that.getFilter('crimeType') + '’.'
        } else {
          // !All/!(Violent/PersonalTheft)
          return 'Individual larger chart views for this combination of selections cannot currently be generated by the tool.'
        }
      }
    } else {
      // All/Any
      return null
    }
  } else if (that.hs.pageKey.includes('Crime')) {
    if (that.getFilter('categoryDesc2') === 'All') {
      if (that.getFilter('categoryDesc1') === 'All') {
        // 2=All, 1=All
        return prefix + 'via Custom Graphics <a href="/multi-year-trends/characteristic">here</a>, by selecting the crime type of interest under ‘Data Filter Settings: CRIME TYPE’.'
      } else {
        // 2=All, 1=!All
        return prefix + 'via Custom Graphics <a href="/multi-year-trends/characteristic">here</a>, by selecting under ‘Data Filter Settings:’ the crime type of interest under ‘CRIME TYPE’, selecting ‘' + that.getFilter('categoryDesc1') + '’ for FILTER CHARACTERISTIC:’, and ‘' + that.getFilter('levelDesc1') + '’ for ‘FILTER CHARACTERISTIC VALUE:’'
      }
    } else {
      if (that.getFilter('categoryDesc1') === 'All') {
        // 1=!All, 2=All (both all already handled above)
        return prefix + 'via Custom Graphics <a href="/multi-year-trends/characteristic">here</a>, by selecting under ‘Data Filter Settings:’ the crime type of interest under ‘CRIME TYPE’, selecting ‘' + that.getFilter('categoryDesc2') + '’ for ‘FILTER CHARACTERISTIC:’, and ‘' + that.getFilter('levelDesc2') + '’ for ‘FILTER CHARACTERISTIC VALUE:’'
      } else {
        // 1=!All, 2!All
        return 'Individual larger chart views for this combination of selections cannot currently be generated by the tool.'
      }
    }
  } else {
    return 'custom not assigned yet'
  }
}

// Title formatting utility function
function stripAllFromTitle (titleString) {
  // Manage crime type All:All scenarios
  let cleanTitle = titleString.replace('All: All', '')
  cleanTitle = cleanTitle.replace('All: All', '')
  cleanTitle = cleanTitle.replace(' by All by All', '')
  cleanTitle = cleanTitle.replace(' by All', '')

  return cleanTitle
}

// function adjectiveVicType (vicType) {
//  if (vicType === 'person') {
//    return 'personal'
//  } else { return vicType }
// }
/**
 * Loop through chartKeys and return true if chart's data property, hasFlags, is true
 * @param  {Object} that The harness context
 * @param  {chartKeys} array array of chart
 */
export function checkForFlags (that, chartKeys) {
  return chartKeys.some(chartKey => that.getChartData(chartKey) && that.getChartData(chartKey)['hasFlags'])
}
/**
 * Format the subtitle text below a chart
 * @param  {Object} that The harness context
 * @param  {chartKeys} isSmallMultiple optional, if true it will grab the chartIds prop from the harness context
 */
export function formatSubtitle (that, isSmallMultiple = false) {
  const url = window.location.href.split('#')[0]
  let finalText = ''
  let subtitleKeysToAlwaysShow = ['confidenceInterval']
  let unitFlagsPresent = false
  // Add text we always want present
  subtitleKeysToAlwaysShow.forEach(key => {
    finalText += that.subtitle[key].join('<br/>') + '<br/>'
  })
  // Add text for unit flags if they are present in chart
  if (that.filters['unitFlag'] && that.getFilter('unitFlag')) {
    if (isSmallMultiple) {
      unitFlagsPresent = checkForFlags(that, that.chartIds)
    } else {
      unitFlagsPresent = checkForFlags(that, [that.chart.key])
    }

    if (unitFlagsPresent) {
      finalText += that.subtitle['unitFlag'].join('<br/>') + '<br/>'
    }
  }
  // Add text for race
  if ((that.filters.categoryDesc1 && that.getFilter('categoryDesc1') === 'Race/Hispanic origin') || (that.filters.categoryDesc2 && that.getFilter('categoryDesc2') === 'Race/Hispanic origin')) {
    finalText += that.subtitle['raceNote'].join('<br/>') + '<br/>'
  }
  // Add note for hh income (use for all cases? or only whre unknown appears?)
  if ((that.filters.categoryDesc1 && that.getFilter('categoryDesc1') === 'Household income') || (that.filters.categoryDesc2 && that.getFilter('categoryDesc2') === 'Household income')) {
    finalText += that.subtitle['householdIncomeNote'].join('<br/>') + '<br/>'
  }
  // Add for source note
  if (isSmallMultiple) {
    if (that.chartIds.length && ((that.filters.categoryDesc1 && that.getFilter('categoryDesc1') === 'Age') || (that.filters.categoryDesc2 && that.getFilter('categoryDesc2') === 'Age'))) {
      // this could get refined to only show fir the 12-17 (both 12-14 and 15-17 filters) age group for catB value
      finalText += that.subtitle.exception2006 + '<br/>' + that.subtitle.gen2016Note + '<br/>' + that.subtitle.age2016Note + '<br>' + that.subtitle.sourceNote + formatMinAndMaxYearsFromChart(that, that.chartIds[0]) + '. ' + url
    // normal small multiple note
    } else if (that.chartIds.length) {
      finalText += that.subtitle.exception2006 + '<br/>' + that.subtitle.gen2016Note + '<br/>' + that.subtitle.sourceNote + formatMinAndMaxYearsFromChart(that, that.chartIds[0]) + '. ' + url
    } else {
      finalText += that.subtitle.exception2006 + '<br/>' + that.subtitle.gen2016Note + '<br/>' + that.subtitle.sourceNote + '. ' + url
    }
  // for full single area charts, not small mult, but have no year filter
  // or for quick graphics??
  } else if (that.filters.year === undefined) {
    // full area with age selection
    if ((that.filters.categoryDesc1 && that.getFilter('categoryDesc1') === 'Age') || (that.filters.categoryDesc2 && that.getFilter('categoryDesc2') === 'Age')) {
      finalText += that.subtitle.exception2006 + '<br/>' + that.subtitle.gen2016Note + '<br/>' + that.subtitle.age2016Note + '<br/>' + that.subtitle.sourceNote + formatMinAndMaxYearsFromChart(that, that.chart.key) + '. ' + url
    // this also catches lollipop in quick graphics bc there is no filter so no year filter
    } else if (that.title === 'Percent of violent victimizations by victim-offender relationship') {
      finalText += that.subtitle.sourceNote + formatMinAndMaxYearsFromChart(that, that.chart.key) + '. ' + url
    } else if (that.waypoint === 'quickGraphics' && that.chart.key.includes('Reason')) {
      // reason for reporting notes
      if (that.chart.key.includes('ReasonforReportingToPolice')) {
        finalText += that.subtitle.reportingReasonNote + '<br/>' + that.subtitle.sourceNote + formatMinAndMaxYearsFromChart(that, that.chart.key) + '. ' + url
      } else if (that.chart.key.includes('ReasonforNotReportingToPolice')) {
        finalText += that.subtitle.notReportingReasonNote + '<br/>' + that.subtitle.sourceNote + formatMinAndMaxYearsFromChart(that, that.chart.key) + '. ' + url
      }
    } else {
      // normal full area-
      finalText += that.subtitle.exception2006 + '<br/>' + that.subtitle.gen2016Note + '<br/>' + that.subtitle.sourceNote + formatMinAndMaxYearsFromChart(that, that.chart.key) + '. ' + url
    }
    // for single year. Not necessary for 2year charts since comparison is disabled
  } else if (that.filters.year && that.getFilter('year') === 2006) {
    finalText += that.subtitle.exception2006 + '<br/>' + that.subtitle.sourceNote + formatMinAndMaxYearsFromChart(that, that.chart.key) + '. ' + url
    // for single year or 2 year
  } else if ((that.filters.year && that.getFilter('year') === 2016) || (that.filters.year2 && that.getFilter('year2') === 2016)) {
    if ((that.filters.categoryDesc1 && that.getFilter('categoryDesc1') === 'Age') || (that.filters.categoryDesc2 && that.getFilter('categoryDesc2') === 'Age')) {
      finalText += that.subtitle.gen2016Note + '<br/>' + that.subtitle.age2016Note + '<br/>' + that.subtitle.sourceNote + formatMinAndMaxYearsFromChart(that, that.chart.key) + '. ' + url
    } else {
      finalText += that.subtitle.gen2016Note + '<br/>' + that.subtitle.sourceNote + formatMinAndMaxYearsFromChart(that, that.chart.key) + '. ' + url
    }
  // single or 2 year
  } else if (that.filters.year) {
    finalText += that.subtitle.sourceNote + formatMinAndMaxYearsFromChart(that, that.chart.key) + '. ' + url
  } else {
    finalText += that.subtitle.exception2006 + '<br/>' + that.subtitle.sourceNote + formatMinAndMaxYearsFromChart(that) + '. ' + url
  }
  return finalText
}

export function formatFilename (text) {
  let titleSplit = text.split('<br/>')
  let formattedFilename = titleSplit[0]
  let wrapper = document.createElement('div')
  wrapper.innerHTML = titleSplit.slice(1).join('<br/>')
  let elements = wrapper.getElementsByTagName('span')
  const arr = Array.from(elements);
  arr.forEach(elem => {
    if (elem.innerHTML) {
      formattedFilename += '--' + elem.innerHTML
    }
  })
  return formattedFilename
}
