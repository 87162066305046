<template>
  <div>
    <notEligible
      :chart="chart"
      v-if="(this.getChartData(this.chart.key) === false) || (this.getChartData(this.chart.key) && this.getChartData(this.chart.key)['giveAlert'] && (this.getChartData(this.chart.key)['giveAlert'] === true))"
    />
    <highcharts
      v-else-if="vis"
      v-show="this.getChartData(this.chart.key) !== false"
      :options="chartOptions"

      ref="highchartsChart"
      :callback="afterChartCreated"
    />
  </div>
</template>

<script>
import chartSettings from './chartSettings'
import plotOptions from './plotOptions'
import notEligible from '../notEligible'
import { axisLabelFormatter, pluralsFix, labelLowerCase } from '@/utils'
import { transformToSentenceCase } from '@/chartTitleFormatter'

// import * as d3 from 'd3'
export default {
  name: 'Dumbbell',
  data () {
    return {
      vis: true,
      subscription: null
    }
  },
  mixins: [chartSettings, plotOptions],
  components: { notEligible },
  computed: {
    chartOptions () {
      let charA = this.hs.filters['categoryDesc1'] ? this.getFilter('categoryDesc1') : ''
      let charB = this.hs.filters['categoryDesc2'] ? this.getFilter('categoryDesc2') : ''
      let crimeSelected = this.hs.filters['crimeType'] ? this.getFilter('crimeType') : ''
      let isCrimeType = this.page.key === 'Year-to-Year Comparison Crime Type'
      let allEmph = charA !== 'All' && charB !== 'All' ? 'all ' : ''
      return {
        chart: {
          type: 'dumbbell',
          inverted: true,
          spacingBottom: 30,
          marginRight: 50,
          // margin top for floating title to keep plot area heights correct with text wrap
          // marginTop: 70,
          className: 'dumbbell',
          height: this.getChartData(this.chart.key) ? this.getChartData(this.chart.key).height : 200,
          events: {
            render: function (e) {
              // for each dumbbell series, remove the lower graphic and draw a circle in its place
              this.series.forEach(series => {
                if (series.type === 'dumbbell') {
                  // bring to front hack to overlap data label dots?
                  // series.parentElement.appendChild(series)
                  series.points.forEach(point => {
                    let newOpacity = point.color === 'rgba(255, 255, 255, 0)' || point.lowColor === 'rgba(255, 255, 255, 0)' ? 0 : 1
                    let moveXtoMove = point.dataLabels[1].translateX - 24 + 'px'
                    let moveY = (point.dataLabels[1].translateY + 1) + 'px'
                    point.dataLabel.css({
                      color: point.color,
                      opacity: newOpacity,
                      transform: 'translate(' + moveXtoMove + ',' + moveY + ')',
                      textAnchor: 'middle'
                    })
                    // potential fix for z-index issue: http://bl.ocks.org/eesur/4e0a69d57d3bfc8a82c2
                    point.lowerGraphic.hide()
                  })
                }
              })
            }
          }
        },
        credits: {
          enabled: false
        },
        title: {
          text: this.getChartData(this.chart.key) ? this.getChartData(this.chart.key)['title'] : 'Single year title',
          style: {
            fontWeight: 500,
            fontSize: '1.5rem'
          }
          // verticalAlign: 'top'
        },
        subtitle: {
          text: this.subtitle ? this.formattedSubtitle : null,
          align: 'left',
          verticalAlign: 'bottom',
          style: {
            fontSize: 11
          },
          useHTML: true,
          // floating: true,
          y: 10,
          lineWidth: 0,
          lineColor: 'rgba(255, 255, 255, 0)'
        },
        xAxis: [{
          type: 'category',
          categories: this.categories,
          offset: this.getChartData(this.chart.key) ? this.getChartData(this.chart.key).minorwidth : 80,
          lineColor: 'rgba(255, 255, 255, 0)',
          lineWidth: 0,
          minorGridLineWidth: 0,
          minorTickLength: 0,
          tickLength: 0,
          labels: {
            formatter: function () {
              if (this.value === 'All' && charA !== 'All') {
                return ''
              } else {
                return transformToSentenceCase(this.value)
              }
            },
            style: {
              fontSize: '14px',
              fontWeight: 'bold',
              color: '#272727'
            }
          }
        }],
        yAxis: [
          {
            title: {
              //              text: (this.getChartData(this.chart.key) && this.getChartData(this.chart.key).estimateType === 'Percent' && isCrimeType === false) ? this.chartSettings.yAxisTitleText.replace('<br/>', ' ') + allEmph + pluralsFix(crimeSelected) : this.chartSettings.yAxisTitleText.replace('<br/>', ' ')
              //
              text: this.calculateYAxisText(allEmph, crimeSelected, isCrimeType)
            },
            labels: {
              style: {
                fontSize: '12px',
                textOverflow: 'none'
              },
              formatter: function () {
                return axisLabelFormatter(this, this.chart.series[0].userOptions.estType)
              }
            },
            max: this.getChartData(this.chart.key) ? this.getChartData(this.chart.key)['max'] : null,
            min: 0
            // height: 50
          },
          {
            title: {
              text: ''
            },
            max: 3,
            width: 2,
            left: '-1%',
            visible: false,
            labels: {
              enabled: false
            },
            min: 0
          },
          {
            // top axis for tall charts
            title: {
              //              text: (this.getChartData(this.chart.key) && this.getChartData(this.chart.key).estimateType === 'Percent' && isCrimeType === false) ? this.chartSettings.yAxisTitleText.replace('<br/>', ' ') + allEmph + crimeSelected.toLowerCase() : this.chartSettings.yAxisTitleText.replace('<br/>', ' ')

              text: this.calculateYAxisText(allEmph, crimeSelected, isCrimeType)
            },
            labels: {
              style: {
                fontSize: '12px'
              },
              formatter: function () {
                return axisLabelFormatter(this, this.chart.series[0].userOptions.estType)
              }
            },
            linkedTo: 0,
            opposite: true,
            visible: this.getChartData(this.chart.key) && this.getChartData(this.chart.key).height > 900
          }
        ],
        series: this.series,
        legend: {
          enabled: true,
          itemStyle: {
            textAlign: 'right'
          },
          labelFormatter: function () {
            let flagName = this.name === 'Count flag' ? 'Number flag' : this.name
            if (flagName === 'Percent flag' || flagName === 'Rate flag' || flagName === 'Number flag') {
              //  change made to use 'estimate flag' not the specific unit
              return '<span style="fill:#ce4646">' + '✱/▲ ' + '</span>' + 'Estimate flag'
            } else if (flagName === '95% C.I.') {
              return flagName + ': <span style="font-size: 1.3rem; fill: rgba(185, 85, 0, 0.13)"> ■</span>= ' + this.chart.series[0].options.custom[0][0]['year'] + ', <span style="width:3rem"></span> ' + '<span style="font-size: 1.3rem; fill: rgba(0, 0, 0, 0.12)"> ■<span>= ' + this.chart.series[0].options.custom[0][1]['year']
            } else if (this.chart.series[0].options.className.includes('estimates')) {
              return this.chart.series[0].options.custom[0][0]['year'] + '= <span>● ' + ', ' + this.chart.series[0].options.custom[0][1]['year'] + '=  ▶ <i>(increase)</i> ◀ <i>(decrease)</i> ● <i>(no change)</i> ' + '</span>'
            } else {
              return flagName
            }
          },
          // symbolHeight: flagName === '95% C.I.' ? 0 : undefined,
          // symbolWidth: flagName === '95% C.I.' ? 0 : undefined,
          symbolPadding: 0.00001,
          symbolWidth: 0.00001,
          symbolRadius: 0.00001,
          symbolHeight: 0.00001
        },
        plotOptions: {
          series: {
            connectorWidth: 3.5,
            groupPadding: this.categories && this.categories.length > 1 ? 0.13 : 0,
            lowColor: 'rgba(255, 255, 255, 0)',
            events: {
              legendItemClick: function () {
                return false
              }
            },
            dataLabels: {
              style: {
                color: this.color + ' !important',
                textOutline: 'white'
              },
              z: 2,
              align: 'center'
            }
          } // end series

        }, // end plot options
        exporting: {
          enabled: this.exportingEnabled
        },
        tooltip: {
          useHTML: true,
          folowPointer: true,
          backgroundColor: '#fff',
          borderColor: '#b9b9b9',
          valueDecimals: 2,
          valueSuffix: this.chartSettings.valueSuffix,
          shared: false,
          formatter: function () {
            let unit = this.series.options.estType
            let unitLabel = unit.charAt(0).toUpperCase() + unit.slice(1)
            unitLabel = unitLabel === 'Count' ? 'Number' : unitLabel
            if (this.series.options.estType === 'percent') {
              let allEmph = charA !== 'All' && charB !== 'All' ? 'all ' : ''
              if (isCrimeType === false) {
                unitLabel = 'Percent of ' + pluralsFix(crimeSelected) + (charB === 'All' ? '' : ', ' + labelLowerCase(charB) + '= ' + this.point.category)
              } else if (isCrimeType === true) {
                if (this.point.category !== 'All') { unitLabel = 'Percent of ' + allEmph + pluralsFix(this.point.category) }
              }
            }
            let ptIn = this.point.index
            let charBfill = isCrimeType ? 'Crime type' : charB
            let formattedSeriesName = (this.x === 'All' ? '' : charBfill + ': <b>' + transformToSentenceCase(this.x) + '</b><br/>') + (this.series.name === 'All' ? '' : charA + ': <b>' + this.series.name + '</b>')
            if (formattedSeriesName.endsWith(': ')) {
              formattedSeriesName = formattedSeriesName.replace(': ', '')
            }
            const point = this.series.options.custom[ptIn]
            if (unit === 'count') {
              return formattedSeriesName + '<table><tr><th></th><th>' +
                point[0].year + '</th><th>' +
                point[1].year + '</th></tr><tr><td>' +
                unitLabel + ': </td><td><b>' +
                (isNaN(point[0][unit]) ? 'N/A' : Number(point[0][unit]).toLocaleString()) + '</b></td><td><b>' +
                (isNaN(point[1][unit]) ? 'N/A' : Number(point[1][unit]).toLocaleString()) + '</b></td></tr><tr><td>95% C.I.:</td><td><b>' +
                (isNaN(point[0][unit + 'LB']) ? 'N/A' : Number(point[0][unit + 'LB']).toLocaleString()) + '-' +
                (isNaN(point[0][unit + 'UB']) ? 'N/A' : Number(point[0][unit + 'UB']).toLocaleString()) + '</b></td><td><b>' +
                (isNaN(point[1][unit + 'LB']) ? 'N/A' : Number(point[1][unit + 'LB']).toLocaleString()) + '-' +
                (isNaN(point[1][unit + 'UB']) ? 'N/A' : Number(point[1][unit + 'UB']).toLocaleString()) + '</b></td></tr><tr><td>S.E.:</td><td><b>' +
                (isNaN(point[0][unit + 'SE']) ? 'N/A' : Number(point[0][unit + 'SE']).toLocaleString()) + '</b></td><td><b>' +
                (isNaN(point[1][unit + 'SE']) ? 'N/A' : Number(point[1][unit + 'SE']).toLocaleString()) + '</b></td></tr></table>'
            } else {
              return formattedSeriesName + '<table><tr><th></th><th>' +
                point[0].year + '</th><th>' +
                point[1].year + '</th></tr><tr><td>' +
                unitLabel + ': </td><td><b>' +
                (isNaN(point[0][unit]) ? 'N/A' : Number(point[0][unit]).toFixed(1).toLocaleString()) + '</b></td><td><b>' +
                (isNaN(point[1][unit]) ? 'N/A' : Number(point[1][unit]).toFixed(1).toLocaleString()) + '</b></td></tr><tr><td>95% C.I.:</td><td><b>' +
                (isNaN(point[0][unit + 'LB']) ? 'N/A' : Number(point[0][unit + 'LB']).toFixed(2).toLocaleString()) + '-' +
                (isNaN(point[0][unit + 'UB']) ? 'N/A' : Number(point[0][unit + 'UB']).toFixed(2).toLocaleString()) + '</b></td><td><b>' +
                (isNaN(point[1][unit + 'LB']) ? 'N/A' : Number(point[1][unit + 'LB']).toFixed(2).toLocaleString()) + '-' +
                (isNaN(point[1][unit + 'UB']) ? 'N/A' : Number(point[1][unit + 'UB']).toFixed(2).toLocaleString()) + '</b></td></tr><tr><td>S.E.:</td><td><b>' +
                (isNaN(point[0][unit + 'SE']) ? 'N/A' : Number(point[0][unit + 'SE']).toFixed(2).toLocaleString()) + '</b></td><td><b>' +
                (isNaN(point[1][unit + 'SE']) ? 'N/A' : Number(point[1][unit + 'SE']).toFixed(2).toLocaleString()) + '</b></td></tr></table>'
            }
          }
        },
        dataLabels: {
          format: '{point.name}',
          z: 2
        }
      }
    },
    categories () {
      if (this.getChartData(this.chart.key)) {
        return this.getChartData(this.chart.key).categories || this.getChartData(this.chart.key).reduce((acc, datum) => { acc.push(datum[0]); return acc }, [])
      }
      return null
    },
    series () {
      if (this.getChartData(this.chart.key)) {
        return this.getChartData(this.chart.key).series || [{ name: '', data: this.getChartData(this.chart.key) }]
      }
      return null
    }
  },
  methods: {
    getTitle: function () {
      let titleValue = null
      if (this.getChartData(this.chart.key)) {
        titleValue = this.hs.pageKey === 'quickGraphics' ? this.title + ', ' + this.getChartData(this.chart.key).minYear : this.getChartData(this.chart.key)['title']
      } else {
        titleValue = this.title
      }
      return titleValue
    },
    calculateYAxisText: function (allEmph, crimeSelected, isCrimeType) {
      let yAxisText
      if (this.getChartData(this.chart.key) && this.getChartData(this.chart.key).estimateType === 'Percent' && isCrimeType === false) {
        if (this.hs.pageKey === 'quickGraphics' && this.getChartData(this.chart.key).series[0].custom[0].categoryDesc2 && this.getChartData(this.chart.key).series[0].custom[0].categoryDesc2 !== ('All')) {
          yAxisText = this.chartSettings.yAxisTitleText
        } else {
          // revison for row percents
          yAxisText = this.chartSettings.yAxisTitleText.replace('<br/>', ' ') + pluralsFix(crimeSelected) + (this.getFilter('categoryDesc2') !== 'All' ? ', per ' + labelLowerCase(this.getFilter('categoryDesc2')) : '')
        }
      } else {
        yAxisText = this.chartSettings.yAxisTitleText.replace('<br/>', ' ')
      }
      return yAxisText
    }
  },
  mounted () {
    // this functionality removes and redraws the visualization to deal with the update issues around markers
    this.subscription = this.$store.subscribeAction({
      before: function (action, state) {
        if (this.chart && action.type === this.getChartDataActionString(this.chart.key)) {
          this.vis = false
        }
      }.bind(this),

      after: function (action, state) {
        if (action.type === this.getChartDataActionString(this.chart.key)) {
          this.vis = true
        }
      }.bind(this)
    })
  },
  beforeDestroy () {
    this.subscription()
  }
}
</script>
